import { Injectable,Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BimStore {
    // 切换赛事类别
    public bimInfoStore: BehaviorSubject<any> = new BehaviorSubject(null);
  
    constructor() {

    }
}