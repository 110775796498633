export class BimCertRegedist {
    constructor(
      public  id?: string,
      public  userId?: string,
      public  name?: string,
      public  tel?: string,
      public  sex?: string,
      public  namePinyin?: string,
      public  major?: string,
      public  idCard?: string,
      public  email?: string,
      public  birthday?: Date,
      public  education?: string,
      public  qq?: string,
      public  postalCode?: string,
      public  registrationType?: string,
      public  price?: number,
      public  province?: string,
      public  city?: string,
      public region?:string,
      public  location?: string,
      public  schoolName?: string,
      public  address?: string,
      public  occupation?: string,
      public  orderSn?: string,
      public  orderStatus?: number,
      public  total?: number,
      public  dispatchedStatus?: string,
      public  invoiceStatus?: string,
      public  invoiceReceivingName?: string,
      public  invoiceReceivingTel?: string,
      public  invoiceReceivingAddress?: string,
      public  invoiceType?: number,
      public  invoiceTitle?: string,
      public  taxpayerSn?: string,
      public  invoiceAddress?: string,
      public  invoiceTel?: string,
      public  bankAccount?: string,
      public  bankName?: string,
      public  paymentType?: number,
      public  payResult?: string,
      public  payTime?: Date,
      public  orderNum?: number,
      public  remark?: string,
      public  createTime?: Date,
      public  creator?: string,
      public  updateTime?: Date,
      public  updator?: string,
    ) {}
  }