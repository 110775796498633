import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { CacheService } from '@delon/cache';
import { FileUploadService } from '@shared/components/fileUpload/fileUpload.service';
import { HttpAddressService } from '@shared/session/http-address.service';
import { NzMessageService } from 'ng-zorro-antd';
import { isNullOrUndefined } from 'util';
import { BimInfoSchoolsService } from '../school-information-manage/bim-info-schools.service';
import { BimSchoolsService } from '../school-register/bim-school.service';

@Component({
  selector: 'app-competition-team-info',
  templateUrl: './competition-team-info.component.html',
  styleUrls: ['./competition-team-info.component.less']
})
export class CompetitionTeamInfoComponent implements OnInit {

  public infoEventId = null;
  public bimTeamId = this.cacheService.get('bimTeamId')['value'];
  public bimTeamInfo = null;
  public bimTeamFile = null;

  public bimSchoolId = null;
  public bimSchool = null;

  public bimInfo = this.cacheService.get('bimInfo')['value'];

  constructor(
    private router: Router,
    private msg: NzMessageService,
    private routeInfo: ActivatedRoute,
    private cacheService: CacheService,
    private bimSchoolsService: BimSchoolsService,
    private fileUploadService: FileUploadService,
    private httpAddressService: HttpAddressService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  ) {
    this.infoEventId = this.routeInfo.snapshot.params['bimEventId'];
  }

  ngOnInit() {
    if (isNullOrUndefined(this.bimInfo)) {
      this.router.navigate(['/enroll/contestSelect']);
      return;
    }
    this.initTeamData();
  }

  editSchoolInfo() {
    this.router.navigate(['/enroll/team/competitionRegistration' + `/${this.infoEventId}`], {
      queryParams: {
        isEdit: true,
      },
    });
  }

  initTeamData() {
    if (isNullOrUndefined(this.bimTeamId)) {
      this.msg.info('无此团队信息，请重新登录');
    } else {
      this.bimSchoolsService.getBimTeamById(this.bimTeamId).subscribe(res => {
        this.bimTeamInfo = res;
      });

      this.fileUploadService.query({
        'fileFk.equals': this.bimTeamId,
        'extField1.equals': 'studentRegiste'
      }).subscribe(res => {
        if (res && res.body.length > 0) {
          this.bimTeamFile = res.body[0];
        }
      });
    }
  }

  getBimTeams(list, num) {
    if (!isNullOrUndefined(list) && list.length > num) {
      return list[num].name;
    } else {
      return null;
    }
  }


  downFile(fileId) {
    const a = document.createElement('a');
    const url =  this.httpAddressService.apiGateway + '/thsadmin/api/sys-files/download/' + fileId;
    a.href = url;
    a.click();
  }
}
