import {Injectable} from '@angular/core';
import * as $ from 'jquery';
/**
 *
 * 服务接口地址
 * @export
 * @class HttpAddressService
 */
@Injectable({providedIn: 'root'})
export class  HttpAddressService {

    /**
     *
     * 网关
     * @private
     * @type {string}
     * @memberof HttpAddressService
     */
    private _apiGateway: string;


    /**
     * 文件专用网关
     * @private
     * @type {string}
     * @memberof HttpAddressService
     */
    private _fileGateway: string;

    /**
     *
     * 认证
     * @private
     * @type {string}
     * @memberof HttpAddressService
     */
    private _authServe: string;

    /**
     * 系统管理配置
     */
    private _systemServe: string;

    /**
     * 教育服务配置
     */
    private _eduServe: string;

    /**
     * BIM大赛服务配置
     */
    private _bimServe: string;

    /**
     * 教育服务配置
     */
    private _webServe: string;

    /**
     * BIM大赛服务配置
     */
    private _bimWebServe: string;

    /**
     * 文件服务配置
     */
    private _fileServer: string;

    /**
     * 图片浏览
     */
    private _imgServer: string;

    /**
     * 工作流配置
     */
    private _workflowServe: string;

    private _OssServe: any;
    constructor() {

    }
    /**
     *
     * 下载配置文件进行请求地址初始化
     * @returns
     * @memberof HttpAddressService
     */
    getApplicationConfig() {
        const thisClone = this;
        return new Promise((_resolve, _reject) => {
            $.ajax({
                url: '/assets/appconfig.json',
                method: 'GET'
            }).done(result => {
                thisClone._authServe = result.AuthServe;
                thisClone._apiGateway = result.ApiGateway;
                thisClone._fileGateway = result.FileGateway;
                thisClone._systemServe = result.SystemServe;
                thisClone._eduServe = result.EduServe;
                thisClone._bimServe = result.BimServe;
                thisClone._webServe = result.WebServe;
                thisClone._bimWebServe = result.BimWebServe;
                thisClone._fileServer = result.FileServer;
                thisClone._workflowServe = result.WorkflowServe;
                thisClone._imgServer = result.ApiGateway + result.SystemServe;
                thisClone._OssServe = result.OssServe;
                _resolve();
            });
        });
    }
    /**
     *
     * 网关服务地址
     * @readonly
     * @type {string}
     * @memberof HttpAddressService
     */
    get  apiGateway(): string {
        return this._apiGateway;
    }
    /**
     *
     * 文件网关服务地址
     * @readonly
     * @type {string}
     * @memberof HttpAddressService
     */
    get  fileGateway(): string {
        return this._fileGateway;
    }
    /**
     *
     * 认证服务地址
     * @readonly
     * @type {string}
     * @memberof HttpAddressService
     */
    get  authServe(): string {
        return this._authServe;
    }

    /**
     * 获取系统服务信息
     * @returns {any}
     */
    get systemServe(): any {
      return this._systemServe;
    }

    /**
     * 获取图片服务地址
     * @returns {any}
     */
    get imgServe(): any {
        return this._imgServer;
    }

    /**
     * 获取教育服务信息
     */
    get EduServe(): any {
        return this._eduServe;
    }

    /**
     * 获取BIM大赛服务信息
     */
    get BimServe(): any {
    return this._bimServe;
    }

    /**
     * 获取教育门户端服务信息
     */
    get EduWebServe(): any {
        return this._webServe;
    }

    /**
     * 获取BIM大赛门户端服务信息
     */
    get BimWebServe(): any {
        return this._bimWebServe;
    }

    /**
     * 获取文件服务信息
     */
    get FileServer(): any {
        return this._fileServer;
    }

    /**
     *
     *获取工作流服务地址
    * @readonly
    * @type {*}
    * @memberof HttpAddressService
    */
    get workflowServe(): any {
        return this._workflowServe;
    }

    /**
     *
     * 获取OSS服务地址
     * @readonly
     * @type {*}
     * @memberof HttpAddressService
     */
    get ossServe(): any {
        return this._OssServe;
    }

}
