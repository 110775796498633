
import { Injectable } from '@angular/core';
import { ThsBaseService } from '@shared/components/thsBaseService';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BimScoringRuleService extends ThsBaseService<any>  {

  public bimScoringRuleItemUrl = '';
  public bimWorkCoreUrl = '';
  constructor(
    public http: HttpClient, httpAddressService: HttpAddressService) {
    super(http, httpAddressService.BimServe + '/bim-scoring-rules');
    this.bimScoringRuleItemUrl = httpAddressService.BimServe + '/bim-scoring-rule-items';
    this.bimWorkCoreUrl = httpAddressService.BimServe + '/bim-work-cores';
  }

  getRuleItemByWorkId(param: any) {
    const url =  `${this.bimScoringRuleItemUrl}/getRuleItemByWorkId`;
    return this.http.get<any>(url, {params: param ,  observe: 'response'});
  }

  saveWorkCores(param: any) {
    const url =  `${this.bimWorkCoreUrl}/saveWorkCores`;
    return this.http.post<any>(url, param, { observe: 'response' });
  }

  // 获取大赛评分信息
  getBimWorkCores(queryParams: any) {
    return this.http.get<any>(this.bimWorkCoreUrl, {params: queryParams ,  observe: 'response'})
    .pipe(map((res: HttpResponse<any>) => this.convertDateArrayFromServer(res)));
  }

  getWorkAdvisorsData(queryParams) {
    return this.http.get<any>(`${this.bimWorkCoreUrl}/getWorkAdvisorsData`, {params: queryParams ,  observe: 'response'})
    .pipe(map((res: HttpResponse<any>) => this.convertDateArrayFromServer(res)));
  }
}
