import {
  Component,
  ViewChild,
  Inject,
  OnInit,
  Input,
  ElementRef,
  Injector,
} from '@angular/core';
import { SettingsService } from '@delon/theme';
import { ModalHelper } from '@delon/theme';
import { UserLoginComponent } from '../../routes/passport/login/login.component';
import { TokenService, DA_SERVICE_TOKEN } from '@delon/auth';
import { CacheService } from '@delon/cache';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterLinkActive,
} from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd';
import { NewsService } from 'app/routes/admin/teacher/news/news.service';

@Component({
  selector: 'layout-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less'],
})
export class HeaderComponent implements OnInit {
  @Input() isVisiteAdmin: boolean;
  @Input() isVisiteRegister: boolean;

  searchToggleStatus: boolean;
  messageNum = '0';
  isLogin = false;
  isTeacher = false;
  isStudent = false;
  index = '1';
  form: FormGroup;
  dateNum: number;
  constructor(
    fb: FormBuilder,
    private injector: Injector,
    private routerInfo: ActivatedRoute,
    private router: Router,
    private msg: NzMessageService,
    private elementRef: ElementRef,
    public settings: SettingsService,
    private cacheService: CacheService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: TokenService,
    private modalHelper: ModalHelper,
    private newsService: NewsService,
  ) {
    this.form = fb.group({
      txtSearch: [null],
    });

    router.events.subscribe(evt => {
      if (evt instanceof NavigationEnd) {
        const token = this.tokenService.get().token;
        if (token) {
          const entityType = this.tokenService.get().entity_type;
          this.isTeacher = entityType === '1';
        }
      }
    });
  }

  ngOnInit() {
    //
    //  if (this.cacheService.getNone('homeIndex') == null){
    //   this.elementRef.nativeElement.querySelector('#homeIndex').click();
    //   this.cacheService.set('homeIndex', true);
    //  }
    this.countNews();
    this.getSelectIndex();
    this.initIsLogin();
    // const _this_ = this;
    // setInterval(function() {
    //   console.log(new Date());
    //   console.log('每隔60s获取新的消息');
    //   _this_.countNews();
    // }, 1000 * 60 );
  }

  public countNews() {
    const token = this.tokenService.get().token;
    if (token) {
      this.newsService.countNews('0').subscribe(res => {
        const result = res;
        if (result && result.success && result.success === true) {
          this.messageNum = result.data + '';
        }
      });
    }
  }

  getSelectIndex() {
    const uri = this.router.url;
    if (uri.indexOf('/home') !== -1) {
      this.index = '1';//首页
      return;
    }
    if (uri.indexOf('/course') !== -1) {
      this.index = '2';//在线教育
      return;
    }
    if (uri.indexOf('/certification') !== -1) {
      this.index = '3';//BIM认证
      return;
    }
    if (uri.indexOf('/resource') !== -1) {
      this.index = '4';//资源
      return;
    }
    if (uri.indexOf('/download') !== -1) {
      this.index = '5';//软件下载
      return;
    }
    if (uri.indexOf('/enroll') !== -1) {
      this.index = '6';//赛事活动
      return;
    }
  }

  initIsLogin() {
    const token = this.tokenService.get().token;
    if (token) {
      // 登录时间戳
      const time = this.tokenService.get().time;
      // 当前时间戳
      const curTime = new Date().getTime();
      if (curTime > time + 7 * 24 * 60 * 60 * 100) {
        this.isLogin = false;
        this.tokenService.clear();
        return;
      }
      this.isLogin = true;
      const entityType = this.tokenService.get().entity_type;
      if (entityType === '1') {
        this.isTeacher = true;
      }else{
        this.isTeacher = false;
      }
      if (entityType === '0' || entityType === '2') {
        this.isStudent = true;
      }else{
        this.isStudent = false;
      }
    } else {
      this.isLogin = false;
      this.isTeacher = false;
      this.isStudent = false;
    }
  }

  loginOut() {
    this.tokenService.clear();
    this.cacheService.remove('bimInfo');
    this.initIsLogin();
    
    //删除头部菜单选中样式
    const lis = $('#ulMenu').find('*').filter('.ant-menu-item-selected');
    if(lis && lis.length > 0){
      for(let i = 0; i < lis.length;i ++){
        $(lis[i]).removeClass('ant-menu-item-selected');
      }
    }
    this.index = '1';
  }

  toggleCollapsedSideabar() {
    this.settings.setLayout('collapsed', !this.settings.layout.collapsed);
  }

  searchToggleChange() {
    this.searchToggleStatus = !this.searchToggleStatus;
  }

  openLoginModal() {
    this.modalHelper
      .open(UserLoginComponent, {}, 482, { nzClassName: 'login-modal',nzMaskClosable:false })
      .subscribe(() => {
        //  let token = this.tokenService.get();
        this.initIsLogin();
      });
  }
  get txtSearch() {
    return this.form.controls.txtSearch;
  }
  // 搜索
  search() {
    this.injector
      .get(Router)
      .navigateByUrl('/routes/home/search?content=' + this.txtSearch.value);
  }

  // 打开我的消息窗口
  openMyMsg() {
    if (!this.isLogin) {
      this.openLoginModal();
      return;
    }
    let url = '';

    if (this.isTeacher) {
      url = 'teacher';
    }

    if (this.isStudent) {
      url = 'student';
    }

    this.injector.get(Router).navigateByUrl('/admin/' + url + '/news');
  }
}
