import { Injectable } from '@angular/core';
import { ThsBaseService } from '@shared/components/thsBaseService';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';

@Injectable({ providedIn: 'root' })
export class BimSoftDownloadDetailsService extends ThsBaseService<any> {
  private bimSoftDownloadDetailUrl = '';

  constructor(
    public http: HttpClient, httpAddressService: HttpAddressService) {
    super(http, httpAddressService.BimServe + '/bim-soft-download-details');
    this.bimSoftDownloadDetailUrl = httpAddressService.BimWebServe + '/bim-soft-download-details';
  }
  
  getBimSoftDownloadDetail(queryParams?: any) {
    this.resourceUrl = this.bimSoftDownloadDetailUrl;
    return this.query(queryParams);
  }
}
