import { Component, OnInit } from '@angular/core';
import {NzMessageService, NzModalRef} from "ng-zorro-antd";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BimReceiptInstrictor} from "../bim-receipt-info.model";
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-teacher-edit',
  templateUrl: './teacher-edit.component.html',
  styles: []
})
export class TeacherEditComponent implements OnInit {

  validateForm: FormGroup;
  record: BimReceiptInstrictor;
  isAdd = true;

  constructor(
    private nzModalRef: NzModalRef,
    private fb: FormBuilder,
    private msg: NzMessageService,
  ) { }

  ngOnInit() {
    if (this.isAdd) {
      this.validateForm = this.fb.group({
        email: [null, [Validators.required, Validators.email]],
        name: [null, [Validators.required, Validators.maxLength(50)]],
        sex: [null, [Validators.required]],
        // idCard: [null, [Validators.required, Validators.maxLength(18), Validators.minLength(18)]],
        phone: [null, [Validators.required, Validators.pattern(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/)]],
        major: [null, [Validators.required, Validators.maxLength(50)]],
        // qq: [null, [Validators.required]],
        job: [null, [Validators.required, Validators.maxLength(20)]],
        faculty: [null, [Validators.required, Validators.maxLength(50)]],
      })
    } else {
      this.validateForm = this.fb.group({
        email: [this.record.email, [Validators.required, Validators.email]],
        name: [this.record.name, [Validators.required, Validators.maxLength(50)]],
        sex: [isNullOrUndefined(this.record.sex) ? null : Number(this.record.sex), [Validators.required]],
        // idCard: [null, [Validators.required, Validators.maxLength(18), Validators.minLength(18)]],
        phone: [this.record.phone, [Validators.required, Validators.pattern(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/)]],
        major: [this.record.major, [Validators.required, Validators.maxLength(50)]],
        // qq: [null, [Validators.required]],
        job: [this.record.job, [Validators.required, Validators.maxLength(20)]],
        faculty: [this.record.faculty, [Validators.required, Validators.maxLength(50)]],
      })
    }
  }

  onClose() {
    this.nzModalRef.destroy();
  }

  onSave() {
    // tslint:disable-next-line:forin
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[ i ].markAsDirty();
      this.validateForm.controls[ i ].updateValueAndValidity();
    }
    if (this.validateForm.invalid) {
      return;
    }
    if (this.isHasSpecialChar(this.validateForm.controls['name'].value)) {
      this.msg.warning('姓名不可有空格或特殊字符');
      return false;
    }
    if (this.isHasSpecialChar(this.validateForm.controls['faculty'].value)) {
      this.msg.warning('院系不可有空格或特殊字符');
      return false;
    }
    if (this.isHasSpecialChar(this.validateForm.controls['major'].value)) {
      this.msg.warning('专业不可有空格或特殊字符');
      return false;
    }
    if (this.isHasSpecialChar(this.validateForm.controls['job'].value)) {
      this.msg.warning('职务不可有空格或特殊字符');
      return false;
    }
    if (isNullOrUndefined(this.record)) this.record = {};
    this.record['name'] = this.validateForm.get('name').value;
    this.record['sex'] = this.validateForm.get('sex').value;
    this.record['phone'] = this.validateForm.get('phone').value;
    this.record['major'] = this.validateForm.get('major').value;
    this.record['job'] = this.validateForm.get('job').value;
    this.record['email'] = this.validateForm.get('email').value;
    this.record['faculty'] = this.validateForm.get('faculty').value;
    this.nzModalRef.destroy(this.record);
  }

  isHasSpecialChar(str) {
    var pattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]");
    if(str.indexOf(' ') > -1 || pattern.test(str)){
      return true;
    } else {
      return false;
    }
  }
}
