import {Component, Inject, OnInit} from '@angular/core';
import {CacheService} from "@delon/cache";
import {BimSchoolsService} from "../school-register/bim-school.service";
import {BimItemsService} from "../../bim-items/bim-items.service";
import {TeacherEditComponent} from "./teacher-edit/teacher-edit.component";
import {ModalHelper} from "@delon/theme";
import {StudentEditComponent} from "./student-edit/student-edit.component";
import {NzMessageService, NzModalService, UploadFile, UploadFilter, UploadXHRArgs} from "ng-zorro-antd";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {V} from "@angular/core/src/render3";
import {BimReceiptInfoService} from "./bim-receipt-info.service";
import {BimReceiptInfo, BimReceiptInstrictor, BimReceiptStudent} from "./bim-receipt-info.model";
import {HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpRequest, HttpResponse} from "@angular/common/http";
import {GuidUtil} from "@shared/utils/guid.util";
import {DA_SERVICE_TOKEN, ITokenService} from "@delon/auth";
import {HttpAddressService} from "@shared/session/http-address.service";
import {ReceiptUploadFileComponent} from "./receipt-upload-file/receipt-upload-file.component";
import { isNullOrUndefined } from 'util';
import { LeaderEditComponent } from './leader-edit/leader-edit.component';
import * as moment from 'moment';

@Component({
  selector: 'app-receipt-upload',
  templateUrl: './receipt-upload.component.html',
  styles: []
})
export class ReceiptUploadComponent implements OnInit {

  teamId = '';
  // teamName = '';
  // schoolName = '';
  schoolId = '';
  items = [];
  bimItems = [];
  teamInfo: any = {name: '',schoolTop: '',schoolId: '',bimSchoolName: ''};

  curTeamEvent: any;

  studentList:BimReceiptStudent[] = [];
  teacherList:BimReceiptInstrictor[] = [];
  leaderList = [];

  validateForm: FormGroup;
  validateForm2: FormGroup;

  saveLoading = false;

  receiptInfo: BimReceiptInstrictor;

  downloadLoading = false;

  receiptUrl = '';

  bimInfo: any;

  isLook = false;

  public LIVE_STATUS = {'0': '统一安排',  '1' : '自行安排'};

  constructor(
    private cacheService: CacheService,
    private bimSchoolsService: BimSchoolsService,
    private bimItemsService:BimItemsService,
    public modal: ModalHelper,
    public modalService: NzModalService,
    private fb: FormBuilder,
    private bimReceiptInfoService: BimReceiptInfoService,
    private msg: NzMessageService,
    private http: HttpClient,
    private httpAddressService: HttpAddressService,
  ) {
    this.receiptUrl = this.httpAddressService.BimServe + '/bim-receipt-infos';
  }

  ngOnInit() {
    this.teamId = this.cacheService.get('bimTeamId')['value'];
    this.curTeamEvent = this.cacheService.get('curTeamEvent')['value'];
    this.bimInfo = this.cacheService.get('bimInfo')['value'];
    this.getBimItems();
    this.getBimInfo();
    this.isLook = this.curTeamEvent.receiptStatus == 2;

    this.validateForm = this.fb.group({
      invoiceTitle: [null, [Validators.required]],
      dutyNum: [null, [Validators.required, Validators.maxLength(50)]],
      addrAndPhone: [null, [Validators.maxLength(200)]],
      account: [null,[Validators.maxLength(200)]],
      contacts: [null,[Validators.required, Validators.maxLength(50)]],
      phone: [null,[Validators.required, Validators.pattern(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/)]],
      email: [null,[Validators.email, Validators.required]],
      remark: [null,[Validators.maxLength(200)]]
    });

    this.validateForm2 = this.fb.group({
      traffic: [null, [Validators.required, Validators.maxLength(20)]],
      arriveTime: [null, [Validators.required]],
      leaveTime: [null, [Validators.required]],
      liveStatus: [null, [Validators.required]],
      singleRoom: [null, []],
      standardRoom: [null, []],
      liveDetail: [null, []],
    });

    if (this.isLook) {
      this.validateForm.disable();
      this.validateForm2.disable();
    }

  }

  getReceipt() {
    const params = {
      'schoolId.equals': this.teamInfo.schoolId,
      'teamId.equals': this.teamId
    };
    this.bimReceiptInfoService.query(params).subscribe(res => {
      if (res && res.body && res.body.length === 1) {
        let teacherList = res.body[0].instrictorList;
        teacherList.forEach(e => {
          if (e.order === null) {
            const tList = this.teacherList.filter(t => t.name === e.name);
            if (tList.length > 0) {
              e.order = tList[0].order;
            }
          }
        });
        teacherList = teacherList.sort((a, b) => a.order - b.order);
        console.log(teacherList);
        let studentList = res.body[0].studentList;
        studentList.forEach(e => {
          if (e.stuOrder === null) {
            const tList = this.studentList.filter(t => t.name === e.name);
            if (tList.length > 0) {
              e.stuOrder = tList[0].stuOrder;
            }
          }
        });
        studentList = studentList.sort((a, b) => a.stuOrder - b.stuOrder);
        console.log(studentList);

        let leaderList = res.body[0].leaderList;
        console.log(leaderList);

        this.leaderList = leaderList;
        this.teacherList = teacherList;
        this.studentList = studentList;
        let info = res.body[0];
        this.receiptInfo = info;
        this.validateForm.setValue({
          invoiceTitle: info.invoiceTitle,
          dutyNum: info.dutyNum,
          addrAndPhone: info.addrAndPhone,
          account: info.account,
          contacts: info.contacts,
          phone: info.phone,
          email: info.email,
          remark: info.remark
        });
        this.validateForm2.setValue({
          traffic: info.traffic,
          arriveTime: info.arriveTime,
          leaveTime: info.leaveTime,
          liveStatus: info.liveStatus + '',
          singleRoom: info.singleRoom,
          standardRoom: info.standardRoom,
          liveDetail: info.liveDetail
        });

        const program = info.gameProgram.split(",");
        this.bimItems.forEach(item => {

          item.check = false;
          if (program.includes(item.id)) {
            item.check = true;
          }
        })
      }
    })
  }

  getBimItems() {
    const params = {
      sort: ['type,asc', 'orderSn,asc']
    };
    this.bimItemsService.getAllBimItemsInBimEvents(this.curTeamEvent.id, params).subscribe(res => {
      if (res && res.body) {
        this.bimItems = res.body;
        this.bimItems.forEach(item => {
          item['disabled'] = true;
        });
        this.setItems();
      }
    })
  }

  getBimInfo() {
    if (this.teamId && this.teamId.length > 0) {
      this.bimSchoolsService.getBimTeamById(this.teamId).subscribe(res => {
        this.teamInfo = res;
        this.items = [];
        res.teamItems.forEach(item => {
          // 230403 无论是前台队伍自己报的专项 还是后台修改的专项 都自动在填写回执的时候勾选上 
          // if (item.schoolTop && item.schoolTop === 1)  {
            this.items.push(item);
          // }
        });
        this.teacherList = [];
        let order = 1;
        res.teamTeachers.forEach(item => {
          let data = new BimReceiptInstrictor();
          data.name = item.name;
          data.sex = item.sex;
          data.major = item.profession;
          data.phone = item.mobile;
          data.job = item.duties;
          data.email = item.email;
          data.faculty = item.faculty;
          data.order = order;
          this.teacherList.push(data);
          order++;
        });
        this.studentList = [];
        order = 1;
        res.teamStudents.forEach(item => {
          let data = new BimReceiptStudent();
          data.name = item.name;
          data.email = item.email;
          data.phone = item.tel;
          data.grade = item.grade;
          data.sex = item.sex;
          data.major = item.profession;
          data.faculty = item.faculty;
          data.stuOrder = order;
          this.studentList.push(data);
          order++;
        });
        this.getReceipt();
        this.setItems();
      })
    }
  }

  setItems() {
    if (this.bimItems && this.bimItems.length > 0 && this.items && this.items.length > 0) {
      this.bimItems.forEach(item => {
        this.items.forEach(ele => {
          if (ele.itemId === item.id) {
            item.check = true;
          }
        })
      })
    }
  }

  editTeacher(isAdd: boolean,record?: any) {
    console.log('record:',record);
    this.modal.static(TeacherEditComponent,{isAdd: isAdd,record: record},600).subscribe(res => {
      if (res) {
        if (isAdd) {
          this.teacherList = [... this.teacherList, res];
        } else {
          this.teacherList.forEach(item => {
            if (item.id === res.id) {
              item = res;
            }
          })
        }
      }
    })
  }

  deleteTeacher(record) {
    this.modalService.confirm({
      nzTitle     : '是否移除此条指导老师记录？',
      nzContent   : '',
      nzOkText    : '确定',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.teacherList.splice(this.teacherList.indexOf(record),1);
        this.teacherList = [... this.teacherList];
      },
      nzCancelText: '否',
      nzOnCancel  : () => console.log('Cancel')
    });
  }

  editStudent(isAdd: boolean,record?: any) {
    console.log('record:',record);
    this.modal.static(StudentEditComponent, { isAdd: isAdd, record: record}, 600).subscribe(res => {
      if (res) {
        if (isAdd) {
          this.studentList = [... this.studentList, res];
        } else {
          this.studentList.forEach(item => {
            if (item.id === res.id) {
              item = res;
            }
          })
        }
      }
    })
  }

  deleteStudent(record) {
    console.log(this.studentList.indexOf(record));
    this.modalService.confirm({
      nzTitle     : '是否移除此条学生记录？',
      nzContent   : '',
      nzOkText    : '确定',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.studentList.splice(this.studentList.indexOf(record), 1);
        this.studentList = [... this.studentList];
      },
      nzCancelText: '否',
      nzOnCancel  : () => console.log('Cancel')
    });
  }

  save() {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[ i ].markAsDirty();
      this.validateForm.controls[ i ].updateValueAndValidity();
    }
    if (this.validateForm2.get('liveStatus').value == '0') {
      const singleRoom = this.validateForm2.controls['singleRoom'].value;
      const standardRoom = this.validateForm2.controls['standardRoom'].value;
      if ((singleRoom != null || singleRoom.length > 0) && (standardRoom == null || standardRoom.length === 0)) {
          this.validateForm2.controls['standardRoom'].setValue(0);
      }
      if ((standardRoom != null || standardRoom.length > 0) && (singleRoom == null || singleRoom.length === 0)) {
          this.validateForm2.controls['singleRoom'].setValue(0);
      }
    }
    for (const i in this.validateForm2.controls) {
      this.validateForm2.controls[ i ].markAsDirty();
      this.validateForm2.controls[ i ].updateValueAndValidity();
    }
    if (this.validateForm.status === "VALID" && this.validateForm2.status === "VALID") {
      if (this.validateForm2.get('liveStatus').value == '0') {
        if (this.validateForm2.controls['singleRoom'].value == 0 && 
        this.validateForm2.controls['standardRoom'].value == 0) {
          this.msg.warning('标间数量和单间数量至少填写一个！');
          return;
        }
      }
      
      if (!this.leaderList || this.leaderList.length === 0) {
        this.msg.warning('请添加带队老师信息！');
        return;
      }
      if (!this.studentList || this.studentList.length === 0) {
        this.msg.warning('请添加参赛队员信息！');
        return;
      }
      for (let i = 0; i < this.studentList.length; i++) {
        if (isNullOrUndefined(this.studentList[i].sex)) {
          this.msg.warning('参赛队员【' + this.studentList[i].name + '】未选择性别！');
          return;
        }
      }
      
      if (!this.teacherList || this.teacherList.length === 0) {
        this.msg.warning('请添加指导老师信息！');
        return;
      }
      for (let i = 0; i < this.teacherList.length; i++) {
        if (isNullOrUndefined(this.teacherList[i].sex)) {
          this.msg.warning('指导老师【' + this.teacherList[i].name + '】未选择性别！');
          return;
        }
      }

      if (this.isHasSpecialChar(this.validateForm2.controls['traffic'].value)) {
        this.msg.warning('交通方式不可有空格或特殊字符');
        return false;
      }
      if (this.isHasSpecialChar(this.validateForm2.controls['liveDetail'].value)) {
        this.msg.warning('住宿安排不可有空格或特殊字符');
        return false;
      }
      if (this.isHasSpecialChar(this.validateForm.controls['invoiceTitle'].value)) {
        this.msg.warning('发票抬头不可有空格或特殊字符');
        return false;
      }
      if (this.isHasSpecialChar(this.validateForm.controls['dutyNum'].value)) {
        this.msg.warning('纳税人识别号不可有空格或特殊字符');
        return false;
      }
      if (this.isHasSpecialChar(this.validateForm.controls['contacts'].value)) {
        this.msg.warning('发票联系人不可有空格或特殊字符');
        return false;
      }
      
      if (this.isHasPdfSpecialChar(this.validateForm.controls['addrAndPhone'].value)) {
        this.msg.warning('发票地址及电话不可有字符&<>');
        return false;
      }
      if (this.isHasPdfSpecialChar(this.validateForm.controls['account'].value)) {
        this.msg.warning('发票开户行及账号不可有字符&<>');
        return false;
      }
      if (this.isHasPdfSpecialChar(this.validateForm.controls['remark'].value)) {
        this.msg.warning('发票信息备注不可有字符&<>');
        return false;
      }


      let bimReceipt = new BimReceiptInfo();
      if (this.receiptInfo && this.receiptInfo.id && this.receiptInfo.id.length > 0) {
        bimReceipt = this.receiptInfo;
      }
      for (let i = 0; i < this.leaderList.length; i++) {
        this.leaderList[i].teacherOrder = i + 1;
      }
      for (let i = 0; i < this.studentList.length; i++) {
        this.studentList[i].stuOrder = i + 1;
      }
      for (let i = 0; i < this.teacherList.length; i++) {
        this.teacherList[i].order = i + 1;
      }
      bimReceipt.schoolId = this.teamInfo.schoolId;
      bimReceipt.invoiceTitle = this.validateForm.get('invoiceTitle').value;
      bimReceipt.dutyNum = this.validateForm.get('dutyNum').value;
      bimReceipt.gameProgram = this.items.map(item => item.itemId).join(',');
      bimReceipt.teamName = this.teamInfo.name;
      bimReceipt.addrAndPhone = this.validateForm.get('addrAndPhone').value || '';
      bimReceipt.account = this.validateForm.get('account').value || '';
      bimReceipt.contacts = this.validateForm.get('contacts').value;
      bimReceipt.phone = this.validateForm.get('phone').value;
      bimReceipt.email = this.validateForm.get('email').value;
      bimReceipt.remark = this.validateForm.get('remark').value || '';

      bimReceipt.traffic = this.validateForm2.get('traffic').value || '';
      bimReceipt.arriveTime = this.validateForm2.get('arriveTime').value || '';
      bimReceipt.leaveTime = this.validateForm2.get('leaveTime').value || '';
      bimReceipt.liveStatus = this.validateForm2.get('liveStatus').value;
      if (bimReceipt.liveStatus == '0') {
        bimReceipt.singleRoom = this.validateForm2.get('singleRoom').value;
        bimReceipt.standardRoom = this.validateForm2.get('standardRoom').value;
        bimReceipt.liveDetail = null;
      } else {
        bimReceipt.singleRoom = null;
        bimReceipt.standardRoom = null;
        bimReceipt.liveDetail = this.validateForm2.get('liveDetail').value || '';
      }

      bimReceipt.isSubmit = 1;
      bimReceipt.teamId = this.teamId;
      bimReceipt.leaderList = this.leaderList;
      bimReceipt.instrictorList = this.teacherList;
      bimReceipt.studentList = this.studentList;
      bimReceipt.type = '20';
      this.saveLoading = true;
      if (this.receiptInfo && this.receiptInfo.id && this.receiptInfo.id.length > 0) {
        this.bimReceiptInfoService.update(bimReceipt).subscribe(res => {
          this.saveLoading = false;
          if (res && res.body) {
            this.msg.success('保存成功！');
          }
        },error1 => {
          this.saveLoading = false;
          this.msg.error('保存错误！');
        })
      } else {
        this.bimReceiptInfoService.create(bimReceipt).subscribe(res => {
          this.saveLoading = false;
          if (res && res.body) {
            this.receiptInfo = res.body;
            this.msg.success('保存成功！');
          }
        },error1 => {
          this.saveLoading = false;
          this.msg.error('保存错误！');
        })
      }
    }

  }

  uploadFile() {
    this.modal.static(ReceiptUploadFileComponent,{receiptInfo: this.receiptInfo},600).subscribe(res => {})
  }

  download() {
    this.downloadLoading = true;
    const url = `${this.receiptUrl}/exportPdf`;
    //需要token验证的打开下载链接的方式与不需要验证的windows.open是一样的
    this.http.get(url ,  {
      params: {id: this.receiptInfo.id},
      headers: new HttpHeaders({ 'Content-Type': 'application/pdf' }),
      observe: 'response',
      responseType: 'blob',
      withCredentials: true,
    }).subscribe(data => {
      let blob = new Blob([data.body]);
      let objectUrl = URL.createObjectURL(blob);
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display:none');
      a.setAttribute('href', objectUrl);
      a.setAttribute('download',  this.bimInfo.name + this.curTeamEvent.name + '决赛回执.pdf');
      a.click();
      URL.revokeObjectURL(objectUrl);
      this.downloadLoading = false;
    },error => {
      this.downloadLoading = false;
      console.error('回执下载失败 ->', error);
    })
  }

  formatSex(sex) {
    return (!isNullOrUndefined(sex)) ? (Number(sex) === 0 ? '男' : '女') : '-';
  }

  editLeader(isAdd: boolean, record?: any) {
    console.log('record:',record);
    this.modal.static(LeaderEditComponent, { isAdd: isAdd, record: record}, 600).subscribe(res => {
      if (res) {
        if (isAdd) {
          this.leaderList = [... this.leaderList, res];
        } else {
          this.leaderList.forEach(item => {
            if (item.id === res.id) {
              item = res;
            }
          })
        }
      }
    })
  }

  deleteLeader(record) {
    console.log(this.leaderList.indexOf(record));
    this.modalService.confirm({
      nzTitle     : '是否移除此条带队老师记录？',
      nzContent   : '',
      nzOkText    : '确定',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.leaderList.splice(this.leaderList.indexOf(record), 1);
        this.leaderList = [... this.leaderList];
      },
      nzCancelText: '否',
      nzOnCancel  : () => console.log('Cancel')
    });
  }

  liveStatusChange(itemSelect){
    console.log(itemSelect);
    this.validateForm2.get('singleRoom').clearValidators();
    this.validateForm2.get('standardRoom').clearValidators();
    if (itemSelect == '0') {
       //清除校验
       this.validateForm2.get('singleRoom').setValidators([Validators.required, Validators.max(100)]);
       this.validateForm2.get('standardRoom').setValidators([Validators.required, Validators.max(100)]);
    } else {
      this.validateForm2.get('singleRoom').setValue(null);
      this.validateForm2.get('standardRoom').setValue(null);
    }
  }

  isHasSpecialChar(str) {
    if (!isNullOrUndefined(str)) {
      var pattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]");
      if(str.indexOf(' ') > -1 || pattern.test(str)){
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  disabledStartDate = (startValue: Date): boolean => {
    if (!startValue) {
      return false;
    }
    if (this.validateForm2.controls['leaveTime'].value) {
      return startValue.getTime() < (new Date().getTime() - 1000 * 60 * 60 * 12) || startValue.getTime() > (new Date(this.validateForm2.controls['leaveTime'].value).getTime() + 1000 * 60 * 60 * 12);
    } else {
      return startValue.getTime() < (new Date().getTime() - 1000 * 60 * 60 * 12);
    }
  };
  
  disabledEndDate = (endValue: Date): boolean => {
    if (!endValue) {
      return false;
    }
    if (this.validateForm2.controls['arriveTime'].value) {
      return endValue.getTime() < new Date(this.validateForm2.controls['arriveTime'].value).getTime();
    } else {
      return endValue.getTime() < (new Date().getTime() - 1000 * 60 * 60 * 12);
    }
  };

  standardRoomChange(roomNum) {
    if (!this.validateForm2.get('singleRoom').value) {
      this.validateForm2.get('singleRoom').setValue(0);
    }
  }

  singleRoomChange(roomNum) {
    if (!this.validateForm2.get('standardRoom').value) {
      this.validateForm2.get('standardRoom').setValue(0);
    }
  }

  isHasPdfSpecialChar(str) {
    if (!isNullOrUndefined(str)) {
      var pattern = new RegExp("[&<>]");
      if(pattern.test(str)){
        return true;
      } else {
        return false;
      }
    }
    return false;
  }
}
