import { Component, OnInit, ElementRef, ViewChild, Inject, OnDestroy } from '@angular/core';
import { ModalHelper } from '@delon/theme';
import { EvaluateModalComponent } from './evaluate-modal/evaluate-modal.component';
import { LearnService } from './learn.service';
import { HttpAddressService } from '@shared/session/http-address.service';
import { Router, ActivatedRoute } from '@angular/router';
import { EduCourseCatalogTreeNodeModel } from './eduCourseCatalog.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { DomSanitizer } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { FollowService } from './follow/follow.service';
import { LikePointsService } from './like-points/like-points.service';
import { LikePoints } from './like-points/like-points.model';
import { FileUploadService } from '@shared/components/fileUpload/fileUpload.service';
import { StudyInfoService } from './study-info/study-info.service';
import { StudyInfo } from './study-info/study-info.model';
import { StudyHistory } from './study-history/study-history.model';
import { HistoryDetailsService } from './study-history/study-history.service';
import { LearnHomeworkService } from './homework/homework.service';
import { LearnExamService } from './exam/exam.service';
import { Exam } from 'app/routes/admin/teacher/examine/exam.model';
import { deepCopy } from '@delon/util';
import { isNullOrUndefined } from 'util';
import { SysFileService } from '@shared/sysfile/sysFile';

@Component({
  selector: 'app-learn',
  templateUrl: './learn.component.html',
  styleUrls: ['./learn.component.less', '../../../../assets/video/css/video-js.min.less']
})
export class LearnComponent implements OnInit, OnDestroy {
  @ViewChild('wd') wd: ElementRef;
  @ViewChild('remark') remark: ElementRef;
  @ViewChild('favorablePriceRemark') favorablePriceRemark: ElementRef;

  // 是否显示水印
  isShowWatermark = false;
  // 提交留言按钮加载
  submitLoading = false;
  // 分页数据
  total = 0;
  page = 1;
  size = 10;
  // 课时list
  catalogList = [];
  params = {};
  paramslist = {};
  // 教师用户id
  teacherUserId = '';
  // 是否关注
  isFollow = false;
  activeIndex = 0;
  loading = false;
  // 课程实体
  courseInfo: any = {};
  belongUserId: string;
  personMajor: any = {}; // 所授专业
  honor: any = {}; // 所获荣誉
  achievements: any = {}; // 所获成果
  // 是否登录
  isLogin = false;
  // 实体id
  entityId = '';
  // 用户id
  userId = '';
  // 实体名
  personName = '';
  // 用户账号
  userAccount = '';
  // 标识margin-top
  top = 0;
  // 标识 margin-left
  left = 0;
  // 答疑List
  questionList = [];
  question: {};
  form: FormGroup;
  forms: FormGroup;

  // 课程id
  courseId: string;

  //  // 课程名称
  // courseName: string;
  submitting = false;

  // 当前课时名称
  currentCatalogName: string;

  // 当前课时id
  courseCatalogId = '';

  // 图片预览前缀
  imagePrefix: string;

  // 下载课件前缀
  downloadPrefix: string;

  // 视频播放前缀
  videoPrefix: string;

  // 学习历史主表记录
  studyInfo = new StudyInfo();

  // 学习历史详情
  studyHistory = new StudyHistory();

  // addEventListener
  isAddEventListener = false;

  isShowBiginBtn = true;

  // 是否含有作业
  isHasHomework = false;

  // 是否含有考试
  isHasExam = false;

  // 考试list
  examList: Exam[];

  // 作业list 兼容多个作业
  homeworkList = [];

  isOpenTest = false;

  isInit = false;

  myPlayer: any;

  // 考试时间 s
  TEST_TIME = 2. * 60 * 60;
  // 剩余考试时间
  test_time = '02:30:00';
  // 定时器
  timer = null;
  speedTimer = null;
  currentTab = 0;

  showVideo = true;
  trialEnd = false;
  canViewVideo = false;

  public copyRightNotice = '【版权声明】本课程版权归提供者所有，仅限个人学习，严禁任何形式的录制、传播和账号分享。一经发现，平台将依法保留追究权，情节严重者将承担法律责任';

  public watermarkId = null;
  public watermarkFixId = null;
  // 是否显示版权声明
  public isShowRightNotice = false;
  public rightNoticeId = null;
  public rightNoticeFixId = null;

  mainTabs = [
    { name: '视频', icon: '/assets/icons/video.png', url: '', files: [] },
    { name: 'PPT', icon: '/assets/icons/ppt.png', url: '', files: [] },
    { name: '文档', icon: '/assets/icons/doc.png', url: '', files: [] }
  ];
  resourceList = [
    { name: '', type: 'video', size: '0', 'url': '', 'isShow': false },
    { name: '', type: 'ppt', size: '0', 'url': '', 'isShow': false },
    { name: '', type: 'doc', size: '0', 'url': '', 'isShow': false },
  ];
  video: any;

  waterMarkLeft: number | string = '100%';

  constructor(
    private fb: FormBuilder,
    private fileUploadService: FileUploadService,
    public msg: NzMessageService,
    private modalHelper: ModalHelper,
    private elementRef: ElementRef,
    private learnService: LearnService,
    private httpAddressService: HttpAddressService,
    private routerInfo: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private followService: FollowService,
    private datePipe: DatePipe,
    private router: Router,
    private sysFileService: SysFileService,
    private likePointsService: LikePointsService,
    private studyInfoService: StudyInfoService,
    private learnHistoryService: HistoryDetailsService,
    private learnHomeworkService: LearnHomeworkService,
    private learnExamService: LearnExamService,
    private modalService: NzModalService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  ) {
    (<any>window).pdfWorkerSrc = 'assets/js/pdf.worker.min.js';
    const token = this.tokenService.get().token;
    this.courseId = this.routerInfo.snapshot.queryParams['courseId'];
    this.teacherUserId = this.routerInfo.snapshot.queryParams['teacherUserId'];
    this.courseCatalogId = this.routerInfo.snapshot.queryParams['catalogId'];
    this.personName = this.tokenService.get().entity_name; // 登录人
    this.userId = this.tokenService.get().user_id; // 登录id
    this.userAccount = this.tokenService.get().user_account; // 登录账号
    this.entityId = this.tokenService.get().entity_id;
    if (token && token !== null && token !== '') {
      this.isLogin = true;
    } else {
      // 没登录跳回首页
      // this.router.navigateByUrl('/home');
    }
    this.imagePrefix = this.httpAddressService.fileGateway + this.httpAddressService.systemServe + '/sys-files/download/';
    this.downloadPrefix = this.httpAddressService.fileGateway + this.httpAddressService.systemServe + '/sys-files/download/';
    // this.videoPrefix = this.httpAddressService.fileGateway + this.httpAddressService.EduWebServe + '/edu-courses/view/';
    this.videoPrefix = this.httpAddressService.fileGateway + this.httpAddressService.FileServer + '/sys-files/view/';

    this.form = this.fb.group({
      description: [null], // 内容
    });
    this.forms = this.fb.group({
      description: [null], // 内容
    });
  }

  // 初始化页面
  ngOnInit() {
    this.getCourseInfo();
    if (this.isLogin) {
      this.getCourseQuestions();
      this.isFollowTeacher();
    }
  }
  // tslint:disable-next-line: use-life-cycle-interface

  biginTest(item: any) {
    if (this.isOpenTest === true) {
      this.msg.error('已经开始作业，请提交后开始答题');
      return;
    }
    this.isOpenTest = true;
    item.isShowBiginBtn = false;
    this.countDown();
  }

  viewTest(item: any) {
    item.isShowBiginBtn = false;
  }

  randomString(e) {
    e = e || 32;
    const t = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz';
    const a = t.length;
    let n = '';
    for (let i = 0; i < e; i++) {
      n += t.charAt(Math.floor(Math.random() * a));
    }
    return n;
  }

  getRandomNum(min: number, max: number) {
    const range = max - min;
    const rand = Math.random();
    return (min + Math.round(rand * range));
  }


  // flag = true 强制出现水印,false = 50%几率出现水印
  addWatermark(flag?: boolean) {
    if (!this.isShowWatermark) {
      // 百分之50几率出现水印
      if (!flag) {
        const r = this.getRandomNum(0, 100);
        if (r < 50) {
          return;
        }
      }
      $('#' + this.watermarkId).remove();
      // 动态生成 id
      this.watermarkId = this.randomString(5);
      const r1 = this.getRandomNum(75, 870);
      const r2 = this.getRandomNum(60, 870);
      // tslint:disable-next-line: max-line-length
      const html = '<div class="waterMark" style="color:red; position: absolute;left: ' + r1 + 'px;top: ' + r2 + 'px;" id="' + this.watermarkId + '"><span>' 
                        + (isNullOrUndefined(this.userAccount) ? '在线用户' : this.userAccount) + '</span></div>';
      // 追加节点
      $('.vjs-text-track-display').prepend(html);

      this.isShowWatermark = true;
      const removeId = this.watermarkId;
      // 5秒钟之后通过id移除
      setTimeout(() => {
        $('#' + removeId).remove();
        this.isShowWatermark = false;
      }, 5000);
    }
  }

  // flag = true 强制出现水印,false = 50%几率出现水印
  addWatermarkFix() {
    // 动态生成 id
    this.watermarkFixId = this.randomString(5);
    const r1 = this.getRandomNum(75, 870);
    const r2 = this.getRandomNum(60, 870);
    // tslint:disable-next-line: max-line-length
    const html = '<div class="waterMark" style="color:red; position: absolute;left: ' + this.waterMarkLeft + ';top: ' + r2 + 'px;" id="' 
          + this.watermarkFixId + '"><span>' + (isNullOrUndefined(this.userAccount) ? '在线用户' : this.userAccount) + '</span></div>';
    // 追加节点
    $('.vjs-text-track-display').prepend(html);
  }

  // flag = true 15s自动消失,false = 始终显示,不自动消失
  addRightNotice() {
    if (!this.isShowRightNotice) {
      $('#' + this.rightNoticeId).remove();
      this.rightNoticeId = this.randomString(5);
      // tslint:disable-next-line: max-line-length
      const html = '<div class="copy-notice" style="text-align: center; " id="' + this.rightNoticeId + '"><span style="color: black;font-size:20px;opacity:0.3;filter:alpha(opacity=30);">'
        + this.copyRightNotice + '</span></div>';
      $('.vjs-text-track-display').prepend(html);
      this.isShowRightNotice = true;
      const removeId = this.rightNoticeId;
      setTimeout(() => {
        $('#' + removeId).remove();
        this.isShowRightNotice = false;
      }, 15000);
    }
  }

  addRightNoticeFix() {
    this.rightNoticeFixId = this.randomString(5);
    // tslint:disable-next-line: max-line-length
    const html = '<div class="copy-notice" style="text-align: center; " id="' + this.rightNoticeFixId + '"><span style="color: black;font-size:20px;opacity:0.3;filter:alpha(opacity=30);">'
      + this.copyRightNotice + '</span></div>';
    $('.vjs-text-track-display').prepend(html);
  }



  // videoJs初始化
  initVideo() {
    const myVideo = document.getElementById('my-video');
    myVideo.style.display = '';
    const myVideoHtml5 = document.getElementById('my-video_html5_api');
    if (myVideoHtml5 != null) {
      myVideoHtml5.style.display = '';
    }
    this.showVideo = true;
    this.trialEnd = false;
    if (this.isInit) {
      return;
    }
    this.isInit = true;
    const __this = this;
    videojs.addLanguage('zh-CN', {
      'Play': '播放',
      'Pause': '暂停',
      'Current Time': '当前时间',
      'Duration': '时长',
      'Remaining Time': '剩余时间',
      'Stream Type': '媒体流类型',
      'LIVE': '直播',
      'Loaded': '加载完毕',
      'Progress': '进度',
      'Fullscreen': '全屏',
      'Non-Fullscreen': '退出全屏',
      'Mute': '静音',
      'Unmute': '取消静音',
      'Playback Rate': '播放速度',
      'Subtitles': '字幕',
      'subtitles off': '关闭字幕',
      'Captions': '内嵌字幕',
      'captions off': '关闭内嵌字幕',
      'Chapters': '节目段落',
      'Close Modal Dialog': '关闭弹窗',
      'Descriptions': '描述',
      'descriptions off': '关闭描述',
      'Audio Track': '音轨',
      'You aborted the media playback': '视频播放被终止',
      'A network error caused the media download to fail part-way.': '网络错误导致视频下载中途失败。',
      // tslint:disable-next-line: max-line-length
      'The media could not be loaded, either because the server or network failed or because the format is not supported.': '视频因格式不支持或者服务器或网络的问题无法加载。',
      // tslint:disable-next-line: max-line-length
      'The media playback was aborted due to a corruption problem or because the media used features your browser did not support.': '由于视频文件损坏或是该视频使用了你的浏览器不支持的功能，播放终止。',
      'No compatible source was found for this media.': '无法找到此视频兼容的源。',
      'The media is encrypted and we do not have the keys to decrypt it.': '视频已加密，无法解密。',
      'Play Video': '播放视频',
      'Close': '关闭',
      'Modal Window': '弹窗',
      'This is a modal window': '这是一个弹窗',
      'This modal can be closed by pressing the Escape key or activating the close button.': '可以按ESC按键或启用关闭按钮来关闭此弹窗。',
      ', opens captions settings dialog': ', 开启标题设置弹窗',
      ', opens subtitles settings dialog': ', 开启字幕设置弹窗',
      ', opens descriptions settings dialog': ', 开启描述设置弹窗',
      ', selected': ', 选择',
      'captions settings': '字幕设定',
      'Audio Player': '音频播放器',
      'Video Player': '视频播放器',
      'Replay': '重播',
      'Progress Bar': '进度小节',
      'Volume Level': '音量',
      'subtitles settings': '字幕设定',
      'descriptions settings': '描述设定',
      'Text': '文字',
      'White': '白',
      'Black': '黑',
      'Red': '红',
      'Green': '绿',
      'Blue': '蓝',
      'Yellow': '黄',
      'Magenta': '紫红',
      'Cyan': '青',
      'Background': '背景',
      'Window': '视窗',
      'Transparent': '透明',
      'Semi-Transparent': '半透明',
      'Opaque': '不透明',
      'Font Size': '字体尺寸',
      'Text Edge Style': '字体边缘样式',
      'None': '无',
      'Raised': '浮雕',
      'Depressed': '压低',
      'Uniform': '均匀',
      'Dropshadow': '下阴影',
      'Font Family': '字体库',
      'Proportional Sans-Serif': '比例无细体',
      'Monospace Sans-Serif': '单间隔无细体',
      'Proportional Serif': '比例细体',
      'Monospace Serif': '单间隔细体',
      'Casual': '舒适',
      'Script': '手写体',
      'Small Caps': '小型大写字体',
      'Reset': '重启',
      'restore all settings to the default values': '恢复全部设定至预设值',
      'Done': '完成',
      'Caption Settings Dialog': '字幕设定视窗',
      'Beginning of dialog window. Escape will cancel and close the window.': '开始对话视窗。离开会取消及关闭视窗',
      'End of dialog window.': '结束对话视窗'
    });
    const courseCatalog = this.catalogList.filter(e => e.id === this.courseCatalogId)[0];
    this.myPlayer = videojs('my-video');
    this.myPlayer.on('timeupdate', function (event) {
      // tslint:disable-next-line: radix
      const currentTime = parseInt(parseInt(this.currentTime()).toFixed(0));
      const duration = this.duration();
      if (currentTime % 60 === 0) {
        __this.studyHistory.studyHours = currentTime / 60;
        __this.updateOrSaveStudyHistory(__this.studyHistory);
      }
      // 开头5秒添加
      if (currentTime === 1) {
        __this.addWatermark(true);
      }
      // 每隔30秒50%几率随机触发
      if (currentTime % 30 === 0) {
        __this.addWatermark(true);
      }
      // 最后5秒添加
      if (duration - currentTime < 5) {
        __this.addWatermark(true);
      }
      // 开头添加
      if (currentTime === 1) {
        __this.addRightNotice();
      }
      // 每隔20分钟触发
      if (currentTime % (60 * 20) === 0) {
        __this.addRightNotice();
      }
      if (courseCatalog !== undefined) {
        const courseInfo = __this.courseInfo;
        // 是否可观看视频
        if (!__this.canViewVideo) {
          // 视频试看
          if (courseCatalog.isTrial === '1' && courseInfo.isCharge !== '0' && courseInfo.price !== null) {
            // 默认试看5分钟
            const trialDuration = courseCatalog.trialDuration === null ? 5 : courseCatalog.trialDuration;

            if (currentTime > trialDuration * 60) {
              // 视频暂停操作
              this.pause();
              // 提示层显示
              // __this.showVideo = false;
              myVideo.style.display = 'none';
              // myVideo.setAttribute('src', '');
              if (!__this.trialEnd) {
                __this.trialEnd = true;
                __this.modalService.info({
                  nzTitle: '试看结束，购买后可观看完整课程',
                  nzContent: '',
                  nzOkText: '立即购买',
                  nzOkType: 'info',
                  nzOnOk: () => {
                    __this.router.navigateByUrl(`/course/detail?courseId=${__this.courseId}`);
                  },
                  nzCancelText: '取消',
                  nzOnCancel: () => console.log('Cancel')
                });
              }
            } else {
              __this.showVideo = true;
              __this.trialEnd = false;
              myVideo.style.display = '';
              if (myVideoHtml5 != null) {
                myVideoHtml5.style.display = '';
              }
            }
          }
        }
      }

    });

    this.myPlayer.on('pause', function (event) {
      console.log('pause');
      console.log(event);
      $('#' + __this.watermarkId).remove();
      $('#' + __this.rightNoticeId).remove();
      __this.addWatermarkFix();
      __this.addRightNoticeFix();
    });

    this.myPlayer.on('play', function (event) {
      console.log('start');
      console.log(event);
      $('#' + __this.watermarkFixId).remove();
      $('#' + __this.rightNoticeFixId).remove();
      __this.isShowRightNotice = false;
      __this.isShowWatermark = false;
      __this.addWatermark(true);
      __this.addRightNotice();
    });

    videojs('my-video').ready(function () {
      const player = this;
      // player.play();
    });
  }

  disposeVideo() {
    if (this.myPlayer) {
      try {
        this.myPlayer.dispose();
      } catch (error) {
        console.log('error...');
      }
      this.isInit = false;
    }
  }

  // 考试倒计时
  countDown() {
    this.timer = setInterval(() => {
      this.TEST_TIME--;
      if (this.TEST_TIME < 0) {
        this.TEST_TIME = 0;
        clearInterval(this.timer);
      }

      const h = Math.floor(this.TEST_TIME / (60 * 60));
      const H = h * 60 * 60;
      const M = this.TEST_TIME - H;
      const m = Math.floor(M / 60);
      const s = M - m * 60;
      this.test_time = `0${h}:${String(m).length > 1 ? m : '0' + m}:${String(s).length > 1 ? s : '0' + s}`;

    }, 1000);
  }

  // 获取作业
  getHomeWorkMsg() {
    this.learnHomeworkService.queryHomeworkMsg(this.courseId, this.courseCatalogId).subscribe(res => {
      if (res && res.body && res.body.code === '1') {
        this.isHasHomework = true;
        const reconds = res.body.data;
        this.homeworkList = reconds;
        let type = '';
        if (reconds && reconds.length > 0) {
          this.homeworkList.forEach(e0 => {
            e0.isShowBiginBtn = true;
            const typeList = e0.eduHomeworkTypeDTOList;
            if (typeList && typeList.length > 0) {
              typeList.forEach(e => {
                if (e.subjectType === '0' && e.subjectQuantity > 0) {
                  e.type = '单选';
                  type += '、' + e.type;
                }
                if (e.subjectType === '1' && e.subjectQuantity > 0) {
                  e.type = '多选';
                  type += '、' + e.type;
                }
                if (e.subjectType === '2' && e.subjectQuantity > 0) {
                  e.type = '判断';
                  type += '、' + e.type;
                }
              });
              e0.type = type.substring(1);
            }
          });
        }
      } else {
        this.isHasHomework = false;
      }
    });
  }

  // 获取考试
  getExamMsg() {
    this.learnExamService.queryByCourseId(this.courseId).subscribe(res => {
      const reconds = res.body;
      this.examList = reconds;
      if (reconds && reconds.length > 0) {
        this.isHasExam = true;
      } else {
        this.isHasExam = false;
      }
    });
  }

  // 改变分数状态 -- 接收从子组件提交后的变更
  updateHomework(item: any) {
    this.homeworkList.forEach(e => {
      if (e.id === item.eduHomeworkId) {
        e.remark = item.totalGetScore;
        this.isOpenTest = false;
      }
    });
  }

  /**
   * 根据课程ID获取课程信息
   * @param courseId
   */
  getCourseInfo() {
    this.learnService.getCourseInfo(this.courseId).subscribe((res: any) => {
      this.courseInfo = res;
      // this.courseName =  this.courseInfo.courseName;
      this.getCatalogList();
      if (this.isLogin) {
        this.getExamMsg();
      }
    });
  }

  /**
   * 查询课时
   */
  getCatalogList() {
    this.params = {
      'eduCourseId.equals': this.courseId,
      'sort': 'orderNum,asc'
    };
    this.learnService.getCataloglist(this.params).subscribe((res: any) => {
      if (res && res.body) {
        this.catalogList = res.body;
        if (this.catalogList.length > 0) {
          this.currentCatalogName = this.catalogList[0].name;
          if (!this.courseCatalogId || this.courseCatalogId === '') {
            this.courseCatalogId = this.catalogList[0].id;
            if (this.isLogin) {
              this.getHomeWorkMsg();
            }
          }
          this.catalogList.forEach((item, index) => {
            if (this.courseCatalogId === item.id) {
              this.activeIndex = index;
              this.currentCatalogName = item.name;
            }
            item.duration = this.formatDuration(item.duration);
          });
          this.getCourseFile(this.courseCatalogId);
        }
        this.loading = false;
      }
    });
  }

  // 增加或者更新学习历史主表
  updateOrSaveStudyInfo(info: StudyInfo) {
    if (!this.isLogin) {
      return;
    }
    // 添加记录 -- 仅第一次
    if (!info.eduCourseId || info.eduCourseId === null) {
      info.eduCourseId = this.courseId;
      info.eduCatalogId = this.courseCatalogId;
      info.eduStudentId = this.entityId;
    }
    this.studyInfoService.updateOrSave(info).subscribe(v => {
      if (v && v !== null) {
        this.studyInfo = v;
      }
    });
  }

  // 增加或者更新学习历史详情表
  updateOrSaveStudyHistory(history: StudyHistory) {
    if (!this.isLogin) {
      return;
    }
    // 添加记录 -- 仅第一次
    if (!history.eduCourseId || history.eduCourseId === null) {
      history.eduCourseId = this.courseId;
      history.eduCatalogId = this.courseCatalogId;
      history.eduStudentId = this.entityId;
      history.studyHours = 1;
      history.studyTime = new Date();
      this.learnHistoryService.createHistory(history).subscribe(v => {
        if (v && v !== null) {
          this.studyHistory = v.body;
        }
      });
    } else {
      this.learnHistoryService.updateHistory(history).subscribe(v => {
        if (v && v !== null) {
          this.studyHistory = v.body;
        }
      });
    }
  }

  // 获取课件
  getCourseFile(catalogId: string) {
    console.log(this.courseInfo);
    const courseCatalog = this.catalogList.filter(e => e.id === this.courseCatalogId)[0];
    if (!this.isLogin) {
      // 是否是免费视频
      this.canViewVideo = !(this.courseInfo.isCharge !== '0' && this.courseInfo.price !== null);
      if (!this.canViewVideo && courseCatalog.isTrial !== '1') {
        this.msg.remove();
        this.msg.info('请购买后学习');
        return;
      }
      this.initVideoFile(catalogId);
    } else {
      // 先判断用户能不能观看此视频
      this.learnService.isCanViewCatalog(catalogId).subscribe(r => {
        this.canViewVideo = !(r !== true && this.courseInfo.isCharge !== '0' && this.courseInfo.price !== null);
        if (!this.canViewVideo && courseCatalog.isTrial !== '1') {
          this.msg.remove();
          this.msg.info('请购买后学习');
          return;
        }
        this.initVideoFile(catalogId);
      });
    }
  }

  initVideoFile(catalogId: string) {
    const param = {
      'fileFk.equals': catalogId
    };
    if (this.studyHistory.eduCourseId && this.studyHistory.eduCourseId !== '') {
      // 将上一个视频的最后播放记录发送给后台
      const copyHistory = deepCopy(this.studyHistory);
      this.updateOrSaveStudyHistory(copyHistory);
    }
    if (this.isLogin) {
      this.getHomeWorkMsg();
    }
    this.learnService.getCourseCatalogFile(catalogId).subscribe(res => {
      const records = res.body;
      this.initResourceList();
      this.resetMainTabs();
      if (records && records !== null && records.length > 0) {
        records.forEach(val => {
          if (val.extField1 === '0') {
            this.mainTabs[2].files.push(val);
            this.setResource(this.resourceList[2], val);
          }

          if (val.extField1 === '1') {
            this.mainTabs[1].files.push(val);
            this.setResource(this.resourceList[1], val);
          }

          if (val.extField1 === '2') {
            this.mainTabs[0].files.push(val);
            this.setResource(this.resourceList[0], val);
            setTimeout(() => {
              this.initVideo();
              $('#my-video').bind('contextmenu', function () { // 取消右键事件
                return false;
              });
            }, 0);
          }
        });
        this.initMainTabs();
        // 存在视频
        if (this.mainTabs[0].url) {
          // setTimeout(() => {
          //   this.video =  this.elementRef.nativeElement.querySelector('.course-video');
          //   // 拖动进度条事件
          //   this.video.ontimeupdate = (e) => {
          //     console.log(this.video.currentTime)
          //   }
          // }, 0);

          // 第一次进来
          this.updateOrSaveStudyInfo(new StudyInfo());
          this.updateOrSaveStudyHistory(new StudyHistory());
        } else {
          this.disposeVideo();
        }
        // // 销毁定时器
        // if (this.speedTimer) {
        //   clearInterval(this.speedTimer);
        // }
        // // 初始化定时器
        // this.initTimer();
        if (!isNullOrUndefined(this.courseCatalogId)) {
          const index = this.catalogList.findIndex(e => e.id === this.courseCatalogId);
          if (index && index > -1) {
            this.selectItem(index);
          }
        }
        // if (!isNullOrUndefined(this.courseIndex)) {
        //   if (this.courseIndex.indexOf('.') > 0) {
        //     this.courseIndex = this.courseIndex.split('.')[0];
        //   }
        //   if (!isNaN(this.courseIndex) && this.courseIndex > 0) {
        //     this.selectItem(this.courseIndex - 1);
        //   }
        // }
      } else {
        this.disposeVideo();
      }
    });
  }

  onloadFile(tab, file, i) {
    // 非视频文件
    if (i !== 0) {
      this.disposeVideo();
      this.updateOrSaveStudyInfo(new StudyInfo());
      this.updateOrSaveStudyHistory(new StudyHistory());
      // if (this.speedTimer) {
      //   clearInterval(this.speedTimer);
      // }
      this.msg.remove();
      this.msg.loading('加载中');
      setTimeout(() => {
        tab.url = this.downloadPrefix + file.id;
        this.resetMainTabsUrl();
        this.msg.remove();
      }, 3000);
    } else {
      // 视频文件
      this.updateOrSaveStudyInfo(new StudyInfo());
      this.updateOrSaveStudyHistory(new StudyHistory());
      // 销毁定时器
      // if (this.speedTimer) {
      //   clearInterval(this.speedTimer);
      // }
      // this.initTimer();
      //  this.getVideoBlob(file.id);
      tab.url = this.videoPrefix + file.id + '?token=' + this.tokenService.get().token + '&catalogId=' + this.courseCatalogId;
      setTimeout(() => {
        this.initVideo();
      }, 0);
    }
    this.currentTab = i;
  }


  // private getVideoBlob(id) {
  //   const token = this.tokenService.get().token;
  //   const xhr = new XMLHttpRequest(); // 创建XMLHttpRequest对象

  //   xhr.open('GET', this.httpAddressService.apiGateway +
  //    this.httpAddressService.FileServer + '/sys-files/view/' + id, true); // 配置请求方式、请求地址以及是否同步
  //   xhr.setRequestHeader('Authorization', 'Bearer ' + token);

  //   xhr.responseType = 'blob'; // 设置结果类型为blob;
  //   xhr.onreadystatechange  = function(e) {
  //       // alert(this.status);
  //       if (this.response) {
  //           // 获取blob对象
  //           const blob = this.response;
  //           console.log(blob);
  //           // 获取blob对象地址，并把值赋给容器
  //           $('#video').attr('src', URL.createObjectURL(blob));
  //       }
  //   };
  //   xhr.send();
  // }

  private resetMainTabs() {
    this.mainTabs.forEach(val => {
      val.files = [];
      val.url = '';
    });
  }

  private resetMainTabsUrl() {
    this.mainTabs.forEach((val, index) => {
      if (this.currentTab !== index) {
        val.url = '';
      }
    });
  }

  private initMainTabs() {
    const val = this.mainTabs[0];
    if (val.files.length > 0) {
      val.url = this.videoPrefix + val.files[0].id + '?token=' + this.tokenService.get().token + '&catalogId=' + this.courseCatalogId;
    }
  }

  changeBgColor(show: boolean) {
    if (show) {
      setTimeout(() => {
        $('.ant-popover-inner').css('background-color', '#1E1E1E');
        $('.ant-popover-arrow').css('background-color', '#1E1E1E');
      }, 0);
    }

  }

  // 切换查看的文件
  // 会将其他tab的url重置
  nzSelectChange() {
    const files = this.mainTabs[this.currentTab].files;
    if (files.length > 0) {
      this.msg.remove();
      this.msg.loading('加载中');
      // 如果切换前是播放视频 ，则保存浏览记录
      if (this.mainTabs[0].url) {
        this.updateOrSaveStudyHistory(this.studyHistory);
        // if (this.speedTimer) {
        //   clearInterval(this.speedTimer);
        // }
      }

      // 如果切换到视频，则重置定时器
      if (this.currentTab === 0) {
        this.updateOrSaveStudyHistory(new StudyHistory());
        // tslint:disable-next-line: max-line-length
        this.mainTabs[this.currentTab].url = this.videoPrefix + files[0].id + '?token=' + this.tokenService.get().token + '&catalogId=' + this.courseCatalogId;
        setTimeout(() => {
          this.initVideo();
        }, 0);
        // // 销毁定时器
        // if (this.speedTimer) {
        //   clearInterval(this.speedTimer);
        // }
      } else {
        // 初始化定时器
        // this.initTimer();
        setTimeout(() => {
          // tslint:disable-next-line: max-line-length
          // this.mainTabs[this.currentTab].url = this.videoPrefix + files[0].id + '?token=' +  this.tokenService.get().token + '&catalogId=' + this.courseCatalogId;
          this.mainTabs[this.currentTab].url = this.downloadPrefix + files[0].id;
          this.resetMainTabsUrl();
          this.msg.remove();
          this.disposeVideo();
        }, 3000);
      }

    }
  }

  private setResource(resource: any, record: any) {
    resource.isShow = true;
    resource.name = record.fileName;
    resource.size = this.fileUploadService.readablizeBytes(record.fileSize);
    resource.url = this.downloadPrefix + record.id;
  }

  // 通过课程的id获取课程的答疑
  getCourseQuestions() {
    const param = {
      page: this.page - 1,
      size: this.size,
      sort: ['publish_time,desc']
    };
    this.learnService.getAnswerList(this.courseId, param).subscribe((res: any) => {
      this.questionList = res.body;
      this.total = res.headers.get('x-total-count');
    });
  }

  getQuestionsList(parentId) {
    this.learnService.gettopicreplies(parentId).subscribe((res: any) => {
      this.question = res.data;
    });
  }

  // 是否关注老师
  isFollowTeacher() {
    this.followService.isHasFollow(this.teacherUserId).subscribe(res => {
      this.isFollow = res;
    });
  }

  // 关注
  addFollowTeacher() {
    const param = {
      'eduCourseId': this.courseId,
      'followTime': this.datePipe.transform(new Date(), 'yyyy-MM-ddTHH:mm:ss') + 'Z',
      'followUserId': this.teacherUserId,
      'userId': this.userId
    };
    this.followService.followTeacher(param).subscribe(res => {
      const recond = res.body;
      if (recond && recond.id && recond.id !== null) {
        this.msg.remove();
        this.msg.info('关注成功');
        this.isFollow = true;
      }
    });
  }

  // 取消关注
  removeFollowTeacher() {
    this.followService.removeFollow(this.teacherUserId).subscribe(res => {
      this.msg.remove();
      this.msg.info('取消关注成功');
      this.isFollow = false;
    });

  }


  // 点赞
  addLikePoint(item) {
    const param = new LikePoints();
    param.courseId = this.courseId;
    param.courseCatalogId = this.courseCatalogId;
    param.likePointTime = new Date();
    param.topicId = item.id;
    param.entityId = this.entityId;
    this.likePointsService.create(param).subscribe(res => {
      const recond = res.body;
      if (recond && recond.id && recond.id !== null) {
        item.like_points = item.like_points + 1;
        item.likePoints = true;
        this.msg.info('点赞成功');
      }
    });
  }
  // 取消点赞
  removeLikePoint(item) {
    this.likePointsService.deleteByTopicId(item.id).subscribe(res => {
      item.likePoints = false;
      item.like_points--;
    });
  }


  pageIndexChange() {
    this.getCourseQuestions();
  }


  // 打开评论框
  openEvaluateModal() {
    this.modalHelper.open(EvaluateModalComponent,
      { courseName: this.courseInfo.courseName }, 680,
      { nzClassName: 'evaluate-modal' }).subscribe(() => {
      });
  }

  changeCourseFile() {

  }

  // 选中课时后执行   --新建
  selectItem(i) {
    if (this.activeIndex === i) {
      return;
    }
    this.currentTab = 0;
    this.activeIndex = i;
    this.currentCatalogName = this.catalogList[i].name;
    this.courseCatalogId = this.catalogList[i].id;
    this.getCourseFile(this.catalogList[i].id);
    // this.updateOrSaveStudyInfo(new StudyInfo());
    // this.updateOrSaveStudyHistory(new StudyHistory());
  }

  // 打开回复框
  slideToggleReplyBox(i) {
    $('#reply-box-' + i).slideToggle('fast');
  }

  // 添加答疑
  _submit() {
    this.submitLoading = true;
    const record = {
      eduCourseId: this.courseId,
      publisher: this.entityId,
      courseCatalogId: this.courseCatalogId,
      courseCatalogName: this.currentCatalogName,
      eduCourseName: this.courseInfo.courseName,
      isTop: '1',
      type: '0',
      likePoints: '0',
      replyNum: 0,
      publishTime: new Date(),
      description: this.form.controls['description'].value
    };
    this.learnService.saveQuestionList(record).subscribe((resp) => {
      this.submitLoading = false;
      if (resp.body && resp.body.id !== null) {
        this.msg.info('添加成功');
        this.form.controls['description'].setValue('');
        this.getCourseQuestions();
        this._close(true);
      } else {
        this.msg.info('添加失败');
        this._close(false);
      }
      this.submitting = false;
    });
  }

  // 添加子回复
  reply(item: any) {
    const record = {
      eduTopicId: item.id,
      replyUser: this.entityId,
      parentId: item.id,
      isTop: '1',
      isGet: '1',
      likePoints: '0',
      replyTime: new Date(),
      description: this.forms.controls['description'].value
    };
    this.submitting = true;
    this.learnService.saveQuestionReply(record).subscribe((resp) => {
      if (resp.body && resp.body.id !== null) {
        this.forms.controls['description'].setValue('');
        item.replyNum++;
        this.msg.info('回复成功');
        this.getCourseQuestions();
        this._close(true);
      } else {
        this.msg.info('添加失败');
        this._close(false);
      }
      this.submitting = false;
    });
  }
  /**
  * 组件关闭
  * @param refresh
  */
  _close(refresh: boolean) {
    // this.modal.destroy(refresh);
  }


  // 格式化视频时间
  formatDuration(duration: number) {
    if (!duration) {
      return '';
    }
    let hour = '0';
    let min = '0';
    let sec = '0';
    // 小时
    if (duration > 60 * 60) {
      const tmp = Math.floor(duration / (60 * 60));
      duration = duration % (60 * 60);
      if (tmp < 10) {
        hour = hour + tmp;
      } else {
        hour = tmp + '';
      }
    }
    // 分钟
    if (duration > 60) {
      const tmp = Math.floor(duration / 60);
      duration = duration % 60;
      if (tmp < 10) {
        min = min + tmp;
      } else {
        min = tmp + '';
      }
    }
    // 秒
    if (duration < 10) {
      sec = sec + duration;
    } else {
      sec = duration + '';
    }

    if (hour !== '0') {
      return hour + ':' + min + ':' + sec;
    }
    return min + ':' + sec;
  }


  /**
   * 遍历节点
   * @param node
   * @param hashMap
   * @param array
   */
  visitNode(node: EduCourseCatalogTreeNodeModel, hashMap: object, array: EduCourseCatalogTreeNodeModel[]): void {
    if (!hashMap[node.label]) {
      hashMap[node.label] = true;
      array.push(node);
    }
  }


  private initResourceList() {
    this.resourceList = [
      { name: '无', type: 'video', size: '0', 'url': '', 'isShow': false },
      { name: '无', type: 'ppt', size: '0', 'url': '', 'isShow': false },
      { name: '无', type: 'doc', size: '0', 'url': '', 'isShow': false },
    ];
  }

  // // 初始化定时器
  //  initTimer() {
  //   // 每61秒向后台请求一次，记录视频观看时间 ,考虑可能有延迟 延迟1秒
  //   this.speedTimer = setInterval(() => {
  //   this.video =  this.elementRef.nativeElement.querySelector('.video-js');
  // //  if (this.isAddEventListener === false) {
  //   // 暂停播放事件 发送数据给后台
  //  //   this.isAddEventListener = true;
  //     this.video.onpause = () => {
  //       this.studyHistory.studyHours = Math.ceil(this.video.currentTime) / 60;
  //       this.updateOrSaveStudyHistory(this.studyHistory);
  //     };

  //   // 结束播放事件 更新数据 并关闭定时器
  //     this.video.onended = () => {
  //       // this.studyHistory.studyHours = Math.ceil(this.video.currentTime) / 60;
  //       // this.updateOrSaveStudyHistory(this.studyHistory);
  //       if (this.speedTimer) {
  //         clearInterval(this.speedTimer);
  //       }
  //     };
  //   // 重新开始播放事件 创建历史记录
  //     this.video.oncanplay = () => {
  //       this.updateOrSaveStudyHistory(new StudyHistory());
  //     };
  // //  }
  //   // 视频播放时执行
  //     if (this.video.paused === false) {
  //       this.studyHistory.studyHours = Math.ceil(this.video.currentTime) / 60;
  //       this.updateOrSaveStudyHistory(this.studyHistory);
  //     }
  //   }, 3000);
  // }



  // tslint:disable-next-line: use-life-cycle-interface
  ngOnDestroy() {
    this.disposeVideo();

  }


  getStudyHistory() {
    return this.studyHistory;
  }
}
