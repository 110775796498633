import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ThsBaseService } from '@shared/components/thsBaseService';
import { map } from 'rxjs/operators';
import { BimCertProductRegedist } from './bim-cert-product-regedist.module';

@Injectable({
  providedIn: 'root'
})
export class BimCertProductRegedistService extends ThsBaseService<BimCertProductRegedist> {
    constructor(http: HttpClient, httpAddressService: HttpAddressService) {
      super(http, httpAddressService.BimServe + '/bim-cert-product-regedists');
    }


    saveProductByRegedists(param: any): Observable<HttpResponse<any>> {
      let url = this.resourceUrl + '/saveProductByRegedists';
      return this.http.get<any>(url, {params:param, observe: 'response'});
    }

}
