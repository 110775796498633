import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ThsBaseService } from '@shared/components/thsBaseService';
import { map } from 'rxjs/operators';
import { BimCertRegedist } from './bim-cert-regedist.module';

@Injectable({
  providedIn: 'root'
})
export class BimCertRegedistService extends ThsBaseService<BimCertRegedist> {
  constructor(http: HttpClient, httpAddressService: HttpAddressService) {
    super(http, httpAddressService.BimServe + '/bim-cert-regedists');
  }

  saveBimCertRegedist (param: any) {
     const url = this.resourceUrl + '/saveBimCertRegedist';
    return this.http.post<any>(url, param, { observe: 'response'});
  }

  queryByStatus(status: string, param ?: any): Observable<HttpResponse<any>> {
    return this.http.get<any>(this.resourceUrl + '/my-order-web/' + status, {params: param, observe: 'response'});
  }

  cancelOrder(id: string): Observable<any> {
    return this.http.patch<any>(this.resourceUrl + '/cancel/' + id, {observe: 'response'});
  }

  restoreOrder(id: string): Observable<any> {
    return this.http.patch<any>(this.resourceUrl + '/restore/' + id, {observe: 'response'});
  }

  expireOrder(id: string): Observable<any> {
    return this.http.patch<any>(this.resourceUrl + '/expire/' + id, {observe: 'response'});
  }

  wxPay(orderId: any) {
    return this.http.post<any>(this.resourceUrl + '/wx-pay/' + orderId, { observe: 'response'});
  }

  getMyOrderDetails(id: string): Observable<HttpResponse<any>>  {
    return this.http.get<any>(this.resourceUrl + '/my-order/detail/' + id, { observe: 'response'})
    .pipe(map((res: HttpResponse<any>) => this.convertDateFromServer(res)));
  }




  getPersonSchoolName(param ?: any): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/getPersonSchoolName`, {params: param, observe: 'response'});
  }


  wxPayBimYun(orderId: any) {
    return this.http.post<any>(this.resourceUrl + '/wx-pay-bimyun/' + orderId, { observe: 'response'});
  }

  getMyCertProductData(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/getMyCertProductData`, { observe: 'response'});
  }
}
