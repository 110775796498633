import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NzMessageService, NzModalRef} from "ng-zorro-antd";
import { isNullOrUndefined } from 'util';
import * as moment from 'moment';

@Component({
  selector: 'app-leader-edit',
  templateUrl: './leader-edit.component.html',
  styles: []
})
export class LeaderEditComponent implements OnInit {

  validateForm: FormGroup;
  record: any;
  isAdd = true;

  constructor(
    private nzModalRef: NzModalRef,
    private fb: FormBuilder,
    private msg: NzMessageService,
  ) { }

  ngOnInit() {
    if (this.isAdd) {
      this.validateForm = this.fb.group({
        name: [null, [Validators.required, Validators.maxLength(50)]],
        sex: [null, [Validators.required]],
        nation: [null, [Validators.required, Validators.maxLength(10)]],
        college: [null, [Validators.required, Validators.maxLength(100)]],
        job: [null, [Validators.required, Validators.maxLength(20)]],
        phone: [null, [Validators.required, Validators.pattern(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/)]],
        email: [null, [Validators.required, Validators.email]],
      })
    } else {
      this.validateForm = this.fb.group({
        name: [this.record.name, [Validators.required, Validators.maxLength(50)]],
        sex: [isNullOrUndefined(this.record.sex) ? null : Number(this.record.sex), [Validators.required]],
        nation: [this.record.nation, [Validators.required, Validators.maxLength(10)]],
        college: [this.record.college, [Validators.required, Validators.maxLength(100)]],
        job: [this.record.job, [Validators.required, Validators.maxLength(20)]],
        phone: [this.record.phone, [Validators.required, Validators.pattern(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/)]],
        email: [this.record.email, [Validators.required, Validators.email]],
      })
    }
  }

  onClose() {
    this.nzModalRef.destroy();
  }

  onSave() {
    // tslint:disable-next-line:forin
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[ i ].markAsDirty();
      this.validateForm.controls[ i ].updateValueAndValidity();
    }
    if (this.validateForm.invalid) {
      return;
    }
    if (this.isHasSpecialChar(this.validateForm.controls['name'].value)) {
      this.msg.warning('姓名不可有空格或特殊字符');
      return false;
    }
    if (this.isHasSpecialChar(this.validateForm.controls['nation'].value)) {
      this.msg.warning('民族不可有空格或特殊字符');
      return false;
    }

    if (this.isHasSpecialChar(this.validateForm.controls['college'].value)) {
      this.msg.warning('学院不可有空格或特殊字符');
      return false;
    }

    if (this.isHasSpecialChar(this.validateForm.controls['job'].value)) {
      this.msg.warning('职务不可有空格或特殊字符');
      return false;
    }


    if (isNullOrUndefined(this.record)) this.record = {};
    this.record['name'] = this.validateForm.get('name').value;
    this.record['sex'] = this.validateForm.get('sex').value;
    this.record['nation'] = this.validateForm.get('nation').value;
    this.record['college'] = this.validateForm.get('college').value;
    this.record['job'] = this.validateForm.get('job').value;
    this.record['phone'] = this.validateForm.get('phone').value;
    // this.record['duties'] = this.validateForm.get('duties').value;
    this.record['email'] = this.validateForm.get('email').value;
    this.nzModalRef.destroy(this.record);
  }

  isHasSpecialChar(str) {
    var pattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]");
    if(str.indexOf(' ') > -1 || pattern.test(str)){
      return true;
    } else {
      return false;
    }
  }

}
