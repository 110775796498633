import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { CacheService } from '@delon/cache';
import { NzMessageService } from 'ng-zorro-antd';
import { isNullOrUndefined } from 'util';
import { BimSchools } from '../school-register/bim-school.model';
import { BimSchoolsService } from '../school-register/bim-school.service';
import { BimInfoSchoolsService } from './bim-info-schools.service';

@Component({
  selector: 'app-school-information-manage',
  templateUrl: './school-information-manage.component.html',
  styleUrls: ['./school-information-manage.component.less']
})
export class SchoolInformationManageComponent implements OnInit {

  // 1邀请码  2审核中  3审核未通过

  public bimSchoolId = null;
  public bimSchool = null;

  public infoEventId = null;
  public bimInfoId = null;
  public bimInfo = this.cacheService.get('bimInfo')['value'];
  public isJoin = true;

  constructor(
    private router: Router,
    private msg: NzMessageService,
    private routeInfo: ActivatedRoute,
    private cacheService: CacheService,
    private bimSchoolsService: BimSchoolsService,
    private bimInfoSchoolsService: BimInfoSchoolsService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  ) {
    this.bimSchoolId = this.tokenService.get().bimSchoolId;
    this.infoEventId = this.routeInfo.snapshot.params['bimEventId'];
  }

  ngOnInit() {
    if (isNullOrUndefined(this.bimInfo)) {
      this.router.navigate(['/enroll/contestSelect']);
      return;
    }
    this.bimInfoId = this.bimInfo.id;
    this.initSchoolData();
    this.initBimInfoSchoolRelate();
  }

  editSchoolInfo() {
    this.router.navigate(['/enroll/team/schoolRegister' + `/${this.infoEventId}`], {
      queryParams: {
        isEdit: true,
      },
    });
  }

  initSchoolData() {
    if (isNullOrUndefined(this.bimSchoolId)) {
      this.msg.info('无此学校信息，请重新登录');
    } else {
      this.bimSchoolsService.getBimSchoolById(this.bimSchoolId).subscribe(res => {
        this.bimSchool = res;
      });
    }
  }

  initBimInfoSchoolRelate() {
    this.bimInfoSchoolsService.query({
      'bimInfoId.equals': this.bimInfoId,
      'schoolId.equals': this.bimSchoolId
    }).subscribe(res => {
      this.isJoin = res && res.body.length > 0;
    });
  }

  confirmJoin() {
    this.bimInfoSchoolsService.create({
      'bimInfoId': this.bimInfoId,
      'schoolId': this.bimSchoolId
    }).subscribe(res => {
      this.isJoin = true;
      this.msg.success('参加成功');
    });
  }

  formatDate(str: string) {
    const date = new Date(str);
    return date.getFullYear() + '年' + (date.getMonth() + 1) + '月' + date.getDate() + '日';
  }

}
