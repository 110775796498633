import { Injectable } from '@angular/core';
import {ThsBaseService} from "@shared/components/thsBaseService";
import {BimWorks} from "./bim-works.model";
import { HttpClient, HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BimWorksService extends ThsBaseService<BimWorks>{

  bimWorksUrl = '';

  constructor(
    public http: HttpClient,
    httpAddressService: HttpAddressService
  ) {
    super(http, httpAddressService.BimServe + '/bim-works');
    this.bimWorksUrl = httpAddressService.BimServe + '/bim-works';
  }

  showWorks(params): Observable<HttpResponse<BimWorks[]>> {
    return this.http.get<BimWorks[]>(this.bimWorksUrl + '/showWorks', { params: params, observe: 'response'});
  }

  findWorkDetails(params): Observable<HttpResponse<BimWorks>> {
    return this.http.get<BimWorks>(`${this.bimWorksUrl}/findWorkDetails`, { params: params, observe: 'response'});
  }

  updateUploadDate(id): Observable<HttpResponse<BimWorks>> {
    return this.http.get<BimWorks>(`${this.bimWorksUrl}/updateUploadDate/${id}`, { observe: 'response'});
  }
}
