import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { NzMessageService } from 'ng-zorro-antd';
import { UserService } from '../admin/user.service';
import { EduSoftwareDownloadService } from './download.service';
import { EduSoftwareSNService } from './software-sn.service';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.less'],
})
export class DownloadComponent implements OnInit {
  data = [
    {
      title: '规划与设计软件',
      children: [
        // {
        //   subTitle: '规划软件'
        // },
        { subTitle: '建筑设计' },
        { subTitle: '设备设计（MEP）' },
        // { subTitle: 'uniBIM for CAD平台' }
      ],
    },
    {
      title: 'BIM建模',
      children: [
        { subTitle: 'BIM建模快手' },
        { subTitle: 'BIM模型评分系统' },
      ],
    },
    {
      title: '装配式建筑',
      children: [
        { subTitle: '装配式建筑识图' },
        { subTitle: '装配式建筑施工' },
      ],
    },
    {
      title: '国产BIM 软件建模',
      children: [{ subTitle: 'UeBIM' }, { subTitle: 'ueBIM评分系统' }],
    },
    {
      title: '正向一体化BIM、CIM应用',
      children: [{ subTitle: 'BIM for Revit套包' }, 
        { subTitle: 'CIM平台' },
        { subTitle: '房地产智慧评估平台' }],
    },
    {
      title: '绿色建筑BIM应用',
      children: [{ subTitle: '绿色建筑系列软件' }],
    },
    {
      title: '数字建造与管理BIM应用',
      children: [
        // { subTitle: '三维算量for CAD 2023' },
        // { subTitle: '安装算量 for CAD 2023' },
        { subTitle: 'BIM for Revit套包' },
        { subTitle: '清单计价 2016' },
        { subTitle: '三维场布' },
        { subTitle: 'BIM 5D教育云平台'},
        { subTitle: 'BAP施工动画大师' }
      ],
    },
    {
      title: '数字建筑碳排放计算与减碳设计',
      children: [
        { subTitle: '碳排放计算软件' },
        { subTitle: '建筑碳排放CEEB' },
      ],
    },
  ];

  activeIndex = 0;
  activeSubIndex = 0;

  isVisible = false;
  isVisibleBR = false;

  modalContent =
    '检测到您的个人信息不完善；软件采取授权形式发放，请完善个人信息后，再点击软件下载';

  url = '';

  constructor(
    private eduSoftwareDownloadService: EduSoftwareDownloadService,
    private userService: UserService,
    private eduSoftwareSNService: EduSoftwareSNService,
    private router: Router,
    public msg: NzMessageService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  ) {}

  ngOnInit() {}

  change(index, subIndex) {
    console.log(index, subIndex);
    this.activeIndex = index;
    this.activeSubIndex = subIndex;
  }

  public handleOk() {
    if (this.url) {
      this.isVisible = false;
      window.open(this.url);
      this.url = null;
      this.isVisible = false;
      return;
    }
    this.isVisible = false;
    let uri = '';
    const token = this.tokenService.get();
    if (!token.token) {
      uri = '/download';
    } else {
      const type = token.entity_type;
      if (type === '1') {
        uri = '/admin/teacher/account';
      } else {
        uri = '/admin/student/account';
      }
    }
    this.router.navigateByUrl(uri);
  }

  public handleOkBR() {
    // 调用发送邮件接口
    this.msg.loading('获取授权码中，请稍后', { nzDuration: 10000 });
    const downloadConent = this.data[this.activeIndex].children[
      this.activeSubIndex
    ].subTitle;
    this.eduSoftwareSNService.applySoftwareSN(downloadConent).subscribe(res => {
      const result = res.body;
      debugger;
      if (result && result.success) {
        this.msg.remove();
        this.msg.info(
          '申请成功，系统已将授权码发送至您的邮箱与个人中心我的消息，请确认查收',
        );
        this.isVisibleBR = false;
        if (this.url) {
          this.isVisible = false;
          window.open(this.url);
          this.url = null;
          this.isVisible = false;
          return;
        }
      } else {
        this.msg.remove();
        this.msg.error(result.message);
        if (this.url) {
          window.open(this.url);
          this.url = null;
        }
        this.isVisibleBR = false;
      }
    });
  }

  public isDownload(url: string, name: string) {
    this.msg.remove();
    this.msg.loading('校验用户信息中，请稍后');
    const token = this.tokenService.get().token;
    if (!token) {
      this.msg.remove();
      this.isVisible = true;
      this.modalContent = '登录并完善信息后才能下载软件哦';
      return;
    }
    this.userService.checkDowmload().subscribe(res => {
      const result = res.body;
      if (result.success) {
        // const downloadConent = this.data[this.activeIndex].children[this.activeSubIndex].subTitle;
        // if (downloadConent === 'BIM for Revit 套包' || downloadConent === 'BIM 建模快手') {
        //   this.isVisibleBR = true;
        //   this.url = url
        //   this.msg.remove();
        // } else {
        this.addDownloadRecord(url, name);
        // }
      } else {
        this.msg.remove();
        this.modalContent = result.message;
        this.isVisible = true;
      }
    });
  }

  private addDownloadRecord(url: string, name: string) {
    const param = {
      softwareTypeName: this.data[this.activeIndex].title,
      softwareSubTypeName: this.data[this.activeIndex].children[
        this.activeSubIndex
      ].subTitle,
      softwareName: name,
    };
    this.eduSoftwareDownloadService
      .addEduSoftwareDownload(param)
      .subscribe(res => {
        this.msg.remove();
        const record = res.body;
        if (record && record.id) {
          this.url = url;
          this.handleOk();
        } else {
          this.modalContent = '网络繁忙，请重试';
          this.isVisible = true;
        }
      });
  }
}
