import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {DA_SERVICE_TOKEN, ITokenService} from "@delon/auth";
import {GuidUtil} from "@shared/utils/guid.util";
import {CacheService} from "@delon/cache";
import {BimSchoolsService} from "../school-register/bim-school.service";
import {BimWorksService} from "./bim-works.service";
import {ThsadminFileService} from "@shared/components/f-upload/thsadmin-file.service";
import { zip } from 'rxjs';
import {NzMessageService} from "ng-zorro-antd";

@Component({
  selector: 'app-work-submit',
  templateUrl: './work-upload.component.html',
  styleUrls: ['./work-upload.component.less'],
})
export class WorkUploadComponent implements OnInit {
  @ViewChild('projectUpload') projectUpload: any;
  @ViewChild('projectUpload2') projectUpload2: any;
  navList: any = [
    { name: '第一阶段作品提交（校内晋级赛）', disabled: false, code: 'first' },
    { name: '第二阶段作品提交（决赛）', disabled: false, code: 'second' },
  ];
  currentNav: any = null;
  fileFk1 = GuidUtil.getNewGUIDString();
  fileFk2 = GuidUtil.getNewGUIDString();
  teamId = '';
  teamInfo: any = {name: '',schoolTop: '',schoolId: ''};
  items = [{name: '',itemId: ''},{name: '', itemId: ''}];
  loading = false;
  curTeamEvent: any;
  isView1 = false;
  isView2 = false;
  currentStage = 1;
  type = 0;
  constructor(
    @Inject(DA_SERVICE_TOKEN) protected tokenService: ITokenService,
    private cacheService: CacheService,
    private bimSchoolsService: BimSchoolsService,
    private bimWorksService: BimWorksService,
    private thsadminFileService: ThsadminFileService,
    private msg: NzMessageService,
  ) {}

  ngOnInit() {
    this.currentNav = this.navList[0];
    this.teamId = this.cacheService.get('bimTeamId')['value'];
    this.curTeamEvent = this.cacheService.get('curTeamEvent')['value'];
    if (this.curTeamEvent.schoolWorkUploadStatus && this.curTeamEvent.schoolWorkUploadStatus === 1) {
      this.currentStage = 1;
      this.type = 0;
    }
    if (this.curTeamEvent.advisorsWorkUploadStatus && this.curTeamEvent.advisorsWorkUploadStatus === 1) {
      this.currentNav = this.navList[1];
      this.currentStage = 2;
      this.type = 1;
    } else if (this.curTeamEvent.advisorsWorkUploadStatus && this.curTeamEvent.advisorsWorkUploadStatus === 2) {
      this.currentStage = 3;//校内赛全国赛作品提交阶段都已经结束
      this.isView1 = true;
      this.isView2 = true;
    } else {
      this.navList[1].disabled = true;
    }
    this.getBimInfo();
  }

  getBimInfo() {
    if (this.teamId && this.teamId.length > 0) {
      this.bimSchoolsService.getBimTeamById(this.teamId).subscribe(res => {
        this.teamInfo = res;
        this.items = res.teamItems;
        if (this.type === 1) {
          this.items = [];
          // 20230330 如果团队已晋级，无论有没有第一阶段作品，第二阶段所有赛项都可上传作品
          if (res.schoolTop === 1) {
            res.teamItems.forEach(item => {
              // if (item.schoolTop && item.schoolTop === 1)  {
                this.items.push(item);
              // }
            });
          }
        }
        // if (!res.schoolTop || res.schoolTop === null || res.schoolTop === 0) {
        //   this.navList[1].disabled = true;
        // }
      })
    }
  }

  navClick(index,item) {
    debugger;
    if (item.disabled) {
      return;
    }
    this.isView1 = false;
    this.isView2 = false;
    this.currentNav = this.navList[index];
     if (index === 0) {
       //获取校内赛作品
       if (this.currentStage === 2 || this.currentStage === 3) {
         this.isView1 = true;
         this.isView2 = true;
         this.type = 0;
       }
     } else {
       this.type = 1;
     }
     if (this.currentStage === 3) {
       this.isView1 = true;
       this.isView2 = true;
     }
    this.items = this.teamInfo.teamItems;
    if (this.type === 1) {
      this.items = [];
      // 20230330 如果团队已晋级，无论有没有第一阶段作品，第二阶段所有赛项都可上传作品
      if (this.teamInfo.schoolTop === 1) {
        this.teamInfo.teamItems.forEach(it => {
          // if (item.schoolTop && item.schoolTop === 1)  {
            this.items.push(it);
          // }
        });
      }
    }
  }

  submit() {
    if (this.projectUpload) {
      this.projectUpload.submitForm();
      if (this.projectUpload.check) {
        if (this.projectUpload2) {
          this.projectUpload2.submitForm();
          if (this.projectUpload2.check) {
            if (this.items && this.items.length === 2) {
              const works1 = this.projectUpload.works;
              const works2 = this.projectUpload2.works;
              this.loading = true;
              if (works1 && works1.id && works2 && works2.id) {
                zip(
                  this.bimWorksService.update(works1),
                  this.bimWorksService.update(works2)
                ).subscribe(() => {
                  this.loading = false;
                  this.msg.success('提交成功！');
                })
              } else if (works1 && works1.id && works2 && !works2.id) {
                works2.workTop = 0;
                zip(
                  this.bimWorksService.update(works1),
                  this.bimWorksService.create(works2)
                ).subscribe(([res1,res2]) => {
                  this.loading = false;
                  this.msg.success('提交成功！');
                  if (res2 && res2.body) {
                    this.projectUpload2.works.id = res2.body.id;
                    const data = {
                      oldFk: this.fileFk2,
                      newFk: res2.body.id,
                    };
                    this.thsadminFileService.updateFileFkBatch(data).subscribe()
                  }
                })
              } else if (works1 && !works1.id && works2 && works2.id) {
                works1.workTop = 0;
                zip(
                  this.bimWorksService.create(works1),
                  this.bimWorksService.update(works2)
                ).subscribe(([res1,res2]) => {
                  this.loading = false;
                  this.msg.success('提交成功！');
                  if (res1 && res1.body) {
                    this.projectUpload.works.id = res1.body.id;
                    const data = {
                      oldFk: this.fileFk1,
                      newFk: res1.body.id,
                    };
                    this.thsadminFileService.updateFileFkBatch(data).subscribe()
                  }
                });
              } else {
                works1.workTop = 0;
                works2.workTop = 0;
                zip(
                  this.bimWorksService.create(works1),
                  this.bimWorksService.create(works2)
                ).subscribe(([res1,res2]) => {
                  this.loading = false;
                  this.msg.success('提交成功！');
                  console.log(res1, res2);
                  if (res1 && res1.body) {
                    this.projectUpload.works.id = res1.body.id;
                    const data = {
                      oldFk: this.fileFk1,
                      newFk: res1.body.id,
                    };
                    this.thsadminFileService.updateFileFkBatch(data).subscribe();
                  }
                  if (res2 && res2.body) {
                    this.projectUpload2.works.id = res2.body.id;
                    const data = {
                      oldFk: this.fileFk2,
                      newFk: res2.body.id,
                    };
                    this.thsadminFileService.updateFileFkBatch(data).subscribe()
                  }
                });
              }
            }
          }
        }
        if (this.items && this.items.length === 1) {
          const works1 = this.projectUpload.works;
          this.loading = true;
          if (works1 && works1.id) {
            this.bimWorksService.update(works1).subscribe(() => {
              this.loading = false;
              this.msg.success('提交成功！');
            })
          } else if (works1 && !works1.id) {
            works1.workTop = 0;
            this.bimWorksService.create(works1).subscribe(res => {
              if (res && res.body) {
                this.projectUpload.works.id = res.body.id;
                const data = {
                  oldFk: this.fileFk1,
                  newFk: res.body.id,
                };
                this.thsadminFileService.updateFileFkBatch(data).subscribe(() => {
                  this.loading = false;
                  this.msg.success('提交成功！');
                })
              }
            })
          }
        }

      }
    }
  }

  submitWork1() {
    if (this.projectUpload) {
      this.projectUpload.submitForm();
      if (this.projectUpload.check) {
        const works1 = this.projectUpload.works;
        this.loading = true;
        if (works1 && works1.id) {
          this.bimWorksService.update(works1).subscribe(() => {
            this.loading = false;
            this.msg.success('提交成功！');
          });
        } else if (works1 && !works1.id) {
          works1.workTop = 0;
          this.bimWorksService.query({
            'teamId.equals': works1.teamId,
            'workType.equals': works1.workType,
            'itemId.equals': works1.itemId
          }).subscribe(wRes => {
            if (wRes && wRes.body.length > 0) {
              // 已有提交作品 进行更新
              works1['id'] = wRes.body[0].id;
              this.bimWorksService.update(works1).subscribe(res => {
                if (res && res.body) {
                  this.projectUpload.works.id = res.body.id;
                  const data = {
                    oldFk: this.fileFk1,
                    newFk: res.body.id,
                  };
                  this.thsadminFileService.updateFileFkBatch(data).subscribe(() => {
                    this.loading = false;
                    this.msg.success('提交成功！');
                  });
                }
              });
            } else {
              // 无提交作品，进行新增
              this.bimWorksService.create(works1).subscribe(res => {
                if (res && res.body) {
                  this.projectUpload.works.id = res.body.id;
                  const data = {
                    oldFk: this.fileFk1,
                    newFk: res.body.id,
                  };
                  this.thsadminFileService.updateFileFkBatch(data).subscribe(() => {
                    this.loading = false;
                    this.msg.success('提交成功！');
                  });
                }
              });
            }
          })
        }
      }
    }
  }

  submitWork2() {
    if (this.projectUpload2) {
      this.projectUpload2.submitForm();
      if (this.projectUpload2.check) {
        const works2 = this.projectUpload2.works;
        this.loading = true;
        if (works2 && works2.id) {
          this.bimWorksService.update(works2).subscribe(() => {
            this.loading = false;
            this.msg.success('提交成功！');
          });
        } else if (works2 && !works2.id) {
          works2.workTop = 0;
          this.bimWorksService.query({
            'teamId.equals': works2.teamId,
            'workType.equals': works2.workType,
            'itemId.equals': works2.itemId
          }).subscribe(wRes => {
            if (wRes && wRes.body.length > 0) {
              works2['id'] = wRes.body[0].id;
              this.bimWorksService.update(works2).subscribe(res => {
                if (res && res.body) {
                  this.projectUpload2.works.id = res.body.id;
                  const data = {
                    oldFk: this.fileFk2,
                    newFk: res.body.id,
                  };
                  this.thsadminFileService.updateFileFkBatch(data).subscribe(() => {
                    this.loading = false;
                    this.msg.success('提交成功！');
                  });
                }
              });
            } else {
              this.bimWorksService.create(works2).subscribe(res => {
                if (res && res.body) {
                  this.projectUpload2.works.id = res.body.id;
                  const data = {
                    oldFk: this.fileFk2,
                    newFk: res.body.id,
                  };
                  this.thsadminFileService.updateFileFkBatch(data).subscribe(() => {
                    this.loading = false;
                    this.msg.success('提交成功！');
                  });
                }
              });
            }
          });
        }
      }
    }
  }
}
