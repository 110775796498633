import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ThsadminFileService } from '@shared/components/f-upload/thsadmin-file.service';
import { HttpAddressService } from '@shared/session/http-address.service';
import { isNullOrUndefined } from 'util';
import { BimWorks } from '../team/work-upload/bim-works.model';
import { BimWorksService } from '../team/work-upload/bim-works.service';

@Component({
  selector: 'app-work-preview',
  templateUrl: './work-preview.component.html',
  styleUrls: ['./work-preview.component.less'],
})
export class WorkPreviewComponent implements OnInit {
  public bimWorkId = null;
  imagePrefix = '';
  // 视频播放前缀
  videoPrefix: string;

  effect = 'scrollx';
  videoFileList = [];
  imageList = [];
  workInfo: BimWorks = new BimWorks();

  constructor(
    private routeInfo: ActivatedRoute,
    private bimWorksService: BimWorksService,
    private httpAddressService: HttpAddressService,
    private thsadminFileService: ThsadminFileService,
  ) {
    this.bimWorkId = this.routeInfo.snapshot.params['bimWorkId'];
    this.imagePrefix =
      this.httpAddressService.apiGateway + '/thsadmin/api/sys-files/preview/';
    this.videoPrefix =
      this.httpAddressService.fileGateway +
      this.httpAddressService.FileServer +
      '/sys-files/workFileView/';
  }
  ngOnInit(): void {
    if (!isNullOrUndefined(this.bimWorkId)) {
      this.getWorkDetails();
      this.getFiles();
    } else {
    }
  }

  getWorkDetails() {
    this.bimWorksService
      .findWorkDetails({ id: this.bimWorkId })
      .subscribe(res => {
        if (res && res.body) {
          this.workInfo = res.body;
        }
      });
  }

  getFiles() {
    const params = {
      'fileFk.equals': this.bimWorkId,
      sort: ['createdDate,asc'],
    };
    this.thsadminFileService.getSysFile(params).subscribe(res => {
      if (res && res.body && res.body.length > 0) {
        this.videoFileList = [];
        this.imageList = [];
        res.body.forEach(item => {
          const file = {
            uid: item.id,
            name: item.fileName,
            size: item.fileSize,
            url:
              this.httpAddressService.apiGateway +
              '/thsadmin/api/sys-files/download/' +
              item.id,
            path: item.fileSavePath,
          };
          if (item.extField1 === 'team_works_video') {
            file.url = this.videoPrefix + item.id;
            this.videoFileList.push(file);
          } else if (item.extField1 === 'team_works_image') {
            file.url = this.imagePrefix + item.id;
            this.imageList.push(file);
          }
        });
      }
    });
  }
}
