import { Component, OnInit } from '@angular/core';
import { HttpAddressService } from '@shared/session/http-address.service';
import { HomeService } from 'app/routes/enroll/home/home.service';
import { isNullOrUndefined } from 'util';
import { CacheService } from '@delon/cache';

@Component({
  selector: 'app-school-total',
  templateUrl: './school-total.component.html',
  styleUrls: ['./school-total.component.less'],
})
export class SchoolTotalComponent implements OnInit {

  public bimSchoolList = [];
  imagePrefix = '';
  public bimInfo;
  queryParams = {
    'searchValue.contains': null
  };
  isLoading = true;
  page = 0;
  size = 32;
  total = 0;

  constructor(
    private homeService: HomeService,
    private httpAddressService: HttpAddressService,
    private cacheService: CacheService,
  ) {
    this.imagePrefix = this.httpAddressService.apiGateway + '/ths-bim/api/sys-files/preview/';
    this.bimInfo = this.cacheService.get('bimInfo')['value'];
  }

  ngOnInit(): void {
    this.getBimSchool(true);
  }

  getBimSchool(isReset) {
    if(isReset){
      this.page = 0;
      this.bimSchoolList = [];
      this.total = 0;
    }
    this.isLoading = true;
    const params = Object.assign({
      'contenttype.equals': 'coopCollege',
      'istop.equals': 1,
      page: this.page,
      size: this.size,
      sort: ['author,asc'],
    },this.queryParams);

    Object.keys(params).forEach(key => {
      if(isNullOrUndefined(params[key]) || params[key] === '' || params[key].length == 0){
        delete params[key];
      }
    })
    
    if(this.bimInfo && this.bimInfo.type === 'province'){
      params['contenttype.equals'] = 'coopCollegeProvince';//省级赛事
      params['bimInfoId.equals'] = this.bimInfo.id;
    }else if(this.bimInfo && this.bimInfo.type === 'enterprise'){
      params['contenttype.equals'] ='coopCollegeEnterprise';//行业赛
      params['bimInfoId.equals'] = this.bimInfo.id;
    }

    this.homeService.getThsNews(params).subscribe(res => {
      const datas = res.body;
      
      if(datas){
        datas.forEach(item => {
          this.bimSchoolList = [...this.bimSchoolList,item];
        })
      }
      
      this.total = Number(res.headers.get('X-Total-Count'));
      this.isLoading = false;
    }, () => { this.isLoading = false });
  }

  search(){
    this.page = 0;
    this.getBimSchool(true);
  }

  next(){
    this.page++;
    this.getBimSchool(false);
  }

  openUrl(url) {
    if (!isNullOrUndefined(url)) {
      if (url.startsWith('http://') || url.startsWith('https://')) {
        window.open(url);
      } else {
        window.open('http://' + url);
      }
    }
  }

}
