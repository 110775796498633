import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { HomeComponent } from './home/home.component';
import { DynamicDetailComponent } from './home/dynamic-detail/dynamic-detail.component';
import { SchoolTotalComponent } from './home/school-total/school-total.component';
import { ContestSelectComponent } from './home/contest-select/contest-select.component';
import { CoopCarenterComponent } from './home/coop-parenter/coop-parenter.component';
import { LearningClassroomComponent } from './learning-classroom/learning-classroom.component';
import { WorksShowsComponent } from './works-shows/works-shows.component';
import { WorksShowsDetailComponent } from './works-shows/works-shows-detail/works-shows-detail.component';
import { GameComponent } from './game/game.component';
import { GameInfoComponent } from './game-info/game-info.component';
import { RegulationComponent } from './regulation/regulation.component';
import { ShowComponent } from './show/show.component';
import { GameStyleComponent } from './game-style/game-style.component';
import { ContestListComponent } from './contest-list/contest-list.component';
import { DownloadBimComponent } from './download/download.component';
import { GameStyleSelectComponent } from './game-style/game-style-select/game-style-select.component';
import { PreviewImgVideoComponent } from '@shared/components/preview-imgvideo/preview-imgvideo.component';
import { SoftDownloadBimComponent } from './download/soft-download/soft-download.component';

const routes: Routes = [
  {
    path: '',
    // component: LayoutComponent,
    children: [
      { path: '', component: HomeComponent },
      {
        path: 'contestSelect',
        component: ContestSelectComponent,
        data: { title: '选择大赛' },
      },
      {
        path: 'dynamicDetail',
        component: DynamicDetailComponent,
        data: { title: '大赛动态' },
      },
      {
        path: 'schoolTotal',
        component: SchoolTotalComponent,
        data: { title: '报名院校' },
      },
      {
        path: 'team',
        loadChildren: './team/team.module#TeamModule',
        data: { title: '团队赛' },
      },
      {
        path: 'personal',
        loadChildren: './personal/personal.module#PersonalModule',
        data: { title: '个人赛' },
      },
      {
        path: 'contest',
        component: GameComponent,
        data: { title: '各项赛事' },
      },
      {
        path: 'contestInfo',
        component: GameInfoComponent,
        data: { title: '赛事详情' },
      },
      // {
      //   path: 'contestInfo/:bimInfoId',
      //   component: GameInfoComponent,
      //   data: { title: '赛事详情' },
      // },
      {
        path: 'regulation',
        component: RegulationComponent,
        data: { title: '赛事详情' },
      },
      {
        path: 'show',
        component: ShowComponent,
        data: { title: '赛事详情' },
      },
      {
        path: 'contestStyle',
        component: GameStyleComponent,
        data: { title: '比赛风采' },
      },
      {
        path: 'contestStyleIndex',
        component: GameStyleSelectComponent,
        data: { title: '选择赛事比赛风采' },
      },
      {
        path: 'previews',
        component: PreviewImgVideoComponent,
        data: { title: '预览图片和视频' },
      },
      {
        path: 'contestInfos',
        component: ContestListComponent,
        data: { title: '大赛信息' },
      },
      {
        path: 'coopCarenter/:contentType',
        component: CoopCarenterComponent,
        data: { title: '合作伙伴' },
      },
      {
        path: 'learningClassroom',
        component: LearningClassroomComponent,
        data: { title: '学习课堂' },
      },
      {
        path: 'worksShows',
        component: WorksShowsComponent,
        data: { title: '作品展示' },
      },
      {
        path: 'worksShowsDetail',
        component: WorksShowsDetailComponent,
        data: { title: '作品详情' },
      },
      {
        path: 'download',
        component: SoftDownloadBimComponent,
        data: { title: '软件下载' },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EnrollRoutingModule {}
