import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharePageService } from './share-page.service';
import { HttpAddressService } from '@shared/session/http-address.service';
import {
  DA_SERVICE_TOKEN,
  ITokenService,
  LocalStorageStore,
} from '@delon/auth';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { LearnService } from '../course/learn/learn.service';
import { FileUploadService } from '@shared/components/fileUpload/fileUpload.service';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-share-page',
  templateUrl: './share-page.component.html',
  styleUrls: ['./share-page.component.less'],
})
export class SharePageComponent implements OnInit {
  constructor(
    private router: Router,
    public msg: NzMessageService,
    private routerInfo: ActivatedRoute,
    private learnService: LearnService,
    private modalService: NzModalService,
    private sharePageService: SharePageService,
    private fileUploadService: FileUploadService,
    private httpAddressService: HttpAddressService,
    @Inject(DA_SERVICE_TOKEN) protected tokenService: ITokenService,
  ) {
    const token = this.tokenService.get().token;
    if (token && token !== null && token !== '') {
      this.isLogin = true;
    } else {
      // 没登录跳回首页
      // this.router.navigateByUrl('/home');
    }
    this.routerInfo.queryParams.subscribe(res => {
      this.courseId = res.courseId;
    });
    this.courseCatalogId = this.routerInfo.snapshot.queryParams['catalogId'];
    this.downloadPrefix =
      this.httpAddressService.fileGateway +
      this.httpAddressService.systemServe +
      '/sys-files/download/';
    this.userAccount = this.tokenService.get().user_account; // 登录账号
    this.videoPrefix =
      this.httpAddressService.fileGateway +
      this.httpAddressService.FileServer +
      '/sys-files/view/';
  }

  courseId: any;

  ngOnInit() {
    this.initInfo();
    this.initMuluTree();
  }

  activeKey: number = 0;

  selectedValue: any = null;
  selectList: any[] = [{ name: 'ces', value: '123' }];

  courseActiveKey: any = null;
  oriCatalogList: any;
  catalogList: any;

  // 是否登录
  isLogin = false;
  // 课程实体
  courseInfo: any = {};
  canViewVideo = false;
  // 当前课时名称
  currentCatalogName: string;
  // 当前课时id
  courseCatalogId = '';
  // 下载课件前缀
  downloadPrefix: string;
  showVideo = true;
  trialEnd = false;
  isInit = false;
  myPlayer: any;
  // 用户账号
  userAccount = '';

  waterMarkLeft: number | string = '100%';

  public copyRightNotice =
    '【版权声明】本课程版权归提供者所有，仅限个人学习，严禁任何形式的录制、传播和账号分享。一经发现，平台将依法保留追究权，情节严重者将承担法律责任';

  public watermarkId = null;
  public watermarkFixId = null;
  // 是否显示版权声明
  public isShowRightNotice = false;
  public rightNoticeId = null;
  public rightNoticeFixId = null;
  // 是否显示水印
  isShowWatermark = false;
  // 视频播放前缀
  videoPrefix: string;
  currentTab = 0;
  activeIndex = 0;

  // 提交答疑按钮加载
  submitLoading = false;

  evaLoading = false;
  // 分页数据
  total = 0;
  page = 1;
  size = 10;
  // 答疑List
  questionList = [];

  searchValue = null;

  mainTabs = [
    { name: '视频', icon: '/assets/icons/video.png', url: '', files: [] },
    { name: 'PPT', icon: '/assets/icons/ppt.png', url: '', files: [] },
    { name: '文档', icon: '/assets/icons/doc.png', url: '', files: [] },
  ];

  initInfo() {
    this.sharePageService.getCrouseInfos(this.courseId).subscribe(res => {
      this.courseInfo = res.body;
    });
  }

  tabChange(index: number) {
    console.log(index);
  }

  // 收藏 评价
  isSaved: Boolean;
  handleSave(save) {
    this.isSaved = save;
  }

  layerShow: Boolean;
  handleAppraise() {
    this.layerShow = true;
  }

  star: any = 2.5;
  inputValue2: any;
  submit2() {
    const param = {
      eduCourseId: this.courseId,
      courseName: this.courseInfo.courseName,
      appraiseTime: new Date(),
      appraiseContent: this.inputValue2,
      appraiseGrade: this.star,
    };
    console.log(param);
    this.evaLoading = true;
    this.learnService.saveEvaluationlist(param).subscribe((resp) => {
      if (resp.body && resp.body.id !== null) {
        this.layerShow = false;
        this.evaLoading = false;
        this.msg.info('添加成功');
      } else {
        this.evaLoading = false;
        this.msg.info('添加失败');
      }
    });
  }

  // 课程目录
  initMuluTree() {
    this.sharePageService
      .getMuluTree({
        'eduCourseId.equals': this.courseId,
        sort: 'orderNum,asc',
      })
      .subscribe((res: any) => {
        console.log(res);
        this.oriCatalogList = res.body
        // .filter(e => e.id === this.courseCatalogId)
        .map(item => {
          return {
            ...item,
            duration: this.formatDuration(item.duration),
          };
        });
        this.catalogList = this.oriCatalogList
        if (this.catalogList.length > 0) {
          this.currentCatalogName = this.catalogList[0].name;
          if (!this.courseCatalogId || this.courseCatalogId === '') {
            this.courseCatalogId = this.catalogList[0].id;
            if (this.isLogin) {
              // this.getHomeWorkMsg();
            }
          }
          this.catalogList.forEach((item, index) => {
            if (this.courseCatalogId === item.id) {
              this.activeIndex = index;
              this.courseActiveKey = item.id;
              this.currentCatalogName = item.name;
            }
          });
          this.getCourseFile(this.courseCatalogId);
        }
      });
  }

  // 格式化视频时间
  formatDuration(duration: number) {
    if (!duration) {
      return '';
    }
    let hour = '0';
    let min = '0';
    let sec = '0';
    // 小时
    if (duration > 60 * 60) {
      const tmp = Math.floor(duration / (60 * 60));
      duration = duration % (60 * 60);
      if (tmp < 10) {
        hour = hour + tmp;
      } else {
        hour = tmp + '';
      }
    }
    // 分钟
    if (duration > 60) {
      const tmp = Math.floor(duration / 60);
      duration = duration % 60;
      if (tmp < 10) {
        min = min + tmp;
      } else {
        min = tmp + '';
      }
    }
    // 秒
    if (duration < 10) {
      sec = sec + duration;
    } else {
      sec = duration + '';
    }

    if (hour !== '0') {
      return hour + ':' + min + ':' + sec;
    }
    return min + ':' + sec;
  }

  videoUrl: any;
  videoResource: any;
  selectVideo(item: any) {
    this.videoResource = item;
    this.courseActiveKey = item.id;
    this.courseCatalogId = item.id;
    this.currentCatalogName = item.name;
    this.initVideoFile(item.id);
  }

  // 获取课件
  getCourseFile(catalogId: string) {
    console.log(this.courseInfo);
    this.initVideoFile(catalogId);
  }

  initVideoFile(catalogId: string) {
    this.waterMarkShow = false;
    const param = {
      'fileFk.equals': catalogId,
    };
    this.learnService.getCourseCatalogFile(catalogId).subscribe(res => {
      const records = res.body;
      this.initResourceList();
      this.resetMainTabs();
      if (records && records !== null && records.length > 0) {
        records.forEach(val => {
          if (val.extField1 === '0') {
            this.mainTabs[2].files.push(val);
            this.setResource(this.resourceList[2], val);
          }

          if (val.extField1 === '1') {
            this.mainTabs[1].files.push(val);
            this.setResource(this.resourceList[1], val);
          }

          if (val.extField1 === '2') {
            this.mainTabs[0].files.push(val);
            this.setResource(this.resourceList[0], val);
            setTimeout(() => {
              this.bindVideo();
              // this.initVideo();
              $('#my-video').bind('contextmenu', function() {
                // 取消右键事件
                return false;
              });
            }, 0);
          }
        });
        console.log(this.mainTabs);
        this.initMainTabs();
        // 存在视频
        if (this.mainTabs[0].url) {
        } else {
          this.disposeVideo();
        }
        if (!isNullOrUndefined(this.courseCatalogId)) {
          const index = this.catalogList.findIndex(
            e => e.id === this.courseCatalogId,
          );
          if (index && index > -1) {
            this.selectItem(index);
          }
        }
      }
    });
    // this.videoUrl = 'http://202.105.146.197:11480/thsfiles/api/sys-files/view/7ecfd52f-ae12-4bff-932c-f901f9b3f344?token=undefined&catalogId=cf5bd808-3804-482d-a4a0-cb5dcecf4290';
    // this.sharePageService
    //   .getVideo({
    //     'fileFk.equals': id,
    //   })
    //   .subscribe((res: any) => {
    //     this.videoUrl =
    //       'http://202.105.146.197:11480' +
    //       this.httpAddressService.FileServer +
    //       '/sys-files/view/' +
    //       res.body[0].id +
    //       '?token=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX3Byb3ZpbmNlIjoi5YyX5Lqs5biCIiwidXNlcl9jb2xsZWdlIjoi5YyX5Lqs5aSn5a2mIiwiZW50aXR5X25hbWUiOiLlvKDlrabnlJ8iLCJzZXgiOiIxIiwibW9iaWxlIjoiMTMyMTIzNDg5ODkiLCJ1c2VyX2NpdHkiOiLljJfkuqzluIJcclxuIiwiZW50aXR5X2lkIjoiOTQ1MDUwODMtNjEyNy00Yzc0LTljZWItNDA2MGU3ZDI4MzVhIiwiYWNjb3VudF9pZCI6ImFhYzU5ODUzLTliNDItNDEwMi1iOWNiLTZlNTc3ZTNiYmQzMiIsInVzZXJfdHlwZSI6IjAiLCJ1c2VyX2lkIjoiYWFjNTk4NTMtOWI0Mi00MTAyLWI5Y2ItNmU1NzdlM2JiZDMyIiwiZW50aXR5X2RlcGFydG1lbnQiOm51bGwsImFjY291bnRfbmFtZSI6InN0cjMzMzY2IiwidXNlcl9hY2NvdW50Ijoic3RyMzMzNjYiLCJlbnRpdHlfY29sbGVnZSI6IuWMl-S6rOWkp-WtpiIsImV4cCI6MTY5MDkzOTU3NiwiZW1haWwiOiIxNzYwMDU5NTg5MEAxNjMuY29tIn0.B9w0I5IU3XTuWXiLayz5_kAI3fZpOAzgouPKgmzHihF_mkkFa71EQLnbmVv73ybMlDQzkY4BJnFK0tGNgt-WCuF83fhOAQbixR-d0H2eVglmvaq1bzFnd0XHx056_GTiDdkLEkWBDgfSRDvfpXbpwvquSPDWuh64fcaX9fuGEh9aE-6TRnKWZuPDz8Jvj0yPrOjJT2oI4lNsyXZSDpx88zdvGAx_XuDc48eVWwO9o38T5cCx7S5rye1KSwjfXRXMEhtMYr66woYpa_v32hytXR5cPkCxHBuJi_dK7123tHo5ZeESJU5Y7YReFo-RUgH01oGvvodS9491f2DiCLuJlg&catalogId=cf5bd808-3804-482d-a4a0-cb5dcecf4290';
    //   });
  }

  // 选中课时后执行   --新建
  selectItem(i) {
    if (this.activeIndex === i) {
      return;
    }
    this.currentTab = 0;
    this.activeIndex = i;
    this.currentCatalogName = this.catalogList[i].name;
    this.courseCatalogId = this.catalogList[i].id;
    this.getCourseFile(this.catalogList[i].id);
  }

  disposeVideo() {
    if (this.myPlayer) {
      try {
        this.myPlayer.dispose();
      } catch (error) {
        console.log('error...');
      }
      this.isInit = false;
    }
  }

  private setResource(resource: any, record: any) {
    resource.isShow = true;
    resource.name = record.fileName;
    resource.size = this.fileUploadService.readablizeBytes(record.fileSize);
    resource.url = this.downloadPrefix + record.id;
  }

  // 课程答疑
  inputValue: string;
  submit() {
    this.submitLoading = true;
    const params = {
      courseCatalogId: this.courseActiveKey,
      courseCatalogName: this.currentCatalogName,
      description: this.inputValue,
      eduCourseId: this.courseId,
      eduCourseName: this.courseInfo.courseName,
      isTop: '1',
      likePoints: '0',
      publishTime: new Date(),
      replyNum: 0,
      type: '0',
    };
    console.log(params);
    this.learnService.saveWebQuestionList(params).subscribe(res => {
      this.submitLoading = false;
      if (res.body && res.body.id !== null) {
        this.msg.info('添加成功');
        this.inputValue = null;
        this.initQuestions();
      } else {
        this.msg.info('添加失败');
      }
    });
  }

  private initResourceList() {
    this.resourceList = [
      { name: '无', type: 'video', size: '0', url: '', isShow: false },
      { name: '无', type: 'ppt', size: '0', url: '', isShow: false },
      { name: '无', type: 'doc', size: '0', url: '', isShow: false },
    ];
  }

  quaList: any;
  initQuestions() {
    const param = {
      page: this.page - 1,
      size: this.size,
      sort: ['publish_time,desc'],
    };
    this.learnService
      .getWebAnswerList(this.courseId, param)
      .subscribe((res: any) => {
        this.questionList = res.body;
        this.total = res.headers.get('x-total-count');
      });
  }

  // 课程下载
  resourceList = [
    { name: '', type: 'video', size: '0', url: '', isShow: false },
    { name: '', type: 'ppt', size: '0', url: '', isShow: false },
    { name: '', type: 'doc', size: '0', url: '', isShow: false },
  ];

  getRandomNum(min: number, max: number) {
    const range = max - min;
    const rand = Math.random();
    return min + Math.round(rand * range);
  }

  // flag = true 15s自动消失,false = 始终显示,不自动消失
  addRightNotice() {
    if (!this.isShowRightNotice) {
      $('#' + this.rightNoticeId).remove();
      this.rightNoticeId = this.randomString(5);
      // tslint:disable-next-line: max-line-length
      const html =
        '<div class="copy-notice" style="text-align: center; " id="' +
        this.rightNoticeId +
        '"><span style="color: black;font-size:20px;opacity:0.3;filter:alpha(opacity=30);">' +
        this.copyRightNotice +
        '</span></div>';
      $('.vjs-text-track-display').prepend(html);
      this.isShowRightNotice = true;
      const removeId = this.rightNoticeId;
      setTimeout(() => {
        $('#' + removeId).remove();
        this.isShowRightNotice = false;
      }, 15000);
    }
  }

  addRightNoticeFix() {
    this.rightNoticeFixId = this.randomString(5);
    // tslint:disable-next-line: max-line-length
    const html =
      '<div class="copy-notice" style="text-align: center; " id="' +
      this.rightNoticeFixId +
      '"><span style="color: black;font-size:20px;opacity:0.3;filter:alpha(opacity=30);">' +
      this.copyRightNotice +
      '</span></div>';
    $('.vjs-text-track-display').prepend(html);
  }

  randomString(e) {
    e = e || 32;
    const t = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz';
    const a = t.length;
    let n = '';
    for (let i = 0; i < e; i++) {
      n += t.charAt(Math.floor(Math.random() * a));
    }
    return n;
  }

  private resetMainTabs() {
    this.mainTabs.forEach(val => {
      val.files = [];
      val.url = '';
    });
  }

  private resetMainTabsUrl() {
    this.mainTabs.forEach((val, index) => {
      if (this.currentTab !== index) {
        val.url = '';
      }
    });
  }

  private initMainTabs() {
    const val = this.mainTabs[0];
    if (val.files.length > 0) {
      val.url =
        this.videoPrefix +
        val.files[0].id +
        '?token=' +
        this.tokenService.get().token +
        '&catalogId=' +
        this.courseCatalogId +
        '&qrCodeView=true';
    }
  }

  download(item: any) {
    let a = document.createElement('a');
    let url = item.url;
    let filename = item.name;
    a.href = url;
    a.download = filename;
    a.click();
  }

  bindVideo() {
    let that = this;
    let video = document.getElementById('my-video');
    console.log(video)
    video.addEventListener('canplay', function() {
      that.handleRun();
    });
    video.addEventListener('play', function() {
      that.handleRun();
    });
    video.addEventListener('pause', function() {
      that.handleRun();
    });
  }
  waterMarkShow: Boolean;
  handleRun() {
    if (this.waterMarkShow) return;
    this.waterMarkShow = true;
    setTimeout(() => {
      this.waterMarkShow = false;
    }, 20000);
  }

  searchChange(value) {
    if (!isNullOrUndefined(value) && value.trim().length > 0) {
      this.catalogList = this.oriCatalogList.filter(e => e.name.indexOf(value.trim()) > -1);
    } else {
      this.catalogList = this.oriCatalogList;
    }
  }
}
