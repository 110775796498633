import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpResponse } from '@angular/common/http';
import { BimSchools } from './bim-schools.model';
import {ThsBaseService } from  '@shared/components/thsBaseService';
import { HttpAddressService } from '@shared/session/http-address.service';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export class BimSchoolsService extends ThsBaseService<BimSchools> {
    constructor(http: HttpClient, httpAddressService: HttpAddressService) {
        super(http, httpAddressService.BimServe + `/bim-schools`);
    }


    exportExcel(queryParams ?: any) {
        const url = `${this.resourceUrl}/exportExcel`;
    
        //需要token验证的打开下载链接的方式与不需要验证的windows.open是一样的
        this.http.get(url ,  {
          params: queryParams ,
          headers: new HttpHeaders({ 'Content-Type': 'application/vnd.ms-excel' }),
          observe: 'response',
          responseType: 'blob',
          withCredentials: true,
        }).subscribe(
          data => {
            let blob = new Blob([data.body]);
            let objectUrl = URL.createObjectURL(blob);
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display:none');
            a.setAttribute('href', objectUrl);
            a.setAttribute('download',  '学校名单' + '.xlsx');
            a.click();
            URL.revokeObjectURL(objectUrl);
          },
          error => {
            this.handleError('下载失败,请稍后重试!');
            console.error('学校名单下载失败 ->', error);
          },
        );
    
      }



      exportBimInfoSchoolExcel(queryParams ?: any) {
        const url = `${this.resourceUrl}/exportBimInfoSchoolExcel`;
    
        //需要token验证的打开下载链接的方式与不需要验证的windows.open是一样的
        this.http.get(url ,  {
          params: queryParams ,
          headers: new HttpHeaders({ 'Content-Type': 'application/vnd.ms-excel' }),
          observe: 'response',
          responseType: 'blob',
          withCredentials: true,
        }).subscribe(
          data => {
            let blob = new Blob([data.body]);
            let objectUrl = URL.createObjectURL(blob);
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display:none');
            a.setAttribute('href', objectUrl);
            a.setAttribute('download',  '学校名单' + '.xlsx');
            a.click();
            URL.revokeObjectURL(objectUrl);
          },
          error => {
            this.handleError('下载失败,请稍后重试!');
            console.error('学校名单下载失败 ->', error);
          },
        );
    
      }



    regenerateRegCode(schoolIds:any): Observable<HttpResponse<any>> {
        let url = this.resourceUrl + '/regenerateRegCode';
        return this.http.put<any>(url, schoolIds, { observe: 'response'});
    }


    sendSMSCode(mobile: string, code:string) {
      return this.http.post<any>(this.resourceUrl + '/sendSMSCode/' + mobile +'/' + code, { observe: 'response'});
    }
  
    sendEmailCode(email: string, code:string) {
      return this.http.post<any>(this.resourceUrl + '/email-code/' + email +'/' + code, { observe: 'response'});
    }


    
    findPageBimInfoSchools(queryParams?: any): Observable<HttpResponse<BimSchools[]>> {
      const tmpUrl = this.resourceUrl + '/findPageBimInfoSchools';
      return this.http.get<BimSchools[]>(tmpUrl, { params: queryParams, observe: 'response' }).pipe(
        tap(_ => this.log(`findPageBimInfoSchools`)),
        catchError(this.handleError<any>('findPageBimInfoSchools'))
      );
    }
  
      
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param ResultData - optional value to return as the observable ResultData
   */
  private handleError<T>(operation = 'operation', ResultData?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for module consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty ResultData.
      return of(ResultData as T);
    };
  }

  /** Log a ModuleService message with the MessageService */
  private log(message: string) {
    // this.messageService.info('ModuleService: ' + message);
    console.log('ResultDataService: ' + message);
  }

  // 获取bim大赛报名学校
  getProvinceSchoolInfoPage(queryParams?: any) {
    this.resourceUrl = `${this.resourceUrl}/getProvinceSchoolInfoPage`;
    return this.query(queryParams);
  }

}

