import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
import { ThsBaseService } from '@shared/components/thsBaseService';

@Injectable({
  providedIn: 'root',
})
export class SharePageService extends ThsBaseService<any> {
  private baseUrl = this.httpAddressService.EduServe;
  private fileUrl = this.httpAddressService.systemServe;
  private crouseUrl = '';
  private muluTreeUrl = '';
  private buyUrl = '';
  private videoUrl = '';
  private getQuestionsUrl = '';
  private publishUrl = '';

  constructor(
    public http: HttpClient,
    private httpAddressService: HttpAddressService,
  ) {
    super(http, httpAddressService.BimServe);
    this.crouseUrl = this.baseUrl + '/web/edu-courses/course/';
    this.muluTreeUrl = this.baseUrl + '/web/edu-course-catalogs/tree';
    this.buyUrl = this.baseUrl + '/edu-course-catalogs/buy/';
    this.videoUrl = this.fileUrl + '/sys-files';
    this.getQuestionsUrl = this.baseUrl + '/edu-topics/topicList/';
    this.publishUrl = this.baseUrl + '/edu-topics';
  }

  getCrouseInfos(id?: any) {
    this.resourceUrl = this.crouseUrl + id;
    return this.http.get(this.resourceUrl, {
      observe: 'response',
    });
  }

  getMuluTree(params?: any) {
    this.resourceUrl = this.muluTreeUrl;
    return this.http.get(this.resourceUrl, {
      params,
      observe: 'response',
    });
  }

  checkBuy(id?: any) {
    this.resourceUrl = this.buyUrl + id;
    return this.http.get(this.resourceUrl, {
      observe: 'response',
    });
  }

  getVideo(params?: any) {
    this.resourceUrl = this.videoUrl;
    return this.http.get(this.resourceUrl, {
      params,
      observe: 'response',
    });
  }

  getQuestions(id: string, params?: any) {
    this.resourceUrl = this.getQuestionsUrl + id;
    return this.http.get(this.resourceUrl, {
      params,
      observe: 'response',
    });
  }

  publish(params?: any) {
    this.resourceUrl = this.publishUrl;
    const copy = this.convertDateFromClient(params);
    return this.http.post(this.resourceUrl, copy, { observe: 'response' });
  }
}
