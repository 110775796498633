import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GuidUtil } from '@shared/utils/guid.util';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { CacheService } from '@delon/cache';

import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';

import { _HttpClient, ModalHelper } from '@delon/theme';
import { zip } from 'rxjs';
import * as moment from 'moment';
import { isNullOrUndefined } from 'util';
import { BimPersonItemsService } from 'app/routes/enroll/personal/bim-person-items/bim-person-items.service';
import { BimItemsService } from 'app/routes/enroll/bim-items/bim-items.service';
import { UcommonService } from 'app/routes/enroll/ucommon/ucommon.server';
import { BimInfoSchoolsEditComponent } from 'app/routes/enroll/school/bim-info-schools/edit/edit.component';
import { BimPerson } from 'app/routes/enroll/personal/bim-person.model';
import { BimPersonService } from 'app/routes/enroll/personal/bim-person.service';

@Component({
  selector: 'app-compet-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.less'],
})
export class CompetSignUpComponent implements OnInit {
  public personId = null;
  public bimEventId = null;
  schoolId: string;
  submitting = false;
  schoolTypeList = [];
  record = new BimPerson();
  title = '比赛报名';
  public signUpTitle = '';

  userEmail: string;
  entityName: string;
  entityId: string;
  entityMobile: string;

  ItemTypes = [];
  Items = [];
  personItems = [];

  projectList = [
    { label: '土建项目', value: '0', checked: false },
    { label: '机电项目', value: '1', checked: false },
  ];

  buttonIsVisible = true;
  form: FormGroup;

  public bimInfo = this.cacheService.get('bimInfo')['value'];
  public curTeamEvent = this.cacheService.get('curTeamEvent')['value'];
  public signUpflag = 0;

  public isItemSingle = 0;

  isfinish = false;

  constructor(
    private fb: FormBuilder,
    private msg: NzMessageService,
    private cacheService: CacheService,
    private router: Router,
    private routerInfo: ActivatedRoute,
    private modalService: NzModalService,
    private bimPersonService: BimPersonService,
    public modal: ModalHelper,
    private bimItemsService: BimItemsService,
    private bimPersonItemsService: BimPersonItemsService,
    private ucommonService: UcommonService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  ) {
    const mobile = this.tokenService.get().mobile;
    this.entityName = tokenService.get().entity_name;
    this.entityId = tokenService.get().entity_id;
    this.entityMobile = mobile;
    this.userEmail = tokenService.get().email;

    this.bimEventId = this.routerInfo.snapshot.params['bimEventId'];
  }

  ngOnInit() {
    this.initData();
    this.form = this.fb.group({
      id: [null, []],
      personName: [this.entityName, [Validators.required, Validators.maxLength(100)]],
      schoolName: [null, [Validators.required, Validators.maxLength(100)]],
      personTel: [
        this.entityMobile,
        [Validators.required, Validators.pattern(/^1\d{10}$/)],
      ],
      personEmail: [this.userEmail, [Validators.required, Validators.email]],
      isDelete: [0, []],
      //   project: [null,[]],
    });
    // this.form.get('personName').disable();
    // this.form.get('personTel').disable();
  }

  initData() {
    // 未报名
    this.signUpflag = 0;
    if (this.curTeamEvent && !isNullOrUndefined(this.curTeamEvent.startTime)) {
      const startTime = Date.parse(
        moment(this.curTeamEvent.startTime).format('YYYY-MM-DD'),
      );
      const endTime = Date.parse(
        moment(this.curTeamEvent.endTime).format('YYYY-MM-DD'),
      );
      const nowTime = Date.parse(moment(new Date()).format('YYYY-MM-DD'));
      this.signUpflag = nowTime < startTime ? 2 : nowTime > endTime ? 1 : 0;
    }
    // 不可报名
    if (this.signUpflag !== 0) {
      // 未到报名时间
      if (this.signUpflag === 2) {
        this.signUpTitle = '未到报名时间，不可报名！';
      }
      // 已过报名时间
      if (this.signUpflag === 1) {
        this.signUpTitle = '已过报名时间，不可报名！';
      }
      return;
    }
    this.isfinish = true;
    this.isItemSingle = isNullOrUndefined(this.curTeamEvent.isSingle) ? 0 : Number(this.curTeamEvent.isSingle);
    this.loadItems();
  }
 
  projectChange(checked, value) {
    this.projectList.forEach(item => {
      if (item.value !== value) {
        item.checked = false;
      } else {
        item.checked = checked;
      }
    });
  }

  //wxc 添加
  submit() {
    this.buttonIsVisible = false;
    const param = {};
    // tslint:disable-next-line: forin
    for (const key in this.form.controls) {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
      if (this.form.controls[key].value === true) {
        param[key] = '1';
      } else if (this.form.controls[key].value === false) {
        param[key] = '0';
      } else {
        param[key] = this.form.controls[key].value;
      }
    }
    param['schoolId'] = this.schoolId;
    param['bimInfoEventsId'] = this.bimEventId;
    param['userDefine1'] = '-1'; // 行业赛
    // param["creator"] = this.entityId;
    // param["updator"] = this.entityId;

    if (this.form.invalid) {
      this.msg.error('请检查个人信息是否完整');
      this.buttonIsVisible = true;
      return;
    }

    if (this.isMatchName(param['personName'])) {
      this.msg.error(
        '姓名不可包含字母、数字、符号！',
      );
      this.buttonIsVisible = true;
      return;
    }

    if (
      this.personItems == null ||
      this.personItems == undefined ||
      this.personItems.length <= 0
    ) {
      this.msg.error('没有选择参加的专项，请选择后保存！');
      this.buttonIsVisible = true;
      return;
    }

    const ids = [];
    this.personItems.forEach(item => {
      ids.push(item.itemId);
    });
    for (const key in param) {
      if (!isNullOrUndefined(param[key]) && key !== 'isDelete') {
        param[key] = param[key].trim();
      }
    }
    this.saveData(param, ids);
    // this.bimPersonService
    //   .checkPersonApply({
    //     bimInfoId: this.bimInfo.id,
    //     bimInfoEventId: param['bimInfoEventsId'],
    //     schoolId: param['schoolId'],
    //     personName: param['personName'],
    //     personTel: param['personTel'],
    //     itemIds: ids.toString(),
    //   })
    //   .subscribe((cRes: any) => {
    //     if (cRes && cRes.body) {
    //       const data = cRes.body.data;
    //       if (data && data.length > 0) {
    //         this.buttonIsVisible = true;
    //         let msg = '';
    //         data.forEach(e => (msg = msg + e + ','));
    //         this.modalService.warning({
    //           nzTitle:
    //             '同学，你已在本届【' +
    //             msg.substring(0, msg.length - 1) +
    //             '】获奖，不可再次参与',
    //           nzContent: '',
    //           nzOkText: '确定',
    //           nzOkType: 'warning',
    //         });
    //         // this.msg.error(JSON.stringify(data));
    //       } else {
    //         this.saveData(param, ids);
    //       }
    //     }
    //     console.log(cRes);
    //   });
  }

  saveData(param, ids) {
    this.bimPersonService.createWebPerson(param).subscribe(res => {
      this.record = res.body;
      if (this.record) {
        const subParam = {};
        subParam['personId'] = this.record.id;
        zip(
          this.bimPersonItemsService.saveWebItemByTeam({
            personId: this.record.id,
            ItemsIdList: ids.toString(),
          }),
        ).subscribe(() => {
          this.msg.info('报名成功！');
          //  this.buttonIsVisible = true;
          // window.location.reload();
        });
      } else {
        this.msg.error('保存失败');
        this.buttonIsVisible = true;
      }
    });
  }
  

  isMatchMobile(mobile: string) {
    const regex = /^1\d{10}$/;
    return mobile.match(regex);
  }

  selectSchool() {
    this.modal.static(BimInfoSchoolsEditComponent).subscribe(res => {
      if (res != null && res.length > 1) {
        this.msg.error('只能选择一个学校，请重新选择！');
        return false;
      } else if (res != null && res.length === 1) {
        this.schoolId = res[0].id;
        this.record.schoolName = res[0].name;
        this.record.personName = this.entityName;
        this.record.personTel = this.entityMobile;
        this.record.personEmail = this.userEmail;
        this.form.patchValue(this.record);
      }
    });
  }

  loadItems() {
    this.Items = [];
    this.ItemTypes = [];
    let copyParams = {};
    copyParams['sort'] = ['type,asc', 'orderSn,asc'];
    this.bimItemsService
      .getWebBimItemsInBimEvents(this.bimEventId, copyParams)
      .subscribe((res: any) => {
        this.Items = res.body;
        let tmpType = '';
        this.Items.forEach(element => {
          let tmpHasItem = this.ItemTypes.filter(d => d.type === element.type);
          if (element.type != tmpType && tmpHasItem.length <= 0) {
            tmpType = element.type;
            let tmpTypeObj = {};
            tmpTypeObj['type'] = tmpType;
            this.ItemTypes.push(tmpTypeObj);
          }
        });
      });
  }

  getItemByType(type) {
    return this.Items.filter(d => d.type === type).sort(d => d.orderSn);
  }

  getCheckBoxValue(itemId: string) {
    let tmpItems = this.personItems.filter(d => d.itemId === itemId);
    if (tmpItems.length > 0) {
      return 'true';
    } else {
      return '';
    }
  }

  checkboxClick($event, itemId: string) {
    const target = $event.target;
    if (target.checked === true) {
      if (this.isItemSingle === 1) {
        this.personItems = [];
        this.personItems.push({
          'itemId': itemId,
          'bimInfoEventsId': this.record.id
        });
      } else {
        let tmpItems = this.personItems.filter(d => d.itemId === itemId);
        if (tmpItems.length <= 0) {
          this.personItems.push({
            'itemId': itemId,
            'bimInfoEventsId': this.record.id
          });
        }
      }
    } else {
      let nindex;
      this.personItems.forEach((element, index) => {
        if (element.itemId === itemId) {
          nindex = index;
        }
      });

      //删除没有选择项的，并清空空记录
      delete this.personItems[nindex];
      this.personItems = this.ucommonService.delNullObj(this.personItems);
    }
  }

  isMatchName(str: string) {
    const regex = /([a-z0-9][a-z0-9]*)/;
    return str.match(regex);
  }
}
