export class NumberUtil {

  public static numberToChinese(num: any) {
    if (!/^\d*(\.\d*)?$/.test(num)) {
      alert('Number is wrong!');
      return 'Number is wrong!';
    }
    const AA = new Array('零', '一', '二', '三', '四', '五', '六', '七', '八', '九');
    const BB = new Array('', '十', '百', '千', '万', '亿', '点', '');
    const a = ('' + num).replace(/(^0*)/g, '').split('.');
    let k = 0;
    let re = '';
    for (let i = a[0].length - 1; i >= 0; i--) {
      switch (k) {
        case 0:
          re = BB[7] + re;
          break;
        case 4:
          if (!new RegExp('0{4}\\d{' + (a[0].length - i - 1) + '}$').test(a[0]))
            re = BB[4] + re;
          break;
        case 8:
          re = BB[5] + re;
          BB[7] = BB[5];
          k = 0;
          break;
      }
      if (k % 4 === 2 && a[0].charAt(i + 2) !== '0' && a[0].charAt(i + 1) === '0') re = AA[0] + re;
      if (a[0].charAt(i) !== '0') re = AA[a[0].charAt(i)] + BB[k % 4] + re;
      k++;
    }
    if (a.length > 1) {
      re += BB[6];
      for (let i = 0; i < a[1].length; i++) re += AA[a[1].charAt(i)];
    }
    return re;
  }

  public static isMatchEmail(email: string) {
    const regex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    return email.match(regex);
  }

  public static isMatchMobile(mobile: string) {
    const regex = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
    return mobile.match(regex);
  }

  public static isMatchQq(qq: string) {
    const regex = /[1-9][0-9]{4,14}$/;
    return qq.match(regex);
  }
}
