import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CacheService } from '@delon/cache';
import { HttpAddressService } from '@shared/session/http-address.service';
import { param } from 'jquery';
import { isNullOrUndefined } from 'util';
import { HomeService } from './home.service';
import * as moment from 'moment';
import { BimStore } from '@shared/store/bim.store';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less'],
})
export class HomeComponent implements OnInit {
  navList: any = [{ name: '协会发文' }, { name: '大赛动态' }];
  currentNav: any = { name: '大赛动态' };

  subNavList: any = [
    { name: '首页' },
    { name: '团队赛入口' },
    { name: '个人赛入口' },
    { name: '制度文件' },
    { name: '学习课堂' },
    { name: '软件下载' },
    { name: '作品展示' },
    // { name: '教育商城' },
  ];
  currentSubNav: any = { name: '首页' };

  public contentType = {
    协会发文: 'bimSocietyDoc',
    大赛动态: 'bimDynamic',
  };
  public tabTitle = '';
  public CONTENT_TYPE = '';
  public bimDynamicList = [];
  public page = 0;
  public size = 10;
  public total = '0';

  public bimInfoId = null;
  public bimInfo = this.cacheService.get('bimInfo')['value'];
  bimQqList = [];

  bimSchoolList = [];

  imagePrefix = '';
  public bimAdvertiseCenter = null;
  public bimAdvertiseBottom = null;

  testList = [
    [
      { name: '注册报名1', start: '2023年10月20日', end: '2023年12月12日' },
      { name: '注册报名2', start: '2023年10月20日', end: '2023年12月12日' },
      { name: '注册报名3', start: '2023年10月20日', end: '2023年12月12日' },
    ],
    [
      { name: '注册报名4', start: '2023年10月20日', end: '2023年12月12日' },
      { name: '注册报名5', start: '2023年10月20日', end: '2023年12月12日' },
      { name: '注册报名6', start: '2023年10月20日', end: '2023年12月12日' },
    ],
    [
      { name: '注册报名7', start: '2023年10月20日', end: '2023年12月12日' },
      { name: '注册报名8', start: '2023年10月20日', end: '2023年12月12日' },
    ],
  ];

  public processList = [];

  public initDataList = {
    bimSocietyDoc: [],
    bimDynamic: [],
  };

  constructor(
    private router: Router,
    private homeService: HomeService,
    private cacheService: CacheService,
    private bimStore: BimStore,
    private httpAddressService: HttpAddressService,
  ) {
    this.imagePrefix =
      this.httpAddressService.apiGateway + '/ths-bim/api/sys-files/download/';
  }

  ngOnInit() {
    if (isNullOrUndefined(this.bimInfo)) {
      // this.router.navigate(['/enroll/contestSelect']);
      this.router.navigate(['/enroll/contest']);
      return;
    }
    this.bimInfoId = this.bimInfo.id;
    for (let i = 0; i < this.navList.length; i++) {
      this.getThsNews(this.contentType[this.navList[i].name], true);
    }

    this.getBimQqData();
    this.getAdvestisement();
    this.getBimSchool(1000);
    this.getBimInfoProcess();
  }

  initData() {}

  getBimQqData() {
    const params = this.getParams();
    // params['sort'] = ['userDefine1,asc'];
    params['sort'] = ['createTime,asc'];
    this.homeService.getBimQqs(params).subscribe(res => {
      console.log(res);
      const data = res.body;
      if (data && data.length > 0) {
        data.forEach(e => {
          const name = e.name;
          const inx = name.indexOf('(');
          // if (inx > -1) {
          //   e.userDefine2 = name.substring(0, inx);
          //   e.userDefine3 = name
          //     .substring(inx, name.length)
          //     .replace('     ', '');
          // } else {
          //   e.userDefine2 = name;
          // }
          e.userDefine1 = e.userDefine1 ? e.userDefine1 : 'QQ';

          e.typeName = '号';
          if(e.userDefine1 === 'QQ' && e.userDefine1 === '微信'){
            e.typeName = '群';
          }
          if (inx > -1) {
            e.name2 = name.substring(0, inx);
            e.name3 = name
              .substring(inx, name.length)
              .replace('     ', '');
          } else {
            e.name2 = name;
          }
        });
      }
      this.bimQqList = data;
      console.log(data);
    });
  }

  getThsNews(contentType, isInit?: boolean) {
    const params = {
      'bimInfoId.equals': this.bimInfoId,
      'contenttype.equals': contentType,
      page: this.page,
      size: this.size,
      sort: ['istop,desc', 'createTime,desc'],
    };
    this.homeService.getThsNews(params).subscribe(res => {
      if (isInit) {
        this.initDataList[contentType] = res.body;
        this.tabChange(this.navList[1]);
      } else {
        this.bimDynamicList = res.body;
      }
      this.total = res.headers.get('X-Total-Count');
    });
  }

  getAdvestisement() {
    const params = this.getParams();
    params['sort'] = ['createTime,desc'];
    this.homeService.getBimAdversise(params).subscribe(res => {
      const data = res.body;
      if (data && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          const item = data[i];
          if (
            this.bimAdvertiseBottom !== null &&
            this.bimAdvertiseCenter !== null
          ) {
            return;
          }
          if (this.bimAdvertiseCenter === null && item.address === 'center') {
            this.bimAdvertiseCenter = item;
          }
          if (this.bimAdvertiseBottom === null && item.address === 'bottom') {
            this.bimAdvertiseBottom = item;
          }
        }
      }
    });
  }

  getBimSchool(size) {
    
    const params = {
      'contenttype.equals': 'coopCollege',//全国赛事
      'istop.equals': 1,
      page: 0,
      size: size,
      sort: ['author,asc'],
    };
    
    if(this.bimInfo.type === 'province'){
      params['contenttype.equals'] = 'coopCollegeProvince';//省级赛事
      params['bimInfoId.equals'] = this.bimInfoId;
    }else if(this.bimInfo.type === 'enterprise'){
      params['contenttype.equals'] ='coopCollegeEnterprise';//行业赛
      params['bimInfoId.equals'] = this.bimInfoId;
    }
    
    this.homeService.getThsNews(params).subscribe(res => {
      this.bimSchoolList = [];
      const list = res.body;
      if (list && list.length > 0) {
        for (let i = 0; i < list.length; i = i + 16) {
          this.bimSchoolList.push({
            schList: list.slice(i, i + 16),
          });
        }
      }
      console.log(this.bimSchoolList);
    });
  }

  openUrl(url) {
    if (!isNullOrUndefined(url)) {
      if (url.startsWith('http://') || url.startsWith('https://')) {
        window.open(url);
      } else {
        window.open('http://' + url);
      }
    }
  }

  getParams() {
    const params = {
      'bimInfoId.equals': this.bimInfoId,
      size: '10000',
    };
    return params;
  }

  formatDateYM(str: string) {
    return moment(str).format('YYYY-MM');
  }

  formatDateD(str: string) {
    return moment(str).format('DD');
  }

  /**
   * 页码数量变动事件
   * @param
   */
  paginationChangePage(event, contentType) {
    this.page = event - 1;
    this.getThsNews(contentType);
  }

  /**
   * 页码数量变动事件
   * @param
   */
  paginationChangeSize(event, contentType) {
    this.size = event;
    this.getThsNews(contentType);
  }

  dynamciClick(item) {
    this.router.navigate(['/dynamicDetail'], {
      queryParams: {
        id: item.id,
      },
    });
  }

  tabChange(item) {
    (this.page = 0), (this.tabTitle = item.name);
    this.CONTENT_TYPE = this.contentType[item.name];
    // this.getThsNews(this.CONTENT_TYPE);
    this.bimDynamicList = this.initDataList[this.CONTENT_TYPE];
  }

  selContest() {
    this.cacheService.set('bimInfo', null);
    this.bimStore.bimInfoStore.next(null);
  }

  getBimInfoProcess() {
    const params = this.getParams();
    params['isAble.equals'] = 1;
    params['sort'] = ['orderNum,asc'];
    this.homeService.getBimInfoProcess(params).subscribe(res => {
      const data = res.body;
      if (data && data.length > 0) {
        data.forEach(e => {
          const nodeList = [];
          const tempList = e.processNodeList;
          if (tempList && tempList.length > 0) {
            const processSize = tempList.length;
            let end = 0;
            while (end < processSize) {
              const start = end;
              end = end + 3;
              if (end > processSize) end = processSize;
              nodeList.push(tempList.splice(0, 3));
            }
          }
          e['nodeList'] = nodeList;
        });
        console.log(data);
      }
      this.processList = data;
    });
  }

  formatDate(str: string) {
    return moment(str).format('YYYY年MM月DD日');
  }

  reback(){
    this.cacheService.set('bimInfo',null);
    this.bimStore.bimInfoStore.next(null);
    this.router.navigate(['/enroll/contest']);
  }
}
