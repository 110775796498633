import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedModule } from "@shared/shared.module";
import { UtilsModule } from "@shared/utils/utils.module";
import { OrgRoutingModule } from "./org-routing.module";
import { SchoolRegisterComponent } from "./org-register/school-register.component";
import { OrgInformationManageComponent } from "./org-information-manage/school-information-manage.component";
import { OrgIndexComponent } from "./org-index/org-index.component";

const COMPONENTS = [
  OrgIndexComponent,
  SchoolRegisterComponent,
  OrgInformationManageComponent,
];
const COMPONENTS_NOROUNT = [
];

@NgModule({
  imports: [SharedModule, UtilsModule, CommonModule, OrgRoutingModule],
  declarations: [...COMPONENTS, ...COMPONENTS_NOROUNT],
  providers: [],
  entryComponents: COMPONENTS_NOROUNT,
})
export class OrgModule { }