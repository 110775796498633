import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CacheService } from '@delon/cache';
import { HttpAddressService } from '@shared/session/http-address.service';
import { param } from 'jquery';
import { isNullOrUndefined } from 'util';
import * as moment from 'moment';
import { HomeService } from 'app/routes/enterprise/home/home.service';


@Component({
  selector: 'app-enterprise-contest-wonderful',
  templateUrl: './contest-wonderful.component.html',
  styleUrls: ['./contest-wonderful.component.less'],
})
export class ContestWonderfulComponent implements OnInit {



  public bimInfoId = null;
  public bimInfo = this.cacheService.get('bimInfo')['value'];
  tabTitle = '比赛风采';
  

  constructor(
    private router: Router,
    private homeService: HomeService,
    private cacheService: CacheService,
    private httpAddressService: HttpAddressService
  ) {
    
  }

  ngOnInit() {
    
  }

}
