import { Component, Inject, OnInit } from '@angular/core';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { NzMessageService } from 'ng-zorro-antd';
import { isNullOrUndefined } from 'util';
import * as moment from 'moment';
import { BimSchoolsService } from '../school-register/bim-school.service';
import { CacheService } from '@delon/cache';
import { Router } from '@angular/router';
import { SchoolWorkScoringService } from '../school-work-scoring/school-work-scoring.service';

@Component({
  selector: 'app-school-reply-work',
  templateUrl: './school-reply-work.component.html',
  styleUrls: ['./school-reply-work.component.less']
})
export class SchoolReplyWorkComponent implements OnInit {

  public isRating = false;
  public bimSchoolId = null;
  public bimInfoEventId = null;

  public page = 1;
  public size = 10;
  public total = '0';
  schoolWorkList = [];
  public isloadding = false;

  public schoolWork = null;

  public queryParams = {
    // 查询搜索框的字段要预置
    'bimInfoItemId': null,
    'teamName': null,
  };
  public pageSort = ['advisorId', 'uploadDate,desc'];

  public bimInfo = this.cacheService.get('bimInfo')['value'];
  public curTeamEvent = this.cacheService.get('curTeamEvent')['value'];


  public title = null;
  // 全国评委id
  public advisorId = null;


  constructor(
    private router: Router,
    private msg: NzMessageService,
    private cacheService: CacheService,
    private bimSchoolsService: BimSchoolsService,
    private schoolWorkScoringService: SchoolWorkScoringService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  ) {
    this.bimSchoolId = this.tokenService.get().bimSchoolId;
    this.bimInfoEventId = this.curTeamEvent.id;
    this.advisorId = this.tokenService.get().reply_advisor_id;
  }

  ngOnInit(): void {
    if (isNullOrUndefined(this.bimSchoolId) && isNullOrUndefined(this.advisorId)) {
      this.msg.info('无注册学校信息，请重新登录');
      return;
    }
    if (isNullOrUndefined(this.bimInfo)) {
      this.router.navigate(['/enroll/contestSelect']);
      return;
    }
    this.title = '全国答辩评委分配作品';
    this.getDataList();
  }

  getDataList(isReset?: boolean, isSearch?: boolean) {
    const copyParams = this.getParams(isReset, isSearch);
    this.isloadding = true;
    const request = this.schoolWorkScoringService.getBimWorksReplyDataList(copyParams);
    request.subscribe(res => {
      this.isloadding = false;
      const data = res.body;
      if (data.length === 0 && this.page > 1) {
        this.page --;
        this.getDataList();
      }
      this.schoolWorkList = data;
      this.total = res.headers.get('X-Total-Count');
    });
  }

  getParams(isReset?: boolean, isSearch?: boolean) {
    const copyParams = {};
    const q = this.queryParams;
    if (isReset === true) {
      this.page = 0;
    }
    if (!isNullOrUndefined(this.advisorId)) {
      copyParams['advisorId'] = this.advisorId;
    }
    copyParams['bimInfoEventId'] = this.bimInfoEventId;
    Object.keys(q).forEach(function (key) {
      if (q[key] !== '' && q[key] !== null) {
        copyParams[key] = q[key];
      }
    });
    copyParams['page'] = this.page - 1;
    copyParams['size'] = this.size;
    if (isSearch) {
      this.page = 0;
      copyParams['page'] = 0;
    }
    copyParams['sort'] = this.pageSort;
    return copyParams;
  }

  formatSubmitStatus(value, date) {
    if (value + '' === '1') {
      return '已提交' + (isNullOrUndefined(date) ? '' : ` 【${moment(date).format('YYYYMMDD')}】`);
    } else {
      return '未提交';
    }
  }

  getStatusClass(value) {
    if (value + '' === '1') {
      return '';
    } else {
      return 'red';
    }
  }

  sort(sort: { key: string, value: string }): void {
    this.pageSort = [`${sort.key},${sort.value.replace('end', '')}`];
    this.getDataList();
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChange(event) {
    this.page = 0;
    this.size = event;
    this.getDataList();
  }

 /**
  * 页码变动事件
  * @param
  */
  changePageIndex(pageIndex) {
    this.page = pageIndex;
    this.getDataList();
  }

  returnPage(data) {
    this.isRating = false;
  }

  viewWork(item) {
    this.isRating = true;
    this.schoolWork = item;
    this.schoolWork.isSchoolScore = false;
    this.schoolWork.advisorId = this.advisorId;
  }

}
