import { Component, Inject, OnInit } from '@angular/core';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { ModalHelper } from '@delon/theme';
import { UserLoginComponent } from 'app/routes/passport/login/login.component';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { isNullOrUndefined } from 'util';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import { CacheService } from '@delon/cache';
import { BimSchoolsService } from 'app/routes/enroll/team/school-register/bim-school.service';

@Component({
  selector: 'app-org-index',
  templateUrl: './org-index.component.html',
  styleUrls: ['./org-index.component.less'],
})
export class OrgIndexComponent implements OnInit {
  public id = this.cacheService.get('bimEventId')['value'];
  navList: any = [
    { name: '单位注册', link: `/enterprise/org/orgRegister/${this.id}`, isNeedLogin: true, isdisable: true, isReadOnly: true  }, // 1
    { name: '单位信息管理', link: `/enterprise/org/orgInformationManage/${this.id}`, isNeedLogin: true, isdisable: true, isReadOnly: true  }, // 2
  ];

  public schoolNavIndex = [0, 1];

  currentNav: any = null;
  // 是否登录
  isLogin = false;
  isInitStudent = false;
  isInitTeacher = false;

  public curTeamEvent = this.cacheService.get('curTeamEvent')['value'];

  constructor(
    private router: Router,
    private msg: NzMessageService,
    private modalHelper: ModalHelper,
    private cacheService: CacheService,
    private modalService: NzModalService,
    private bimSchoolsService: BimSchoolsService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  ) {
    let tokenVal = this.tokenService.get();
    const token = tokenVal.token;
    let userType = tokenVal.entity_type;
    const mobile = tokenVal.mobile;
    this.currentNav = this.navList[0];
    router.events.subscribe(evt => {
      if (evt instanceof NavigationEnd) {
        const id = this.cacheService.get('bimEventId')['value'];
        this.navList.forEach(item => {
          item.link = item.link.replace(this.id, id);
          if (evt.url === item.link) {
            this.currentNav = item;
          }
        });

        tokenVal = this.tokenService.get();
        userType = tokenVal.entity_type;
        if ((userType === '0' || userType === '2') && (!this.isInitStudent || this.id !== id)) {
          this.isInitStudent = true;
          // 学生
          // this.schoolNavIndex.forEach(e => {
          //   this.navList[e].isdisable = false;
          // });
          this.curTeamEvent = this.cacheService.get('curTeamEvent')['value'];

          // 查询学生是否加入团队
          this.bimSchoolsService.getTeamStudentByTelAndEventId({
            'mobile': mobile,
            'eventId': id
          }).subscribe(res => {
            if (res && res.body.length > 0) {
              const data = res.body[0];
              // 有加入团队
              this.cacheService.set('bimTeamId', data.teamId);
            } else {
              this.cacheService.set('bimTeamId', null);
            }
          });
        }

        if ((userType === '1') && (!this.isInitTeacher || this.id !== id)) {
          this.cacheService.set('bimTeamId', null);
          this.isInitTeacher = true;
        }
        this.id = id;
      }
    });
    if (token && token !== null && token !== '') {
      this.isLogin = true;
      if (userType === '1') {
        this.cacheService.set('bimTeamId', null);
        // 老师
        const bimSchoolId = this.tokenService.get().bimSchoolId;
        if (isNullOrUndefined(bimSchoolId)) {
          this.navList[0].isReadOnly = false;
        } else {
          this.navList[1].isReadOnly = false;
        }
      }
    }
  }

  ngOnInit() {
  }

  clickNav(item) {
    if (this.isLogin || !item.isNeedLogin) {
      this.routerNav(item);
    } else {
      this._loginUser(item);
    }
  }

  routerNav(item) {
    // this.currentNav = item;
    this.router.navigate([item.link]);
  }

  /**
   * 登陆
   */
  _loginUser(item) {
    this.modalService.confirm({
      nzTitle: '确认确定登录?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.modalHelper.static(UserLoginComponent, {  }, 600).subscribe((data: any) => {
          if (data) {
            this.msg.info('登陆成功');
            this.isLogin = true;
            this.routerNav(item);
          }
        });
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }
}
