import { Injectable,Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EnterpriseStore {
    // 游戏大种类id
    public bimInfoStore: BehaviorSubject<any> = new BehaviorSubject(null);
  
    constructor() {

    }
}