import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { UserService } from 'app/routes/admin/user.service';
import { EduSoftwareDownloadService } from 'app/routes/download/download.service';
import { EduSoftwareSNService } from 'app/routes/download/software-sn.service';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { BimSoftDownloadTypeService } from './soft-type.service';
import { CacheService } from '@delon/cache';
import { ArrayService } from '@delon/util';
import { BimSoftDownloadDetailsService } from './soft-detail.service';
import { isNullOrUndefined } from 'util';
import { UserLoginComponent } from 'app/routes/passport/login/login.component';
import { ModalHelper } from '@delon/theme';

@Component({
  selector: 'app-soft-download',
  templateUrl: './soft-download.component.html',
  styleUrls: ['./soft-download.component.less'],
})
export class SoftDownloadBimComponent implements OnInit {
  activeIndex = 0;
  activeSubIndex = 0;
  thirdIndex = null;

  isVisible = false;
  isVisibleBR = false;

  modalContent =
    '检测到您的个人信息不完善；软件采取授权形式发放，请完善个人信息后，再点击软件下载';

  url = '';

  public softTypeList: any;
  public bimInfo = this.cacheService.get('bimInfo')['value'];
  public bimInfoId: String;
  public curSelSoftTypeId = null;
  public softDetailList = [];
  public curSelSoftType = null;

  public loadingEnd = false;

  public loadingTypeEnd = false;

  public item = {
    url: null,
    name: null,
  };

  constructor(
    private router: Router,
    public msg: NzMessageService,
    private modalHelper: ModalHelper,
    private userService: UserService,
    private cacheService: CacheService,
    private arrayService: ArrayService,
    private modalService: NzModalService,
    private eduSoftwareSNService: EduSoftwareSNService,
    private bimSoftDownloadTypeService: BimSoftDownloadTypeService,
    private eduSoftwareDownloadService: EduSoftwareDownloadService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
    public bimSoftDownloadDetailsService: BimSoftDownloadDetailsService,
  ) {}

  ngOnInit() {
    this.bimInfoId = this.bimInfo.id;
    this.getSoftTypeData();
  }

  getSoftTypeData() {
    this.loadingTypeEnd = false;
    this.bimSoftDownloadTypeService.getBimSoftDownloadType({
      'bimInfoId.equals': this.bimInfoId,
      'isAble.equals': '1',
      page: 0,
      size: 1000,
      sort: ['orderNum','name']
    }).subscribe(res => {
      this.loadingTypeEnd = true;
      const data = res.body;
      this.softTypeList = this.arrayService.arrToTree(data, {
        parentIdMapName: "parentId",
      });
      if (this.softTypeList && this.softTypeList.length > 0) {
        const type = this.softTypeList[0];
        if (type.children && type.children.length > 0) {
          this.curSelSoftType = type.children[0];
          this.curSelSoftTypeId = type.children[0].id;
          this.getSoftDetailData();
        }
      }
    });
  }

  getSoftDetailData() {
    if (!isNullOrUndefined(this.curSelSoftTypeId)) {
      this.loadingEnd = false;
      this.bimSoftDownloadDetailsService.getBimSoftDownloadDetail({
        'softTypeId.equals': this.curSelSoftTypeId,
        'isAble.equals': '1',
        page: 0,
        size: 1000,
        sort: ['orderNum','name']
      }).subscribe(res => {
        this.loadingEnd = true;
        this.softDetailList = res.body;
      });
    }
  }

  change(index, subIndex, thirdI?: number, item?: any) {
    console.log(index, subIndex, thirdI);
    this.activeIndex = index;
    this.activeSubIndex = subIndex;
    if (thirdI != undefined) {
      this.thirdIndex = thirdI;
    } else {
      this.thirdIndex = null;
    }
    this.curSelSoftType = item;
    this.curSelSoftTypeId = item.id;
    this.getSoftDetailData();
  }

  public handleOk() {
    if (this.url) {
      this.isVisible = false;
      window.open(this.url);
      this.url = null;
      this.isVisible = false;
      return;
    }
    this.isVisible = false;
    let uri = '';
    const token = this.tokenService.get();
    if (!token.token) {
      this._loginUser({
        'link': '/enroll/download'
      });
      // uri = '/enroll/download';
    } else {
      const type = token.entity_type;
      if (type === '1') {
        uri = '/admin/teacher/account';
      } else {
        uri = '/admin/student/account';
      }
      this.router.navigateByUrl(uri);
    }
  }

  public handleOkBR() {
    // 调用发送邮件接口
    this.msg.loading('获取授权码中，请稍后', { nzDuration: 10000 });
    const downloadConent = this.curSelSoftType.name;
    this.eduSoftwareSNService.applySoftwareSN(downloadConent).subscribe(res => {
      const result = res.body;
      debugger;
      if (result && result.success) {
        this.msg.remove();
        this.msg.info(
          '申请成功，系统已将授权码发送至您的邮箱与个人中心我的消息，请确认查收',
        );
        this.isVisibleBR = false;
        if (this.url) {
          this.isVisible = false;
          window.open(this.url);
          this.url = null;
          this.isVisible = false;
          return;
        }
      } else {
        this.msg.remove();
        this.msg.error(result.message);
        if (this.url) {
          window.open(this.url);
          this.url = null;
        }
        this.isVisibleBR = false;
      }
    });
  }

  public isDownload(url: string, name: string) {
    this.msg.remove();
    this.msg.loading('校验用户信息中，请稍后');
    const token = this.tokenService.get().token;
    if (!token) {
      this.item.url = url;
      this.item.name = name;
      this.msg.remove();
      this.isVisible = true;
      this.modalContent = '登录并完善信息后才能下载软件哦';
      return;
    }
    this.userService.checkDowmload().subscribe(res => {
      const result = res.body;
      if (result.success) {
        // const downloadConent = this.data[this.activeIndex].children[this.activeSubIndex].subTitle;
        // if (downloadConent === 'BIM for Revit 套包' || downloadConent === 'BIM 建模快手') {
        //   this.isVisibleBR = true;
        //   this.url = url
        //   this.msg.remove();
        // } else {
        this.addDownloadRecord(url, name);
        // }
      } else {
        this.msg.remove();
        this.modalContent = result.message;
        this.isVisible = true;
      }
    });
  }

  private addDownloadRecord(url: string, name: string) {
    const list = this.softTypeList.filter(e => e.id === this.curSelSoftType.parentId);
    const softTypeName = (list && list.length > 0) ? list[0].name : this.curSelSoftType.name;
    this.softTypeList.filters
    const param = {
      softwareTypeName: softTypeName,
      softwareSubTypeName: this.curSelSoftType.name,
      softwareName: name,
    };
    this.eduSoftwareDownloadService
      .addEduSoftwareDownload(param)
      .subscribe(res => {
        this.msg.remove();
        const record = res.body;
        if (record && record.id) {
          this.url = url;
          this.handleOk();
        } else {
          this.modalContent = '网络繁忙，请重试';
          this.isVisible = true;
        }
      });
  }
  

  /**
   * 登陆
   */
  _loginUser(item) {
    this.modalHelper.static(UserLoginComponent, {  }, 600).subscribe((data: any) => {
      if (data) {
        this.msg.info('登陆成功');
        this.isDownload(this.item.url, this.item.name);
      }
    });
  }

  routerNav(item) {
    this.router.navigate([item.link]);
  }
}
