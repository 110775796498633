import { Component, OnInit,Inject } from '@angular/core';
import { HomeService } from './home.service';
import { HttpAddressService } from '@shared/session/http-address.service';
import * as moment from 'moment';
import { ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';
import { Router } from '@angular/router';
import { NzModalService, NzMessageService } from 'ng-zorro-antd';
import { ModalHelper } from '@delon/theme';
import { UserLoginComponent } from '../passport/login/login.component';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})
export class HomeComponent implements OnInit {

  params = {};
  dataSet: any[] = [];
  bannerset = [];
  imagePrefix = '';
  personAvatarSRC = {};
  ItemTypeListArray: any[] = [];

  courseList=[];

    // 是否登录
    isLogin = false;


  public liveBroadcastsPage = 0;
  public liveBroadcastsSize = 6;
  public liveBroadCastTotal = '0';
  public liveBroadcastList = [];

  public thsNewsList=[];

  previewAddress: string;

  constructor(
    public msg: NzMessageService,
    private homeService: HomeService,
    private httpAddressService: HttpAddressService,
    private modalService: NzModalService,
    private router: Router,
    private modalHelper: ModalHelper,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  ) {
   // this.imagePrefix = this.httpAddressService.imgServe + '/sys-files/download/';
  //  this.previewAddress = this.httpAddressService.apiGateway + '/thsadmin/api/sys-files/download/';

  
  //this.imagePrefix = this.httpAddressService.imgServe + '/sys-files/downloadOrPreviewForCache?tenantCode=default&isPreview=false&fileId=';
  //this.previewAddress = this.httpAddressService.apiGateway + '/thsadmin/api/sys-files/downloadOrPreviewForCache?tenantCode=default&isPreview=false&fileId=';
  

  this.imagePrefix = this.httpAddressService.imgServe + '/sys-files/preview/';
  this.previewAddress = this.httpAddressService.apiGateway + '/thsadmin/api/sys-files/preview/';


}

  featureCourseList = [
    { title: 'BIM', desc: '建筑信息模型是建筑学、工程学及土木工程的新工具。建筑信息模型或建筑资讯模型一词由Autodesk所创的。', src: '/assets/images/u36.jpg' },
    { title: '施工', desc: '施工，谓工程按计划进行建造。语出宋 朱熹 《西原崔嘉彦书》：“向说栽竹木处，恐亦可便令施工也。', src: '/assets/images/u36.jpg' },
    { title: '造价', desc: '建筑信息模型是建筑学、工程学及土木工程的新工具。建筑信息模型或建筑资讯模型一词由Autodesk所创的。', src: '/assets/images/u36.jpg' },
    { title: '项目管理', desc: '施工，谓工程按计划进行建造。语出宋 朱熹 《西原崔嘉彦书》：“向说栽竹木处，恐亦可便令施工也。', src: '/assets/images/u36.jpg' }
  ];

  teachingCourseList = [
    { title: '本科教学课程', desc: '建筑信息模型是建筑学、工程学及土木工程的新工具。建筑信息模型或建筑资讯模型一词由Autodesk所创的。', src: '/assets/images/u36.jpg' },
    { title: '高职教学课程', desc: '施工，谓工程按计划进行建造。语出宋 朱熹 《西原崔嘉彦书》：“向说栽竹木处，恐亦可便令施工也。', src: '/assets/images/u36.jpg' },
  ];

  certificationList = [
    { title: '1+X认证', desc: '建筑信息模型是建筑学、工程学及土木工程的新工具。建筑信息模型或建筑资讯模型一词由Autodesk所创的。', src: '/assets/images/u36.jpg' },
    { title: '全国BIM应用技能认证', desc: '施工，谓工程按计划进行建造。语出宋 朱熹 《西原崔嘉彦书》：“向说栽竹木处，恐亦可便令施工也。', src: '/assets/images/u36.jpg' },
  ];

  // 视口宽度(包括滚动条)
  viewportW: number;
  // 滚动条宽度
  scrollBarW: number;
  // 内容固定宽度
  fixedW = 1200;
  // 负边距百分比
  mPercent: string = '';
  // 当前用户实体id
  entityId = '';
 // 当前用户id
  userId = '';

  ngOnInit() {
    this.getUserData();
    // 页面初始化 加载数据
  //  this.getCourseClassifies();

    this.getCouserListNew();
    this.getAllEduLiveBroadcasts();

    this.getThsNews();
  }


    // 获取用户信息
    getUserData() {
      const token = this.tokenService.get().token;
      this.userId = this.tokenService.get().user_id;
      this.entityId = this.tokenService.get().entity_id;
      if (token && token !== null && token !== '') {
        this.isLogin = true;
      }
    }

  // 获取教育云分类
  getCourseClassifies () {
    // 分类 0 为无效 1 为有效
    this.homeService.geteducourseclassifies().subscribe((listRes: any) => {
      this.dataSet = listRes.body.data;
      this.getCouserList();
    });
  }
  // 查询专题分类四条数据
  getCouserList() {
    if (this.dataSet !== undefined && this.dataSet != null) {
      const copyParams = {};
          for (let i = 0; i < this.dataSet.length; i++) {
            copyParams['classifyId'] = this.dataSet[i].id;
            this.homeService.geteducoursestop4(copyParams).subscribe((subres: any) => {
                 this.ItemTypeListArray[i] = subres.body.data;
               });
          }
    }
  }


  getCouserListNew() {
   
      const copyParams = {};
      copyParams['classifyId'] = "";
      this.homeService.geteducoursestop8(copyParams).subscribe((subres: any) => {
                this.courseList = subres.body.data;
      });
          
    
  }


  // tslint:disable-next-line: use-life-cycle-interface
  ngAfterContentInit() {
    this.computePercent();
    window.onresize = () => {
      this.computePercent();
    };
  }

  ngAfterContentChecked(): void {
    // 文档宽度(不包括滚动条：在1920设备屏幕下，页面没渲染前为1920，渲染后为1914)
    const clientW = document.documentElement.clientWidth;
    if (!this.scrollBarW && clientW < this.viewportW) {
      // 动态获取浏览器滚动条宽度
      this.scrollBarW = this.viewportW - clientW;
    }
  }

  computePercent() {
    this.viewportW = window.innerWidth;
    this.mPercent = (this.viewportW - this.fixedW) * 100 / 2 / 1200 + '%';
  }

  getStyle() {
    if (this.viewportW > 1200) {
      return {
        // 右边距需减除滚动条宽度
        'margin': `0 calc(-${this.mPercent} + ${this.scrollBarW}px) 0 -${this.mPercent}`
      };
    } else {
      return {}
    }
  }



  
  getAllEduLiveBroadcasts(){
    const params = {
      'isValid.equals': 1,
       page: this.liveBroadcastsPage,
      size: this.liveBroadcastsSize,
      sort: ['jhiOrder,asc', 'createTime,desc'],
    };
    this.homeService.getAllEduLiveBroadcasts(params).subscribe(res => {
      if(res)
      { 
        this.liveBroadcastList = res.body;
        let curDate = new Date();
        this.liveBroadcastList.forEach(item=>{
            if(new Date(item.startTime) <= curDate && new Date(item.endTime ) >= curDate)
            {
                item["dateStatus"] = "1";
            }else{
              item["dateStatus"] = "0";
            }

            if (item.startTime && item.startTime !== null) {
                 item.startTime = moment(item.startTime).format('YYYY-MM-DD HH:mm:ss');
            }

            if (item.endTime && item.endTime !== null) {
              item.endTime = moment(item.endTime).format('YYYY-MM-DD HH:mm:ss');
            }
            
        });

        this.liveBroadCastTotal = res.headers.get('X-Total-Count');
      }
    });
    
  }

  reloadData(page)
  {
    
      this.liveBroadcastsPage = page - 1;
      this.getAllEduLiveBroadcasts();
  }


  getPic(itemId)
  {
     return this.previewAddress + itemId;
  }


  toLiveBroadCast(item)
  {
     
    if(item!= null && item != undefined && item.dateStatus === '1' && item.url!= null && item.url != undefined && item.url.length > 0)
    {
        
        window.open(item.url);
    }
  }


  
  selContest(flag) {
    if (this.isLogin !== true) {
        this.loginUser();
       return;
    }
    let url = "/certification/bimCertification";
    this.router.navigate([url], { queryParams: { certification: flag }});
  }

    /**
   * 登陆
   */

  loginUser() {
    this.modalService.confirm({
      nzTitle: '请登录后操作',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.modalHelper.static(UserLoginComponent, 600).subscribe((data: any) => {
        
          if (data) {
            this.msg.info('登陆成功');
            this.isLogin = true;
            this.ngOnInit();
            //location.reload();
          }
        });
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }



  
  getThsNews() {
    const params = {
      'contenttype.equals': 'bimDynamic',
      page: 0,
      size: 6,
      // sort: ['istop,desc', 'createTime,desc'],////按事业部要求取消置顶的排序 20240315
      sort: ['createTime,desc'],
    };
    this.homeService.getThsNews(params).subscribe(res => {
      this.thsNewsList = res.body;
    });
  }


  formatDateYM(str: string) {
    return moment(str).format('YYYY-MM');
  }

  formatDateD(str: string) {
    return moment(str).format('DD');
  }


  toBIM(){
    // let url = "/enroll/contestSelect";
    let url = "/enroll/contest";
    this.router.navigate([url]);

  }


  toCourseAllList(){

    let url = "/course/list";
    this.router.navigate([url], { queryParams: { typeid: "", typename: "全部"}});


  }
}
