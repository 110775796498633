import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CacheService } from '@delon/cache';
import { HttpAddressService } from '@shared/session/http-address.service';
import { isNullOrUndefined } from 'util';
import { HomeService } from '../home.service';
import { BimStore } from '@shared/store/bim.store';

@Component({
  selector: 'app-contest-select',
  templateUrl: './contest-select.component.html',
  styleUrls: ['./contest-select.component.less'],
})
export class ContestSelectComponent implements OnInit {
  public contestList = [];
  imagePrefix = '';

  constructor(
    private router: Router,
    
    private homeService: HomeService,
    private cacheService: CacheService,
    private httpAddressService: HttpAddressService,
    private bimStore: BimStore,
  ) {
    this.imagePrefix =
      this.httpAddressService.apiGateway + '/thsadmin/api/sys-files/download/';
  }

  ngOnInit(): void {
    this.cacheService.set('bimInfo', null);
    this.getContestList();
    this.bimStore.bimInfoStore.next(null);
  }

  // 获取大赛数据
  getContestList() {
    this.homeService
      .getBimInfos({
        size: 1000,
        'type.equals': 'bim',
        'status.equals': 1,
        'isDelete.equals': 0,
        sort: ['startDate,desc'],
      })
      .subscribe(res => {
        console.log(res);
        this.contestList = res.body;
      });
  }

  selContest(item) {
    if (!isNullOrUndefined(item)) {
      debugger;
      this.cacheService.set('bimInfo', item);
      this.bimStore.bimInfoStore.next(item);
      this.router.navigate(['/enroll']);
    }
  }
}
