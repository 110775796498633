export class BimPerson {
  constructor(
    public  id?: string,
    public  bimInfoEventsId?: string,
    public  schoolId?: string,

    public  schoolName?: string,
    public  schoolType?: string,
    public  regionId?: string,
    public  province?: string,
    
    public  personSex?: string,
    public  idCard?: string,
    public  professionYear?: number,
    public  registLevel?: string,
    public  registNum?: string,
    public  contactAddress?: string,

    public  personName?: string,
    public  personEmail?: string,
    public  personTel?: string,
    public  grade?: string,
    public  profession?: string,
    public  password?: string,
    public  status?: number,
    public  remark?: string,
    public  createTime?: Date,
    public  creator?: string,
    public  updateTime?: Date,
    public  updator?: string,
    public  userDefine1?: string,
    public  userDefine2?: string,
    public  userDefine3?: string,
    public  userDefine4?: string,
    public  userDefine5?: string,
    public  userDefine6?: string,

    public maxTopCount?:number,
    public isSendMessage?:number,
    public isUpdatePrizeInfo?:number

  ) {}
}
