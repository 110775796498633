import { NgModule } from '@angular/core';
import { EnrollRoutingModule } from './enroll-routing.module';
import { NgxEchartsModule } from 'ngx-echarts';
import { HomeComponent } from './home/home.component';
import { LayoutComponent } from './layout/layout.component';
import { TeamModule } from './team/team.module';
import { DynamicDetailComponent } from './home/dynamic-detail/dynamic-detail.component';
import { SchoolTotalComponent } from './home/school-total/school-total.component';
import { ContestSelectComponent } from './home/contest-select/contest-select.component';
import { CoopCarenterComponent } from './home/coop-parenter/coop-parenter.component';
import { UtilsModule } from '../../shared/utils/utils.module';
import { SharedModule } from '../../shared/shared.module';
import { PersonalModule } from './personal/personal.module';
import { LearningClassroomComponent } from './learning-classroom/learning-classroom.component';
import { WorksShowsComponent } from './works-shows/works-shows.component';
import { WorksShowsDetailComponent } from './works-shows/works-shows-detail/works-shows-detail.component';

import { BimInfoSchoolsEditComponent } from './school/bim-info-schools/edit/edit.component';
import { GameComponent } from './game/game.component';
import { GameInfoComponent } from './game-info/game-info.component';
import { RegulationComponent } from './regulation/regulation.component';
import { ShowComponent } from './show/show.component';
import { GameStyleComponent } from './game-style/game-style.component';
import { ContestListComponent } from './contest-list/contest-list.component';
import { DownloadBimComponent } from './download/download.component';
import { GameStyleSelectComponent } from './game-style/game-style-select/game-style-select.component';
import { PreviewImgVideoComponent } from '@shared/components/preview-imgvideo/preview-imgvideo.component';
import { SoftDownloadBimComponent } from './download/soft-download/soft-download.component';

const COMPONENTS = [
  HomeComponent,
  LayoutComponent,
  ContestSelectComponent,
  DynamicDetailComponent,
  SchoolTotalComponent,
  CoopCarenterComponent,
  LearningClassroomComponent,
  WorksShowsComponent,
  WorksShowsDetailComponent,
  DownloadBimComponent,
  GameComponent,
  GameInfoComponent,
  RegulationComponent,
  ShowComponent,
  GameStyleComponent,
  GameStyleSelectComponent,
  ContestListComponent,
  PreviewImgVideoComponent,
  SoftDownloadBimComponent,
];
const COMPONENTS_NOROUNT = [BimInfoSchoolsEditComponent];

@NgModule({
  imports: [
    SharedModule,
    EnrollRoutingModule,
    NgxEchartsModule,
    UtilsModule,
    TeamModule,
    PersonalModule,
  ],
  declarations: [...COMPONENTS, ...COMPONENTS_NOROUNT],
  providers: [],
  entryComponents: COMPONENTS_NOROUNT,
})
export class EnrollModule {}
