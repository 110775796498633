import { Injectable } from '@angular/core';
import { ThsBaseService } from '@shared/components/thsBaseService';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';

@Injectable({ providedIn: 'root' })
export class BimSoftDownloadTypeService extends ThsBaseService<any> {
  private bimSoftDownloadTypeUrl = '';

  constructor(
    public http: HttpClient, httpAddressService: HttpAddressService) {
    super(http, httpAddressService.BimServe + '/bim-soft-download-types');
    this.bimSoftDownloadTypeUrl = httpAddressService.BimWebServe + '/bim-soft-download-types';
  }
  
  getBimSoftDownloadType(queryParams?: any) {
    this.resourceUrl = this.bimSoftDownloadTypeUrl;
    return this.query(queryParams);
  }
}
