export class BimReceiptInfo {
  id?: string;
  schoolId?: string;
  teamEmail?: string;
  invoiceTitle?: string;
  dutyNum?: string;
  gameArea?: number;
  gameProgram?: string;
  liveOrEatStatus?: number;
  live?: string;
  receiptState?: string;
  teamName?: string;
  creator?: string;
  creatorTime?: Date;
  modifier?: string;
  modifiedTime?: Date;
  addrAndPhone?: string;
  account?: string;
  contacts?: string;
  phone?: string;
  email?: string;
  remark?: string;
  fileName?: string;
  fileName2?: string;
  fileName3?: string;
  filePath?: string;
  isSubmit?: number;
  type?: string;
  teamId?: string;

  traffic?: string;
  arriveTime?: string;
  leaveTime?: string;
  liveStatus?: string;
  singleRoom?: string;
  standardRoom?: string;
  liveDetail?: string;

  leaderList?: any[];
  instrictorList?: BimReceiptInstrictor[];
  studentList?: BimReceiptStudent[];
}
//回执指导老师表
export class BimReceiptInstrictor {
  id?: string;
  receiptinfoId?: string;
  name?: string;
  sex?: string;
  major?: string;
  job?: string;
  phone?: string;
  qq?: string;
  email?: string;
  order?: number;
  type?: string;
  faculty?: string;
}
//回执学生表
export class BimReceiptStudent {
  id?: string;
  receiptinfoId?: string;
  name?: string;
  sex?: string;
  nation?: string;
  idCard?: string;
  major?: string;
  graduationTime?: Date;
  phone?: string;
  qq?: string;
  email?: string;
  traffic?: string;
  arriveTime?: Date;
  leaveTime?: Date;
  liveOption?: string;
  roomType?: number;
  roomMate?: string;
  stuOrder?: number;
  isShare?: number;
  dayArrive?: number;
  dayLeave?: number;
  type?: string;
  faculty?: string;
  grade?: string;
}
