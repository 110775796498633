import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CacheService } from '@delon/cache';
import { environment } from '@env/environment';
import { HttpAddressService } from '@shared/session/http-address.service';
import { isNullOrUndefined } from 'util';
import { HomeService } from '../../home/home.service';

@Component({
  selector: 'app-contest-task-book',
  templateUrl: './contest-task-book.component.html',
  styles: [],
})
export class ContestTaskBookComponent implements OnInit {
  public CONTENT_TYPE = 'bimTaskDocList';
  public bimInfoId = null;
  public bimInfo = this.cacheService.get('bimInfo')['value'];
  taskBookList = [];

  constructor(
    private router: Router,
    private homeService: HomeService,
    private cacheService: CacheService,
    private httpAddressService: HttpAddressService,
  ) { }

  ngOnInit() {
    if (isNullOrUndefined(this.bimInfo)) {
      // this.router.navigate(['/enroll/contestSelect']);
      // return;
    }
    this.bimInfoId = this.bimInfo.id;
    this.getTaskBook();
  }

  getTaskBook() {
    this.homeService
      .getThsNews({
        'bimInfoId.equals': this.bimInfoId,
        'contenttype.equals': this.CONTENT_TYPE,
        size: 100,
        sort: ['createTime,desc'],
      })
      .subscribe(res => {
        this.taskBookList = res.body;
      });
  }

  clickTaskBook(id) {
    this.homeService
      .getAllFiles({
        'fileFk.equals': id,
        'extField1.equals': this.CONTENT_TYPE,
      })
      .subscribe(res => {
        const fileList = res.body;
        if (fileList && fileList.length > 0) {
          fileList.forEach(e => this.downFile(e.id));
        }
      });
  }

  downFile(fileId) {
    const a = document.createElement('a');
    const url =
      this.httpAddressService.apiGateway +
      '/thsadmin/api/sys-files/download/' +
      fileId;
    a.href = url;
    a.click();
  }
}
