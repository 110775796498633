import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { data } from 'jquery';
import { NzMessageService } from 'ng-zorro-antd';
import { isNullOrUndefined } from 'util';
import { BimSchoolsService } from '../../school-register/bim-school.service';

@Component({
  selector: 'app-competition-sign-up',
  templateUrl: './competition-sign-up.component.html',
  styles: []
})
export class CompetitionSignUpComponent implements OnInit {

  @Output() confirmRegiste = new EventEmitter();
  @Input() signUpflag = 0;

  public signUpTitle = '欢迎报名，请填写邀请注册码';
  public submitting = false;
  public schoolName = null;
  public regCode = null;
  public regSchool = null;

  constructor(
    private router: Router,
    private msg: NzMessageService,
    private bimSchoolsService: BimSchoolsService,
  ) { }

  ngOnInit() {
    // 不可报名
    // 未到报名时间
    if (this.signUpflag === 2) {
      this.signUpTitle = '未到报名时间，不可报名！';
    }
    // 已过报名时间
    if (this.signUpflag === 1) {
      this.signUpTitle = '已过报名时间，不可报名！';
    }
    // 需完善个人信息
    if (this.signUpflag === 3) {
      this.signUpTitle = '请先完善个人信息后再报名！';
    }
  }

  confirmCode() {
    if (!isNullOrUndefined(this.regCode)) {
      this.submitting = true;
      this.bimSchoolsService.query({
        'regCode.equals': this.regCode,
        'audit.equals': 1
      }).subscribe(res => {
        this.submitting = false;
        if (res && res.body.length > 0) {
          this.regSchool = res.body[0];
          this.schoolName = this.regSchool.name;
        } else {
          this.schoolName = null;
          this.msg.warning('无此学校，请确认注册码是否正确');
        }
      });
    }
  }

  registe() {
    this.confirmRegiste.emit(this.regSchool);
  }

}
