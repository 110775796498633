import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-no-data',
  templateUrl: './no-data.component.html',
  styleUrls: ['./no-data.component.less']
})
export class NoDataComponent implements OnInit {

  @Input() isMessage: boolean;

  constructor() { }

  ngOnInit() {
  }

}
