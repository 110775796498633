import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpResponse } from '@angular/common/http';
import { BimPersionItems } from './bim-person-items.model';
import {ThsBaseService } from  '@shared/components/thsBaseService';
import { HttpAddressService } from '@shared/session/http-address.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export class BimPersonItemsService extends ThsBaseService<BimPersionItems> {
    
    private bimWebPersonItems = '';

    constructor(http: HttpClient, httpAddressService: HttpAddressService) {
        super(http, httpAddressService.BimServe + `/bim-persion-items`);
        this.bimWebPersonItems = httpAddressService.BimWebServe + `/bim-persion-items`;
    }

    saveItemByTeam(param: any): Observable<HttpResponse<any>> {
        let url = this.resourceUrl + '/saveItemByTeam';
        return this.http.get<any>(url, {params:param, observe: 'response'});
    }

    getBimPersonItemByPerId(personId): Observable<HttpResponse<any>> {
        return this.http.get<any>(this.resourceUrl + `/getBimPersonItemByPerId/${personId}`, {observe: 'response'})
        .pipe(map((res: HttpResponse<any>) => this.convertDateArrayFromServer(res)));
    }

    saveWebItemByTeam(param: any): Observable<HttpResponse<any>> {
        let url = this.bimWebPersonItems + '/saveItemByTeam';
        return this.http.get<any>(url, {params:param, observe: 'response'});
    }

}

