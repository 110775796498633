import { NgModule } from '@angular/core';
import { NgxEchartsModule } from 'ngx-echarts';
import { UtilsModule } from '../../shared/utils/utils.module';
import { SharedModule } from '../../shared/shared.module';
import { EnterpriseRoutingModule } from './enterprise-routing.module';
import { ContestSelectComponent } from './home/contest-select/contest-select.component';
import { HomeComponent } from './home/home.component';
import { OrgModule } from './org/org.module';
import { SchoolTotalComponent } from './home/school-total/school-total.component';
import { LearningVideoComponent } from './learning-video/learning-video.component';
import { ContestDynamicComponent } from './contest-dynamic/contest-dynamic.component';
import { CompetitionModule } from './competition/competition.module';
import { DynamicDetailComponent } from './home/dynamic-detail/dynamic-detail.component';
import { OrgListComponent } from './org-list/org-list.component';
import { ContestWonderfulComponent } from './contest-wonderful/contest-wonderful.component';

const COMPONENTS = [
  HomeComponent,
  ContestSelectComponent,
  SchoolTotalComponent,
  LearningVideoComponent,
  ContestDynamicComponent,
  DynamicDetailComponent,
  ContestWonderfulComponent,
];
const COMPONENTS_NOROUNT = [OrgListComponent];

@NgModule({
  imports: [
    SharedModule,
    EnterpriseRoutingModule,
    NgxEchartsModule,
    UtilsModule,
    OrgModule,
    CompetitionModule,
  ],
  declarations: [...COMPONENTS, ...COMPONENTS_NOROUNT],
  providers: [],
  entryComponents: COMPONENTS_NOROUNT,
})
export class EnterpriseModule {}
