import { Component, OnInit } from '@angular/core';
import { AdNumberInfoModule } from '@delon/abc';
import { DatePipe } from '@angular/common';
import { NzMessageService, NzModalRef,NzModalService } from 'ng-zorro-antd';
@Component({
  selector: 'app-bim-certification-confirm',
  templateUrl: './bim-certification-confirm.component.html',
  styleUrls: ['./bim-certification-confirm.component.less']
})
export class BimCertificationConfirmComponent implements OnInit {

  certification: number = 0;//0-THS-BIM报名，1-Autodesk报名，2-专业技能认证报名
  userProductList = [];//用户选择的产品列表id
  chooseUserProductList = [];//用户可以选择的产品列表

  public record={};

  imgUrl:any;

  public isLook = false;

  constructor(
    private datePipe: DatePipe,
    private modal: NzModalRef,
    private modalService: NzModalService,
  ) { }

  ngOnInit() {
  }


  getProductName(productId:string){
      let productName = '';
      this.userProductList.forEach((element, index) => {
        if(element.id === productId)
        {
            productName = element.productName;
        }
      });
      return productName;
  }


  getProductPrice(productId:string){
    let productPrice = '';
    this.userProductList.forEach((element, index) => {
      if(element.id === productId)
      {
        productPrice = element.price;
      }
    });
    return productPrice;
  }

  formateDate(value){
    return this.datePipe.transform(value, 'yyyy-MM-dd') ;
  }



    close() {
      this.modal.close('false');
    }

    subim(){


      // this.modalService.confirm({
      //   nzTitle     : '请核对并确认信息无误，一旦提交，无法更改！ 是否确认提交？',
      //   nzContent   : '',
      //   nzOkText    : '确认提交',
      //   nzOkType    : 'danger',
      //   nzOnOk      : () => {
      //     this.modal.close('success');
      //   },
      //   nzCancelText: '取消',
      //   nzOnCancel  : () => {}
      // });


      this.modal.close('success');
      
    }
}
