import { Injectable } from '@angular/core';
import { ThsBaseService } from '@shared/components/thsBaseService';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
import { BimInfoEventsGroup } from './event-gourp.model';

@Injectable({ providedIn: 'root' })
export class  BimInfoEventsGroupService extends ThsBaseService<BimInfoEventsGroup>  {
  constructor(
    public http: HttpClient, httpAddressService: HttpAddressService ) {
    super(http, httpAddressService.BimServe + '/bim-info-events-groups');
  }
}