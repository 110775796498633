import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WorkUploadComponent } from './work-upload/work-upload.component';
import { HomeComponent } from '../home/home.component';
import { ContestTaskBookComponent } from './contest-task-book/contest-task-book.component';
import { TeamIndexComponent } from './team-index/team-index.component';
import { SchoolRegisterComponent } from './school-register/school-register.component';
import { SchoolInformationManageComponent } from './school-information-manage/school-information-manage.component';
import { CompetitionRegistrationComponent } from './competition-registration/competition-registration.component';
import { JudgeRatingComponent } from './judge-rating/judge-rating.component';
import { ScoreRubricComponent } from './score-rubric/score-rubric.component';
import { CompetitionTeamInfoComponent } from './competition-team-info/competition-team-info.component';
import { SchoolWorkScoringComponent } from './school-work-scoring/school-work-scoring.component';
import { TeamWorkInfoComponent } from './tean-work-info/team-work-info.component';
import { ReceiptUploadComponent } from './receipt-upload/receipt-upload.component';
import { SchoolReplyWorkComponent } from './school-reply-work/school-reply-work.component';
import { ProvinceCompetitionRegistrationComponent } from './province-competition-registration/province-competition-registration.component';
import { ProvinceSchoolInformationManageComponent } from './province-school-information-manage/province-school-information-manage.component';
import { ProvinceSchoolRegisterComponent } from './province-school-register/province-school-register.component';
import { WorkReplyFileComponent } from './work-reply-file/work-reply-file.component';
import { ReplyFileManageComponent } from './work-reply-file/reply-file-manage/reply-file-manage.component';

const routes: Routes = [
  {
    path: 'team',
    component: TeamIndexComponent,
    children: [
      {
        path: 'contestTaskBook/:bimEventId',
        component: ContestTaskBookComponent,
        data: { title: '大赛任务书' },
      },
      {
        path: 'work-upload/:bimEventId',
        component: WorkUploadComponent,
        data: { title: '作品上传' },
      },
      {
        path: 'schoolRegister/:bimEventId',
        component: SchoolRegisterComponent,
        data: { title: '学校注册' },
      },
      {
        path: 'schoolInformationManage/:bimEventId',
        component: SchoolInformationManageComponent,
        data: { title: '学校信息管理' },
      },
      {
        path: 'competitionRegistration/:bimEventId',
        component: CompetitionRegistrationComponent,
        data: { title: '团队赛报名' },
      },
      {
        path: 'competitionTeamInfo/:bimEventId',
        component: CompetitionTeamInfoComponent,
        data: { title: '团队信息管理' },
      },
      {
        path: 'schoolRating/:bimEventId',
        component: SchoolWorkScoringComponent,
        data: { title: '校内评委评分' },
      },
      {
        path: 'judgeRating/:bimEventId',
        component: SchoolWorkScoringComponent,
        data: { title: '全国评委评分' },
      },
      {
        path: 'replyWork/:bimEventId',
        component: SchoolReplyWorkComponent,
        data: { title: '全国答辩评委分配作品' },
      },
      {
        path: 'scoreRubric',
        component: ScoreRubricComponent,
        data: { title: '评分细则' },
      },
      {
        path: 'teamWorkInfo',
        component: TeamWorkInfoComponent,
        data: { title: '作品信息管理' },
      },      
      {
        path: 'receiptUpload/:bimEventId',
        component: ReceiptUploadComponent,
        data: { title: '回执打印上传' },
      },
      {
        path: 'provinceSchoolRegister/:bimEventId',
        component: ProvinceSchoolRegisterComponent,
        data: { title: '省赛学校注册' },
      },
      {
        path: 'provinceSchoolInformationManage/:bimEventId',
        component: ProvinceSchoolInformationManageComponent,
        data: { title: '省赛学校信息管理' },
      },
      {
        path: 'provinceCompetitionRegistration/:bimEventId',
        component: ProvinceCompetitionRegistrationComponent,
        data: { title: '比赛报名' },
      },
      {
        path: 'provinceCompetitionInfo/:bimEventId',
        component: ProvinceCompetitionRegistrationComponent,
        data: { title: '报名信息管理' },
      },
      {
        path: 'replyFileUpload/:bimEventId',
        component: WorkReplyFileComponent,
        data: { title: '答辩材料上传' },
      },
      {
        path: 'replyFileManage',
        component: ReplyFileManageComponent,
        data: { title: '答辩材料管理' },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TeamRoutingModule { }
