import { Component, Inject, ViewChild } from '@angular/core';
import {
  Router,
  NavigationEnd,
  RouteConfigLoadStart,
  NavigationError,
} from '@angular/router';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import {
  ScrollService,
  MenuService,
  SettingsService,
  ModalHelper,
} from '@delon/theme';
import { HomeService } from 'app/routes/enterprise/home/home.service';
import { HttpAddressService } from '@shared/session/http-address.service';
import { HeaderComponent } from '../header/header.component';
import { CacheService } from '@delon/cache';
import { isNullOrUndefined } from 'util';
import { UserLoginComponent } from 'app/routes/passport/login/login.component';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { zip } from 'rxjs';
import { EnterpriseStore } from './enterprise.store';

@Component({
  selector: 'layout-enterprise',
  templateUrl: './enterprise.component.html',
  styleUrls: ['./enterprise.component.less'],
})
export class LayoutEnterpriseComponent {
  isFetching = false;
  bannerList: any[];
  imagePrefix = '';
  loading = true;
  pamms = {};
  @ViewChild('header') header: HeaderComponent; // 父组件中获得子组件的引用

  subNavList: any = [];
  currentSubNav: any = { code: 'sub-home', name: '首页', path: '/enterprise' };
  showSubNav = false;
  thirdNavList: any = [];
  currentThirdNav: any = { name: '', path: '' };
  showThirdNav = false;

  public bimInfo = this.cacheService.get('bimInfo')['value'];
  bimInfoBanner = null;

  public bimInfoEventList = [];

  constructor(
    private router: Router,
    private scroll: ScrollService,
    private cacheService: CacheService,
    private _message: NzMessageService,
    private menuSrv: MenuService,
    private settings: SettingsService,
    private homeService: HomeService,
    private modalHelper: ModalHelper,
    private modalService: NzModalService,
    private httpAddressService: HttpAddressService,
    private enterpriseStore: EnterpriseStore,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  ) {
    this.imagePrefix =
      this.httpAddressService.apiGateway + '/thsadmin/api/sys-files/download/';
    // scroll to top in change page
    router.events.subscribe(evt => {
      if (!this.isFetching && evt instanceof RouteConfigLoadStart) {
        this.isFetching = true;
      }
      if (evt instanceof NavigationError) {
        this.isFetching = false;
        _message.error(`无法加载${evt.url}路由`, { nzDuration: 1000 * 3 });
        return;
      }
      if (evt instanceof NavigationEnd) {
        if (
          !evt.url.includes('/enterprise/contestSelect') &&
          evt.url.includes('/enterprise')
        ) {
          this.showSubNav = true;
        } else {
          this.showSubNav = false;
        }
        const bimInfo = this.cacheService.get('bimInfo')['value'];

        if (evt.url.includes('/enterprise') && !isNullOrUndefined(bimInfo)) {
          this.bimInfoBanner = bimInfo.userDefine6;
          const isSubNavInit = this.cacheService.get('isSubNavInit')['value'];
          if (isNullOrUndefined(isSubNavInit) || !isSubNavInit) {
            this.initSubNav(bimInfo);
          }
        } else {
          this.bimInfoBanner = null;
        }
        if (evt.url.includes('/enterprise/contestSelect')) {
          this.cacheService.set('isSubNavInit', false);
        }
        if (evt.url === '/enterprise') {
          this.currentSubNav = {
            code: 'sub-home',
            name: '首页',
            path: '/enterprise',
          };
        }
        if (evt.url.includes('/enterprise/org')) {
          this.currentSubNav = {
            code: 'org-entry',
            name: '单位注册入口',
            path: '/enroll/enterprise/orgRegister',
          };
        }
        if (evt.url.includes('/enterprise/competition')) {
          this.currentSubNav = {
            code: 'competition-entry',
            name: '报名入口',
            path: '/enroll/enterprise/competition',
          };
        }
      }
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      setTimeout(() => {
        scroll.scrollToTop();
        this.isFetching = false;
      }, 100);
    });
  }
  // tslint:disable-next-line: use-life-cycle-interface
  ngOnInit() {
    console.log(this.currentSubNav);

    //this.getBanner();
    this.initData();
  }

  // 获取banner图片
  getBanner() {
    if (this.router.url.indexOf('certification') !== -1) {
      // 认证
      this.pamms['type.equals'] = 2;
    } else if (this.router.url.indexOf('enterprise') !== -1) {
      // 行业赛
      this.pamms['type.equals'] = 5;
    } else {
      // 首页
      this.pamms['type.equals'] = 1;
    }
    this.pamms['isOk.equals'] = 1;
    this.pamms['sort'] = ['createdDate,asc'];
    this.pamms['size'] = 100;
    this.homeService.getbanners(this.pamms).subscribe(res => {
      this.loading = false;
      this.bannerList = res.body;
    });
  }

  initData() {
    console.log('layout enterprise');
    // if (isNullOrUndefined(this.bimInfo)) {
    //   this.router.navigate(['/enroll/contestSelect']);
    //   return;
    // }

    let url = this.router.url ? this.router.url : '';
    console.log('url,host:', url, location.host);
    let index = url.indexOf('/');
    let sysPre = 'null';
    if (index == 0) {
      url = url.substring(1);
    }
    let lastIndex = url.indexOf('/');
    let wenIndex = url.lastIndexOf('?');
    if (lastIndex != -1) {
      sysPre = url.substring(0, lastIndex); //系统访问
    } else if (wenIndex != -1) {
      sysPre = url.substring(0, wenIndex); //系统访问
    } else if (lastIndex == -1 && wenIndex == -1 && url) {
      sysPre = url;
    }

    console.log('contestCityId:', sysPre);

    //获取本次大赛内容
    this.homeService
      .getBimInfos({
        size: 1000,
        'type.equals': 'enterprise',
        'contestCityId.in': [sysPre, location.host],
        'status.equals': 1,
        'isDelete.equals': 0,
        sort: ['startDate,desc'],
      })
      .subscribe(bimRes => {
        const bimResult = bimRes.body;
        if (bimResult && bimResult.length > 0) {
          this.cacheService.set('bimInfo', bimResult[0]);
          this.bimInfo = bimResult[0];
          this.bimInfoBanner = this.bimInfo.userDefine6;
          this.enterpriseStore.bimInfoStore.next(this.bimInfo);

          this.initSubNav();
        } else {
          this.cacheService.set('bimInfo', null);
          this.bimInfoBanner = null;
          const data = [
            { code: 'sub-home', name: '首页', path: '/enterprise' },
            {
              code: 'competition-entry',
              name: '报名入口',
              path: '/enterprise',
            },
            {
              code: 'contestDynamic',
              name: '比赛动态',
              path: '/enterprise/contestDynamic',
            },
            {
              code: 'soft-download',
              name: '比赛风采',
              path: '/enterprise/contestWonderful',
            },
          ];
          this.subNavList = data;
          // console.log('layout/enterprise/home/this.bimInfo:',this.bimInfo);
          // this.router.navigate(['/enterprise/contestSelect']);
          // return;
        }

        console.log('bimInfoBanner:', this.bimInfoBanner);
      });

    // this.initSubNav();
  }

  initSubNav(item?) {
    const userType = this.tokenService.get().entity_type;
    const data = [{ code: 'sub-home', name: '首页', path: '/enterprise' }];
    const bimInfo = isNullOrUndefined(item) ? this.bimInfo : item;
    if (isNullOrUndefined(bimInfo)) {
      return;
    }
    this.bimInfo = bimInfo;

    this.homeService
      .getBimInfoEvent({
        'bimInfoId.equals': bimInfo.id,
        'isDelete.equals': 0,
        sort: ['createTime,desc'],
      })
      .subscribe((res: any) => {
        this.cacheService.set('isSubNavInit', true);
        const events = res.body;
        this.bimInfoEventList = events;
        // 团队赛赛项
        // const teamEvents = events.filter(e => e.eventsType === 0);
        // if (teamEvents && teamEvents.length > 0 && (isNullOrUndefined(userType) || (userType === '1'))) {
        //   const teamPath = [];
        //  // const personPath = [];
        //   teamEvents.forEach(e => {
        //     teamPath.push.apply(teamPath, [
        //       { code: 'org-entry', name: '单位注册入口' + `-${e.name}`, path: '/enterprise/org/orgRegister' + `/${e.id}`, bimEventId: e.id }
        //     ]);
        //   });
        //   data.push.apply(data, [{ code: 'org-entry', name: '单位注册入口', path: teamPath.length > 0 ? teamPath : ''  }]);
        // }
        const personEvents = events.filter(e => e.eventsType === 1);
        if (
          personEvents &&
          personEvents.length > 0 &&
          (isNullOrUndefined(userType) ||
            (userType === '0' || userType === '2'))
        ) {
          const personPath = [];
          personEvents.forEach(e => {
            personPath.push.apply(personPath, [
              {
                code: 'competition-entry',
                name: '报名入口' + `-${e.name}`,
                path: '/enterprise/competition/signUpCity' + `/${e.id}`,
                bimEventId: e.id,
              },
            ]);
          });
          data.push.apply(data, [
            {
              code: 'competition-entry',
              name: '报名入口',
              path: personPath.length > 0 ? personPath : '',
            },
          ]);
        }

        data.push.apply(data, [
          {
            code: 'contestDynamic',
            name: '比赛动态',
            path: '/enterprise/contestDynamic',
          },
          {
            code: 'soft-download',
            name: '比赛风采',
            path: '/enterprise/contestWonderful',
          },
        ]);

        // data.push.apply(data, [
        //   { code: 'learning', name: '学习视频', path: '/enterprise/learningClassroom' },
        //   { code: 'soft-download', name: '软件下载', path: '' },
        // ]);
        this.subNavList = data;
      });
  }

  openUrl() {
    const url = $('.slick-active a img').attr('name');
    if (url.startsWith('http://') || url.startsWith('https://')) {
      window.open(url);
    } else {
      window.open('http://' + url);
    }
  }

  subOnClick(value) {
    if (isNullOrUndefined(this.bimInfo)) {
      this.router.navigate(['/enterprise']);
      return;
    }
    this.currentSubNav = value;
    debugger;
    if (value.code === 'org-entry' && !isNullOrUndefined(value.bimEventId)) {
      const token = this.tokenService.get().token;

      if (isNullOrUndefined(token) || token === '') {
        this._loginUser(value);
        return;
      }
      this.cacheService.set('bimEventId', value.bimEventId);
      this.setBimInfoEventData(value, 'curTeamEvent');
    } else if (
      value.code === 'competition-entry' &&
      !isNullOrUndefined(value.bimEventId)
    ) {
      // const token = this.tokenService.get().token;
      // if (isNullOrUndefined(token) || token === '') {
      //   this._loginUser(value);
      //   return;
      // }
      this.cacheService.set('personBimEventId', value.bimEventId);
      this.setBimInfoEventData(value, 'curTeamEvent');
    } else {
      if (!isNullOrUndefined(value.path) && value.path !== '') {
        this.router.navigate([value.path]);
      }
    }
  }

  setBimInfoEventData(value, key) {
    this.homeService
      .getBimInfoEvent({
        'id.equals': value.bimEventId,
      })
      .subscribe(res => {
        this.cacheService.set(key, null);
        if (res && res.body.length > 0) {
          this.cacheService.set(key, res.body[0]);
        } else {
          const data = this.bimInfoEventList.filter(
            e => e.id === value.bimEventId,
          );
          if (data.length > 0) {
            this.cacheService.set(key, data[0]);
          }
        }
        if (!isNullOrUndefined(value.path) && value.path !== '') {
          this.router.navigate([value.path]);
        }
      });
  }

  thirdOnClick(value) {
    this.currentThirdNav = value;
    this.router.navigate([value.path]);
  }

  clickCoopPartener(item, type) {
    this.bimInfo = this.cacheService.get('bimInfo')['value'];
    if (isNullOrUndefined(this.bimInfo)) {
      this.router.navigate(['/enterprise']);
      return;
    }
    this.currentSubNav = item;
    if (type === 'college') {
      this.router.navigate(['/enterprise/coopCarenter/coopCollege']);
    }
  }

  /**
   * 登陆
   */
  _loginUser(value) {
    this.modalService.confirm({
      nzTitle: '确认确定登录?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.modalHelper
          .static(UserLoginComponent, {}, 600)
          .subscribe((data: any) => {
            if (data) {
              this._message.info('登陆成功');
              this.subOnClick(value);
            }
          });
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel'),
    });
  }
}
