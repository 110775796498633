import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BimInfoSchools } from './bim-info-schools.model';
import {ThsBaseService } from '@shared/components/thsBaseService';
import { HttpAddressService } from '@shared/session/http-address.service';
@Injectable({ providedIn: 'root' })
export class BimInfoSchoolsService extends ThsBaseService<BimInfoSchools> {
    constructor(http: HttpClient, httpAddressService: HttpAddressService) {
        super(http, httpAddressService.BimServe + `/bim-info-schools`);
    }
}

