import { Component, Inject, ViewChild } from '@angular/core';
import {
  Router,
  NavigationEnd,
  RouteConfigLoadStart,
  NavigationError,
} from '@angular/router';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import {
  ScrollService,
  MenuService,
  SettingsService,
  ModalHelper,
} from '@delon/theme';
import { HomeService } from 'app/routes/home/home.service';
import { HttpAddressService } from '@shared/session/http-address.service';
import { HeaderComponent } from '../header/header.component';
import { CacheService } from '@delon/cache';
import { isNullOrUndefined } from 'util';
import { UserLoginComponent } from 'app/routes/passport/login/login.component';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { BimStore } from '@shared/store/bim.store';

@Component({
  selector: 'layout-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.less'],
})
export class LayoutDefaultComponent {
  isFetching = false;
  bannerList: any[];
  imagePrefix = '';
  loading = true;
  pamms = {};
  @ViewChild('header') header: HeaderComponent; // 父组件中获得子组件的引用

  subNavList: any = [];
  currentSubNav: any = { code: 'sub-home', name: '首页', path: '/enroll' };
  showSubNav = false;
  thirdNavList: any = [];
  currentThirdNav: any = { name: '', path: '' };
  showThirdNav = false;

  public bimInfo = this.cacheService.get('bimInfo')['value'];
  bimInfoBanner = null;

  public bimInfoEventList = [];

  constructor(
    private router: Router,
    private scroll: ScrollService,
    private cacheService: CacheService,
    private _message: NzMessageService,
    private menuSrv: MenuService,
    private settings: SettingsService,
    private homeService: HomeService,
    private modalHelper: ModalHelper,
    private modalService: NzModalService,
    private httpAddressService: HttpAddressService,
    private bimStore: BimStore,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  ) {
    this.imagePrefix =
      this.httpAddressService.apiGateway + '/thsadmin/api/sys-files/download/';
    // scroll to top in change page
    router.events.subscribe(evt => {
      if (!this.isFetching && evt instanceof RouteConfigLoadStart) {
        this.isFetching = true;
      }
      if (evt instanceof NavigationError) {
        this.isFetching = false;
        _message.error(`无法加载${evt.url}路由`, { nzDuration: 1000 * 3 });
        return;
      }
      if (evt instanceof NavigationEnd) {
        if (
          !evt.url.includes('/enroll/contestSelect') &&
          evt.url != '/enroll/contest' &&
          evt.url != '/enroll/contestInfo' &&
          !evt.url.includes('/enroll/contestInfo?') &&
          evt.url.includes('/enroll')
        ) {
          this.showSubNav = true;
        } else {
          this.showSubNav = false;
        }
        
        const bimInfo = this.cacheService.get('bimInfo')['value'];
        
        if(!evt.url.includes('/enroll')){
          this.bimStore.bimInfoStore.next(null);
        }else{
          this.bimStore.bimInfoStore.next(bimInfo);
        }
        if (evt.url.includes('/enroll') && evt.url != '/enroll/contest'
          && !isNullOrUndefined(bimInfo)) {
          console.log(evt.url, 'innerbanner');
          this.bimInfoBanner = bimInfo.userDefine6;
          const isSubNavInit = this.cacheService.get('isSubNavInit')['value'];
          if (isNullOrUndefined(isSubNavInit) || !isSubNavInit) {
            this.initSubNav(bimInfo);
          }
        } else {
          this.bimInfoBanner = null;
        }
        if (
          evt.url.includes('/enroll/contestSelect') ||
          !evt.url.includes('/enroll/contest') ||
          !evt.url.includes('/enroll/contestInfo')
        ) {
          this.cacheService.set('isSubNavInit', false);
        }
        if (evt.url.includes('/enroll/worksShows')) {
          this.currentSubNav = {
            code: 'work-show',
            name: '作品展示',
            path: '/enroll/worksShows',
          };
        }
        
        if (evt.url.includes('/enroll/contestStyle')) {
          this.currentSubNav = {
            code: 'contestStyle',
            name: '比赛风采',
            path: '/enroll/contestStyle',
          };
        }
        if (evt.url === '/enroll') {
          this.currentSubNav = {
            code: 'sub-home',
            name: '首页',
            path: '/enroll',
          };
        }
        if (evt.url.includes('/enroll/team')) {
          this.currentSubNav = {
            code: 'team-entry',
            name: '团队赛入口',
            path: '/enroll/team/contestTaskBook',
          };
        }
        if (evt.url.includes('/enroll/personal')) {
          this.currentSubNav = {
            code: 'personal-entry',
            name: '个人赛入口',
            path: bimInfo.type === 'province' ? '/enroll/personal/provinceSignUp' : '/enroll/personal/signUp',
          };
        }
        if (evt.url.includes('/enroll/regulation')) {
          this.currentSubNav = {
            code: 'regulation',
            name: '制度文件',
            path: '/enroll/regulation',
          };
        }
        if (evt.url.includes('/enroll/download')) {
          this.currentSubNav = {
            code: 'soft-download',
            name: '软件下载',
            path: '/enroll/download',
          };
        }
      }
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      setTimeout(() => {
        scroll.scrollToTop();
        this.isFetching = false;
      }, 100);
    });
  }
  // tslint:disable-next-line: use-life-cycle-interface
  ngOnInit() {
    console.log(this.currentSubNav);
    this.getBanner();
    this.initData();
    // const _this_ = this;
    // setInterval(function() {
    //   console.log(new Date());
    //   console.log('每隔60s获取新的消息');
    //   _this_.header.countNews();
    // }, 1000 * 60 );
  }

  // 获取banner图片
  getBanner() {
    if (this.router.url.indexOf('certification') !== -1) {
      // 认证
      this.pamms['type.equals'] = 2;
    } else {
      // 首页
      this.pamms['type.equals'] = 1;
    }
    this.pamms['isOk.equals'] = 1;
    this.pamms['sort'] = ['createdDate,asc'];
    this.pamms['size'] = 5;
    this.homeService.getbanners(this.pamms).subscribe(res => {
      this.loading = false;
      this.bannerList = res.body;
    });
  }

  initData() {
    // if (isNullOrUndefined(this.bimInfo)) {
    //   this.router.navigate(['/enroll/contestSelect']);
    //   return;
    // }
    this.initSubNav();
  }

  initSubNav(item?) {
    const userType = this.tokenService.get().entity_type;
    const data = [{ code: 'sub-home', name: '首页', path: '/enroll' }];
    const bimInfo = isNullOrUndefined(item) ? this.bimInfo : item;
    if (isNullOrUndefined(bimInfo)) {
      return;
    }
    this.bimInfo = bimInfo;

    this.homeService
      .getBimInfoEvent({
        'bimInfoId.equals': bimInfo.id,
        'isDelete.equals': 0,
        sort: ['createTime,desc'],
      })
      .subscribe((res: any) => {
        this.cacheService.set('isSubNavInit', true);
        const events = res.body;
        this.bimInfoEventList = events;

        
        const teamPath = [];
        // 团队赛赛项
        const teamEvents = events.filter(e => e.eventsType === 0);
        if (teamEvents && teamEvents.length > 0) {
          // const personPath = [];
          teamEvents.forEach(e => {
            teamPath.push.apply(teamPath, [
              {
                code: 'team-entry',
                name: '团队赛入口' + `-${e.name}`,
                path: '/enroll/team/contestTaskBook' + `/${e.id}`,
                bimEventId: e.id,
              },
              // {
              //   code: 'personal-entry',
              //   name: '个人赛入口',
              //   path: '/enroll/personal/signUp' + `/${e.id}`,
              //   bimEventId: e.id,
              // },
            ]);
          });
        }

        //个人赛
        const personEvents = events.filter(e => e.eventsType === 1);
        if (
          personEvents &&
          personEvents.length > 0 &&
          (isNullOrUndefined(userType) ||
            (userType === '0' || userType === '2'))
        ) {
          personEvents.forEach(e => {
            teamPath.push.apply(teamPath, [
              {
                code: 'personal-entry',
                name: '个人赛入口' + `-${e.name}`,
                path: (bimInfo.type === 'province' ? '/enroll/personal/provinceSignUp' : '/enroll/personal/signUp') + `/${e.id}`,
                bimEventId: e.id,
              },
            ]);
          });
        }
        
        if(teamPath && teamPath.length > 0){
          data.push.apply(data, [
            {
              code: 'team-entry',
              name: '比赛入口',
              path: teamPath.length > 0 ? teamPath : '',
            },
          ]);
        }
        

        // if (teamEvents && teamEvents.length > 0) {
        //   const teamPath = [];
        //   // const personPath = [];
        //   teamEvents.forEach(e => {
        //     teamPath.push.apply(teamPath, [
        //       {
        //         code: 'team-entry',
        //         name: '团队赛入口' + `-${e.name}`,
        //         path: '/enroll/team/contestTaskBook' + `/${e.id}`,
        //         bimEventId: e.id,
        //       },
        //       {
        //         code: 'personal-entry',
        //         name: '个人赛入口' + `-${e.name}`,
        //         path: '/enroll/personal/signUp' + `/${e.id}`,
        //         bimEventId: e.id,
        //       },
        //     ]);
        //   });
        //   data.push.apply(data, [
        //     {
        //       code: 'team-entry',
        //       name: '团队赛入口',
        //       path: teamPath.length > 0 ? teamPath : '',
        //     },
        //   ]);
        // }
        // const personEvents = events.filter(e => e.eventsType === 1);
        // if (
        //   personEvents &&
        //   personEvents.length > 0 &&
        //   (isNullOrUndefined(userType) ||
        //     (userType === '0' || userType === '2'))
        // ) {
        //   const personPath = [];
        //   personEvents.forEach(e => {
        //     personPath.push.apply(personPath, [
        //       {
        //         code: 'personal-entry',
        //         name: '个人赛入口' + `-${e.name}`,
        //         path: '/enroll/personal/signUp' + `/${e.id}`,
        //         bimEventId: e.id,
        //       },
        //     ]);
        //   });
        //   data.push.apply(data, [
        //     {
        //       code: 'personal-entry',
        //       name: '个人赛入口',
        //       path: personPath.length > 0 ? personPath : '',
        //     },
        //   ]);
        // }

        data.push.apply(data, [
          {
            code: 'regulation',
            name: '制度文件',
            path: '/enroll/regulation',
          },
          {
            code: 'learning',
            name: '学习课堂',
            path: '/enroll/learningClassroom',
          },
          {
            code: 'contestStyle',
            name: '比赛风采',
            path: '/enroll/contestStyle',
          },
          {
            code: 'work-show',
            name: '作品展示',
            path: '/enroll/worksShows',
          },
          // {
          //   code: 'work-show',
          //   name: '作品展示',
          //   path: '/enroll/show',
          // },

          { code: 'soft-download', name: '软件下载', path: '/enroll/download' },
          // { code: 'edu-mall', name: '教育商城', path: '' },
          // { code: 'coop-partener', name: '合作伙伴', path: '' },
        ]);
        this.subNavList = data;
      });
  }

  openUrl() {
    const url = $('.slick-active a img').attr('name');
    if (url.startsWith('http://') || url.startsWith('https://')) {
      window.open(url);
    } else {
      window.open('http://' + url);
    }
  }

  subOnClick(value) {
    if (isNullOrUndefined(this.bimInfo)) {
      this.router.navigate(['/enroll/contestSelect']);
      return;
    }
    if (value.code === 'edu-mall') {
      window.open('http://edumall.thsware.com:9061/');
    } else if (value.code === 'soft-download') {
      //window.open('https://educloud.thsware.com/#/download');
      this.currentSubNav = value;
    } else {
      this.currentSubNav = value;
    }
    if (value.code === 'team-entry' && !isNullOrUndefined(value.bimEventId)) {
      this.cacheService.set('bimEventId', value.bimEventId);
      this.setBimInfoEventData(value, 'curTeamEvent');
    } else if (
      value.code === 'personal-entry' &&
      !isNullOrUndefined(value.bimEventId)
    ) {
      const token = this.tokenService.get().token;
      if (isNullOrUndefined(token) || token === '') {
        this._loginUser(value);
        return;
      }
      this.cacheService.set('personBimEventId', value.bimEventId);
      this.setBimInfoEventData(value, 'curTeamEvent');
    } else {
      if (!isNullOrUndefined(value.path) && value.path !== '') {
        this.router.navigate([value.path]);
      }
    }

    // if (value.code === 'team-entry') {
    //   this.showThirdNav = true;
    //   this.thirdNavList = [
    //     { name: '大赛任务书', path: '/enroll/team/contestTaskBook' },
    //     { name: '学校注册' },
    //     { name: '学校信息管理' },
    //     { name: '校内作品评分' },
    //     { name: '团队赛报名' },
    //     { name: '团队信息管理' },
    //     { name: '作品上传', path: '/enroll/team/work-upload' },
    //     { name: '回执打印上传' },
    //     { name: '全国评委评分' },
    //   ];
    //   this.currentThirdNav = this.thirdNavList[0];
    // } else {
    //   this.showThirdNav = false;
    // }
  }

  setBimInfoEventData(value, key) {
    this.homeService
      .getBimInfoEvent({
        'id.equals': value.bimEventId,
      })
      .subscribe(res => {
        this.cacheService.set(key, null);
        if (res && res.body.length > 0) {
          this.cacheService.set(key, res.body[0]);
        } else {
          const data = this.bimInfoEventList.filter(
            e => e.id === value.bimEventId,
          );
          if (data.length > 0) {
            this.cacheService.set(key, data[0]);
          }
        }
        if (!isNullOrUndefined(value.path) && value.path !== '') {
          this.router.navigate([value.path]);
        }
      });
  }

  thirdOnClick(value) {
    this.currentThirdNav = value;
    this.router.navigate([value.path]);
  }

  clickCoopPartener(item, type) {
    this.bimInfo = this.cacheService.get('bimInfo')['value'];
    if (isNullOrUndefined(this.bimInfo)) {
      this.router.navigate(['/enroll/contestSelect']);
      return;
    }
    this.currentSubNav = item;
    if (type === 'college') {
      this.router.navigate(['/enroll/coopCarenter/coopCollege']);
    }
  }

  /**
   * 登陆
   */
  _loginUser(value) {
    // 登录成功就直接跳转到立刻学习页面进行学习
    this.modalService.confirm({
      nzTitle: '确认确定登录?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.modalHelper
          .static(UserLoginComponent, {}, 600)
          .subscribe((data: any) => {
            if (data) {
              this._message.info('登陆成功');
              this.subOnClick(value);
            }
          });
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel'),
    });
  }
}
