import { Component, OnInit } from '@angular/core';
import { HttpAddressService } from '@shared/session/http-address.service';
import { HomeService } from 'app/routes/enroll/home/home.service';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-school-total',
  templateUrl: './school-total.component.html',
  styleUrls: ['./school-total.component.less'],
})
export class SchoolTotalComponent implements OnInit {

  public bimSchoolList = [];
  imagePrefix = '';

  constructor(
    private homeService: HomeService,
    private httpAddressService: HttpAddressService,
  ) {
    this.imagePrefix = this.httpAddressService.apiGateway + '/thsadmin/api/sys-files/preview/';
  }

  ngOnInit(): void {
    this.getBimSchool(1000);
  }

  getBimSchool(size) {
    const params = {
      'contenttype.equals': 'coopCollegeEnterprise',
      'istop.equals': 1,
      page: 0,
      size: size,
      sort: ['author,asc'],
    };
    this.homeService.getThsNews(params).subscribe(res => {
      this.bimSchoolList = res.body;
    });
  }

  openUrl(url) {
    if (!isNullOrUndefined(url)) {
      if (url.startsWith('http://') || url.startsWith('https://')) {
        window.open(url);
      } else {
        window.open('http://' + url);
      }
    }
  }

}
