import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { HomeService } from './../home/home.service';
import { CacheService } from '@delon/cache';
import { HttpAddressService } from '@shared/session/http-address.service';
import * as moment from 'moment';
import { isNullOrUndefined } from 'util';
import { BimStore } from '@shared/store/bim.store';

@Component({
  selector: 'app-game-info',
  templateUrl: './game-info.component.html',
  styleUrls: ['./game-info.component.less'],
})
export class GameInfoComponent implements OnInit {
  
  selectedProvince = '';
  selectedItem = '全国';
  selectedTabIndex = 0;
  // gameType = '';
  contestBimList = [];
  contestProvinceList = [];
  provinceList = [];//省份数据
  imagePrefix = '';
  searchParams = { searchValue:null,searchValue2:null, bimEventType: null,bimEventType2:null};

  public page = 0;
  public size = 10;
  public total = '0';
  
  public page2 = 0;
  public total2 = '0';

  constructor(private route: ActivatedRoute,
    private router: Router,
    private homeService: HomeService,
    private cacheService: CacheService,
    private bimStore: BimStore, 
    private httpAddressService: HttpAddressService) {
      this.imagePrefix = this.httpAddressService.apiGateway + '/thsadmin/api/sys-files/download/';
    }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const tabId = params['g'];
      if (tabId === '2') {
        this.selectedTabIndex = 1;
      } else {
        this.selectedTabIndex = 0;
      }
      this.getAllContestList(this.selectedTabIndex,true);
    });

    // this.route.paramMap.subscribe(params => {
    //   debugger;
    //   const myParam = params.get('bimInfoId');
    //   console.log(myParam);
    // });
    
    this.getAllProvinceList();
  }

  search(){
    this.getAllContestList(this.selectedTabIndex,true);
  }

  reset(){
    this.searchParams.searchValue = null;
    this.searchParams.bimEventType = null;
    this.searchParams.searchValue2 = null;
    this.searchParams.bimEventType2 = null;
    if(this.selectedTabIndex === 0){
      this.page = 0;
    }else{
      this.page2 = 0;
    }
 
    this.search();
  }

  changeBimEventType(e){
    this.search();
  }

  // 获取全国赛事数据
  getAllContestList(type,isReset) {
    if(isReset){
      if(type == 0){
        this.page = 0;
      }else{
        this.page2 = 0;
      }
    }

    const params = {
      size: this.size,
      page: type == 0 ? this.page : this.page2,
      'type': type == 0 ? 'bim' : 'province',// bim 为全国赛  province为省赛
      // sort: ['startDate,desc'],
    }
   
    if(type == 0){
      if(this.searchParams.searchValue){
        params['searchValue'] = this.searchParams.searchValue;
      }
      if(this.searchParams.bimEventType){
        params['bimEventType'] = this.searchParams.bimEventType;
      }
    }else{
      if(this.searchParams.searchValue2){
        params['searchValue'] = this.searchParams.searchValue2;
      }
      if(this.searchParams.bimEventType2){
        params['bimEventType'] = this.searchParams.bimEventType2;
      }
      if(this.selectedItem && this.selectedItem != '全国'){
        params['province'] = this.selectedItem;
      }
    }
    
    

    this.homeService
      .getAllContestInfos(params)
      .subscribe(res => {
        // console.log(res);
        if(res.body){
          res.body.forEach(item => {
            const startDate = !item.startDate ? null : moment(moment(item.startDate).format('YYYY-MM-DD'));
            const endDate = !item.endDate ? null : moment(moment(item.endDate).format('YYYY-MM-DD'));
            item.status = '进行中';
            const curDate = moment(moment().format('YYYY-MM-DD'));
            let diffDay = null;
            //赛事生效时间包含开始和截止时间的当天
            if(startDate){
              item.startDateStr = startDate.format('YYYY年MM月DD日');
              item.dateStr = item.startDateStr;
              diffDay = startDate.diff(curDate, 'days');
              if(diffDay > 0){
                item.status = '未开始';
              }
            }
            if(endDate){
              if(endDate.format('YYYY') === startDate.format('YYYY')){
                item.endDatetStr = endDate.format('MM月DD日');
              }else{
                item.endDatetStr = endDate.format('YYYY年MM月DD日');
              }
              if(item.dateStr){
                item.dateStr += '-' + item.endDatetStr;
              }

              diffDay = curDate.diff(endDate, 'days');
              if(diffDay > 0){
                item.status = '已结束';
              }
            }
            
            //主办单位
            const zbCompanyList = item.zbCompanyList;
            if(zbCompanyList && zbCompanyList.length > 0){
              item.zbCompany = zbCompanyList.map(w=>w.companyName).join('、');
            }

            //承办单位
            const cbCompanyList = item.cbCompanyList;
            if(cbCompanyList && cbCompanyList.length > 0){
              item.cbCompany = cbCompanyList.map(w=>w.companyName).join('、');
            }

            //协办单位
            const xbCompanyList = item.xbCompanyList;
            if(xbCompanyList && xbCompanyList.length > 0){
              item.xbCompany = xbCompanyList.map(w=>w.companyName).join('、');
            }
          })
        }
        if(type == '0'){
          this.contestBimList = res.body;
          this.total = res.headers.get('X-Total-Count');
        }else{
          this.contestProvinceList = res.body;
          this.total2 = res.headers.get('X-Total-Count');
        }
      });
  }

  //获取省份数据
  getAllProvinceList(){
    this.homeService.getAllProvinceList().subscribe(res => {
      const datas = res.body;
      if(datas){
        datas.forEach(item => {
          let name = item.name ? item.name : '';
          item.nameNew = name;
          const lengthIndex = name.length - 1;

          if(name.lastIndexOf('市') == lengthIndex){
            item.nameNew = name.substring(0,lengthIndex);
          }
          if(name.lastIndexOf('省') == lengthIndex){
            item.nameNew = name.substring(0,lengthIndex);
          }
        })
      }
      datas.unshift({ id:null,name: '全国',nameNew: '全国' });
      this.provinceList = datas;
    });
  }

  changeTab(tab){
    this.getAllContestList(tab.index,false);
  }

  onProvinceTagClick(item: any) {
    this.selectedItem = item.name;
    
    this.getAllContestList(this.selectedTabIndex,true);
  }

  selContest(item) {
    if (!isNullOrUndefined(item)) {
      this.cacheService.set('bimInfo', item);
      this.bimStore.bimInfoStore.next(item);
      this.router.navigate(['/enroll']);
    }
  }

  

  /**
   * 页码数量变动事件
   * @param
   */
  paginationChangePage(event,type) {
    this.page = event - 1;
    this.getAllContestList(type,false);
  }

  /**
   * 页码数量变动事件
   * @param
   */
  paginationChangeSize(event,type) {
    this.size = event;
    this.getAllContestList(type,false);
  }
}
