import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd';
import { isNullOrUndefined } from 'util';
import { CacheService } from '@delon/cache';
import { Router } from '@angular/router';
import { BimScoringRuleService } from '../../score-rubric/bim-scoring-rule.service';

@Component({
  selector: 'app-score-detail',
  templateUrl: './score-detail.component.html',
  styleUrls: ['./score-detail.component.less']
})
export class ScoreDetailComponent implements OnInit {

  public teamId = this.cacheService.get('bimTeamId')['value'];
  public bimInfoEventId = null;

  schoolWorkList = [];
  public isloadding = false;

  public bimInfo = this.cacheService.get('bimInfo')['value'];

  @Input() schoolWork = null;
  @Output() returnPage = new EventEmitter();

  constructor(
    private router: Router,
    private msg: NzMessageService,
    private cacheService: CacheService,
    private bimScoringRuleService: BimScoringRuleService,
  ) {
  }

  ngOnInit(): void {
    if (isNullOrUndefined(this.bimInfo)) {
      this.router.navigate(['/enroll/contestSelect']);
      return;
    }
    console.log(this.schoolWork);
    this.getDataList();
  }

  getDataList(isReset?: boolean, isSearch?: boolean) {
    if (isNullOrUndefined(this.teamId)) {
      this.msg.info('无报名团队，请重新登录');
      return;
    }
    const copyParams = this.getParams(isReset, isSearch);
    this.isloadding = true;
    this.bimScoringRuleService.getWorkAdvisorsData(copyParams).subscribe(res => {
      this.isloadding = false;
      this.schoolWorkList = res.body;
    });
  }

  getParams(isReset?: boolean, isSearch?: boolean) {
    const copyParams = {};
    copyParams['teamId'] = this.teamId;
    copyParams['bimWorkId'] = this.schoolWork.id;
    return copyParams;
  }

  rePage() {
    this.returnPage.emit();
  }

}
