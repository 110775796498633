import { SettingsService } from '@delon/theme';
import { Component, OnDestroy, Inject, Optional } from '@angular/core';
import { NzMessageService, NzModalService, NzModalRef } from 'ng-zorro-antd';
import {
  SocialService,
} from '@delon/auth';
import { PreviewImgVideo } from './preview-imgvideo.model';
import { data } from 'jquery';

@Component({
  selector: 'preview-imgvideo',
  templateUrl: './preview-imgvideo.component.html',
  styleUrls: ['./preview-imgvideo.component.less'],
  providers: [SocialService],
})
export class PreviewImgVideoComponent implements OnDestroy {
  previewIndex = 0;//当前预览索引
  curPreviewIndex = -1;//当前点击的文件索引（0开始）
  title = '';
  fileType:string;//文件类型 图片：image,视频：video
  dataList:PreviewImgVideo[];//格式 
  fileList:PreviewImgVideo[] = [];
  currentPage = 0;
  currentSize = 5;
  currentStart = 0;
  currentEnd = 0;

  constructor(
    private modal: NzModalRef,
  ) {
    
  }

  ngOnInit() {
    this.currentStart = 0;

    if(this.dataList && this.dataList.length > 0){
      const selList = this.dataList.filter(w=>w.id === 'noimage');
      if(selList && selList.length <= 0){
        this.dataList = [...this.dataList,new PreviewImgVideo('noimage')];
        
        console.log(this.dataList);
      }
      
      if(this.curPreviewIndex >= 0){
        this.currentStart = this.curPreviewIndex;
        let end = this.currentStart + this.currentSize;
        if(end >= this.dataList.length){
          end = this.dataList.length;
        }
        this.currentEnd = end;
        this.fileList = this.dataList.slice(this.currentStart, this.currentEnd);
      }else{
        this.currentEnd = this.dataList.length < this.currentSize ? (this.dataList.length) : (this.currentSize);
        this.fileList = this.dataList.slice(this.currentStart, this.currentEnd);
      }
      
    }

    debugger;
  }

  first(){
    this.previewIndex = 0;
    this.currentStart = 0;
    this.currentEnd = this.dataList.length < this.currentSize ? this.dataList.length : this.currentSize;
    this.fileList = this.dataList.slice(this.currentStart, this.currentEnd);
  }

  next(){
    this.previewIndex++;
    const maxNum = this.dataList.length;
    if(this.previewIndex >= this.currentSize){
      this.previewIndex = 0;
      let newStart = this.currentStart + this.currentSize;
      let newEnd = this.currentEnd + this.currentSize;
      if(newStart <= maxNum){
        this.currentStart = newStart;
      }
      
      if(newEnd <= maxNum){
        this.currentEnd = newEnd;
      }else{
        this.currentEnd = maxNum;
      }
    }
    
    const datas = this.dataList.slice(this.currentStart, this.currentEnd);
    const lastData = this.previewIndex < datas.length ? datas[this.previewIndex] : null;
    if(lastData && lastData.id === 'noimage'){
      this.first();
    }else{
      this.fileList = datas;
    }
  }

  previous(){
    this.previewIndex--;
    if(this.previewIndex <= 0){
      let newStart = this.currentStart - this.currentSize;
      let newEnd = this.currentEnd - this.fileList.length;

      if(newStart >= 0){
        this.currentStart = newStart;
        this.currentEnd = newEnd;
        this.previewIndex = this.dataList.length < this.currentSize ? (this.dataList.length - 1) : (this.currentSize - 1);
      }else{
        this.currentStart = 0;
        this.currentEnd = this.dataList.length < this.currentSize ? (this.dataList.length) : (this.currentSize);
        this.previewIndex = 0;
      }
      this.fileList = this.dataList.slice(this.currentStart, this.currentEnd);
    }
  }


  changeFileIndex(i){
    this.previewIndex = i;
  }

  ngOnDestroy(): void {
    
  }

  close() {
    this.modal.destroy();
  }
}
