import { Component, OnInit, Inject } from '@angular/core';
import { CertificationService } from './certification.service';
import { HttpAddressService } from '@shared/session/http-address.service';
import { ModalHelper } from '@delon/theme';
import { EnlistComponent } from './enlist/enlist.component';
import { Certification } from './certification.model';
import { UserLoginComponent } from '../passport/login/login.component';
import { NzModalService, NzMessageService } from 'ng-zorro-antd';
import { ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';
import { Router } from '@angular/router';
@Component({
  selector: 'app-certification',
  templateUrl: './certification.component.html',
  styleUrls: ['./certification.component.less']
})
export class CertificationComponent implements OnInit {


    // 数据集合
    dataList = [];

  // 视口宽度(包括滚动条)
  viewportW: number;
  // 滚动条宽度
  scrollBarW: number;
  // 内容固定宽度
  fixedW = 1200;
  // 负边距百分比
  mPercent: string = '';

    // 是否登录
    isLogin = false;

  // 当前用户实体id
  entityId = '';
 // 当前用户id
  userId = '';
  constructor(
    private certificationService: CertificationService,
    private modalHelper: ModalHelper,
    private httpAddressService: HttpAddressService,
    private modalService: NzModalService,
    public msg: NzMessageService,
    private router: Router,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  ) {
  }

  ngOnInit() {

    this.getUserData();
    this.loadData();
  }


  loadData() {
    const param = {
      'isOk.equals': '1',
      'sort': ['orderNum,asc']
    };
    this.certificationService.query(param).subscribe(res => {
      const reconds = res.body;
      if (reconds && reconds !== null && reconds.length > 0) {
        this.dataList = reconds;
        this.dataList.forEach(val => {
          if (val.linkUrl !== null && val.linkUrl !== '') {
            val.linkUrl = 'http://' + val.linkUrl;
          }
        });
      }
    });
  }

  // 获取用户信息
  getUserData() {
    const token = this.tokenService.get().token;
    this.userId = this.tokenService.get().user_id;
    this.entityId = this.tokenService.get().entity_id;
    if (token && token !== null && token !== '') {
      this.isLogin = true;
    }
  }


  ngAfterContentInit() {
    this.computePercent();
    window.onresize = () => {
      this.computePercent();
    };
  }

  ngAfterContentChecked(): void {
    // 文档宽度(不包括滚动条：在1920设备屏幕下，页面没渲染前为1920，渲染后为1914)
    const clientW = document.documentElement.clientWidth;
    if (!this.scrollBarW && clientW < this.viewportW) {
      // 动态获取浏览器滚动条宽度
      this.scrollBarW = this.viewportW - clientW;
    }
  }

  //外负边距相关
  computePercent() {
    this.viewportW = window.innerWidth;
    this.mPercent = (this.viewportW - this.fixedW) * 100 / 2 / 1200 + '%';
  }
  getStyle() {
    if (this.viewportW > 1200) {
      return {
        // 右边距需减除滚动条宽度
        'margin': `0 calc(-${this.mPercent} + ${this.scrollBarW}px) 0 -${this.mPercent}`
      };
    } else {
      return {}
    }
  }


  selContest(flag) {
    if (this.isLogin !== true) {
        this.loginUser();
       return;
    }
    let url = "/certification/bimCertification";
    this.router.navigate([url], { queryParams: { certification: flag }});
  }



  /**
   * 登陆
   */

  loginUser() {
    this.modalService.confirm({
      nzTitle: '请登录后操作',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.modalHelper.static(UserLoginComponent, 600).subscribe((data: any) => {
          if (data) {
            this.msg.info('登陆成功');
            this.isLogin = true;
            this.ngOnInit();
            // window.location.reload();
          }
        });
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }


  openRegister(item: Certification) {
    if (this.isLogin !== true) {
        this.loginUser();
       return;
    }
    let type = '';
    let title = '';
    if (item.title === 'THS-BIM认证') {
      type = '1';
      title = '建筑信息模型（BIM）职业能力认证（原THS-BIM职业能力认证）';
    }

    if (item.title === 'Autodesk认证') {
      type = '2';
      title = 'Autodesk认证报名';
    }

    this.modalHelper.static( EnlistComponent,
      { type: type, eduCertChannelId: item.id}, 817,
      { nzTitle: title }).subscribe(() => {
      });
  }

  openExamSystem(item:Certification){
    if (this.isLogin !== true) {
      this.loginUser();
     return;
  }
      let type = '';
      let title = '';
      let url="";
      if (item.title === 'THS-BIM认证') {
        type = '1';
        title = '建筑信息模型（BIM）职业能力认证（原THS-BIM职业能力认证）';
        url="http://202.105.146.197:15080/#/passport/exam/login";
      }

      if (item.title === 'Autodesk认证') {
        type = '2';
        title = 'Autodesk认证报名';
        url="https://www.adsk-certification.cn/";
      }
      window.open(url);
  }

  certQuery(item:Certification){
    if (this.isLogin !== true) {
      this.loginUser();
     return;
  }
      let type = '';
      let title = '';
      let url="";
      if (item.title === 'THS-BIM认证') {
        type = '1';
        title = 'THS-BIM认证报名';
        url="/#/certification";
      }

      if (item.title === 'Autodesk认证') {
        type = '2';
        title = 'Autodesk认证报名';
        url="https://www.adsk-certification.cn/certificate_search/user_cert.php";
      }
      window.open(url);
  }

  toIndex(){
    if (this.isLogin !== true) {
        this.loginUser();
       return;
    }
    this.router.navigate(['/certification/index']);
  }

}
