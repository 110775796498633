import { Component,Inject, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd';
import { CacheService } from '@delon/cache';

import { ActivatedRoute } from '@angular/router';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';

import { _HttpClient,ModalHelper } from '@delon/theme';
import { NzModalService } from 'ng-zorro-antd';
import { isNullOrUndefined } from 'util';
import * as moment from 'moment';
import { BimItemsService } from 'app/routes/enroll/bim-items/bim-items.service';
import { BimPersonItemsService } from '../../bim-person-items/bim-person-items.service';
import { BimPersonResultService } from '../../bim-person-result/bim-person-result.service';
import { BimPersonTearchersService } from '../../bim-person-teacher/bim-person-teacher.service';
import { BimPerson } from '../../bim-person.model';
import { BimPersonService } from '../../bim-person.service';
import { PersonEditResultComponent } from '../../edit-person-result/edit.component';
import { PersonEditComponent } from '../../edit-person/edit.component';
import { ProvincePersonEditComponent } from '../province-edit-person/edit.component';


@Component({
  selector: 'app-province-my-entry',
  templateUrl: './province-my-entry.component.html',
  styleUrls: ['./province-my-entry.component.less']
})
export class ProvinceMyEntryComponent implements OnInit {

  public bimEventId = null;
  checkedItem = 0;
 public bimPerson: BimPerson;
  matchDetailList = [
   
  ]

  isVisible=false;
  // { result: 'qualified', awards: 'first', project: '土建' },
  // { result: 'unqualified', awards: 'second', project: '机电' }

  ItemTypes=[];
  Items =[];
  personItems=[];
  personSchooleList: any[] = [];
  public signUpflag = 0;

  public isUpdatePrizeInfo = 0;
  
  public curTeamEvent = this.cacheService.get('curTeamEvent')['value'];


  constructor(
    private msg: NzMessageService,
    private cacheService: CacheService,
    private routerInfo: ActivatedRoute,
    private bimPersonService:BimPersonService,
    private modal: ModalHelper,
    private bimItemsService:BimItemsService,
    private bimPersonItemsService:BimPersonItemsService,
    private bimPersonTearchersService:BimPersonTearchersService,
    private bimPersonResultService:BimPersonResultService,
    private modalService: NzModalService,

    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,

  ) {

    this.bimPerson = new BimPerson();
    const id = this.routerInfo.snapshot.params["bimEventId"];

    this.bimEventId = id;
    this.cacheService.set("personBimEventId", this.bimEventId);


   }

  ngOnInit() {
    // 未报名
    this.signUpflag = 0;
    if (this.curTeamEvent && !isNullOrUndefined(this.curTeamEvent.startTime)) {
      const startTime = Date.parse(moment(this.curTeamEvent.startTime).format('YYYY-MM-DD'));
      const endTime = Date.parse(moment(this.curTeamEvent.endTime).format('YYYY-MM-DD'));
      const nowTime = Date.parse(moment(new Date()).format('YYYY-MM-DD'));
      this.signUpflag = nowTime < startTime ? 2 : (nowTime > endTime ? 1 : 0);

      this.isUpdatePrizeInfo = this.curTeamEvent.isUpdatePrizeInfo;
      console.log(this.curTeamEvent);
      console.log(this.isUpdatePrizeInfo);
    }

    this.getData();
 
    
   
  }


  getData(){
    const mobile = this.tokenService.get().mobile;

    let copyParams = {  sort: ['createTime,desc']};
    copyParams['personTel.equals'] = mobile;
    copyParams['bimInfoEventsId.equals'] = this.bimEventId;
    copyParams['isDelete.equals'] = 0;
    copyParams['page'] = 0;
    copyParams['size'] = 10;

    // 查询学生是否加入团队
    this.bimPersonService.queryNewAnd(copyParams).subscribe(res => {
      if (res && res.body.length > 0) {
         this.bimPerson = res.body[0];

         this.loadItems();
         this.getPersonTearcherList();
         this.getPersonResult();
      }else{
        this.bimPerson = new BimPerson();
      }
        
    });

  }

  changeItem() {

    

    if(this.matchDetailList.length > 0 )
    {
      if(this.checkedItem >= 1)
      {
        this.checkedItem = 0;
      }else
      {
        this.checkedItem = 1;
      }
    }else{
      if(this.checkedItem >= 1)
      {
        this.checkedItem = 0;
      }else
      {
        this.checkedItem = 2;
      }
    }
  }

  editSchoolInfo() {
    this.modal
    .static(ProvincePersonEditComponent, { personId: this.bimPerson.id, bimEventId: this.bimEventId })

    .subscribe(() => {
        this.getData();   
    });
  }


  loadItems(){
    this.Items=[];
    this.ItemTypes=[];
    let copyParams = {};
    copyParams['sort'] =['type,asc', 'orderSn,asc'];
    this.bimItemsService.getAllBimItemsInBimEvents(this.bimEventId, copyParams).subscribe((res: any) => {
      
          this.Items = res.body;
          let tmpType="";
          this.Items.forEach(element => {
               let tmpHasItem = this.ItemTypes.filter(d => d.type === element.type);
               if(element.type != tmpType && tmpHasItem.length <= 0)
               {
                   tmpType = element.type;
                   let tmpTypeObj = {};
                   tmpTypeObj["type"] = tmpType;
                   this.ItemTypes.push(tmpTypeObj);
               }
          });

          this.loadPersonItems();
    });
  }

  getItemByType(type){
    return this.Items.filter(d => d.type === type).sort(d=>d.orderSn);
  }

  loadPersonItems(){
    this.personItems = [];
    // let copyParams = {};
    // copyParams['personId.contains'] = this.bimPerson.id;
    this.bimPersonItemsService.getBimPersonItemByPerId(this.bimPerson.id).subscribe((res: any) => {
        this.personItems = res.body;
    });
  }


  getIsPersonItem(itemId){
     let icount = this.personItems.filter(d => d.itemId === itemId).sort(d=>d.orderSn).length ;
     return icount >0;
  }

  getIsPersonItemStatus(itemId){
    let personItemListTmp = this.personItems.filter(d => d.itemId === itemId).sort(d=>d.orderSn) ;
    let istatus = 0;
    if(istatus !=null && personItemListTmp != undefined && personItemListTmp.length >0)
    {
      istatus = personItemListTmp[0].status ;
    }
    return istatus >0;
 }


  getPersonTearcherList(){
    let copyParams = {};

    copyParams['personId.contains'] = this.bimPerson.id;

    this.bimPersonTearchersService.query(copyParams)
      .subscribe((res: any) => {
         this.personSchooleList = res.body;
      });

  }

  getPersonResult(){
    // let copyParams = {};
    // copyParams['personId.contains'] = this.bimPerson.id;

    this.bimPersonResultService.getPersonResultByPersonId(this.bimPerson.id)
      .subscribe((res: any) => {
        this.matchDetailList=res.body;
        this.matchDetailList.forEach(item=>{
          if(item.scoreRemark === "合格")
          {
            item["result"] = "qualified";
          }else if (item.scoreRemark === "不合格"){
             item["result"] = "unqualified";
          }

          if(item.prizeLevel === "一等奖")
          {
             item["awards"] = "first";
          } else if(item.prizeLevel === "二等奖")
          {
             item["awards"] = "second";
          } else if(item.prizeLevel === "三等奖")
          {
             item["awards"] = "third";
          }else 
          {
              item["awards"] = "no";
          }
        });
      });
  }


  downLoadCert(itemId){
    if(this.bimEventId != null && this.bimEventId != undefined && this.bimEventId.length >0)
    {
      
    }else{
      return false;
    }

    if(this.bimPerson != null  &&  this.bimPerson != undefined &&  this.bimPerson.id != null && this.bimPerson.id != undefined && this.bimPerson.id.length >0)
    {
      
    }else{
      return false;
    }



    let copyParams = {};
    copyParams['eventId'] = this.bimEventId;
    copyParams['personId'] = this.bimPerson.id;
    copyParams['itemId'] = itemId;
    this.bimPersonResultService.exportCertToPdf(copyParams);
  }


  canshaiqueren(id){

      let personItemTmpList = this.personItems.filter(d => d.itemId === id);
      if(personItemTmpList == null || personItemTmpList == undefined || personItemTmpList.length<=0)
      {
        this.msg.error('没有报名该专项，不能确认参赛！');
        return;
      }


      let personItem = personItemTmpList[0];
      let status = personItem.status;
      let msg="";
      if(status == 1)
      {
        msg="取消"
        status = 0;
      }else{
          status = 1;
      }


     
      this.modalService.confirm({
        nzTitle     : '是否' + msg + '确认参赛？',
        nzContent   : '',
        nzOkText    : '确认',
        nzOkType    : 'danger',
        nzOnOk      : () => {
          personItem.status = status;

          this.bimPersonItemsService.update(personItem).subscribe(() => {
            this.msg.info(msg + "确认参赛成功！");
          });
         
        },
        nzCancelText: '取消',
        nzOnCancel  : () => {}
      });

  }


  test(){
    this.isVisible = !this.isVisible;
  }


  editPersonResultInfo(){
      this.modal
      .static(PersonEditResultComponent, { personId: this.bimPerson.id, bimEventId: this.bimEventId })

      .subscribe(() => {
          this.getData();   
      });
  }
}
