import { Component, Inject, OnInit, Input } from '@angular/core';
import { SettingsService } from '@delon/theme';
import { ModalHelper } from '@delon/theme';
import { TokenService, DA_SERVICE_TOKEN } from '@delon/auth';
import { CacheService } from '@delon/cache';
import { NavigationEnd, Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { UserLoginComponent } from 'app/routes/passport/login/login.component';

@Component({
  selector: 'layout-enter-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class EnterHeaderComponent implements OnInit {

  @Input() isVisiteAdmin: boolean;
  @Input() isVisiteRegister: boolean;

  searchToggleStatus: boolean;
  isLogin = false;
  isTeacher = false;
  isStudent = false;
  index = '1';
  form: FormGroup;
  dateNum: number;
  constructor(
    fb: FormBuilder,
    router: Router,
    public settings: SettingsService,
    private cacheService: CacheService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: TokenService,
    private modalHelper: ModalHelper,
  ) {
    this.form = fb.group({
      txtSearch: [null],
    });
    
    router.events.subscribe(evt => {
      if (evt instanceof NavigationEnd) {
        const token = this.tokenService.get().token;
        if (token) {
          const entityType = this.tokenService.get().entity_type;
          this.isTeacher = entityType === '1';
        }
      }
    });
  }

  ngOnInit() {
    this.initIsLogin();
  }

  initIsLogin() {
    const token = this.tokenService.get().token;
    if (token) {
      // 登录时间戳
      const time = this.tokenService.get().time;
      // 当前时间戳
      const curTime = new Date().getTime();
      if (curTime > (time + ( 7 * 24 * 60 * 60 * 100))) {
        this.isLogin = false;
        this.tokenService.clear();
        return;
      }
      this.isLogin = true;
      const entityType = this.tokenService.get().entity_type;
      if (entityType === '1') {
        this.isTeacher = true;
      }
      if (entityType === '0' || entityType === '2') {
        this.isStudent = true;
      }
    } else {
      this.isLogin = false;
    }
  }

  loginOut() {
    this.tokenService.clear();
    this.cacheService.remove('bimInfo');
    this.initIsLogin();

    this.index = '1';
  }


  toggleCollapsedSideabar() {
    this.settings.setLayout('collapsed', !this.settings.layout.collapsed);
  }

  searchToggleChange() {
    this.searchToggleStatus = !this.searchToggleStatus;
  }

  openLoginModal() {
    this.modalHelper.open(UserLoginComponent, {}, 482, { nzClassName: 'login-modal' }).subscribe(() => {
      this.initIsLogin();
    });
  }

}
