import { Injectable } from '@angular/core';
import { ThsBaseService } from '@shared/components/thsBaseService';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
import { BimSchoolDetails } from './bim-school-details.model';

@Injectable({ providedIn: 'root' })
export class  BimSchoolDetailsService extends ThsBaseService<BimSchoolDetails>  {
  constructor(
    public http: HttpClient, httpAddressService: HttpAddressService ) {
    super(http, httpAddressService.BimServe + '/bim-school-details');
  }
}
