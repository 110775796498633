import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CacheService } from '@delon/cache';
import { HttpAddressService } from '@shared/session/http-address.service';
import { HomeService } from './../home.service';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-enterprise-contest-select',
  templateUrl: './contest-select.component.html',
  styleUrls: ['./contest-select.component.less'],
})
export class ContestSelectComponent implements OnInit {
  public contestList = [];
  imagePrefix = '';

  constructor(
    private router: Router,
    
    private homeService: HomeService,
    private cacheService: CacheService,
    private httpAddressService: HttpAddressService,
  ) {
    this.imagePrefix =
      this.httpAddressService.apiGateway + '/thsadmin/api/sys-files/download/';
  }

  ngOnInit(): void {
    this.cacheService.set('bimInfo', null);
    this.getContestList();
  }

  // 获取大赛数据
  getContestList() {
    this.homeService
      .getBimInfos({
        size: 1000,
        'type.equals': 'enterprise',
        'status.equals': 1,
        'isDelete.equals': 0,
        sort: ['startDate,desc'],
      })
      .subscribe(res => {
        console.log(res);
        this.contestList = res.body;
      });
  }

  selContest(item) {
    if (!isNullOrUndefined(item)) {
      this.cacheService.set('bimInfo', item);
      this.router.navigate(['/enterprise']);
    }
  }
}
