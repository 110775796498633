import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TeamRoutingModule } from './team-routing.module';
import { WorkUploadComponent } from './work-upload/work-upload.component';
import { ContestTaskBookComponent } from './contest-task-book/contest-task-book.component';
import { TeamIndexComponent } from './team-index/team-index.component';
import { ProjectUploadComponent } from './work-upload/components/project-upload/project-upload.component';
import { SchoolRegisterComponent } from './school-register/school-register.component';
import { SharedModule } from '../../../shared/shared.module';
import { UtilsModule } from '../../../shared/utils/utils.module';
import { SchoolInformationManageComponent } from './school-information-manage/school-information-manage.component';
import { CompetitionRegistrationComponent } from './competition-registration/competition-registration.component';
import { JudgeRatingComponent } from './judge-rating/judge-rating.component';
import { CompetitionSignUpComponent } from './competition-registration/competition-sign-up/competition-sign-up.component';
import { ScoreRubricComponent } from './score-rubric/score-rubric.component';
import { CompetitionTeamInfoComponent } from './competition-team-info/competition-team-info.component';
import { SchoolWorkScoringComponent } from './school-work-scoring/school-work-scoring.component';
import { TeamWorkInfoComponent } from './tean-work-info/team-work-info.component';
import { ScoreDetailComponent } from './tean-work-info/score-detail/score-detail.component';
import { ReceiptUploadComponent } from './receipt-upload/receipt-upload.component';
import { TeacherEditComponent } from './receipt-upload/teacher-edit/teacher-edit.component';
import { StudentEditComponent } from './receipt-upload/student-edit/student-edit.component';
import { ReceiptUploadFileComponent } from './receipt-upload/receipt-upload-file/receipt-upload-file.component';
import { SchoolReplyWorkComponent } from './school-reply-work/school-reply-work.component';
import { WorkListComponent } from './school-reply-work/work-list/work-list.component';
import { ProvinceCompetitionRegistrationComponent } from './province-competition-registration/province-competition-registration.component';
import { ProvinceSchoolInformationManageComponent } from './province-school-information-manage/province-school-information-manage.component';
import { ProvinceSchoolRegisterComponent } from './province-school-register/province-school-register.component';
import { LeaderEditComponent } from './receipt-upload/leader-edit/leader-edit.component';
import { WorkReplyFileComponent } from './work-reply-file/work-reply-file.component';
import { ReplyUploadComponent } from './work-reply-file/reply-upload/reply-upload.component';
import { ReplyFileManageComponent } from './work-reply-file/reply-file-manage/reply-file-manage.component';

const COMPONENTS = [
  WorkUploadComponent,
  ContestTaskBookComponent,
  TeamIndexComponent,
  ProjectUploadComponent,
  SchoolRegisterComponent,
  SchoolInformationManageComponent,
  CompetitionRegistrationComponent,
  JudgeRatingComponent,
  ScoreRubricComponent,
  CompetitionTeamInfoComponent,
  SchoolWorkScoringComponent,
  ReceiptUploadComponent,
  TeamWorkInfoComponent,
  SchoolReplyWorkComponent,
  WorkListComponent,
  ProvinceCompetitionRegistrationComponent,
  ProvinceSchoolInformationManageComponent,
  ProvinceSchoolRegisterComponent,
  WorkReplyFileComponent,
  ReplyFileManageComponent,
];
const COMPONENTS_NOROUNT = [
  CompetitionSignUpComponent,
  ScoreDetailComponent,
  TeacherEditComponent,
  StudentEditComponent,
  ReceiptUploadFileComponent,
  LeaderEditComponent,
  ReplyUploadComponent,
];

@NgModule({
  imports: [SharedModule, UtilsModule, CommonModule, TeamRoutingModule],
  declarations: [...COMPONENTS, ...COMPONENTS_NOROUNT],
  providers: [],
  entryComponents: COMPONENTS_NOROUNT,
})
export class TeamModule { }
