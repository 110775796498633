import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../../../shared/shared.module';
import { UtilsModule } from '../../../shared/utils/utils.module';
import { PersonalRoutingModule } from './personal-routing.module';
import { SignUpComponent } from './sign-up/sign-up.component';
import { PersonalIndexComponent } from './personal-index/personal-index.component';
import { MyEntryComponent } from './my-entry/my-entry.component';
import {PersonEditComponent} from './edit-person/edit.component';
import {PersonEditResultComponent} from './edit-person-result/edit.component';
import { ProvinceSignUpComponent } from './province/province-sign-up/province-sign-up.component';
import { ProvinceMyEntryComponent } from './province/province-my-entry/province-my-entry.component';
import { ProvincePersonEditComponent } from './province/province-edit-person/edit.component';

const COMPONENTS = [
  PersonalIndexComponent,
  SignUpComponent,
  MyEntryComponent,
  ProvinceSignUpComponent,
  ProvinceMyEntryComponent,
];
const COMPONENTS_NOROUNT = [
  PersonEditComponent,
  PersonEditResultComponent,
  ProvincePersonEditComponent,
];

@NgModule({
  imports: [SharedModule, UtilsModule, CommonModule, PersonalRoutingModule],
  declarations: [...COMPONENTS, ...COMPONENTS_NOROUNT,],
  providers: [],
  entryComponents: COMPONENTS_NOROUNT,
})
export class PersonalModule { }
