import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { FileUploadService } from '@shared/components/fileUpload/fileUpload.service';
import { HttpAddressService } from '@shared/session/http-address.service';
import * as OSS from 'ali-oss';

@Component({
  selector: 'app-work-list',
  templateUrl: './work-list.component.html',
  styleUrls: ['./work-list.component.less']
})
export class WorkListComponent implements OnInit {

  @Input() schoolWork = null;
  public selWorkType = 'team_works_image';
  workTypeArr = {
    '图片': 'team_works_image',
    '文档': 'team_works_word',
    'PPT': 'team_works_ppt',
    '视频': 'team_works_video',
    '模型': 'team_works_model'
  };

  public allWorkFileList = [];
  public disableFileList = [];
  imagePrefix = '';

  // 视频播放前缀
  videoPrefix: string;

  downOSSFile: string;

  isInit = false;
  myPlayer: any;
  videoUrl = null;
  imgUrl = null;

  @Output() returnPage = new EventEmitter();

  public ossClient;

  constructor(
    private fileUploadService: FileUploadService,
    private httpAddressService: HttpAddressService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  ) {
    this.imagePrefix = this.httpAddressService.apiGateway + '/thsadmin/api/sys-files/preview/';
    this.downOSSFile = this.httpAddressService.fileGateway + this.httpAddressService.FileServer + '/sys-files/downloadOSSFile/';

    this.ossClient = new OSS(this.httpAddressService.ossServe);
  }

  ngOnInit() {
    this.fileUploadService.query({
      'fileFk.equals': this.schoolWork.workId,
    }).subscribe(res => {
      this.allWorkFileList = res.body;
    });
  }

  rePage() {
    this.returnPage.emit();
  }

  downFile(item) {
    const a = document.createElement('a');
    let url = null;
    if (item.extField2 === 'AliOss') {
      const filename = item.fileName;
      const response = {
        'content-disposition': `attachment; filename=${encodeURIComponent(filename)}`
      };
      url = this.ossClient.signatureUrl(item.fileSavePath, { response });
    } else {
      url = this.httpAddressService.apiGateway + '/thsadmin/api/sys-files/download/' + item.id;
    }
    a.href = url;
    a.click();
  }

  formatWorkType(item) {
    for (const key in this.workTypeArr) {
      if (item.extField1 === this.workTypeArr[key]) {
        return key;
      }
    }
    return null;
  }

}
