export class BimPersonTearchers {
  constructor(
    public  id?: string,
    public  personId?: string,
    public  name?: string,
    public  profession?: string,
    public  email?: string,
    public  remark?: string,
    public  sex?: string,
    public  mobile?: string,
    public  photoPath?: string,
    public  type?: number,
  ) {}
}