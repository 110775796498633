import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpAddressService } from '@shared/session/http-address.service';
import { HomeService } from 'app/routes/enroll/home/home.service';
import { DictDetailService } from 'app/shared/components/dict/dict-detail.service';
import { CourseService } from 'app/routes/course/course.service';
import { data } from 'jquery';
import { zip } from 'rxjs';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-learning-video',
  templateUrl: './learning-video.component.html',
  styleUrls: ['./learning-video.component.less']
})
export class LearningVideoComponent implements OnInit {

  public title = '学习视频';

  public dataList = [];
  public curSelect: any = null;
  public fileList = [];

  public page = 0;
  public size = 20;
  public total = '0';


  imagePrefix = '';
  dictDetailIds = [];//课程类型（itemValue3为行业赛）

  public queryParams = {
    // 查询搜索框的字段要预置
    'isPublish.equals': '1',
    'isDelete.equals': '0',
    'course_name.contains': null,
    // sort固定
    sort: ['startDate,desc', 'createTime,desc']
  };


  constructor(
    private homeService: HomeService,
    private routeInfo: ActivatedRoute,
    private httpAddressService: HttpAddressService,
    private dictDetailService: DictDetailService,
    private courseService: CourseService,
  ) {
    this.imagePrefix =
      this.httpAddressService.apiGateway + '/thsadmin/api/sys-files/download/';
  }

  ngOnInit(): void {
    this.initLeaningVideo();
    this.getLeaningVideos();
  }

  initLeaningVideo(){
    const params = {
      'itemValue3.equals': '行业赛'
    };
    zip(
      this.dictDetailService.query(params)
    ).subscribe(([dictRes]) => {
      const datas = dictRes.body;
      this.dictDetailIds = datas.map(w=>w.id);
      if(!this.dictDetailIds){
        this.dictDetailIds = [];
      }
      this.getLeaningVideos();
    });
  }
  
  getLeaningVideos(isReset?: boolean, isSearch?: boolean) {
    const params =  this.getParams(isReset, isSearch);
    this.courseService.queryCourse(params).subscribe(res=>{
      this.total = res.headers.get('X-Total-Count');
      this.dataList = res.body;
    });
  }


  getParams(isReset?: boolean, isSearch?: boolean) {
    const copyParams = {};
    const q = this.queryParams;
    if (isReset === true) {
      this.page = 0;
      Object.keys(q).forEach(function (key) {
        if (key.indexOf('isOk') > -1) {
          q[key] = '';
        } else {
          q[key] = null;
        }
      });
    }
    Object.keys(q).forEach(function (key) {
      if (q[key] !== '' && q[key] !== null) {
        copyParams[key] = q[key];
      }
    });
    
    copyParams['isDelete.equals'] = '0';
    copyParams['page'] = this.page;
    copyParams['size'] = this.size;
    if (isSearch) {
      this.page = 0;
      copyParams['page'] = 0;
    }
    return copyParams;
  }


  initDataFile() {
    this.homeService.getAllFiles({
      'fileFk.equals': this.curSelect.id,
    }).subscribe(fRes => {
      this.fileList = fRes.body;
    });
  }

  downFile(fileId) {
    const a = document.createElement('a');
    const url =  this.httpAddressService.apiGateway + '/thsadmin/api/sys-files/download/' + fileId;
    a.href = url;
    a.click();
  }

  clickCoop(item) {
    this.curSelect = item;
    this.initDataFile();
  }



  /**
  * 页码数量变动事件
  * @param
  */
 paginationChange(event: { pi: number; ps: number; }) {
  if (event.pi === undefined) {
    return;
  }
  this.page = event.pi - 1;
  this.size = event.ps;
  this.getLeaningVideos();
}

/**
* 页码数量变动事件
* @param
*/
paginationChangePage(event) {
  this.page = event - 1;
  this.getLeaningVideos();
}

/**
* 页码数量变动事件
* @param
*/
paginationChangeSize(event) {
  this.size = event;
  this.getLeaningVideos();
}

}
