import { Component, OnInit,ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { environment } from '@env/environment';
import { HttpAddressService } from '@shared/session/http-address.service';
import { ListService } from './list.service';


import { HomeService } from '../../home/home.service';
import * as moment from 'moment';
import { formArrayNameProvider } from '@angular/forms/src/directives/reactive_directives/form_group_name';




@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.less']
})
export class ListComponent implements OnInit {
  @ViewChild('courseStatus') courseStatus: ElementRef;

  // 视口宽度(包括滚动条)
  viewportW: number;
  // 滚动条宽度
  scrollBarW: number;
  // 内容固定宽度
  fixedW = 1200;
  // 负边距百分比
  mPercent: string = '';


  dataSet: any[] = [];

  dataValue = 0;

  ItemTypeSubListArray:any[]=[];
  ItemTypeListArray: any[] = [];
  courseValue=[];

  params = {
    // 'page': '0',
    // 'size': '16',
    // // 'sort': 'creation_time,asc'
  };
  page = 0 ;
  size = 16 ;
  param = {};
  total = 0;
  pageIndex = 1;
  imgSrc: string;
  typeid: string;
  typename: string;
  typeRootID: string;
  typeName: string;
  cousename: string;
  tabName = '全部';
  typeList: any[] = [];
  searchCourseName = '';
  CourseDataList: any[] = [];
  courseType: any[] = [];
  courseTypelist: any[] = [];
  courseTypeaylist: any[] = [];
  constructor(
    private elementRef: ElementRef,
    private listService: ListService,
    private httpAddressService: HttpAddressService,
    private routerInfo: ActivatedRoute,
    private router: Router,
    private homeService: HomeService,
    
     ) {
      //  图片的展示路径
      this.imgSrc = this.httpAddressService.apiGateway + '/thsadmin/api/sys-files/download/';


      this.routerInfo.queryParams.subscribe(parms => {
        if (parms.typeid) {
           this.typeRootID = parms.typeid;  
           this.params['classifyId.equals'] = this.typeRootID;
        }
      });

     }

  //分类
  classifyItem1 = 0;
  classifyItem2 = 0;
  classifyItem3 = 0;
  classifyList1 = ['全部', '高职课程', '本科课程'];
  classifyList2 = ['全部', '智能建造技术', '工程管理', '工程造价', '绿色建筑碳排放'];
  classifyList3 = ['全部', '免费课程', '收费课程','其他课程'];
  
  // 1全部 2 即将结束 3 以完结
  courseList = [
    {
      name: '全部',
      Key: ' ',
    },
    {
      name: '进行中',
      Key: '1',
    },
    {
      name: '即将结束',
      Key: '2',
    },
    {
      name: '已结束',
      Key: '3',
    }
  ];
  //排序
  toolbarItem = 0;



  ngOnInit() {
  //   this.typeRootID =  this.routerInfo.snapshot.queryParams['typeid'];
    // this.typeName = this.routerInfo.snapshot.queryParams['typename'];

    // this.params['classifyId.equals'] = this.typeRootID;

    this.getCourseClassifies();

    this.getcourseType();
    this.getCourseByid();
  }

  ngAfterContentInit() {
    this.computePercent();
    window.onresize = () => {
      this.computePercent();
    };
  }

  ngAfterContentChecked(): void {
    // 文档宽度(不包括滚动条：在1920设备屏幕下，页面没渲染前为1920，渲染后为1914)
    const clientW = document.documentElement.clientWidth;
    if (!this.scrollBarW && clientW < this.viewportW) {
      // 动态获取浏览器滚动条宽度
      this.scrollBarW = this.viewportW - clientW;
    }
  }

  goDetailPage() {
    this.router.navigateByUrl('/course/detail');
  }

  getcourseType() {
    this.listService.findPageByCoursetype(this.typeRootID).subscribe((sub: any) => {
      this.courseTypelist = sub.body.data;
      for (let index = 0; index <  this.courseTypelist.length; index++) {
        const classifytype =  this.courseTypelist[index].courseType;
        this.gettypelist(classifytype);
      }
    });
  }
  gettypelist(classifytype: string) {
    this.listService.getcourselist(this.typeRootID, classifytype).subscribe((sub: any) => {
      this.courseTypeaylist = sub.data;
    });
  }
  onTabSelect(item: any) {
    this.params['courseType.equals'] = item;
    this.tabName = item;
    this.gettypelist(item);
    this.getCourseByid();
  }
  onTabcousename(item: any) {
    this.params['courseName.equals'] = item;
    this.cousename = item;
    this.getCourseByid();
  }
  /**
   * 查找课程
   */
  _search() {
    if (this.searchCourseName && '' !== this.searchCourseName.trim()) {
      this.params['courseName.contains'] = this.searchCourseName;
    } else {
      if (this.params['courseName.contains']) {
        delete this.params['courseName.contains'];
      }
    }
    this.getCourseByid();
  }
   // 根据分类id获取课程的数据 1全部 2 即将结束 3 以完结
  tabTo(item: any) {
    if (item.Key === '1') {
      this.tabName = '进行中';
      this.params['courseStatus.equals'] = '1';
      this.getCourseByid();
    } else if (item.Key === '2') {
      this.tabName = '即将结束';
      this.params['courseStatus.equals'] = '2';
      this.getCourseByid();
    } else if (item.Key === '3') {
      this.tabName = '已结束';
      this.params['courseStatus.equals'] = '3';
      this.getCourseByid();
    }  else {
      delete this.params['courseStatus.equals'];
      this.tabName = '全部';
      this.getCourseByid();
    }
  }
  // 根据分类id获取课程的数据 1全部 2 即将结束 3 以完结
  getCourseByid() {
    
    this.params['isPublish.equals'] = '1';
    this.params['isDelete.equals'] = '0';
    this.params['page'] = this.page - 1;
    this.params['size'] = this.size;
    this.listService.findPageByCourseId(this.params).subscribe((subres: any) => {
      this.CourseDataList = subres.body;
      this.total = subres.headers.get('X-Total-Count');
    });
  }
  comprehensive() {
    this.params['sort'] = ['orderNum,asc'];
    this.getCourseByid();
    this.toolbarItem = 0;
  }
  Newest() {
    this.params['sort'] = ['updateSection,desc'];
    this.getCourseByid();
    this.toolbarItem = 1;
  }
  watch() {
    this.params['sort'] = ['historyCount,desc'];
    this.getCourseByid();
    this.toolbarItem = 2;
  }
  collection() {
    this.params['sort'] = ['favoriteCount,desc'];
    this.getCourseByid();
    this.toolbarItem = 3;
  }
  /**
  * 页码数量变动事件
  * @param
  */
  pageIndexChange(event) {
    this.page = event;
    this.getCourseByid();
  }
  pageSizeChange(event) {
    this.size = event;
    this.getCourseByid();
  }
  
  //外负边距相关
  computePercent() {
    this.viewportW = window.innerWidth;
    this.mPercent = (this.viewportW - this.fixedW) * 100 / 2 / 1200 + '%';
  }
  getStyle() {
    if (this.viewportW > 1200) {
      return {
        // 右边距需减除滚动条宽度
        'margin': `0 calc(-${this.mPercent} + ${this.scrollBarW}px) 0 -${this.mPercent}`
      };
    } else {
      return {}
    }
  }



  // 获取教育云分类
  getCourseClassifies () {
    // 分类 0 为无效 1 为有效
    this.homeService.geteducourseclassifies().subscribe((listRes: any) => {

      this.dataSet = [];
      this.dataSet.push({ id: '-1', name: '全部' });

      for(let j= 0; j <listRes.body.data.length; j++ )
      {
         let disctItem = listRes.body.data[j];
         this.dataSet.push({  id: disctItem.id, name: disctItem.name }) ;

         if(this.typeRootID != null  &&  this.typeRootID  != undefined &&  this.typeRootID.length >0 && this.typeRootID === disctItem.id)
         {
           this.dataValue = j+1;
           
         }

      };

      //this.dataSet = listRes.body.data;
      this.getCourseClassifiesSubType();
    });
  }


  //获取课程分类中的一级子分类
  getCourseClassifiesSubType(){
    this.ItemTypeSubListArray = [];
    if (this.dataSet !== undefined && this.dataSet != null) {
          for (let i = 0; i < this.dataSet.length; i++) {
            this.courseValue[i] = 0;
            this.homeService.getDictDetailsByValue2(this.dataSet[i].id).subscribe((subres: any) => {
              let data = subres;
                  if(data.success)
                  {
                      let disctId =  data.data[0].id;                   
                      this.homeService.getDictDetailsByPid(disctId).subscribe((dictSubres: any) => {
                         if(dictSubres.success)
                         {
                            
                            this.ItemTypeSubListArray[i]=[];

                            this.ItemTypeSubListArray[i].push({ parentId: disctId, id: '-1', itemText: '全部' });
 

                            for(let j= 0; j <dictSubres.data.length; j++ )
                            {
                               let disctItem = dictSubres.data[j];
                               this.ItemTypeSubListArray[i].push({ parentId: disctItem.parentId, id: disctItem.id, itemText: disctItem.itemText }) ;
                            };

                         }
                         
                      });

                  }
               });
          }
    }
  }




  setDataValue(i:number,classifyId:string){
    debugger;
      this.dataValue = i;
    //  this.params['courseTypeId.equals'] = null;

      delete this.params['courseTypeId.equals'] ;

      if(classifyId === "-1")
      {
        //this.params['classifyId.equals'] = null;     
        
        delete this.params['classifyId.equals'] ;
        
        this.getCourseByid();
      }
      else{
        this.params['classifyId.equals'] = classifyId;
        this.getCourseByid();
      }
  }



    setCourseValue(i:number,dictId:string){
      debugger;
      this.courseValue[this.dataValue] = i;

      if(dictId === "-1")
      {
      //  this.params['courseTypeId.equals'] = null;
      delete  this.params['courseTypeId.equals'];   
        this.getCourseByid();
      }
      else{
        this.params['courseTypeId.equals'] = dictId;
        this.getCourseByid();
      }
  }


  setCostValue(i:number){
     this.classifyItem3 = i;
     if(i === 0)
     {
      delete this.params['isCharge.equals'];
        this.getCourseByid();
     }else if(i === 3){
      this.params['isCharge.specified'] = false;
      this.getCourseByid();
     }
     
     else{
      this.params['isCharge.equals'] = i-1;
      this.getCourseByid();
     }
  }

}
