import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HomeService } from './../home/home.service';
import { CacheService } from '@delon/cache';
import { HttpAddressService } from '@shared/session/http-address.service';
import { isNullOrUndefined } from 'util';
import * as moment from 'moment';
import { BimStore } from '@shared/store/bim.store';

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.less'],
})
export class GameComponent implements OnInit {
  public selectedValue: any;
  public bimInfoList = ['Option 1', 'Option 2', 'Option 3', 'Option 4'];
  public contestBimList = [];//全国赛
  public contestProvinceList = [];//省赛
  loading = true;
  loadingProvince = true;
  imagePrefix = '';

  constructor(private router: Router,
    private homeService: HomeService,
    private cacheService: CacheService,
    private bimStore: BimStore,
    private httpAddressService: HttpAddressService) {
      this.imagePrefix = this.httpAddressService.apiGateway + '/thsadmin/api/sys-files/download/';
  }

  ngOnInit() {
    this.cacheService.set('bimInfo', null);
    this.bimStore.bimInfoStore.next(null);
    this.getAllContestList('bim');
    this.getAllContestList('province');
  }

  // 获取全国赛事数据
  getAllContestList(type) {
    if(type === 'bim'){
      this.loading = true;
    }else if(type === 'province'){
      this.loadingProvince = true;
    }
    this.homeService
      .getAllContestInfos({
        size: 4,
        'type': type
      })
      .subscribe(res => {
        if(type === 'bim'){
          this.loading = false;
        }else if(type === 'province'){
          this.loadingProvince = false;
        }
        // console.log(res);
        if(res.body){
          res.body.forEach(item => {
            const startDate = !item.startDate ? null : moment(item.startDate);
            const endDate = !item.endDate ? null : moment(item.endDate);
            if(startDate){
              item.startDateStr = startDate.format('YYYY年MM月DD日');
              item.dateStr = item.startDateStr;
            }
            if(endDate){
              if(endDate.format('YYYY') === startDate.format('YYYY')){
                item.endDatetStr = endDate.format('MM月DD日');
              }else{
                item.endDatetStr = endDate.format('YYYY年MM月DD日');
              }
              if(item.dateStr){
                item.dateStr += '-' + item.endDatetStr;
              }
            }
            
            //主办单位
            const zbCompanyList = item.zbCompanyList;
            if(zbCompanyList && zbCompanyList.length > 0){
              item.zbCompany = zbCompanyList.map(w=>w.companyName).join('、');
            }

            //承办单位
            const cbCompanyList = item.cbCompanyList;
            if(cbCompanyList && cbCompanyList.length > 0){
              item.cbCompany = cbCompanyList.map(w=>w.companyName).join('、');
            }
          })
        }
        if('bim' === type){
          this.contestBimList = res.body;
        }else if('province' === type){
          this.contestProvinceList = res.body;
        }
      },() => {
        if(type === 'bim'){
          this.loading = false;
        }else if(type === 'province'){
          this.loadingProvince = false;
        }
      });
  }

  selectContest(item){
    if (!isNullOrUndefined(item)) {
      this.cacheService.set('bimInfo', item);
      this.bimStore.bimInfoStore.next(item);
      this.router.navigate(['/enroll']);
    }
  }

  selContest(tabId): void {
    // 使用 Router 服务导航到相应的路由，并传递 tabId 参数
    this.router.navigate(['/enroll/contestInfo'], {
      queryParams: { g:tabId },
      // queryParamsHandling: 'merge',
    });
  }
}
