import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { CacheService } from '@delon/cache';
import { FileUploadService } from '@shared/components/fileUpload/fileUpload.service';
import { HttpAddressService } from '@shared/session/http-address.service';
import { GuidUtil } from '@shared/utils/guid.util';
import { NumberUtil } from '@shared/utils/numberUtil.util';
import { NzMessageService, NzModalService, UploadFile } from 'ng-zorro-antd';
import { isNullOrUndefined } from 'util';
import { BimSchoolsService } from '../school-register/bim-school.service';
import * as moment from 'moment';
import { UserService } from 'app/routes/admin/user.service';

@Component({
  selector: 'app-competition-registration',
  templateUrl: './competition-registration.component.html',
  styles: []
})
export class CompetitionRegistrationComponent implements OnInit, OnDestroy {

  isRegiste = false;
  // 是否登录
  isLogin = false;
  title = '团队信息注册';
  public dataId = null;

  form: FormGroup;
  studentList = [];
  teacherList = [];
  editCache = {};

  @ViewChild('modalWarning')
  modalWarning: TemplateRef<any>;
  public warningList = [];
  public submitting = false;

  public isEdit = false;
  public bimSchoolId = null;
  public bimSchool = null;
  public bimInfo = this.cacheService.get('bimInfo')['value'];

  public curTeamEvent = this.cacheService.get('curTeamEvent')['value'];
  public signUpflag = 0;

  fileList = [];
  imgList = [];
  uploadAddress: string;
  previewAddress: string;
  entityName = '';

  public registeFileId = null;
  public registeFileName = null;

  public infoEventId = null;
  public bimInfoEventsItems = [];
  public bimInfoEventsMutex = [];
  public itemTypes = [];
  public items = [];

  public bimTeamId = this.cacheService.get('bimTeamId')['value'];
  public bimTeamInfo = null;
  public bimTeamFile = null;
  
  public isSingle = '0';

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private msg: NzMessageService,
    private userService: UserService,
    private routeInfo: ActivatedRoute,
    private cacheService: CacheService,
    private modalService: NzModalService,
    private fileUploadService: FileUploadService,
    private bimSchoolsService: BimSchoolsService,
    private httpAddressService: HttpAddressService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  ) {
    this.infoEventId = this.routeInfo.snapshot.params['bimEventId'];
    this.uploadAddress = '/thsadmin/api/sys-files/upload';
    this.previewAddress = httpAddressService.apiGateway + '/thsadmin/api/sys-files/preview/';
    this.form = this.fb.group({
      id: [null, []],
      schoolId: [null, []],
      name: [null, [Validators.required, Validators.maxLength(100)]],
      email: [null, [Validators.required, Validators.email]],
      eventsId: [this.infoEventId, []],
      registeFileName: [null, []],
      isDelete: [0, []]
    });
    const token = this.tokenService.get().token;
    if (token && token !== null && token !== '') {
      this.isLogin = true;
    }
    this.isEdit = this.routeInfo.snapshot.queryParams['isEdit'];
    this.entityName = tokenService.get().entity_name;
  }

  ngOnInit() {
    this.isSingle = this.curTeamEvent.isSingle;
    if (this.isEdit) {
      this.title = '团队信息修改';
      this.isRegiste = true;
      this.initTeamData();
    } else {
      const teamId = this.cacheService.get('bimTeamId')['value'];
      if (!isNullOrUndefined(teamId)) {
        this.router.navigate(['/enroll/team/competitionTeamInfo' + `/${this.infoEventId}`]);
        return;
      }
      // 未报名
      this.signUpflag = 0;
      if (this.curTeamEvent && !isNullOrUndefined(this.curTeamEvent.startTime)) {
        const startTime = Date.parse(moment(this.curTeamEvent.startTime).format('YYYY-MM-DD'));
        const endTime = Date.parse(moment(this.curTeamEvent.endTime).format('YYYY-MM-DD'));
        const nowTime = Date.parse(moment(new Date()).format('YYYY-MM-DD'));
        this.signUpflag = nowTime < startTime ? 2 : (nowTime > endTime ? 1 : 0);
      }
      if (isNullOrUndefined(this.entityName) || this.entityName === '' || this.isMatchName(this.entityName)) {
        this.signUpflag = 3;
      }
      // 不可报名
      if (this.signUpflag !== 0) {
        return;
      }
      this.dataId = new GuidUtil().toString();
      this.addDefStudent();
      this.addTeacher();
      this.loadBimInfoEventsItems();
    }
    this.initEventMutex();
  }

  ngOnDestroy(): void {
    if (this.imgList.length > 0) {
      this.imgList.forEach(e => {
        this.fileUploadService.delete(e).subscribe();
      });
    }
    if (!isNullOrUndefined(this.registeFileId) && !this.isEdit) {
      this.fileUploadService.delete(this.registeFileId).subscribe();
    }
  }

  initEventMutex() {
    this.bimSchoolsService.getMutexNameByEventId({
      'bimInfoEventId': this.infoEventId
    }).subscribe(res => {
      this.bimInfoEventsMutex = res.body;
    });
  }

  confirmRegiste(data) {
    this.isRegiste = true;
    this.bimSchool = data;
  }

  reRegiste() {
    this.isRegiste = false;
    this.bimSchool = null;
  }

  loadBimInfoEventsItems(initData?) {
    this.bimInfoEventsItems = [];
    this.bimSchoolsService.getBimInfoEventItem({
      'bimInfoEventsId.equals': this.infoEventId
    }).subscribe((res: any) => {
      this.bimInfoEventsItems = res.body;
      this.loadItems(initData);
    });
  }

  loadItems(initData?) {
    this.items = [];
    this.itemTypes = [];
    this.bimSchoolsService.getBimItem({
      'isDelete.equals': 0,
      'size': 1000,
      'sort': ['type,asc', 'orderSn,asc']
    }).subscribe((res: any) => {
      const data = res.body;
      this.items = data.filter(e => this.bimInfoEventsItems.filter(d => d.itemId === e.id).length > 0);
      console.log(this.items);
      const allType = [];
      this.items.forEach(e => {
        if (!allType.includes(e.type)) {
          allType.push(e.type);
          this.itemTypes.push({
            'type': e.type,
            'data': this.getItemByType(e.type, initData)
          });
        }
      });
    });
  }

  getItemByType(type, initData?) {
    const data = this.items.filter(d => d.type === type).sort(d => d.orderSn);
    if (!isNullOrUndefined(initData)) {
      data.forEach(e => e.checked = initData.filter(s => s.itemId === e.id).length > 0);
    }
    return data;
  }

  checkClick(checked, type, id) {
    if (this.isSingle === '1' && checked) {
      debugger
      this.itemTypes.forEach(e => {
        if (e.type === type) {
          e.data.forEach(d => {
            if (d.id !== id) {
              d.checked = false;
            }
          });
        }
      });
    }
    // let num = 0;
    // if (checked) {
    //   this.itemTypes.forEach(e => {
    //     e.data.forEach(d => {
    //       if (d.checked) {
    //         num++;
    //       }
    //     });
    //   });
    //   if (num > 2) {
    //     this.onWarning('最多可选两项');
    //     this.itemTypes.forEach(e => {
    //       if (e.type === type) {
    //         e.data.forEach(d => {
    //           if (d.id === id) {
    //             d.checked = false;
    //           }
    //         });
    //       }
    //     });
    //   }
    // }
  }

  addDefStudent(): void {
    const mobile = this.tokenService.get().mobile;
    this.studentList = [...this.studentList, {
      id: new GuidUtil().toString(),
      name: this.tokenService.get().name,
      faculty: null,
      profession: null,
      grade: null,
      mobile: mobile,
      email: this.tokenService.get().email,
      imgUrl: null,
      photoPath: null
    }];
    this.insertStudentCache();
  }

  addStudent(): void {
    if (this.studentList.length < 5) {
      this.studentList = [...this.studentList, {
        id: new GuidUtil().toString(),
        name: null,
        faculty: null,
        profession: null,
        grade: null,
        mobile: null,
        email: null,
        imgUrl: null,
        photoPath: null
      }];
      this.insertStudentCache();
    }
  }

  insertStudentCache(): void {
    this.studentList.forEach(item => {
      if (!this.editCache[item.id]) {
        this.editCache[item.id] = {
          edit: true,
          data: item
        };
      }
    });
  }

  editStudent(id: string): void {
    this.editCache[id].edit = true;
  }

  saveStudent(id: string): void {
    const data = this.editCache[id].data;
    if (this.checkDataFormat(data)) {
      if (!isNullOrUndefined(this.editCache[id].data['grade'])) {
        this.editCache[id].data['grade'] = moment(this.editCache[id].data['grade']).format('YYYY');
      }
      // console.log(this.editCache[ id ].data)
      // const dataIndex = this.studentList.findIndex(item => item.id === id);
      // Object.assign(this.studentList[ dataIndex ], this.editCache[ id ].data);
      this.editCache[id].edit = false;
    }
  }

  formatGrade(grade) {
    if (!isNullOrUndefined(grade) && grade.toString().length > 4) {
      return moment(grade).format('YYYY');
    } else {
      return grade;
    }
  }

  removeStudent(id: string, imgId: string): void {
    if (this.studentList.length > 1) {
      this.editCache[id].edit = false;
      this.studentList = this.studentList.filter(d => d.id !== id);
      if (!isNullOrUndefined(imgId)) {
        this.fileUploadService.delete(imgId).subscribe();
      }
    }
  }

  addTeacher(): void {
    if (this.teacherList.length < 5) {
      this.teacherList = [...this.teacherList, {
        id: new GuidUtil().toString(),
        name: null,
        faculty: null,
        duties: null,
        profession: null,
        mobile: null,
        email: null,
      }];
      this.insertTeacherCache();
    }
  }

  insertTeacherCache(): void {
    this.teacherList.forEach(item => {
      if (!this.editCache[item.id]) {
        this.editCache[item.id] = {
          edit: true,
          data: item
        };
      }
    });
  }

  editTeacher(id: string): void {
    this.editCache[id].edit = true;
  }

  saveTeacher(id: string): void {
    const data = this.editCache[id].data;
    if (this.checkDataFormat(data)) {
      // const dataIndex = this.teacherList.findIndex(item => item.id === id);
      // Object.assign(this.teacherList[ dataIndex ], this.editCache[ id ].data);
      this.editCache[id].edit = false;
    }
  }

  removeTeacher(id: string): void {
    if (this.teacherList.length > 1) {
      this.editCache[id].edit = false;
      this.teacherList = this.teacherList.filter(d => d.id !== id);
    }
  }

  checkDataFormat(data) {
    let result = false;
    if (data.name === null) {
      this.onWarning('请输入姓名！');
    } else if (data.mobile === null) {
      this.onWarning('请输入手机号！');
    } else if (!NumberUtil.isMatchMobile(data.mobile)) {
      this.onWarning('请输入正确的手机号！');
    } else if (data.email === null) {
      this.onWarning('请输入邮箱！');
    } else if (!NumberUtil.isMatchEmail(data.email)) {
      this.onWarning('请输入正确的邮箱！');
    } else {
      if (this.isHasSpecialChar(data.name)) {
        this.onWarning('姓名不可有空格或特殊字符！');
      } else if (this.isHasSpecialChar(data.faculty)) {
        this.onWarning('院系不可有空格或特殊字符！');
      } else if (this.isHasSpecialChar(data.profession)) {
        this.onWarning('专业不可有空格或特殊字符！');
      } else if (this.isHasSpecialChar(data.duties)) {
        this.onWarning('职务不可有空格或特殊字符！');
      } else {
        result = true;
      }
    }
    return result;
  }

  // 上传文件参数
  fileParam = (file: UploadFile) => {
    const tmp = {
      fileFk: this.dataId,
      name: file.name,
      fileDesc: file.name,
      uploader: this.entityName,
      fileType: file.name.substring(file.name.indexOf('.') + 1),
      extField1: 'studentHeadImg'
    };
    return tmp;
  }

  registeFileParam = (file: UploadFile) => {
    const tmp = {
      fileFk: this.dataId,
      name: file.name,
      fileDesc: file.name,
      uploader: this.entityName,
      fileType: file.name.substring(file.name.indexOf('.') + 1),
      extField1: 'studentRegiste'
    };
    return tmp;
  }

  // 上传成功后把旧文件删除
  handleChange(event: any, id) {
    if (event.type === 'success') {
      console.log(event);
      this.imgList.push(event.file.response.id);

      this.studentList.forEach(e => {
        if (e.id === id) {
          // 删除之前上传的文件
          if (!isNullOrUndefined(e.photoPath)) {
            this.fileUploadService.delete(e.photoPath).subscribe();
          }
          e.photoPath = event.file.response.id;
          e.imgUrl = event.file.thumbUrl;
        }
      });
    }
  }

  handleUploadChange(item: any) {
    if (item.type === 'success') {
      const res = item.file.response;
      this.registeFileName = res.fileName;
      if (!isNullOrUndefined(this.registeFileId)) {
        this.fileUploadService.delete(this.registeFileId).subscribe();
      }
      this.registeFileId = res.id;
    }
  }

  beforeUpload = (file: UploadFile): boolean => {
    const imgFormat = ['jpg', 'png', 'jpeg', 'bmp', 'ico'];
    const type = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
    if (imgFormat.indexOf(type) < 0) {
      this.msg.remove();
      this.msg.error('格式错误,仅支持' + imgFormat + '格式');
      return false;
    }

    if (file.size > 100 * 1024) {
      this.msg.remove();
      this.msg.error('支持100KB以下图片上传');
      return false;
    }
    return true;
  }

  beforeRegisteUpload = (file: UploadFile): boolean => {
    const imgFormat = ['doc', 'docx', 'DOC', 'DOCX', 'jpg', 'png', 'jpeg', 'pdf'];
    const type = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
    if (imgFormat.indexOf(type) < 0) {
      this.msg.remove();
      this.msg.error('格式错误,仅支持' + imgFormat + '格式');
      return false;
    }
    if (file.size > 1024 * 1024 * 20) {
      this.msg.remove();
      this.msg.error('支持2M以下文件上传');
      return false;
    }
    return true;
  }

  submit() {
    // tslint:disable-next-line:forin
    for (const id in this.editCache) {
      if (this.editCache[id].edit) {
        this.onError('团队成员或指导老师未保存');
        return false;
      }
    }

    const subParam = {};
    // tslint:disable-next-line:forin
    for (const key in this.form.controls) {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
      subParam[key] = this.form.controls[key].value;
    }
    if (!this.checkData()) {
      return;
    }
    if (this.isHasSpecialChar(subParam['name'])) {
      this.onError('团队名称不可有空格或特殊字符！');
      return;
    }
    const selItem = [];
    this.itemTypes.forEach(e => selItem.push.apply(selItem, e.data.filter(d => d.checked)));
    if (selItem.length === 0) {
      this.onError('请选择参赛项目');
      return false;
    }
    if (selItem.length > 2) {
      this.onError('参赛项目最多可选两项，请进行修改');
      return false;
    }
    if (selItem.length === 2 && this.bimInfoEventsMutex.length > 0) {
      for (let i = 0; i < 2; i++) {
        const item = selItem[i];
        const mutex = this.bimInfoEventsMutex.filter(e => e.mainItemId === item.id);
        if (mutex && mutex.length > 0) {
          const other = selItem[1 - i];
          if (mutex[0].itemId.indexOf(other.id) > -1) {
            this.onError(`参赛项目【${mutex[0].mainItemName}】与【${mutex[0].itemName}】不能同时选择`);
            return false;
          }
        }
      }
    }
    if (!this.checkReaptMobile(this.studentList, '团队成员') || !this.checkReaptMobile(this.teacherList, '指导老师')) {
      return false;
    }
    subParam['id'] = this.dataId;
    subParam['teamItems'] = selItem;
    subParam['schoolId'] = this.bimSchool.id;
    subParam['teamTeachers'] = this.teacherList;
    const studentList = this.studentList;
    studentList.forEach(e => {
      e.grade = this.formatGrade(e.grade);
      e.imgUrl = null;
    });
    subParam['teamStudents'] = studentList;

    if (!this.isEdit) {
      const mobile = this.tokenService.get().mobile;
      this.bimSchoolsService.getTeamStudentByTelAndEventId({
        'mobile': mobile,
        'eventId': this.infoEventId
      }).subscribe(res => {
        this.submitting = false;
        if (res && res.body.length > 0) {
          this.msg.warning('登录用户已有加入团队，请重新登录');
          return;
        } else {
          this.saveData(subParam);
        }
      });
    } else {
      subParam['isEdit'] = true;
      this.saveData(subParam);
    }
  }

  checkData() {
    const token = this.tokenService.get().token;
    if (isNullOrUndefined(token)) {
      this.onError('登录失效，请重新登录');
      return false;
    }
    if (!this.form.valid) {
      this.onError('请填写带 * 的信息');
      return false;
    }
    if (isNullOrUndefined(this.registeFileId)) {
      this.onError('报名附件未上传！');
      return false;
    }
    return true;
  }

  checkReaptMobile(dataList, dataName) {
    if (dataList && dataList.length > 0) {
      const tels = [];
      for (let i = 0; i < dataList.length; i++) {
        const data = dataList[i];
        if (tels.includes(data.mobile)) {
          this.onError(`${dataName}【${data.name}】手机号重复，请修改`);
          return false;
        } else {
          tels.push(data.mobile);
        }
      }
    }
    return true;
  }

  saveData(subParam) {
    this.submitting = true;
    this.bimSchoolsService.registerTeamInfo(subParam).subscribe(res => {
      const data = res.body;
      if (data.success) {
        this.imgList = [];
        if (!this.isEdit) {
          this.registeFileId = null;
          this.msg.success('注册成功');
          this.tokenService.set({
            ...this.tokenService.get(),
            bimSchoolId: data.data.schoolId
          });
          this.cacheService.set('bimTeamId', data.data.id);
          window.location.reload();
        } else {
          this.msg.success('修改成功');
          this.router.navigate(['/enroll/team/competitionTeamInfo' + `/${this.infoEventId}`]);
        }
      } else {
        this.submitting = false;
        const title = data.message;
        if (isNullOrUndefined(data.data)) {
          this.msg.warning(title);
        } else {
          this.warningList = data.data.hasStuList;
          const content = this.modalWarning;
          this.modalService.warning({
            nzTitle: title,
            nzContent: content,
            nzWidth: '60vh'
          });
        }
      }
    });
  }

  private onError(message: string) {
    this.msg.remove();
    this.msg.create('error', message);
  }

  private onWarning(message) {
    this.msg.remove();
    this.msg.create('warning', message);
  }

  initTeamData() {
    if (isNullOrUndefined(this.bimTeamId)) {
      this.msg.info('无此团队信息，请重新登录');
    } else {
      this.dataId = this.bimTeamId;
      this.submitting = true;
      this.bimSchoolsService.getBimTeamById(this.bimTeamId).subscribe(res => {
        this.bimTeamInfo = res;
        this.form.patchValue(this.bimTeamInfo);

        this.bimSchool = {
          'id': this.bimTeamInfo.schoolId,
          'name': this.bimTeamInfo.bimSchoolName
        };
        const teamStudents = res.teamStudents;
        // this.initTeamPerson(teamStudents);
        this.studentList = teamStudents;
        this.studentList.forEach(item => {
          if (!isNullOrUndefined(item.tel)) {
            item['mobile'] = item.tel;
          }
          if (!isNullOrUndefined(item.photoPath)) {
            item['imgUrl'] = this.previewAddress + item.photoPath;
          }
          if (!this.editCache[item.id]) {
            this.editCache[item.id] = {
              edit: false,
              data: item
            };
          }
        });

        const teamTeachers = res.teamTeachers;
        // this.initTeamPerson(teamTeachers);
        this.teacherList = teamTeachers;
        this.teacherList.forEach(item => {
          if (!this.editCache[item.id]) {
            this.editCache[item.id] = {
              edit: false,
              data: item
            };
          }
        });

        this.loadBimInfoEventsItems(res.teamItems);
        this.submitting = false;
      });

      this.fileUploadService.query({
        'fileFk.equals': this.bimTeamId,
        'extField1.equals': 'studentRegiste'
      }).subscribe(res => {
        if (res && res.body.length > 0) {
          this.bimTeamFile = res.body[0];
          this.registeFileName = this.bimTeamFile.fileName;
          this.registeFileId = this.bimTeamFile.id;
        }
      });
    }
  }

  initTeamPerson(personData) {
    for (let i = 0; i < personData.length; i++) {
      const item = personData[i];
      if (!isNullOrUndefined(item.tel)) {
        item['mobile'] = item.tel;
      }
      if (!isNullOrUndefined(item.photoPath)) {
        item['imgUrl'] = this.previewAddress + item.photoPath;
      }

      if (!this.editCache[item.id]) {
        this.editCache[item.id] = {
          edit: false,
          data: { ...item }
        };
      } else {
        this.editCache[item.id].edit = false;
      }
    }
  }

  downFile() {
    if (!isNullOrUndefined(this.bimInfo)) {
      this.fileUploadService.query({
        'fileFk.equals': this.bimInfo.id,
        'extField1.equals': 'studentRegisteTemplate'
      }).subscribe(res => {
        if (res && res.body.length > 0) {
          const a = document.createElement('a');
          const url =  this.httpAddressService.apiGateway + '/thsadmin/api/sys-files/download/' + res.body[0].id;
          a.href = url;
          a.click();
        } else {
          this.msg.warning('大赛无报名模板文件');
        }
      });
    }
  }

  mobileChange(value, index) {
    if (!isNullOrUndefined(value) && NumberUtil.isMatchMobile(value)) {
      this.userService.queryEduUserList({
        'mobile.equals': value
      }).subscribe(res => {
        if (res && res.body.length > 0) {
          const data = res.body[0];
          this.studentList[index].email = data.email;
          this.studentList[index].name = data.personName;
          this.studentList[index].isdisable = true;
        } else {
          this.studentList[index].isdisable = false;
        }
      });
    } else {
      this.studentList[index].isdisable = false;
    }
  }
  
  isMatchName(str: string) {
    const regex = /([a-z0-9][a-z0-9]*)/;
    return str.match(regex);
  }

  isHasSpecialChar(str) {
    if (isNullOrUndefined(str)) {
      return false;
    }
    var pattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]");
    if(str.indexOf(' ') > -1 || pattern.test(str)){
      return true;
    } else {
      return false;
    }
  }
}
