import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { UserRegisterComponent } from '../register/register.component';
import { NzMessageService } from 'ng-zorro-antd';
import { HttpClient } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
import { WebService } from '../web.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'app/routes/admin/user.service';

@Component({
  selector: 'app-update-mobile',
  templateUrl: './update-mobile.component.html',
  styleUrls: ['./update-mobile.component.less']
})
export class UpdateMobileComponent implements OnInit{

  step: number = 0;
  count = 0;
  interval$: any;

  form: FormGroup;

  loading = false;
  constructor(private location: Location,
    public msg: NzMessageService,
    private webService: WebService,
    private router: Router,
    private routerInfo: ActivatedRoute,
    private userService: UserService,
    fb: FormBuilder) {
    this.form = fb.group({
      pwd: [null, [Validators.required]],
      mobile: [null, [Validators.required, Validators.pattern(/^1\d{10}$/)]],
      captcha: [null, Validators.required],
    });
  }


  // region: fields
  get mobile() {
    return this.form.controls.mobile;
  }
  get captcha() {
    return this.form.controls.captcha;
  }

  get pwd() {
    return this.form.controls.pwd;
  }

  ngOnInit() {

  }


  getCaptcha() {
    if ((this.mobile.dirty && this.mobile.errors) || this.mobile.value === null) {
      this.msg.remove();
      this.msg.error('请输入正确的手机号码');
      return;
    }

    this.webService.sendSMSCode(this.mobile.value).subscribe(res => {
      if (!res || !res.success || res.success !== true) {
        this.msg.error(res.msg);
        return ;
      }

      this.count = 59;
      this.interval$ = setInterval(() => {
        this.count -= 1;
        if (this.count <= 0) clearInterval(this.interval$);
      }, 1000);
    });
  }

  mobileSubmit() {
    const param = this.checkAndToParam();
    if (param === '') {
      return;
    }

    this.loading = true;
      // 手机号码
    this.userService.updateMobile(param).subscribe(res => {
      this.loading = false;
      if (res && res.body) {
        const result = res.body;
        if (result.code !== '1') {
          this.msg.remove();
          this.msg.error(result.message);
          return;
        }
        this.msg.info('修改成功');
        // this.router.navigate(['/home'], { queryParams: { hasFooter: false }});
      }
    });
    
  }

  checkAndToParam() {
      if ((this.mobile.dirty && this.mobile.errors) || this.mobile.value === null) {
        this.msg.remove();
        this.msg.error('请填写正确手机号');
        return '';
      }
      if (this.captcha.dirty && this.captcha.errors) {
        this.msg.remove();
        this.msg.error('请填写验证码');
        return '';
      }

      if ((this.pwd.dirty && this.pwd.errors) || this.pwd.value === null ) {
        this.msg.remove();
        this.msg.error('请确认密码填写正确');
        return '';
      }
    
     const  param = {
      mobileCode: this.mobile.value,
      userPassword: hex_md5(this.pwd.value),
      code: this.captcha.value
     };
     return param;
    

  }

  goback() {
    this.location.back();
  }

}
