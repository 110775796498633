import { Component, OnInit } from '@angular/core';
import { HomeService } from './../home/home.service';
import { CacheService } from '@delon/cache';
import { isNullOrUndefined } from 'util';
import { Router } from '@angular/router';

@Component({
  selector: 'app-regulation',
  templateUrl: './regulation.component.html',
  styleUrls: ['./regulation.component.less'],
})
export class RegulationComponent implements OnInit {

  public bimInfo = this.cacheService.get('bimInfo')['value'];
  contentList = [];
  loading = true;

  constructor(
    private homeService: HomeService,
    private cacheService: CacheService,
    private router: Router) {

    }

  ngOnInit() {
    
    if (isNullOrUndefined(this.bimInfo)) {
      // this.router.navigate(['/enroll/contestSelect']);
      this.router.navigate(['/enroll/contest']);
      return;
    }

    this.getReaulationList();
  }

  getReaulationList(){
    this.loading = true;
    const params = {
      'bimInfoId.equals': this.bimInfo.id,
      'contenttype.equals': 'institutional',
      'isDelete.equals': '0',
      'istop.equals': '1',
      sort:['isreport,asc']
    }
    this.homeService.getThsNews(params).subscribe(res => {
      this.loading = false;
      this.contentList = res.body;
    });
  }
}
