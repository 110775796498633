import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContestSelectComponent } from './home/contest-select/contest-select.component';
import { HomeComponent } from './home/home.component';
import { SchoolTotalComponent } from './home/school-total/school-total.component';
import { LearningVideoComponent } from './learning-video/learning-video.component';
import { ContestDynamicComponent } from './contest-dynamic/contest-dynamic.component';
import { DynamicDetailComponent } from './home/dynamic-detail/dynamic-detail.component';
import { ContestWonderfulComponent } from './contest-wonderful/contest-wonderful.component';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: '', component: HomeComponent },
      {
        path: 'contestSelect',
        component: ContestSelectComponent,
        data: { title: '选择大赛' },
      },
      {
        path: 'org',
        loadChildren: './org/org.module#OrgModule',
        data: { title: '单位注册入口' },
      },
      {
        path: 'competition',
        loadChildren: './competition/competition.module#CompetitionModule',
        data: { title: '比赛报名入口' },
      },
      // {
      //   path: 'schoolTotal',
      //   component: SchoolTotalComponent,
      //   data: { title: '报名院校' },
      // },
      // {
      //   path: 'learningVideo',
      //   component: LearningVideoComponent,
      //   data: { title: '学习视频' },
      // },
      {
        path: 'contestDynamic',
        component: ContestDynamicComponent,
        data: { title: '比赛动态' },
      },
      {
        path: 'dynamicDetail',
        component: DynamicDetailComponent,
        data: { title: '大赛动态' },
      },
      {
        path: 'contestWonderful',
        component: ContestWonderfulComponent,
        data: { title: '比赛风采' },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EnterpriseRoutingModule {}
