import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';

import { formatStatePipe } from './formatState.pipe';

import { formatValuePipe } from './formatValue.pipe';

import { formatBooleanPipe } from './formatBoolean.pipe';

import { formatSexPipe } from './formatSex.pipe';

import { ArrayService } from './array.service';
import { htmlPipe } from './innerHtml.pipe';
import { KeysPipe } from './keysPipe.pipe';
import { KeyValuePipe } from './keyValuePipe.pipe';


@NgModule({
    imports: [
        CommonModule
    ],
    providers: [
        ArrayService
    ],
    declarations: [
        formatStatePipe,
        formatValuePipe,
        formatBooleanPipe,
        formatSexPipe,
        htmlPipe,
        KeysPipe,
        KeyValuePipe
    ],
    exports: [
        formatStatePipe,
        formatValuePipe,
        formatBooleanPipe,
        formatSexPipe,
        htmlPipe,
        KeysPipe,
        KeyValuePipe
    ]
})
export class UtilsModule { }
