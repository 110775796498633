import { Component,Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GuidUtil } from '@shared/utils/guid.util';
import { NzMessageService,NzModalRef } from 'ng-zorro-antd';
import { CacheService } from '@delon/cache';

import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';

import {BimPersonService} from '../bim-person.service';
import { _HttpClient,ModalHelper } from '@delon/theme';
import {BimInfoSchoolsEditComponent} from '../../school/bim-info-schools/edit/edit.component';
import { BimPerson } from '../bim-person.model';
import { BimItemsService } from '../../bim-items/bim-items.service';
import {BimPersonItemsService} from '../bim-person-items/bim-person-items.service';
import {UcommonService} from '../../ucommon/ucommon.server';
import {BimPersonTearchers} from '../bim-person-teacher/bim-person-teacher.model';
import {BimPersonTearchersService} from '../bim-person-teacher/bim-person-teacher.service';
import { zip } from 'rxjs';
import * as moment from 'moment';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-person-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.less']
})
export class PersonEditResultComponent implements OnInit {

  personId:string;
  bimEventId:string;
  schoolId:string;
  submitting = false;
  schoolTypeList = [];
  record = new BimPerson();
  title = '个人赛报名信息修改';
  buttonIsVisible=true;
  userEmail:string;
  entityName:string;
  entityId:string;
  entityMobile:string;

  ItemTypes=[];
  Items =[];
  personItems=[];

  projectList = [
    { label: '土建项目', value: '0', checked: false },
    { label: '机电项目', value: '1', checked: false },
  ];
  form: FormGroup;
  
  // instructorList: Array<{
  //   id: string,
  //   name: null,
  //   yx: null,
  //   zw: null,
  //   mobile: null,
  //   profession: null,
  //   email: null,
  // }> = [];

  instructorList:Array<BimPersonTearchers> = [];

  constructor(
    private fb: FormBuilder,
    private msg: NzMessageService,
    private cacheService: CacheService,
    private router: Router,
    private routerInfo: ActivatedRoute,
    private bimPersonService:BimPersonService,
    public modal: ModalHelper,
    private bimItemsService:BimItemsService,
    private bimPersonItemsService:BimPersonItemsService,
    private ucommonService:UcommonService,
    private bimPersonTearchersService:BimPersonTearchersService,
    private nzModalRef: NzModalRef,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  ) {
    const userType = this.tokenService.get().entity_type;
    const mobile = this.tokenService.get().mobile;
    this.entityName = tokenService.get().entity_name;
    this.entityId = tokenService.get().entity_id;
    this.entityMobile = mobile;
    this.userEmail =  tokenService.get().email;
   }

  ngOnInit() {

  
    this.form = this.fb.group({
      id:[null, []],
      personName: [null, [Validators.required, Validators.maxLength(100)]],
      schoolName: [null, []],
      personTel: [null, []],
      personEmail: [null , []],
      grade: [null, []],
      profession: [null, []],
      isDelete: [0, []]
   //   project: [null,[]],
    });
    this.getData();
  }


  getData(){
    this.bimPersonService.queryNewAnd({
      'id.equals': this.personId,
    }).subscribe(res => {
      if (res && res.body.length > 0) {
         this.record = res.body[0];
        this.schoolId = this.record.schoolId;
         this.loadItems();
         this.getPersonTearcherList();

         this.form.patchValue(this.record);
      }
        
    });

}


  projectChange(checked, value) {
    this.projectList.forEach(item => {
      if (item.value !== value) {
        item.checked = false;
      } else {
        item.checked = checked;
      }
    });
  }

  addInstructor(): void {
    if (this.instructorList.length < 2) {
      let  tearcher = new BimPersonTearchers();
      tearcher.id =  new GuidUtil().toString()
      this.instructorList= [...this.instructorList, tearcher];
    } else {
      this.msg.error('不能超过两名指导老师');
    }
    console.log(this.instructorList);
  }

  removeInstructor(id: string): void {
    if (this.instructorList.length > 1) {
      this.instructorList = this.instructorList.filter(d => d.id !== id);
      console.log(this.instructorList);
    }
  }

  //wxc 添加
  submit() {

    this.buttonIsVisible = false;
    const param = {};
    // tslint:disable-next-line: forin
    for (const key in this.form.controls) {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
      if (this.form.controls[key].value === true) {
        param[key] = '1';
      } else if (this.form.controls[key].value === false) {
        param[key] = '0';
      } else {
        param[key] = this.form.controls[key].value;
      }
    }
    param["schoolId"] = this.schoolId;
    param["bimInfoEventsId"] = this.bimEventId;

    param["userDefine1"] =this.record.userDefine1;

    if (this.form.invalid) {
      this.msg.error('请检查个人信息是否完整');
      this.buttonIsVisible = true;
      return;
    }

    if (this.isMatchName(param['personName'])) {
      this.msg.error('参赛人姓名不可包含字母、数字、符号');
      this.buttonIsVisible = true;
      return;
    }

    if (this.isHasSpecialChar(param['personName'])) {
      this.msg.error('参赛人姓名不可包含空格或特殊字符');
      this.buttonIsVisible = true;
      return;
    }

    if(this.personItems == null || this.personItems == undefined || this.personItems.length <= 0)
    {
      this.msg.error('没有选择参加的专项，请选择后保存！');
      this.buttonIsVisible = true;
      return;
    }

    if(this.instructorList == null || this.instructorList == undefined || this.instructorList.length <= 0)
    {
      this.msg.error('没有添加指导老师信息，请添加后保存！');
      this.buttonIsVisible = true;
      return;
    }

    for (let i = 0; i < this.instructorList.length; i++) {
      const data = this.instructorList[i];
      if (!this.isMatchMobile(data.mobile)) {
        this.msg.error('指导老师' + '【' + data.name + '】手机号格式错误');
        this.buttonIsVisible = true;
        return;
      }
      
      if (this.isHasSpecialChar(data.name)) {
        this.msg.error('指导老师' + '【' + data.name + '】姓名不可包含空格或特殊字符');
        this.buttonIsVisible = true;
        return;
      }
      if (this.isHasSpecialChar(data.profession)) {
        this.msg.error('指导老师' + '【' + data.name + '】专业不可包含空格或特殊字符');
        this.buttonIsVisible = true;
        return;
      }
    }

    param['grade'] = moment(param['grade']).format('YYYY');
    this.bimPersonService.update(param).subscribe(res => {
      this.record = res.body;
      if (this.record  ) {

      

        let ids=[];
        this.personItems.forEach(item=>{
            ids.push(item.itemId);
        })

        const subParam = {};
        subParam["personId"] = this.record.id;
        subParam["teacherList"] = this.instructorList;

        zip(
         // this.bimPersonItemsService.saveItemByTeam({'personId': this.record.id, 'ItemsIdList': ids.toString()}),
          this.bimPersonTearchersService.saveTeacherByPerson(subParam)
        ).subscribe(()=>{
             this.msg.info("修改成功！");
             this.buttonIsVisible = true;
             this.nzModalRef.destroy();
        });
      } else {
        this.msg.error('保存失败');
        this.buttonIsVisible = true;
      }
    });
  }

  isMatchMobile(mobile: string) {
    const regex = /^1\d{10}$/;
    return mobile.match(regex);
  }

  isMatchName(str: string) {
    const regex = /([a-z0-9][a-z0-9]*)/;
    return str.match(regex);
  }


  selectSchool(){
    this.modal
    .static(BimInfoSchoolsEditComponent)
    .subscribe((res) => {
      if(res !=null && res.length >1)
      {
          this.msg.error('只能选择一个学校，请重新选择！');
          return false;
      }else if(res !=null && res.length === 1)
      { 
          this.schoolId  = res[0].id;
          this.record.schoolName =  res[0].name;
          this.form.patchValue(this.record);
      }
     
    });
  }


  
  loadItems(){
    this.Items=[];
    this.ItemTypes=[];
    let copyParams = {};
    copyParams['sort'] =['type,asc', 'orderSn,asc'];
    this.bimItemsService.getAllBimItemsInBimEvents(this.bimEventId, copyParams).subscribe((res: any) => {
      
          this.Items = res.body;
          let tmpType="";
          this.Items.forEach(element => {
               let tmpHasItem = this.ItemTypes.filter(d => d.type === element.type);
               if(element.type != tmpType && tmpHasItem.length <= 0)
               {
                   tmpType = element.type;
                   let tmpTypeObj = {};
                   tmpTypeObj["type"] = tmpType;
                   this.ItemTypes.push(tmpTypeObj);
               }
          });

          this.loadPersonItems();
    });
  }

  loadPersonItems(){
    this.personItems = [];
    let copyParams = {};
    copyParams['personId.contains'] = this.personId;
    this.bimPersonItemsService.query(copyParams).subscribe((res: any) => {
        this.personItems = res.body;
    });
  }

  getPersonTearcherList(){
    let copyParams = {};

    copyParams['personId.contains'] = this.personId;

    this.bimPersonTearchersService.query(copyParams)
      .subscribe((res: any) => {
         this.instructorList = res.body;
      });

  }

  getItemByType(type){
    return this.Items.filter(d => d.type === type).sort(d=>d.orderSn);
  }

  getCheckBoxValue(itemId: string){
      let tmpItems =  this.personItems.filter(d=>d.itemId === itemId);  
      if (tmpItems.length > 0)
      {
        return 'true';
      }else{
        return '';
      }

  }

  checkboxClick($event, itemId: string) {
    
    const target = $event.target;
    if(target.checked === true){
      let tmpItems =  this.personItems.filter(d=>d.itemId === itemId);   
      if(tmpItems.length <=0)
      {
         let tmpBimItem ={};
         tmpBimItem["itemId"] = itemId;
         tmpBimItem["bimInfoEventsId"] = this.record.id;
         this.personItems.push(tmpBimItem);
      }
    }else{
      let nindex;
      this.personItems.forEach((element, index) => {

        if (element.itemId === itemId) {
          nindex = index;
        }
      });

      //删除没有选择项的，并清空空记录
       delete this.personItems[nindex];
       this.personItems = this.ucommonService.delNullObj(this.personItems);
    }
  }

  isHasSpecialChar(str) {
    if (isNullOrUndefined(str)) {
      return false;
    }
    var pattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]");
    if(str.indexOf(' ') > -1 || pattern.test(str)){
      return true;
    } else {
      return false;
    }
  }
}
