import { Component, Inject, OnInit } from '@angular/core';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { NzMessageService } from 'ng-zorro-antd';
import { isNullOrUndefined } from 'util';
import { SchoolWorkScoringService } from './school-work-scoring.service';
import * as moment from 'moment';
import { BimSchoolsService } from '../school-register/bim-school.service';
import { CacheService } from '@delon/cache';
import { Router } from '@angular/router';

@Component({
  selector: 'app-school-work-scoring',
  templateUrl: './school-work-scoring.component.html',
  styleUrls: ['./school-work-scoring.component.less']
})
export class SchoolWorkScoringComponent implements OnInit {

  public isRating = false;
  public bimSchoolId = null;
  public bimInfoEventId = null;

  public page = 1;
  public size = 10;
  public total = '0';
  schoolWorkList = [];
  public isloadding = false;

  public bimInfoEventList = [];

  public bimInfoItemMap = {};
  public bimInfoItemList = [];

  public schoolWork = null;

  public queryParams = {
    // 查询搜索框的字段要预置
    'bimInfoItemId': null,
    'teamName': null,
  };
  public pageSort = ['uploadDate,desc'];

  public bimInfo = this.cacheService.get('bimInfo')['value'];
  public curTeamEvent = this.cacheService.get('curTeamEvent')['value'];

  // 校内作品评分状态
  public schoolWorkScoreStatus = 0;
  // 全国评委评分状态
  public advisorsWorkScoreStatus = 0;


  // 校内评分 全国评分
  public isSchoolScore = true;
  public title = null;
  // 全国评委id
  public advisorId = null;


  constructor(
    private router: Router,
    private msg: NzMessageService,
    private cacheService: CacheService,
    private bimSchoolsService: BimSchoolsService,
    private schoolWorkScoringService: SchoolWorkScoringService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  ) {
    this.bimSchoolId = this.tokenService.get().bimSchoolId;
    this.schoolWorkScoreStatus = this.curTeamEvent.schoolWorkScoreStatus;
    this.advisorsWorkScoreStatus = this.curTeamEvent.advisorsWorkScoreStatus;
    this.bimInfoEventId = this.curTeamEvent.id;
    this.advisorId = this.tokenService.get().country_advisor_id;
  }

  ngOnInit(): void {
    if (isNullOrUndefined(this.bimSchoolId) && isNullOrUndefined(this.advisorId)) {
      this.msg.info('无注册学校信息，请重新登录');
      return;
    }
    if (isNullOrUndefined(this.bimInfo)) {
      this.router.navigate(['/enroll/contestSelect']);
      return;
    }
    this.isSchoolScore = this.router.url.indexOf('schoolRating') > -1;
    this.title = this.isSchoolScore ? '校内作品评分' : '全国作品评分';
    this.getDataList();
    // this.loadBimInfoEvents();
    this.bimEventChange(this.bimInfoEventId);
  }

  getDataList(isReset?: boolean, isSearch?: boolean) {
    const copyParams = this.getParams(isReset, isSearch);
    this.isloadding = true;
    const request = this.isSchoolScore ? this.schoolWorkScoringService.getBimWorksDataList(copyParams)
        : this.schoolWorkScoringService.getBimWorksCountryDataList(copyParams);
    request.subscribe(res => {
      this.isloadding = false;
      this.schoolWorkList = res.body;
      if (this.schoolWorkList.length === 0 && this.page > 1) {
        this.page --;
        this.getDataList();
      }
      this.total = res.headers.get('X-Total-Count');
    });
  }

  getParams(isReset?: boolean, isSearch?: boolean) {
    const copyParams = {};
    const q = this.queryParams;
    if (isReset === true) {
      this.page = 0;
    }
    if (this.isSchoolScore) {
      copyParams['schoolId'] = this.bimSchoolId;
      copyParams['workType'] = 0;
    } else {
      if (!isNullOrUndefined(this.advisorId)) {
        copyParams['advisorId'] = this.advisorId;
      }
    }
    copyParams['bimInfoEventId'] = this.bimInfoEventId;
    Object.keys(q).forEach(function (key) {
      if (q[key] !== '' && q[key] !== null) {
        copyParams[key] = q[key];
      }
    });
    copyParams['page'] = this.page - 1;
    copyParams['size'] = this.size;
    if (isSearch) {
      this.page = 0;
      copyParams['page'] = 0;
    }
    copyParams['sort'] = this.pageSort;
    return copyParams;
  }

  formatSubmitStatus(value, date) {
    if (value + '' === '1') {
      return '已提交' + (isNullOrUndefined(date) ? '' : ` 【${moment(date).format('YYYYMMDD')}】`);
    } else {
      return '未提交';
    }
  }

  loadBimInfoEvents() {
    this.bimSchoolsService.getBimInfoEvent({
      'bimInfoId.equals': this.bimInfo.id,
      'eventsType.equals': '0'
    }).subscribe((res: any) => {
      this.bimInfoEventList = res.body;
    });
  }

  bimEventChange(bimInfoEventId) {
    if (!isNullOrUndefined(bimInfoEventId)) {
      if (isNullOrUndefined(this.bimInfoItemMap[bimInfoEventId])) {
        this.bimSchoolsService.getBimItemInfoByEventId({
          'bimInfoEventsId': bimInfoEventId
        }).subscribe(res => {
          this.bimInfoItemList = res.body;
          this.bimInfoItemMap[bimInfoEventId] = this.bimInfoItemList;
        });
      } else {
        this.bimInfoItemList = this.bimInfoItemMap[bimInfoEventId];
      }
    }
  }

  getStatusClass(value) {
    if (value + '' === '1') {
      return '';
    } else {
      return 'red';
    }
  }

  gotoRating(item) {
    if (this.isSchoolScore) {
      // 校内评分
      if (this.schoolWorkScoreStatus === 0) {
        this.msg.warning('校内作品评分未开始');
        return;
      }
      if (this.schoolWorkScoreStatus === 2) {
        this.msg.warning('校内作品评分已结束');
        return;
      }
    } else {
      // 全国评委评分
      if (this.advisorsWorkScoreStatus === 0) {
        this.msg.warning('全国评委评分未开始');
        return;
      }
      if (this.advisorsWorkScoreStatus === 2) {
        this.msg.warning('全国评委评分已结束');
        return;
      }
    }
    if (item && item.submitStatus === 1) {
      this.isRating = true;
      this.schoolWork = item;
      this.schoolWork.isSchoolScore = this.isSchoolScore;
      if (!this.isSchoolScore) {
        this.schoolWork.advisorId = this.advisorId;
      }
    } else {
      this.msg.warning('作品未提交');
    }
  }

  sort(sort: { key: string, value: string }): void {
    this.pageSort = [`${sort.key},${sort.value.replace('end', '')}`];
    this.getDataList();
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChange(event) {
    this.page = 0;
    this.size = event;
    this.getDataList();
  }

 /**
  * 页码变动事件
  * @param
  */
  changePageIndex(pageIndex) {
    this.page = pageIndex;
    this.getDataList();
  }

  returnPage(data) {
    this.isRating = false;
  }

}
