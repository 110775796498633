import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {DA_SERVICE_TOKEN, ITokenService} from "@delon/auth";
import {GuidUtil} from "@shared/utils/guid.util";
import {CacheService} from "@delon/cache";
import {BimSchoolsService} from "../school-register/bim-school.service";
import {ThsadminFileService} from "@shared/components/f-upload/thsadmin-file.service";
import { zip } from 'rxjs';
import {NzMessageService} from "ng-zorro-antd";
import { BimWorksService } from '../work-upload/bim-works.service';

@Component({
  selector: 'app-work-reply-file',
  templateUrl: './work-reply-file.component.html',
  styleUrls: ['./work-reply-file.component.less'],
})
export class WorkReplyFileComponent implements OnInit {
  @ViewChild('projectUpload') projectUpload: any;
  @ViewChild('projectUpload2') projectUpload2: any;
  navList: any = [
    { name: '全国答辩材料上传', disabled: false, code: 'first' },
  ];
  currentNav: any = null;
  fileFk1 = GuidUtil.getNewGUIDString();
  fileFk2 = GuidUtil.getNewGUIDString();
  teamId = '';
  teamInfo: any = {name: '',schoolTop: '',schoolId: ''};
  items = [];
  loading = false;
  curTeamEvent: any;
  isView1 = false;
  isView2 = false;
  currentStage = 1;
  type = 3;
  constructor(
    @Inject(DA_SERVICE_TOKEN) protected tokenService: ITokenService,
    private cacheService: CacheService,
    private bimSchoolsService: BimSchoolsService,
    private bimWorksService: BimWorksService,
    private thsadminFileService: ThsadminFileService,
    private msg: NzMessageService,
  ) {}

  ngOnInit() {
    this.currentNav = this.navList[0];
    this.teamId = this.cacheService.get('bimTeamId')['value'];
    this.curTeamEvent = this.cacheService.get('curTeamEvent')['value'];
    if (this.curTeamEvent.replyWorkUploadStatus && this.curTeamEvent.replyWorkUploadStatus === 1) {
      this.currentStage = 1;
      this.type = 3;
    }
    if (this.curTeamEvent.replyWorkUploadStatus && this.curTeamEvent.replyWorkUploadStatus === 2) {
      this.currentStage = 2;// 全国答辩材料上传阶段结束
      this.isView1 = true;
      this.isView2 = true;
    }
    this.getBimInfo();
  }

  getBimInfo() {
    if (this.teamId && this.teamId.length > 0) {
      this.bimSchoolsService.getBimTeamById(this.teamId).subscribe(res => {
        this.teamInfo = res;
        // this.items = res.teamItems;
        this.items = [];
        if (res.schoolTop === 1) {
          res.teamItems.forEach(item => {
            this.items.push(item);
          });
        } else {
          this.bimWorksService.query({
            'teamId.equals': this.teamId,
            'workType.equals': this.type
          }).subscribe(wRes => {
            if (wRes && wRes.body.length > 0) {
              const data = wRes.body;
              const items = [];
              data.forEach(e => {
                const rItems = res.teamItems.filter(j => j.itemId === e.itemId);
                if (rItems.length > 0) {
                  items.push(rItems[0]);
                }
              });
              this.items = items;
            }
          });
        }
      })
    }
  }

  navClick(index,item) {
    if (item.disabled) {
      return;
    }
    this.isView1 = false;
    this.isView2 = false;
    this.currentNav = this.navList[index];
     if (index === 0) {
       if (this.currentStage === 2 || this.currentStage === 3) {
         this.isView1 = true;
         this.isView2 = true;
         this.type = 3;
       }
     }
     if (this.currentStage === 3) {
       this.isView1 = true;
       this.isView2 = true;
     }
    this.items = this.teamInfo.teamItems;
    if (this.type === 1) {
      this.items = [];
      // 20230330 如果团队已晋级，无论有没有第一阶段作品，第二阶段所有赛项都可上传作品
      if (this.teamInfo.schoolTop === 1) {
        this.teamInfo.teamItems.forEach(it => {
          // if (item.schoolTop && item.schoolTop === 1)  {
            this.items.push(it);
          // }
        });
      }
    }
  }

  submitWork1() {
    if (this.projectUpload) {
      this.projectUpload.submitForm();
      if (this.projectUpload.check) {
        const works1 = this.projectUpload.works;
        this.loading = true;
        if (works1 && works1.id) {
          this.bimWorksService.update(works1).subscribe(() => {
            this.loading = false;
            this.msg.success('提交成功！');
          });
        } else if (works1 && !works1.id) {
          works1.workTop = 0;
          this.bimWorksService.query({
            'teamId.equals': works1.teamId,
            'workType.equals': works1.workType,
            'itemId.equals': works1.itemId
          }).subscribe(wRes => {
            if (wRes && wRes.body.length > 0) {
              // 已有提交作品 进行更新
              works1['id'] = wRes.body[0].id;
              this.bimWorksService.update(works1).subscribe(res => {
                if (res && res.body) {
                  this.projectUpload.works.id = res.body.id;
                  const data = {
                    oldFk: this.fileFk1,
                    newFk: res.body.id,
                  };
                  this.thsadminFileService.updateFileFkBatch(data).subscribe(() => {
                    this.loading = false;
                    this.msg.success('提交成功！');
                  });
                }
              });
            } else {
              // 无提交作品，进行新增
              this.bimWorksService.create(works1).subscribe(res => {
                if (res && res.body) {
                  this.projectUpload.works.id = res.body.id;
                  const data = {
                    oldFk: this.fileFk1,
                    newFk: res.body.id,
                  };
                  this.thsadminFileService.updateFileFkBatch(data).subscribe(() => {
                    this.loading = false;
                    this.msg.success('提交成功！');
                  });
                }
              });
            }
          })
        }
      }
    }
  }

  submitWork2() {
    if (this.projectUpload2) {
      this.projectUpload2.submitForm();
      if (this.projectUpload2.check) {
        const works2 = this.projectUpload2.works;
        this.loading = true;
        if (works2 && works2.id) {
          this.bimWorksService.update(works2).subscribe(() => {
            this.loading = false;
            this.msg.success('提交成功！');
          });
        } else if (works2 && !works2.id) {
          works2.workTop = 0;
          this.bimWorksService.query({
            'teamId.equals': works2.teamId,
            'workType.equals': works2.workType,
            'itemId.equals': works2.itemId
          }).subscribe(wRes => {
            if (wRes && wRes.body.length > 0) {
              works2['id'] = wRes.body[0].id;
              this.bimWorksService.update(works2).subscribe(res => {
                if (res && res.body) {
                  this.projectUpload2.works.id = res.body.id;
                  const data = {
                    oldFk: this.fileFk2,
                    newFk: res.body.id,
                  };
                  this.thsadminFileService.updateFileFkBatch(data).subscribe(() => {
                    this.loading = false;
                    this.msg.success('提交成功！');
                  });
                }
              });
            } else {
              this.bimWorksService.create(works2).subscribe(res => {
                if (res && res.body) {
                  this.projectUpload2.works.id = res.body.id;
                  const data = {
                    oldFk: this.fileFk2,
                    newFk: res.body.id,
                  };
                  this.thsadminFileService.updateFileFkBatch(data).subscribe(() => {
                    this.loading = false;
                    this.msg.success('提交成功！');
                  });
                }
              });
            }
          });
        }
      }
    }
  }
}
