export class BimWorks {
  id?: string;
  name?: string;
  introduction?: string;
  teamId?: string;
  schoolId?: string;
  itemId?: string;
  workSubmitStatus?: number;
  smallImage?: string;
  bigImage?: string;
  videos?: string;
  uploadFile?: string;
  uploadDate?: Date;
  hit?: number;
  core?: number;
  remark?: string;
  netCore?: number;
  teacherCore?: number;
  bim?: number;
  avgCore?: number;
  workType?: number;
  secTeacherCore?: number;
  secNetCore?: number;
  secAvgCore?: number;
  secCore?: number;
  thsTeacherCore?: number;
  thsNetCore?: number;
  thsAvgCore?: number;
  thsCore?: number;
  workTop?: number;
  isReply?: string;
  teamName?: string;
  schoolName?: string;
  schoolType?: string;
  itemName?: string;
  bimInfoId?: string;
  bimInfoName?: string;
  eventsId?: string;
  eventsName?: string;
  secRaterList?: BimAdvisorsDTO[]; //全国赛评分评委
  checked?: boolean;
  firstImgId?: string;
  firstImgUrl?: string;
  teamStudentsDTOList?: Array<any>;
  fileType?: string;
  firstImgSavePath?: string;
  stuName?: string;
  groupName?: string;
  groupNum?: string;
}

export class BimAdvisorsDTO {
  id?: string;
  schoolId?: string;
  name?: string;
  email?: string;
  tel?: string;
  regCode?: string;
  sendMail?: string;
  advisorType?: string;
  description?: string;
}
