import { Component, OnInit } from '@angular/core';
import { CacheService } from '@delon/cache';
import { isNullOrUndefined } from 'util';
import { Router } from '@angular/router';
import { BimStore } from '@shared/store/bim.store';

@Component({
  selector: 'app-game-style-select',
  templateUrl: './game-style-select.component.html',
  styleUrls: ['./game-style-select.component.less'],
})
export class GameStyleSelectComponent implements OnInit {
    public bimInfo = null;
    public bimInfoId = null;

    constructor(
        private router: Router,
        private bimStore: BimStore,
        private cacheService: CacheService) {
    
        this.bimInfo = this.cacheService.get('bimInfo')['value'];
    }
    
    ngOnInit() {
        if (isNullOrUndefined(this.bimInfo)) {
            this.router.navigate(['/enroll/contest']);
            return;
        }

        this.bimInfoId = this.bimInfo.id;
    }

    onSelectItem(item){
        this.router.navigate(['/enroll/contestStyle']);
        this.bimStore.bimInfoStore.next(item);
    }

}