import { Injectable } from '@angular/core';
import {ThsBaseService} from "@shared/components/thsBaseService";
import {BimReceiptInfo} from "./bim-receipt-info.model";
import {HttpAddressService} from "@shared/session/http-address.service";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class BimReceiptInfoService extends ThsBaseService<BimReceiptInfo>{

  constructor(
    public http: HttpClient,
    httpAddressService: HttpAddressService
  ) {
    super(http, httpAddressService.BimServe + '/bim-receipt-infos');
  }
}
