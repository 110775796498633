import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { CacheService } from '@delon/cache';
import { GuidUtil } from '@shared/utils/guid.util';
import { NumberUtil } from '@shared/utils/numberUtil.util';
import { UserService } from 'app/routes/admin/user.service';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { isNullOrUndefined } from 'util';
import { BimInfoSchoolsService } from '../school-information-manage/bim-info-schools.service';
import { BimSchoolsService } from './bim-school.service';

@Component({
  selector: 'app-school-register',
  templateUrl: './school-register.component.html',
  styles: []
})
export class SchoolRegisterComponent implements OnInit {

  // 是否登录
  isLogin = false;
  title = '学校信息注册';

  userLocationList = [];
  userProvinceList = [];
  public locationList = [];
  public provinceList = [];
  public schoolTypeList = { '0': '本科', '1': '专科', '2': '中职' };
  form: FormGroup;
  recSchoolList: Array<{
    id: string,
    name: null,
    zw: null,
    mobile: null,
    profession: null,
    email: null,
  }> = [];
  freqContactList: Array<{
    id: string,
    name: string,
    zw: string,
    mobile: string,
    profession: null,
    email: string,
  }> = [];

  @ViewChild('modalWarning')
  modalWarning: TemplateRef<any>;
  public warningList = [];
  public submitting = false;

  public isEdit = false;
  public bimSchoolId = null;
  public bimSchool = null;
  public infoEventId = null;
  public bimInfoId = null;
  public bimInfo = this.cacheService.get('bimInfo')['value'];

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private msg: NzMessageService,
    private userService: UserService,
    private routeInfo: ActivatedRoute,
    private cacheService: CacheService,
    private modalService: NzModalService,
    private bimSchoolsService: BimSchoolsService,
    private bimInfoSchoolsService: BimInfoSchoolsService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  ) {
    this.form = this.fb.group({
      id: [null, []],
      name: [null, [Validators.required, Validators.maxLength(100)]],
      schoolType: [null, [Validators.required]],
      location: [null, [Validators.required]],
      province: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      regCode: [null, []],
    });
    const token = this.tokenService.get().token;
    if (token && token !== null && token !== '') {
      this.isLogin = true;
    }
    this.isEdit = this.routeInfo.snapshot.queryParams['isEdit'];
    this.bimSchoolId = this.tokenService.get().bimSchoolId;
    this.infoEventId = this.routeInfo.snapshot.params['bimEventId'];
  }

  ngOnInit() {
    if (isNullOrUndefined(this.bimInfo)) {
      this.router.navigate(['/enroll/contestSelect']);
      return;
    }
    this.bimInfoId = this.bimInfo.id;
    this.loadLocationList();

    if (this.isEdit) {
      this.title = '学校信息修改';
      this.initSchoolData();
    } else {
      if (!isNullOrUndefined(this.bimSchoolId)) {
        this.router.navigate(['/enroll/team/schoolInformationManage' + `/${this.infoEventId}`]);
        return;
      }
      this.addDefContact();
      this.addRecSchool();
    }
  }

  /**
   * 加载区域列表
   */
  loadLocationList() {
    // 加载区域与省份数据
    const dataParams = {
      'id.in': this.userLocationList,
      'size': '10000',
      'sort': 'orderNum,asc'
    };

    this.userService.queryRegionList(dataParams).subscribe(res => {
      this.locationList = res.body;
    });
  }

  setLocationData(event) {
    this.loadprovinceList(event);
  }

  /**
   * 加载省份列表
   */
  loadprovinceList(regionId) {
    this.userService.queryProvinceList({
      'id.in': this.userProvinceList,
      'fkregionId.equals': regionId,
      'size' : '10000',
      'sort': 'orderNum,asc'
    }).subscribe(res => {
      this.provinceList = res.body;
    });
  }

  addRecSchool(): void {
    if (this.recSchoolList.length < 3) {
      this.recSchoolList = [...this.recSchoolList, {
        id: new GuidUtil().toString(),
        name: null,
        zw: null,
        mobile: null,
        profession: null,
        email: null,
      }];
    } else {
      this.msg.warning('校内评委最多三人');
    }
  }

  removeRecSchool(id: string): void {
    if (this.recSchoolList.length > 1) {
      this.recSchoolList = this.recSchoolList.filter(d => d.id !== id);
    }
  }

  addDefContact(): void {
    this.freqContactList = [...this.freqContactList, {
      id: new GuidUtil().toString(),
      name: this.tokenService.get().name,
      zw: null,
      mobile: this.tokenService.get().mobile,
      profession: null,
      email: this.tokenService.get().email
    }];
  }

  addContact(): void {
    this.freqContactList = [...this.freqContactList, {
      id: new GuidUtil().toString(),
      name: null,
      zw: null,
      mobile: null,
      profession: null,
      email: null,
    }];
  }

  removeContact(id: string): void {
    if (this.freqContactList.length > 1) {
      this.freqContactList = this.freqContactList.filter(d => d.id !== id);
    }
  }

  submit() {
    const subParam = {};
    // tslint:disable-next-line:forin
    for (const key in this.form.controls) {
      this.form.controls[ key ].markAsDirty();
      this.form.controls[ key ].updateValueAndValidity();
      subParam[key] =  this.form.controls[key].value;
    }
    if (!this.checkData()) {
      return;
    }
    subParam['userDefine1'] = this.bimInfo.id;
    subParam['recommendSchool'] = this.recSchoolList;
    subParam['freqContact'] = this.freqContactList;
    if (!this.isEdit) {
      const mobile = this.tokenService.get().mobile;
      this.submitting = true;
      this.bimSchoolsService.query({
        'freqContact.contains': '&quot;mobile&quot;:&quot;' + mobile + '&quot;'
      }).subscribe(res => {
        this.submitting = false;
        if (res && res.body.length > 0) {
          this.msg.warning('登录用户已有加入学校，请重新登录');
          return;
        } else {
          this.saveData(subParam);
        }
      });
    } else {
      this.saveData(subParam);
    }
  }

  saveData(subParam) {
    this.submitting = true;
    this.bimSchoolsService.registerSchoolInfo(subParam).subscribe(res => {
      console.log(res);
      this.submitting = false;
      const data = res.body;
      if (data.success) {
        if (!this.isEdit) {
          this.msg.success('注册成功，请等待审核');
          this.tokenService.set({
            ...this.tokenService.get(),
            bimSchoolId: data.data.id
          });
          // 自动确认参赛
          this.confirmJoin(data.data.id);
          window.location.reload();
        } else {
          this.msg.success('修改成功，请等待审核');
          this.router.navigate(['/enroll/team/schoolInformationManage' + `/${this.infoEventId}`]);
        }
      } else {
        const title = data.message;
        this.warningList = data.data;
        const content = this.modalWarning;
        this.modalService.warning({
          nzTitle: title,
          nzContent: content,
          nzWidth: '60vh'
        });
      }
    }, () => this.submitting = false);
  }

  confirmJoin(bimSchoolId) {
    this.bimInfoSchoolsService.create({
      'bimInfoId': this.bimInfoId,
      'schoolId': bimSchoolId
    }).subscribe(res => {
      this.msg.success('参加成功');
    });
  }

  checkData() {
    const token = this.tokenService.get().token;
    if (isNullOrUndefined(token)) {
      this.onError('登录失效，请重新登录');
      return false;
    }
    if (!this.form.valid) {
      this.onError('请填写带 * 的信息');
      return false;
    }
    if (!this.checkPersonData('推荐校内评委', this.recSchoolList)) {
      return false;
    }
    if (!this.checkPersonData('联系人', this.freqContactList)) {
      return false;
    }
    return true;
  }

  checkPersonData(msg, list) {
    for (let i = 0; i < list.length; i++) {
      const data = list[i];
      if (isNullOrUndefined(data.name) || isNullOrUndefined(data.mobile) ||
          isNullOrUndefined(data.email) || isNullOrUndefined(data.zw) || isNullOrUndefined(data.profession)) {
        this.onError(msg + '信息未填写完整');
        return false;
      }
      if (!NumberUtil.isMatchMobile(data.mobile)) {
        this.onError(msg + '【' + data.name + '】手机号格式错误');
        return false;
      }
      if (!NumberUtil.isMatchEmail(data.email)) {
        this.onError(msg + '【' + data.name + '】邮箱格式错误');
        return false;
      }
    }
    return true;
  }

  onError(msg: string) {
    this.msg.remove();
    this.msg.error(msg);
  }

  initSchoolData() {
    if (isNullOrUndefined(this.bimSchoolId)) {
      this.msg.info('无此学校信息，请重新登录');
    } else {
      this.submitting = true;
      this.bimSchoolsService.getBimSchoolById(this.bimSchoolId).subscribe(res => {
        this.bimSchool = res;
        this.form.patchValue(this.bimSchool);
        this.recSchoolList = res.recSchoolList;
        this.freqContactList = res.freqContactList;
        this.submitting = false;
      });
    }
  }

  mobileChange(value, index, type) {
    if (!isNullOrUndefined(value) && NumberUtil.isMatchMobile(value)) {
      this.userService.queryEduUserList({
        'mobile.equals': value
      }).subscribe(res => {
        if (res && res.body.length > 0) {
          const data = res.body[0];
          if (type === '1') {
            this.recSchoolList[index].email = data.email;
            this.recSchoolList[index].name = data.personName;
          } else {
            this.freqContactList[index].email = data.email;
            this.freqContactList[index].name = data.personName;
          }
        }
      });
    }
  }

}
