import {
  Component,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { CacheService } from '@delon/cache';
import { GuidUtil } from '@shared/utils/guid.util';
import { NumberUtil } from '@shared/utils/numberUtil.util';
import { UserService } from 'app/routes/admin/user.service';
import { NzMessageService, NzModalService, UploadFile } from 'ng-zorro-antd';
import { isNullOrUndefined } from 'util';
import { BimInfoSchoolsService } from '../school-information-manage/bim-info-schools.service';
import { ModalHelper } from '@delon/theme';
import { BimInfoSchoolsEditComponent } from '../../school/bim-info-schools/edit/edit.component';
import { BimSchoolsService } from '../school-register/bim-school.service';
import { FileUploadService } from '@shared/components/fileUpload/fileUpload.service';
import { HomeService } from 'app/routes/home/home.service';
import { HttpAddressService } from '@shared/session/http-address.service';
import { BimSchoolDetailsService } from '../school-register/bim-school-details.service';
import { UcommonService } from '../../ucommon/ucommon.server';

@Component({
  selector: 'app-province-competition-registration',
  templateUrl: './province-competition-registration.component.html',
  styleUrls: ['./province-competition-registration.component.less'],
})
export class ProvinceCompetitionRegistrationComponent implements OnInit {
  // 是否登录
  isLogin = false;
  title = '学校信息填写';
  isEdit = true; //学校信息填写
  schoolInfo_isEdit = true; //学校信息
  teamLeader_isEdit = true; //带队老师
  teamInfo_isEdit = true; //团队信息

  userLocationList = [];
  userProvinceList = [];
  public locationList = [];
  public provinceList = [];
  public schoolTypeList = { '0': '本科', '1': '专科', '2': '中职' };
  form: FormGroup;
  recSchoolList: Array<{
    id: string;
    name: null;
    zw: null;
    mobile: null;
    profession: null;
    email: null;
  }> = [];

  @ViewChild('modalWarning') modalWarning: TemplateRef<any>;
  public warningList = [];
  public submitting = false;

  public bimInfo = this.cacheService.get('bimInfo')['value'];
  public tabSelectedIndex = 0;

  public infoEventId = null;
  public bimInfoEventsItems = [];
  public bimInfoEventsMutex = [];
  public itemTypes = [];
  public items = [];

  public dataId = null;

  public cloneTeamInfoTabs = [];
  //参赛赛项
  public teamInfoTabs = [];

  public curTeamEvent = this.cacheService.get('curTeamEvent')['value'];

  public isSingle = '0';

  public teamCount = 6;
  public teacherCount = 3;
  public teamStuCount = 5;
  public instructorCount = 5;
  
  public uploadAddress: string;
  entityName = '';
  fileList = [];

  public uploadFileId = null;
  public schoolDetailId = null;
  public isRegistryTeam = false;
  public schoolLeaderId = null;
  public bimTeamId = this.cacheService.get('bimTeamId')['value'];
  public bimSchoolDetail = this.cacheService.get('bimSchoolDetail')['value'];

  public isCanAlter = false;

  
  public isSubmitSchool = false;
  public isSubmitLeader = false;
  public isSubmitTeam = false;
  
  public bimSchoolId = null;
  public userMobile = null;

  public isSchoolEdit = true;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private modal: ModalHelper,
    private msg: NzMessageService,
    private homeService: HomeService,
    private userService: UserService,
    private routeInfo: ActivatedRoute,
    private cacheService: CacheService,
    private modalService: NzModalService,
    private ucommonService: UcommonService,
    private fileUploadService: FileUploadService,
    private bimSchoolsService: BimSchoolsService,
    private httpAddressService: HttpAddressService,
    private bimSchoolDetailsService: BimSchoolDetailsService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  ) {
    this.infoEventId = this.routeInfo.snapshot.params['bimEventId'];
    this.uploadAddress = httpAddressService.apiGateway + httpAddressService.FileServer + '/sys-files/upload';

    this.form = this.fb.group({
      id: [null, []],
      schoolId: [null, []],
      name: [null, [Validators.required, Validators.maxLength(100)]],
      schoolType: [null, []],
      schoolTypeName: [null, []],
      college: [null, []],
      address: [null, []],
      linkmanName: [null, [Validators.required, Validators.maxLength(50)]],
      linkmanPhone: [null, [Validators.required, Validators.pattern(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/)]],
      linkmanQq: [null, [Validators.required, Validators.pattern(/[1-9][0-9]{4,14}$/)]],
      registeFileName: [null, []],
    });
    const token = this.tokenService.get().token;
    if (token && token !== null && token !== '') {
      this.isLogin = true;
    }
    this.entityName = tokenService.get().entity_name;
    this.userMobile = tokenService.get().mobile;
    this.schoolDetailId = tokenService.get().bim_school_details_id;
    this.isRegistryTeam = tokenService.get().is_registry_team;
    this.schoolLeaderId = tokenService.get().bim_school_leader_id;
    
    this.bimSchoolId = this.tokenService.get().bimProvinceSchoolId;
  }

  ngOnInit() {
    if (isNullOrUndefined(this.bimInfo)) {
      this.router.navigate(['/enroll/contestSelect']);
      return;
    }
    // this.form.get('schoolType').disable();
    // this.form.get('college').disable();
    // this.form.get('address').disable();
    this.isSingle = this.curTeamEvent.isSingle;
    if (!isNullOrUndefined(this.bimInfo.teamCount)) {
      this.teamCount = this.bimInfo.teamCount;
    }
    if (!isNullOrUndefined(this.bimInfo.teacherCount)) {
      this.teacherCount = this.bimInfo.teacherCount;
    }
    if (!isNullOrUndefined(this.bimInfo.teamStuCount)) {
      this.teamStuCount = this.bimInfo.teamStuCount;
    }
    if (!isNullOrUndefined(this.bimInfo.instructorCount)) {
      this.instructorCount = this.bimInfo.instructorCount;
    }
    // this.loadLocationList();

    this.initEventMutex();

    this.isEdit =
      this.router.url.indexOf('provinceCompetitionRegistration') > -1;

    if (this.isEdit) {
      if ((!isNullOrUndefined(this.schoolDetailId) && this.isRegistryTeam) || !isNullOrUndefined(this.schoolLeaderId)) {
        this.router.navigate(['/enroll/team/provinceCompetitionInfo' + `/${this.infoEventId}`]);
        return;
      }
      this.isCanAlter = !isNullOrUndefined(this.schoolDetailId);
      if (this.bimSchoolId && !isNullOrUndefined(this.bimSchoolId)) {
        this.isSchoolEdit = false;
        // 已注册学校
        this.initSchoolData();
      }
      this.loadBimInfoEventsItems();
      this.dataId = new GuidUtil().toString();
      if (isNullOrUndefined(this.schoolDetailId)) {
        this.schoolDetailId = new GuidUtil().toString();
      }
      this.title = '报名信息填写';
      this.addRecSchool();
    } else {
      this.title = '报名信息管理';
      if (!isNullOrUndefined(this.schoolDetailId) || !isNullOrUndefined(this.schoolLeaderId)) {
        // 学校日常联系人 学校带队老师
        this.isCanAlter = !isNullOrUndefined(this.schoolDetailId);
        if (this.bimInfo.isSchoolRequire === '1') {
          this.isSchoolEdit = false;
        }
        if (!isNullOrUndefined(this.bimSchoolDetail)) {
          this.bimSchoolsService.find(this.bimSchoolDetail.bimSchoolId).subscribe(res => {
            if (res && res.body) {
              const bimSchool = res.body;
              this.form.patchValue({
                name: bimSchool.name,
                schoolId: bimSchool.id,
                schoolType: bimSchool.schoolType,
                schoolTypeName: this.schoolTypeList[bimSchool.schoolType],
                college: bimSchool.college,
                address: bimSchool.address,
              });
            }
          });
          const detailKeys = ['linkmanName', 'linkmanPhone', 'linkmanQq'];
          for (let i = 0; i < detailKeys.length; i++) {
            this.form.controls[detailKeys[i]].setValue(this.bimSchoolDetail[detailKeys[i]]);
          }
          if (!isNullOrUndefined(this.bimSchoolDetail.leaderTeacher)) {
            const leaderTeacher = JSON.parse(this.ucommonService.ReplaceHtml(this.bimSchoolDetail.leaderTeacher));
            this.recSchoolList = leaderTeacher;
          }
          // 查询团队及队员信息
          this.bimSchoolsService.getProvinceTeamInfo({
            'schoolId': this.bimSchoolDetail.bimSchoolId,
            'bimInfoEventId': this.infoEventId
          }).subscribe(res => {
            if (res && res.body) {
              const data = res.body;
              data.forEach(e => {
                if (e.teamItems && e.teamItems.length > 0) {
                  let itemNames = e.teamItems.map(item => item.name);
                  e['itemNames'] = itemNames.join(',');
                }

                this.fileUploadService.query({
                  'fileFk.equals': e.id,
                  'extField1.equals': 'studentRegiste'
                }).subscribe(res => {
                  if (res && res.body.length > 0) {
                    const fileData = res.body;
                    for (let i = 0; i < fileData.length; i++) {
                      e['registeFileName' + (i + 1)] = fileData[i].fileName;
                      e['registeFileId' + (i + 1)] = fileData[i].id;
                    }
                  }
                });
              });
              this.teamInfoTabs = data;
              this.loadBimInfoEventsItems();
            }
          });
        }
      } else if (!isNullOrUndefined(this.bimTeamId)) {
        // 学校团队成员
        this.isCanAlter = false;
        // 查询团队及队员信息
        this.bimSchoolsService.getProvinceTeamInfo({
          'bimTeamId': this.bimTeamId,
          'bimInfoEventId': this.infoEventId
        }).subscribe(res => {
          if (res && res.body) {
            const data = res.body;
            data.forEach(e => {
              const bimSchool = e.schoolInfo;
              this.form.patchValue({
                name: bimSchool.name,
                schoolId: bimSchool.id,
                schoolType: bimSchool.schoolType,
                schoolTypeName: this.schoolTypeList[bimSchool.schoolType],
                college: bimSchool.college,
                address: bimSchool.address,
              });
              const schoolDetail = e.schoolDetail;
              const detailKeys = ['linkmanName', 'linkmanPhone', 'linkmanQq'];
              for (let i = 0; i < detailKeys.length; i++) {
                this.form.controls[detailKeys[i]].setValue(schoolDetail[detailKeys[i]]);
              }
              if (!isNullOrUndefined(schoolDetail.leaderTeacher)) {
                const leaderTeacher = JSON.parse(this.ucommonService.ReplaceHtml(schoolDetail.leaderTeacher));
                this.recSchoolList = leaderTeacher;
              }
              if (e.teamItems && e.teamItems.length > 0) {
                let itemNames = e.teamItems.map(item => item.name);
                e['itemNames'] = itemNames.join(',');
              }

              this.fileUploadService.query({
                'fileFk.equals': e.id,
                'extField1.equals': 'studentRegiste'
              }).subscribe(res => {
                if (res && res.body.length > 0) {
                  const fileData = res.body;
                  for (let i = 0; i < fileData.length; i++) {
                    e['registeFileName' + (i + 1)] = fileData[i].fileName;
                    e['registeFileId' + (i + 1)] = fileData[i].id;
                  }
                }
              });
            });
            this.teamInfoTabs = data;
            this.loadBimInfoEventsItems();
          }
        });
      }

      this.schoolInfo_isEdit = false; //学校信息
      this.teamLeader_isEdit = false; //带队老师
      this.teamInfo_isEdit = false; //团队信息
    }
  }
  

  initSchoolData() {
    if (isNullOrUndefined(this.bimSchoolId)) {
      this.msg.info('无此学校信息，请重新登录');
    } else {
      this.bimSchoolsService.getBimSchoolById(this.bimSchoolId).subscribe(res => {
        const bimSchool = res;
        this.form.patchValue({
          name: bimSchool.name,
          schoolId: bimSchool.id,
          schoolType: bimSchool.schoolType,
          schoolTypeName: this.schoolTypeList[bimSchool.schoolType],
          college: bimSchool.college,
          address: bimSchool.address,
        });
        const detailKeys = ['linkmanName', 'linkmanPhone', 'linkmanQq'];
        if (!isNullOrUndefined(this.bimSchoolDetail)) {
          // 后台导入学校信息 学校联系人登录注册
          for (let i = 0; i < detailKeys.length; i++) {
            this.form.controls[detailKeys[i]].setValue(this.bimSchoolDetail[detailKeys[i]]);
          }
          if (!isNullOrUndefined(this.bimSchoolDetail.leaderTeacher)) {
            const leaderTeacher = JSON.parse(this.ucommonService.ReplaceHtml(this.bimSchoolDetail.leaderTeacher));
            this.recSchoolList = leaderTeacher;
          }
        } else {
          // 登录人注册学校 无日常联系人信息
          this.form.controls[detailKeys[0]].setValue(this.entityName);
          this.form.controls[detailKeys[1]].setValue(this.userMobile);
        }
      });
    }
  }

  /**
   * 加载区域列表
   */
  loadLocationList() {
    // 加载区域与省份数据
    const dataParams = {
      'id.in': this.userLocationList,
      size: '10000',
      sort: 'orderNum,asc',
    };

    this.userService.queryRegionList(dataParams).subscribe(res => {
      this.locationList = res.body;
    });
  }

  setLocationData(event) {
    this.loadprovinceList(event);
  }

  /**
   * 加载省份列表
   */
  loadprovinceList(regionId) {
    this.userService
      .queryProvinceList({
        'id.in': this.userProvinceList,
        'fkregionId.equals': regionId,
        size: '10000',
        sort: 'orderNum,asc',
      })
      .subscribe(res => {
        this.provinceList = res.body;
      });
  }

  addRecSchool(): void {
    if (this.recSchoolList.length < this.teacherCount) {
      this.recSchoolList = [
        ...this.recSchoolList,
        {
          id: new GuidUtil().toString(),
          name: null,
          zw: null,
          mobile: null,
          profession: null,
          email: null,
        },
      ];
    } else {
      this.msg.warning('带队老师最多' + this.teacherCount + '人');
    }
  }

  removeRecSchool(id: string): void {
    if (this.recSchoolList.length > 1) {
      this.recSchoolList = this.recSchoolList.filter(d => d.id !== id);
    } else {
      this.onError('至少保留一条数据');
    }
  }

  addMembers(index): void {
    if (this.teamInfoTabs[index].teamStudents.length < this.teamStuCount) {
      this.teamInfoTabs[index].teamStudents = [
        ...this.teamInfoTabs[index].teamStudents,
        {
          id: new GuidUtil().toString(),
          name: null,
          gender: null,
          mobile: null,
          profession: null,
          email: null,
          remark: null,
        },
      ];
    } else {
      this.msg.warning('团队成员最多' + this.teamStuCount + '人');
    }
  }

  removeMembers(index, id: string): void {
    if (this.teamInfoTabs[index].teamStudents.length > 1) {
      this.teamInfoTabs[index].teamStudents = this.teamInfoTabs[
        index
      ].teamStudents.filter(d => d.id !== id);
    } else {
      this.onError('至少保留一条数据');
    }
  }

  addInstructor(index): void {
    if (this.teamInfoTabs[index].teamTeachers.length < this.instructorCount) {
      this.teamInfoTabs[index].teamTeachers = [
        ...this.teamInfoTabs[index].teamTeachers,
        {
          id: new GuidUtil().toString(),
          name: null,
          mobile: null,
          profession: null,
          email: null,
          qq: null,
        },
      ];
    } else {
      this.msg.warning('指导老师最多' + this.instructorCount + '人');
    }
  }

  removeInstructor(index, id: string): void {
    if (this.teamInfoTabs[index].teamTeachers.length > 1) {
      this.teamInfoTabs[index].teamTeachers = this.teamInfoTabs[
        index
      ].teamTeachers.filter(d => d.id !== id);
    } else {
      this.onError('至少保留一条数据');
    }
  }

  submit() {
    const subParam = {};
    // tslint:disable-next-line:forin
    for (const key in this.form.controls) {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
      subParam[key] = this.form.controls[key].value;
    }

    if (!this.checkData()) {
      return;
    }
    if (!this.checkTeamItems()) {
      return;
    }
    
    subParam['id'] = this.dataId;
    subParam['infoEventId'] = this.infoEventId;
    subParam['bimInfoId'] = this.bimInfo.id;
    subParam['schoolDetailId'] = this.schoolDetailId;
    subParam['recommendSchool'] = this.recSchoolList;
    subParam['teamInfoTabs'] = this.cloneTeamInfoTabs;
    this.saveData(subParam);
  }

  checkTeamItems() {
    this.teamInfoTabs.forEach(e => {
      // const selItem = [];
      // e.checkOptions.forEach(e => selItem.push.apply(selItem, e.checkOptions.filter(d => d.checked)));
      e['teamItems'] = e.checkOptions.filter(d => d.checked);
      let itemNames = e.teamItems.map(item => item.name);
      e['itemNames'] = itemNames.join(',');
    });
    
    for (let i = 0; i < this.teamInfoTabs.length; i++) {
      const teamInfo  = this.teamInfoTabs[i];
      if (teamInfo.teamItems.length < 1) {
        this.onError((i + 1) + '号团队未选择参赛项目');
        return false;
      }
    
      if (this.bimInfoEventsMutex.length > 0) {
        const teamItems = teamInfo.teamItems;
        for (let j = 0; j < teamItems.length; j++) {
          const data = teamItems[j];
          // 有设置唯一专项
          if (this.bimInfoEventsMutex.indexOf(data.id) > -1) {
            for (let k = 0; k < this.teamInfoTabs.length; k++) {
              if (k !== i) {
                const selList = this.teamInfoTabs[k].checkOptions.filter(e => e.id === data.id && e.checked === true);
                if (selList && selList.length > 0) {
                  this.onError('专项【' + data.name + '】只可一个队伍报名，请检查' + (k + 1) + '号、' + (i + 1) + '号队伍');
                  return false;
                }
              }
            }
          }
        }
      }
    }
    
    this.cloneTeamInfoTabs = JSON.parse(JSON.stringify(this.teamInfoTabs));
    let index = 1;
    this.cloneTeamInfoTabs.forEach(e => {
      e['userDefine2'] = index++;
      delete e.checkOptions;
    });
    return true;
  }

  saveData(subParam) {
    this.submitting = true;
    this.bimSchoolsService.registerProvinceTeamInfo(subParam).subscribe(res => {
      const data = res.body;
      if (data.success) {
        this.msg.success('报名成功');
        if (this.isLogin) {
          this.cacheService.set('bimSchoolDetail', data.data);
          this.tokenService.set({
            ...this.tokenService.get(),
            bim_school_details_id: data.data.id,
            bimProvinceSchoolId: data.data.bimSchoolId,
            is_registry_team: true
          });
        }
        window.location.reload();
      } else {
        this.submitting = false;
        const title = data.message;
        if (isNullOrUndefined(data.data)) {
          this.msg.warning(title);
        } else {
          this.warningList = data.data.hasStuList ? data.data.hasStuList : data.data;
          const content = this.modalWarning;
          this.modalService.warning({
            nzTitle: title,
            nzContent: content,
            nzWidth: '60vh'
          });
        }
      }
    });

    // this.schoolInfo_isEdit = false; //学校信息
    // this.teamLeader_isEdit = false; //带队老师
    // this.teamInfo_isEdit = false; //团队信息
  }

  checkData() {
    // const token = this.tokenService.get().token;
    // if (isNullOrUndefined(token)) {
    //   this.onError('登录失效，请重新登录');
    //   return false;
    // }
    if (!this.checkSchoolData()) {
      return false;
    }
    if (!this.checkPersonData('带队老师', this.recSchoolList)) {
      return false;
    }
    if (!this.checkTeamInfo('团队信息', this.teamInfoTabs)) {
      return false;
    }
    return true;
  }

  checkSchoolData() {
    if (!this.form.valid) {
      this.onError('请填写带 * 的信息');
      return false;
    }
    const name = this.form.controls['name'].value;
    if (this.isHasSpecialChar(name)) {
      this.onError('学校名称不可有空格或特殊字符');
      return false;
    }

    const college = this.form.controls['college'].value;
    if (this.isHasSpecialChar(college)) {
      this.onError('学校院系不可有空格或特殊字符');
      return false;
    }
    const address = this.form.controls['address'].value;
    if (this.isHasSpecialChar(address)) {
      this.onError('学校通讯地址不可有空格或特殊字符');
      return false;
    }
    const linkmanName = this.form.controls['linkmanName'].value;
    if (this.isHasSpecialChar(linkmanName)) {
      this.onError('学校日常联系人名称不可有空格或特殊字符');
      return false;
    }
    return true;
  }

  checkPersonData(msg, list) {
    const tels = [];
    for (let i = 0; i < list.length; i++) {
      const data = list[i];
      if (
        isNullOrUndefined(data.name) ||
        isNullOrUndefined(data.mobile) ||
        isNullOrUndefined(data.email) ||
        isNullOrUndefined(data.zw) ||
        isNullOrUndefined(data.profession) ||
        isNullOrUndefined(data.qq)
      ) {
        this.onError(msg + '信息未填写完整');
        return false;
      }
        
      if (this.isHasSpecialChar(data.name)) {
        this.onError(msg + '【' + data.name + '】姓名不可有空格或特殊字符');
        return false;
      }
      if (this.isHasSpecialChar(data.profession)) {
        this.onError(msg + '【' + data.profession + '】专业不可有空格或特殊字符');
        return false;
      }
      if (this.isHasSpecialChar(data.zw)) {
        this.onError(msg + '【' + data.zw + '】职务不可有空格或特殊字符');
        return false;
      }
      if (!NumberUtil.isMatchMobile(data.mobile)) {
        this.onError(msg + '【' + data.name + '】手机号格式错误');
        return false;
      }
      if (!NumberUtil.isMatchEmail(data.email)) {
        this.onError(msg + '【' + data.name + '】邮箱格式错误');
        return false;
      }
      if (!NumberUtil.isMatchQq(data.qq)) {
        this.onError(msg + '【' + data.name + '】qq格式错误');
        return false;
      }
      if (tels.includes(data.mobile)) {
        this.onError(msg + `【${data.name}】手机号重复，请修改`);
        return false;
      } else {
        tels.push(data.mobile);
      }
    }
    return true;
  }

  checkTeamInfo(msg, list) {
    const emailList = [];
    for (let i = 0; i < list.length; i++) {
      msg = (i + 1) + '号团队';
      const data = list[i];
      // let checkData = data.checkOptions.filter(item => item.checked);
      if (
        isNullOrUndefined(data.name) ||
        isNullOrUndefined(data.email) 
        // || checkData.length == 0
      ) {
        this.onError(msg + '信息未填写完整');
        return false;
      }
      if (this.isHasSpecialChar(data.name)) {
        this.onError(msg + '名称不可有空格或特殊字符');
        return false;
      }
      if (!NumberUtil.isMatchEmail(data.email)) {
        this.onError(msg + '邮箱格式错误');
        return false;
      }
      // 检查团队邮箱是否有重复
      if (emailList.includes(data.email)) {
        this.onError(msg + '邮箱已在其他团队使用，请修改');
        return false;
      }
      emailList.push(data.email);

      let isHasCaptain = false;
      let tels = [];
      for (let membersI = 0; membersI < data.teamStudents.length; membersI++) {
        const membersData = data.teamStudents[membersI];
        if (
          isNullOrUndefined(membersData.name) ||
          isNullOrUndefined(membersData.email) ||
          // isNullOrUndefined(membersData.gender) ||
          isNullOrUndefined(membersData.mobile) ||
          isNullOrUndefined(membersData.profession)
        ) {
          this.onError(msg + '团队成员信息未填写完整');
          return false;
        }
        
        if (this.isHasSpecialChar(membersData.name)) {
          this.onError(msg + '团队成员【' + membersData.name + '】姓名不可有空格或特殊字符');
          return false;
        }
        if (this.isHasSpecialChar(membersData.profession)) {
          this.onError(msg + '团队成员【' + membersData.profession + '】专业不可有空格或特殊字符');
          return false;
        }
        if (!NumberUtil.isMatchMobile(membersData.mobile)) {
          this.onError(
            msg + '团队成员【' + membersData.name + '】手机号格式错误',
          );
          return false;
        }
        if (!NumberUtil.isMatchEmail(membersData.email)) {
          this.onError(
            msg + '团队成员【' + membersData.name + '】邮箱格式错误',
          );
          return false;
        }
        if (!isHasCaptain) {
          isHasCaptain = !isNullOrUndefined(membersData.remark) && membersData.remark;
        }
        if (tels.includes(membersData.mobile)) {
          this.onError(msg + `团队成员【${membersData.name}】手机号重复，请修改`);
          return false;
        } else {
          tels.push(membersData.mobile);
        }
      }
      if (!isHasCaptain) {
        this.onError(
          msg + '团队未指定队长',
        );
        return false;
      }
      tels = [];
      for (
        let instructorI = 0;
        instructorI < data.teamTeachers.length;
        instructorI++
      ) {
        const instructorData = data.teamTeachers[instructorI];
        if (
          isNullOrUndefined(instructorData.name) ||
          isNullOrUndefined(instructorData.email) ||
          isNullOrUndefined(instructorData.mobile) ||
          isNullOrUndefined(instructorData.profession)
        ) {
          this.onError(msg + '指导老师信息未填写完整');
          return false;
        }
        
        if (this.isHasSpecialChar(instructorData.name)) {
          this.onError(msg + '指导老师【' + instructorData.name + '】姓名不可有空格或特殊字符');
          return false;
        }
        if (this.isHasSpecialChar(instructorData.profession)) {
          this.onError(msg + '指导老师【' + instructorData.profession + '】专业不可有空格或特殊字符');
          return false;
        }
        if (!NumberUtil.isMatchMobile(instructorData.mobile)) {
          this.onError(
            msg + '指导老师【' + instructorData.name + '】手机号格式错误',
          );
          return false;
        }
        if (!NumberUtil.isMatchEmail(instructorData.email)) {
          this.onError(
            msg + '指导老师【' + instructorData.name + '】邮箱格式错误',
          );
          return false;
        }
        if (tels.includes(instructorData.mobile)) {
          this.onError(`指导老师【${instructorData.name}】手机号重复，请修改`);
          return false;
        } else {
          tels.push(instructorData.mobile);
        }
      }
    }
    return true;
  }

  onError(msg: string) {
    this.msg.remove();
    this.msg.error(msg);
  }

  mobileChange(value, index, type) {
    if (!isNullOrUndefined(value) && NumberUtil.isMatchMobile(value)) {
      this.userService
        .queryEduUserList({
          'mobile.equals': value,
        })
        .subscribe(res => {
          if (res && res.body.length > 0) {
            const data = res.body[0];
            if (type === '1') {
              this.recSchoolList[index].email = data.email;
              this.recSchoolList[index].name = data.personName;
            }
          }
        });
    }
  }

  closeTab(index: number): void {
    this.teamInfoTabs.splice(index, 1);
  }

  newTab(): void {
    if (this.teamInfoTabs.length < this.teamCount) {
      this.teamInfoTabs.push({
        id: new GuidUtil().toString(),
        name: null,
        email: null,
        eventsId: this.infoEventId,
        isDelete: 0,
        checkOptions: JSON.parse(JSON.stringify(this.items)),
        registeFileName1: null,
        registeFileId1: null,
        registeFileName2: null,
        registeFileId2: null,
        teamStudents: [
          {
            id: new GuidUtil().toString(),
            name: null,
            gender: null,
            mobile: null,
            profession: null,
            email: null,
            remark: null,
          },
        ],
        teamTeachers: [
          {
            id: new GuidUtil().toString(),
            name: null,
            profession: null,
            mobile: null,
            email: null,
            qq: null,
          },
        ],
      });
    } else {
      this.msg.warning('最多' + this.teamCount + '支队伍');
    }
  }

  locationFilter(val) {
    let list = this.locationList.filter(item => item.id == val);
    if (list.length > 0 && list[0].regionName) {
      return list[0].regionName;
    }
  }
  provinceFilter(val) {
    let list = this.provinceList.filter(item => item.id == val);
    if (list.length > 0 && list[0].provinceName) {
      return list[0].provinceName;
    }
  }

  checkOptionsFilter(tab) {
    // if (tab) {
    //   let list = tab.filter(item => !isNullOrUndefined(item.checked) &&item.checked);
    //   if (list.length > 0) {
    //     let list1 = list.map(item => item.label);
    //     return list1.join(',');
    //   }
    // } else {
    //   return null;
    // }
  }
  
  selectSchool(){
    this.modal
    .static(BimInfoSchoolsEditComponent, {
      bimInfoType: 'province',
      bimInfoId: this.bimInfo.id
    })
    .subscribe((res) => {
      if (res != null && res.length >1) {
        this.onError('只能选择一个学校，请重新选择！');
        return false;
      } else if (res != null && res.length === 1) {
        console.log(res[0]);
        this.form.controls['schoolId'].setValue(res[0].id);
        this.form.controls['name'].setValue(res[0].name);
        this.form.controls['schoolType'].setValue(res[0].schoolType);
        if (res[0].schoolType) {
          this.form.controls['schoolTypeName'].setValue(this.schoolTypeList[res[0].schoolType]);
        }
        this.form.controls['college'].setValue(res[0].college);
        this.form.controls['address'].setValue(res[0].address);
      }
    });
  }

  loadBimInfoEventsItems() {
    this.bimInfoEventsItems = [];
    this.homeService.getBimInfoEventItem({
      'bimInfoEventsId.equals': this.infoEventId
    }).subscribe((res: any) => {
      this.bimInfoEventsItems = res.body;
      this.loadItems();
    });
  }

  loadItems() {
    this.items = [];
    this.itemTypes = [];
    this.homeService.getBimItem({
      'isDelete.equals': 0,
      'size': 1000,
      'sort': ['type,asc', 'orderSn,asc']
    }).subscribe((res: any) => {
      const data = res.body;
      this.items = data.filter(e => this.bimInfoEventsItems.filter(d => d.itemId === e.id).length > 0);
      if (this.isEdit) {
        this.newTab();
      } else {
        this.teamInfoTabs.forEach(e => {
          const oItems = JSON.parse(JSON.stringify(this.items));
          oItems.forEach(f => f.checked = e.teamItems.filter(s => s.itemId === f.id).length > 0);
          e['checkOptions'] = oItems;
          e['eventsId'] = e.bimInfoEventsId;
          e.teamStudents.forEach(f => {
            f['mobile'] = f.tel;
            f.remark = (f.remark == 'true');
          });
        });
      }
      console.log(this.teamInfoTabs);
      // this.addMembers(0);
      // this.addInstructor(0);
      // const allType = [];
      // this.items.forEach(e => {
      //   if (!allType.includes(e.type)) {
      //     allType.push(e.type);
      //     this.itemTypes.push({
      //       'type': e.type,
      //       'data': this.getItemByType(e.type, initData)
      //     });
      //   }
      // });
      // console.log(this.itemTypes);
    });
  }

  getItemByType(type, initData?) {
    const data = this.items.filter(d => d.type === type).sort(d => d.orderSn);
    if (!isNullOrUndefined(initData)) {
      data.forEach(e => e.checked = initData.filter(s => s.itemId === e.id).length > 0);
    }
    return data;
  }

  checkClick(checked, data, teamIndex) {
    if (checked) {
      this.teamInfoTabs[teamIndex].teamStudents.forEach(e => {
        if (e.id !== data.id) {
          e.remark = false;
        }
      });
    }
  }

  itemCheckClick(checked, data, teamIndex) {
    if (this.isSingle === '1' && checked) {
      this.teamInfoTabs[teamIndex].checkOptions.forEach(e => {
        if (e.id !== data.id) {
          e.checked = false;
        }
      });
    }
  }

  isHasSpecialChar(str) {
    var pattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]");
    if(str.indexOf(' ') > -1 || pattern.test(str)){
      return true;
    } else {
      return false;
    }
  }

  initEventMutex() {
    this.homeService.getAllMutexDataPageList({
      'bimInfoEventId': this.infoEventId,
      page: 0,
      size: 100
    }).subscribe((res: any) => {
      const data = res.body;
      data.forEach(e => {
        if (!isNullOrUndefined(e.isOnly) && e.isOnly == '1') {
          this.bimInfoEventsMutex.push(e.id);
        }
      })
    });
  }

  beforeRegisteUpload = (file: UploadFile): boolean => {
    const imgFormat = ['doc', 'docx', 'DOC', 'DOCX', 'pdf'];
    const type = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
    if (imgFormat.indexOf(type) < 0) {
      this.msg.remove();
      this.msg.error('格式错误,仅支持' + imgFormat + '格式');
      return false;
    }
    if (file.size > 1024 * 1024 * 20) {
      this.msg.remove();
      this.msg.error('支持20M以下文件上传');
      return false;
    }
    return true;
  }

  registeFileParam = (file: UploadFile) => {
    const tmp = {
      fileFk: this.uploadFileId,
      name: file.name,
      fileDesc: file.name,
      uploader: this.entityName,
      fileType: file.name.substring(file.name.indexOf('.') + 1),
      extField1: 'studentRegiste'
    };
    return tmp;
  }

  handleUploadChange1(item: any, teamIndex) {
    if (item.type === 'success') {
      const res = item.file.response;
      this.teamInfoTabs[teamIndex].registeFileName1 = res.fileName;
      if (!isNullOrUndefined(this.teamInfoTabs[teamIndex].registeFileId1)) {
        this.fileUploadService.delete(this.teamInfoTabs[teamIndex].registeFileId1).subscribe();
      }
      this.teamInfoTabs[teamIndex].registeFileId1 = res.id;
    }
  }

  handleUploadChange2(item: any, teamIndex) {
    if (item.type === 'success') {
      const res = item.file.response;
      this.teamInfoTabs[teamIndex].registeFileName2 = res.fileName;
      if (!isNullOrUndefined(this.teamInfoTabs[teamIndex].registeFileId2)) {
        this.fileUploadService.delete(this.teamInfoTabs[teamIndex].registeFileId2).subscribe();
      }
      this.teamInfoTabs[teamIndex].registeFileId2 = res.id;
    }
  }

  selUploadFile(team) {
    this.uploadFileId = team.id;
    console.log(this.uploadFileId);
  }

  downFile(fileId) {
    const a = document.createElement('a');
    const url =  this.httpAddressService.apiGateway + '/thsadmin/api/sys-files/download/' + fileId;
    a.href = url;
    a.click();
  }

  submitSchoolInfo() {
    if (this.isSubmitSchool) {
      return;
    }
    const subParam = {};
    // tslint:disable-next-line:forin
    for (const key in this.form.controls) {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
      subParam[key] = this.form.controls[key].value;
    }
    if (!this.checkSchoolData()) {
      return false;
    }
    for (let key in subParam) {
      if (this.bimSchoolDetail[key] && key !== 'id') {
        this.bimSchoolDetail[key] = subParam[key]
      }
    }
    this.isSubmitSchool = true;
    this.bimSchoolDetailsService.update(this.bimSchoolDetail).subscribe(res => {
      this.msg.success('保存成功');
      this.bimSchoolDetail = res.body;
      this.cacheService.set('bimSchoolDetail', this.bimSchoolDetail);
      this.schoolInfo_isEdit = false;
      this.isSubmitSchool = false;
    });
  }

  submitLeader() {
    if (this.isSubmitLeader) {
      return;
    }
    if (!this.checkPersonData('带队老师', this.recSchoolList)) {
      return false;
    }
    const subParam = {
      'infoEventId': this.infoEventId,
      'schoolDetailId': this.schoolDetailId,
      'recommendSchool': this.recSchoolList
    };
    console.log(JSON.stringify(subParam));
    this.isSubmitLeader = true;
    this.bimSchoolsService.saveProvinceLeader(subParam).subscribe(res => {
      this.isSubmitLeader = false;
      const data = res.body;
      if (data.success) {
        this.msg.success('保存成功');
        this.teamLeader_isEdit = false;
        this.cacheService.set('bimSchoolDetail', data.data);
      } else {
        const title = data.message;
        if (isNullOrUndefined(data.data)) {
          this.msg.warning(title);
        } else {
          this.warningList = data.data.hasStuList ? data.data.hasStuList : data.data;
          const content = this.modalWarning;
          this.modalService.warning({
            nzTitle: title,
            nzContent: content,
            nzWidth: '60vh'
          });
        }
      }
    });
  }

  submitTeamInfo() {
    if (this.isSubmitTeam) {
      return;
    }
    if (!this.checkTeamInfo('团队信息', this.teamInfoTabs)) {
      return;
    }
    if (!this.checkTeamItems()) {
      return;
    }
    const subParam = {
      'infoEventId': this.infoEventId,
      'schoolId': this.bimSchoolDetail.bimSchoolId,
      'teamInfoTabs': this.cloneTeamInfoTabs
    };
    console.log('subParam=========', JSON.stringify(subParam));
    this.isSubmitTeam = true;
    this.bimSchoolsService.saveProvinceTeam(subParam).subscribe(res => {
      this.isSubmitTeam = false;
      const data = res.body;
      if (data.success) {
        this.msg.success('保存成功');
        this.teamInfo_isEdit = false;
      } else {
        const title = data.message;
        if (isNullOrUndefined(data.data)) {
          this.msg.warning(title);
        } else {
          this.warningList = data.data.hasStuList ? data.data.hasStuList : data.data;
          const content = this.modalWarning;
          this.modalService.warning({
            nzTitle: title,
            nzContent: content,
            nzWidth: '60vh'
          });
        }
      }
    });
  }

  removeFile1(teamIndex) {
    this.teamInfoTabs[teamIndex].registeFileName1 = null;
    if (!isNullOrUndefined(this.teamInfoTabs[teamIndex].registeFileId1)) {
      this.fileUploadService.delete(this.teamInfoTabs[teamIndex].registeFileId1).subscribe();
    }
  }

  removeFile2(teamIndex) {
    this.teamInfoTabs[teamIndex].registeFileName2 = null;
    if (!isNullOrUndefined(this.teamInfoTabs[teamIndex].registeFileId2)) {
      this.fileUploadService.delete(this.teamInfoTabs[teamIndex].registeFileId2).subscribe();
    }
  }
}
