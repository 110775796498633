import { Injectable } from '@angular/core';
import { ThsBaseService } from '@shared/components/thsBaseService';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class  SchoolWorkScoringService extends ThsBaseService<any>  {
  constructor(
    public http: HttpClient, httpAddressService: HttpAddressService ) {
    super(http, httpAddressService.BimServe + '/bim-works');
  }

  getBimWorksDataList(param: any) {
    return this.http.get<any[]>(`${this.resourceUrl}/getBimWorksDataList`, {params: param ,  observe: 'response'})
        .pipe(map((res: HttpResponse<any[]>) => this.convertDateArrayFromServer(res)));
  }

  getStudentWorkDataList(param: any) {
    return this.http.get<any[]>(`${this.resourceUrl}/getStudentWorkDataList`, {params: param ,  observe: 'response'})
        .pipe(map((res: HttpResponse<any[]>) => this.convertDateArrayFromServer(res)));
  }

  getBimWorksCountryDataList(param: any) {
    return this.http.get<any[]>(`${this.resourceUrl}/getBimWorksCountryDataList`, {params: param ,  observe: 'response'})
        .pipe(map((res: HttpResponse<any[]>) => this.convertDateArrayFromServer(res)));
  }

  getBimWorksReplyDataList(param: any) {
    return this.http.get<any[]>(`${this.resourceUrl}/getBimWorksReplyDataList`, {params: param ,  observe: 'response'})
        .pipe(map((res: HttpResponse<any[]>) => this.convertDateArrayFromServer(res)));
  }
}
