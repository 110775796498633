import { Component, Inject, OnInit } from '@angular/core';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { ModalHelper } from '@delon/theme';
import { UserLoginComponent } from 'app/routes/passport/login/login.component';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { isNullOrUndefined } from 'util';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CacheService } from '@delon/cache';

import { BimPersonService } from '../bim-person.service';
import * as moment from 'moment';

@Component({
  selector: 'app-personal-index',
  templateUrl: './personal-index.component.html',
  styleUrls: ['../../team/team-index/team-index.component.less'],
})
export class PersonalIndexComponent implements OnInit {


  public id = this.cacheService.get('personBimEventId')['value'];
  navList: any = [
    { name: '个人赛报名', link: `/enroll/personal/signUp/${this.id}`, isNeedLogin: true, isdisable: true, isReadOnly: false, },
    { name: '我的个人赛', link: `/enroll/personal/myEntry/${this.id}`, isNeedLogin: true, isdisable: true, isReadOnly: false, },
    { name: '个人赛报名', link: `/enroll/personal/provinceSignUp/${this.id}`, isNeedLogin: true, isdisable: false, isReadOnly: false, },
    { name: '我的个人赛', link: `/enroll/personal/provinceMyEntry/${this.id}`, isNeedLogin: true, isdisable: false, isReadOnly: false, },
  ];
  
  public personNavIndex = [0, 1];
  public proinceNavIndex = [2, 3];

  currentNav: any = null;
  // 是否登录
  isLogin = false;
  isInitPage = false;

  constructor(
    private router: Router,
    private msg: NzMessageService,
    private modalHelper: ModalHelper,
    private modalService: NzModalService,
    private cacheService: CacheService,
    private bimPersonService: BimPersonService,
    private routerInfo: ActivatedRoute,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  ) {

    let bimInfo = this.cacheService.get('bimInfo')['value'];
    const token = this.tokenService.get().token;
    let userType = this.tokenService.get().entity_type;
    const mobile = this.tokenService.get().mobile;

    this.currentNav = this.navList[bimInfo.type === 'province' ? 2 : 0];
    router.events.subscribe(evt => {

      if (evt instanceof NavigationEnd) {
        const id = this.cacheService.get('personBimEventId')['value'];
        this.navList.forEach(item => {
          item.link = item.link.replace(this.id, id);
          if (evt.url === item.link) {
            this.currentNav = item;
          }
        });
        
        this.personNavIndex.forEach(e => {
          this.navList[e].isdisable = bimInfo.type !== 'province';
        });
        this.proinceNavIndex.forEach(e => {
          this.navList[e].isdisable = bimInfo.type === 'province';
        });

        userType = this.tokenService.get().entity_type;
        if ((userType === '0' || userType === '2') && (!this.isInitPage || this.id !== id)) {
          // 学生
          this.isInitPage = true;
          // 查询学生是否加入团队
          this.bimPersonService.query({
            'personTel.equals': mobile,
            'bimInfoEventsId.equals': id,
            'isDelete.equals': 0
          }).subscribe(res => {
            if (res && res.body.length > 0) {
              const data = res.body[0];
              this.cacheService.set('personId', data.id);
              this.navList[bimInfo.type === 'province' ? 2 : 0].isReadOnly = true;
              this.navList[bimInfo.type === 'province' ? 3 : 1].isReadOnly = false;
              // this.router.navigate(['/enroll/personal/signUp' + `/${id}`]);
            } else {
              this.navList[bimInfo.type === 'province' ? 2 : 0].isReadOnly = false;
              this.navList[bimInfo.type === 'province' ? 3 : 1].isReadOnly = true;
              //  this.router.navigate(['/enroll/personal/myEntry' + `/${id}`]);
            }
          });
        }

        this.id = id;
      }



    });

    if (token && token !== null && token !== '') {
      this.isLogin = true;
    }

  }

  ngOnInit() {
  }

  clickNav(item) {
    if (this.isLogin || !item.isNeedLogin) {
      this.routerNav(item);
    } else {
      this._loginUser(item);
    }
  }

  routerNav(item) {
    // let bimEventIdTmp =this.cacheService.get("personBimEventId")["value"];
    // this.currentNav = item;
    // this.router.navigate([item.link + `/${bimEventIdTmp}`]);
    this.router.navigate([item.link]);
  }

  /**
   * 登陆
   */
  _loginUser(item) {
    // 登录成功就直接跳转到立刻学习页面进行学习
    this.modalService.confirm({
      nzTitle: '确认确定登录?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.modalHelper.static(UserLoginComponent, {}, 600).subscribe((data: any) => {
          if (data) {
            this.msg.info('登陆成功');
            this.isLogin = true;
            this.routerNav(item);
          }
        });
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }
}
