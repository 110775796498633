import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { NzMessageService, NzModalRef } from 'ng-zorro-antd';
import { ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';

import {
  SimpleTableComponent,
  SimpleTableData,
  SimpleTableColumn,
} from '@delon/abc';

import { APP_PERMISSIONS } from '@shared/app.permissions';
import { HomeService } from 'app/routes/enroll/home/home.service';
import { BimSchools } from 'app/routes/enroll/school/bim-schools.model';
import { BimSchoolsService } from 'app/routes/enroll/school/bim-schools.service';

@Component({
  selector: 'app-org-list',
  templateUrl: './org-list.component.html',
  styleUrls: ['./org-list.component.less'],
})
export class OrgListComponent implements OnInit {
  public page = 0;
  public size = 10;
  public total;
  public APP_PERMISSIONS = APP_PERMISSIONS;
  public list: BimSchools[] = [];
  public totalCallNo = 0;
  public selectedRows: SimpleTableData[] = [];
  locationList = [];
  provinceList = [];

  userLocationList = [];
  userProvinceList = [];

  entityId: string;
  entityName: String;
  accountName: String;

  public qParam = null;

  // HTML模板里面<simple-table>的选择器必须为st
  @ViewChild('st') st: SimpleTableComponent;

  queryParams = {
    // 查询搜索框的字段要预置
    'name.contains': '',
    // sort固定
    sort: ['createTime,desc'],
  };

  columns: SimpleTableColumn[] = [
    { title: '', index: 'id', type: 'checkbox' },
    { title: '单位名称', index: 'name', width: '80%' },
  ];

  constructor(
    public msg: NzMessageService,
    private nzModalRef: NzModalRef,
    public bimSchoolsService: BimSchoolsService,
    private homeService: HomeService,
    @Inject(DA_SERVICE_TOKEN) tokenService: ITokenService,
  ) {
    this.entityId = tokenService.get().entity_id;
    this.entityName = tokenService.get().entity_name;
    this.accountName = tokenService.get().account_name;
  }

  ngOnInit() {
    this.getDataList();
  }

  /**
   * 获取数据列表
   * @param {string} url
   */
  public getDataList(isReset?: boolean) {
    debugger;
    let copyParams = {};
    const q = this.queryParams;
    if (isReset === true) {
      this.st.reset();
      this.page = 0;
      Object.keys(q).forEach(function(key) {
        q[key] = '';
      });
    } else {
      Object.keys(q).forEach(function(key) {
        if (q[key] !== '' && q[key] !== null) {
          copyParams[key] = q[key];
        }
      });
    }
    if (this.qParam !== null) {
      for (const key in this.qParam) {
        copyParams[key] = this.qParam[key];
      }
    }

    copyParams['page'] = this.page;
    copyParams['size'] = this.size;

    copyParams['isDelete.equals'] = '0';
    this.homeService.getBimSchool(copyParams).subscribe((res: any) => {
      this.list = res.body;
      this.total = res.headers.get('X-Total-Count');
    });
  }

  /**
   * 页码数量变动事件
   * @param
   */
  paginationChange(event) {
    console.log(event);
    this.page = event.pi - 1;
    this.size = event.ps;
    this.getDataList();
  }

  /**
   * 过滤器变动事件 支持多选过滤
   * @param
   */
  filterChange(event) {
    let i = 0;
    const _value = [];
    const _type = event.filterMultiple ? 'in' : 'equals';
    event.filters.forEach(element => {
      if (element.checked) {
        _value[i++] = element.value;
      }
    });
    this.queryParams[event.indexKey + '.' + _type] = _value;
    this.getDataList();
  }

  /**
   * 排序变动事件
   * @param
   */
  sortChange(event) {
    const array = this.queryParams['sort'];
    const length = array.length;
    let isInArray = false;
    let value = null;
    if (event.value === 'descend') {
      value = 'desc';
    } else if (event.value === 'ascend') {
      value = 'asc';
    }
    for (let i = 0; i < length; i++) {
      if (array[i].startsWith(event.column.indexKey)) {
        if (value == null) {
          array.splice(i, 1);
          isInArray = true;
          break;
        } else {
          array[i] = event.column.indexKey + ',' + value;
          isInArray = true;
          break;
        }
      }
    }
    if (value != null && !isInArray) {
      array.push(event.column.indexKey + ',' + value);
    }
    // 排序改变时，simpleTable会重置页码
    this.page = 0;
    this.getDataList();
  }

  /**
   * 复选框事件
   * @param {SimpleTableData[]} list
   */
  checkboxChange(list: SimpleTableData[]) {
    this.selectedRows = list;
    this.totalCallNo = this.selectedRows.reduce(
      (total, cv) => total + cv.callNo,
      0,
    );
  }

  // 保存
  submit() {
    this.nzModalRef.destroy(this.selectedRows);
  }

  Cancel() {
    this.nzModalRef.destroy(null);
  }
}
