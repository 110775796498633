import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpResponse } from '@angular/common/http';
import { BimPersonTearchers } from './bim-person-teacher.model';
import {ThsBaseService } from  '@shared/components/thsBaseService';
import { HttpAddressService } from '@shared/session/http-address.service';
import { Observable, of } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class BimPersonTearchersService extends ThsBaseService<BimPersonTearchers> {
    constructor(http: HttpClient, httpAddressService: HttpAddressService) {
        super(http, httpAddressService.BimServe + `/bim-person-tearchers`);
        console.log(this.resourceUrl);
    }


    saveTeacherByPerson(params: any): Observable<HttpResponse<any>> {
        let url = this.resourceUrl + '/saveTeacherByPerson';
        return this.http.post<any>(url, params, {observe: 'response'});
    }

}

