import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OrgIndexComponent } from './org-index/org-index.component';
import { SchoolRegisterComponent } from './org-register/school-register.component';
import { OrgInformationManageComponent } from './org-information-manage/school-information-manage.component';

const routes: Routes = [
  {
    path: 'org',
    component: OrgIndexComponent,
    children: [
      {
        path: 'orgRegister/:bimEventId',
        component: SchoolRegisterComponent,
        data: { title: '单位注册' },
      },
      {
        path: 'orgInformationManage/:bimEventId',
        component: OrgInformationManageComponent,
        data: { title: '单位信息管理' },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OrgRoutingModule { }
