
import { Component, Injectable, Injector, Inject, OnInit } from '@angular/core';
import { BimStore } from '@shared/store/bim.store';import { Subscription } from 'rxjs';
import { HomeService } from 'app/routes/enroll/home/home.service';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'layout-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less']
})
export class FooterComponent implements OnInit {

  isShowBim = false;
  bimInfoSubscription: Subscription;
  bimInfoContactList = [];//联系人
  bimInfoLinkList = [];//相关链接
  bimInfoLinkKeys = new Map<any,any[]>();

  constructor(
    private bimStore:BimStore,
    private bimHomeService: HomeService
  ) {

  }

  ngOnInit() {
    this.bimInfoSubscription = this.bimStore.bimInfoStore.subscribe(data => {
      if(data == null || data.id == null){
        this.isShowBim = false;
      }else{
        this.getBimContactInfo(data.id);
      }
    })
    
  }

  isNotEmpty(val){
    return !isNullOrUndefined(val) && val.trim().length > 0;
  }

  /**
   * 获取bim赛事联系方式（联系人和相关链接）
   */
  getBimContactInfo(bimId){
    this.isShowBim = false;
    this.bimInfoLinkKeys = new Map();
    if(!bimId){
      return;
    }
    const params = {
      'bimInfoId.equals': bimId,
      'type.in': ['5','6'],//联系人和相关链接
      'sort': ['orderNum,asc'],
      size: 1000
    }

    this.bimHomeService.getBimInfoContactData(params).subscribe(res => {
      const data = res.body;
      if(data && data.length > 0){
        const bimInfoContacts = data.filter(w=>w.type === '5');
        this.bimInfoLinkList = data.filter(w=>w.type === '6');
        this.bimInfoContactList = bimInfoContacts.length > 2 ? bimInfoContacts.slice(0,2) : bimInfoContacts;
        if(this.bimInfoLinkList.length > 6){
          this.bimInfoLinkList = this.bimInfoLinkList.slice(0,6);
        }
        
        if(this.bimInfoLinkList && this.bimInfoLinkList.length > 0){
          let index = 0;
          let datas = [];
          let rows = 0;
          const linkMap = new Map();
          this.bimInfoLinkList.forEach((item,index) => {
            if(index % 3 === 0){
              linkMap.set(rows,datas);
              datas = [];
              rows++;
            }
            index++;
            datas.push(item);
          })
          if(!linkMap.get(rows)){
            linkMap.set(rows,datas);
          }
         
          this.bimInfoLinkKeys = linkMap;
          this.isShowBim = true;
        }
        if(!this.isShowBim && this.bimInfoContactList && this.bimInfoContactList.length > 0){
          this.isShowBim = true;
        }
      }
      
    })
  }

}
