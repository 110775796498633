import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { CacheService } from '@delon/cache';
import { GuidUtil } from '@shared/utils/guid.util';
import { NumberUtil } from '@shared/utils/numberUtil.util';
import { UserService } from 'app/routes/admin/user.service';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { isNullOrUndefined } from 'util';
import { BimInfoSchoolsService } from '../school-information-manage/bim-info-schools.service';
import { BimSchoolsService } from '../school-register/bim-school.service';

@Component({
  selector: 'app-province-school-register',
  templateUrl: './province-school-register.component.html',
  styles: []
})
export class ProvinceSchoolRegisterComponent implements OnInit {

  // 是否登录
  isLogin = false;
  title = '学校信息注册';

  userLocationList = [];
  userProvinceList = [];
  public locationList = [];
  public provinceList = [];
  public schoolTypeList = { '0': '本科', '1': '专科', '2': '中职' };
  form: FormGroup;
  recSchoolList: Array<{
    id: string,
    name: null,
    zw: null,
    mobile: null,
    profession: null,
    email: null,
  }> = [];
  freqContactList: Array<{
    id: string,
    name: string,
    zw: string,
    mobile: string,
    profession: null,
    email: string,
  }> = [];

  @ViewChild('modalWarning')
  modalWarning: TemplateRef<any>;
  public warningList = [];
  public submitting = false;

  public isEdit = false;
  public bimSchoolId = null;
  public bimSchool = null;
  public infoEventId = null;
  public bimInfoId = null;
  public bimInfo = this.cacheService.get('bimInfo')['value'];

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private msg: NzMessageService,
    private userService: UserService,
    private routeInfo: ActivatedRoute,
    private cacheService: CacheService,
    private modalService: NzModalService,
    private bimSchoolsService: BimSchoolsService,
    private bimInfoSchoolsService: BimInfoSchoolsService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  ) {
    this.form = this.fb.group({
      id: [null, []],
      name: [null, [Validators.required, Validators.maxLength(100)]],
      schoolType: [null, [Validators.required]],
      college: [null, [Validators.required, Validators.maxLength(100)]],
      address: [null, [Validators.required, Validators.maxLength(100)]],
    });
    const token = this.tokenService.get().token;
    if (token && token !== null && token !== '') {
      this.isLogin = true;
    }
    this.isEdit = this.routeInfo.snapshot.queryParams['isEdit'];
    this.bimSchoolId = this.tokenService.get().bimProvinceSchoolId;
    this.infoEventId = this.routeInfo.snapshot.params['bimEventId'];
  }

  ngOnInit() {
    if (isNullOrUndefined(this.bimInfo)) {
      this.router.navigate(['/enroll/contestSelect']);
      return;
    }
    this.bimInfoId = this.bimInfo.id;

    if (this.isEdit) {
      this.title = '学校信息修改';
      this.initSchoolData();
    } else {
      if (!isNullOrUndefined(this.bimSchoolId)) {
        this.router.navigate(['/enroll/team/provinceSchoolInformationManage' + `/${this.infoEventId}`]);
        return;
      }
    }
  }

  submit() {
    const subParam = {};
    // tslint:disable-next-line:forin
    for (const key in this.form.controls) {
      this.form.controls[ key ].markAsDirty();
      this.form.controls[ key ].updateValueAndValidity();
      subParam[key] =  this.form.controls[key].value;
    }
    if (!this.checkData()) {
      return;
    }
    if (!this.isEdit) {
      const entityId = this.tokenService.get().entity_id;
      this.submitting = true;
      this.bimSchoolsService.getBimSchoolByCreatorAndType({
        'entityId': entityId,
        'schoolType': 'province',
      }).subscribe(res => {
        this.submitting = false;
        if (res && res.body.length > 0) {
          this.msg.warning('登录用户已有注册学校，请重新登录');
          return;
        } else {
          this.saveData(subParam);
        }
      });
    } else {
      this.saveData(subParam);
    }
  }

  saveData(subParam) {
    this.submitting = true;
    subParam['userDefine1'] = this.bimInfo.id;
    subParam['userDefine4'] = 'province';
    subParam['audit'] = 0;
    subParam['isDelete'] = '0';
    if (this.isEdit) {
      this.bimSchoolsService.update(subParam).subscribe(res => {
        this.submitting = false;
        const data = res.body;
        if (data) {
          this.msg.success('修改成功');
          this.router.navigate(['/enroll/team/provinceSchoolInformationManage' + `/${this.infoEventId}`]);
        }
      }, () => this.submitting = false);
    } else {
      this.bimSchoolsService.query({
        'name.equals': subParam['name'],
        'isDelte.equals': '0'
      }).subscribe(res => {
        if (res && res.body.length > 0) {
          this.submitting = false;
          this.msg.remove();
          this.msg.warning('学校名称已经被注册，不能重复注册，请进行修改');
        } else {
          this.bimSchoolsService.create(subParam).subscribe(res => {
            this.submitting = false;
            const data = res.body;
            if (data) {
              this.msg.success('注册成功');
              this.tokenService.set({
                ...this.tokenService.get(),
                bimProvinceSchoolId: data.id
              });
              // 自动确认参赛
              this.confirmJoin(data.id);
              window.location.reload();
            }
          }, () => this.submitting = false);
        }
      })
    }
  }

  cancel() {
    this.router.navigate(['/enroll/team/provinceSchoolInformationManage' + `/${this.infoEventId}`]);
  }

  confirmJoin(bimSchoolId) {
    this.bimInfoSchoolsService.create({
      'bimInfoId': this.bimInfoId,
      'schoolId': bimSchoolId
    }).subscribe(res => {
      this.msg.success('参加成功');
    });
  }

  checkData() {
    const token = this.tokenService.get().token;
    if (isNullOrUndefined(token)) {
      this.onError('登录失效，请重新登录');
      return false;
    }
    if (!this.form.valid) {
      this.onError('请填写带 * 的信息');
      return false;
    }
    const name = this.form.controls['name'].value;
    if (this.isHasSpecialChar(name)) {
      this.onError('学校名称不可有空格或特殊字符');
      return false;
    }

    const college = this.form.controls['college'].value;
    if (this.isHasSpecialChar(college)) {
      this.onError('学校院系不可有空格或特殊字符');
      return false;
    }
    const address = this.form.controls['address'].value;
    if (this.isHasSpecialChar(address)) {
      this.onError('学校通讯地址不可有空格或特殊字符');
      return false;
    }
    return true;
  }

  isHasSpecialChar(str) {
    var pattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]");
    if(str.indexOf(' ') > -1 || pattern.test(str)){
      return true;
    } else {
      return false;
    }
  }

  onError(msg: string) {
    this.msg.remove();
    this.msg.error(msg);
  }

  initSchoolData() {
    if (isNullOrUndefined(this.bimSchoolId)) {
      this.msg.info('无此学校信息，请重新登录');
    } else {
      this.submitting = true;
      this.bimSchoolsService.getBimSchoolById(this.bimSchoolId).subscribe(res => {
        this.bimSchool = res;
        this.form.patchValue(this.bimSchool);
        this.recSchoolList = res.recSchoolList;
        this.freqContactList = res.freqContactList;
        this.submitting = false;
      });
    }
  }

}
