import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService, NzModalRef, NzModalService } from 'ng-zorro-antd';
import { CacheService } from '@delon/cache';

import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';

import { _HttpClient, ModalHelper } from '@delon/theme';
import { BimPerson } from 'app/routes/enroll/personal/bim-person.model';
import { BimInfoSchoolsEditComponent } from 'app/routes/enroll/school/bim-info-schools/edit/edit.component';
import { BimPersonService } from 'app/routes/enroll/personal/bim-person.service';
import { isNullOrUndefined } from 'util';
import { BimSchoolsService } from 'app/routes/enroll/team/school-register/bim-school.service';
import { OrgListComponent } from '../../org-list/org-list.component';

@Component({
  selector: 'app-compet-person-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.less'],
})
export class CompetPersonEditComponent implements OnInit {
  personId: string;
  bimEventId: string;
  schoolId: string;
  submitting = false;
  schoolTypeList = [];
  record = new BimPerson();
  title = '报名信息修改';
  buttonIsVisible = true;
  userEmail: string;
  entityName: string;
  entityId: string;
  entityMobile: string;

  ItemTypes = [];
  Items = [];
  personItems = [];
  form: FormGroup;

  public bimInfo = this.cacheService.get('bimInfo')['value'];

  constructor(
    private fb: FormBuilder,
    private modal: ModalHelper,
    private msg: NzMessageService,
    private nzModalRef: NzModalRef,
    private cacheService: CacheService,
    private bimPersonService: BimPersonService,
    private modalService: NzModalService,
    private bimSchoolsService: BimSchoolsService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  ) {
    const mobile = this.tokenService.get().mobile;
    this.entityName = tokenService.get().entity_name;
    this.entityId = tokenService.get().entity_id;
    this.entityMobile = mobile;
    this.userEmail = tokenService.get().email;
  }

  ngOnInit() {
    this.form = this.fb.group({
      id: [null, []],
      personName: [this.entityName, [Validators.required, Validators.maxLength(100)]],
      personSex: [null, [Validators.required]],
      // professionYear: [null, [Validators.required, Validators.maxLength(100)]],
      idCard: [null, [Validators.required, Validators.pattern(/^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/)]],
      personTel: [
        this.entityMobile,
        [Validators.required, Validators.pattern(/^1\d{10}$/)],
      ],
      personEmail: [this.userEmail, [Validators.required, Validators.email]],
      registLevel: [null, [Validators.required]],
      registNum: [null, [Validators.required, Validators.pattern(/^[0-9]\d{13}$/)]],
      contactAddress: [null, [Validators.required, Validators.maxLength(50)]],
      schoolName: [null, [Validators.required, Validators.maxLength(100)]],
      isDelete: [0, []],
    });
    this.getData();
  }

  getData() {
    this.bimPersonService
      .queryNewAnd({
        'id.equals': this.personId,
      })
      .subscribe(res => {
        if (res && res.body.length > 0) {
          this.record = res.body[0];
          this.schoolId = this.record.schoolId;
          this.form.patchValue(this.record);
          this.form.get('personTel').disable();
          // this.form.get('idCard').disable();
        }
      });
  }

  //wxc 添加
  submit() {
    this.buttonIsVisible = false;
    const param = {};
    // tslint:disable-next-line: forin
    for (const key in this.form.controls) {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
      if (this.form.controls[key].value === true) {
        param[key] = '1';
      } else if (this.form.controls[key].value === false) {
        param[key] = '0';
      } else {
        param[key] = this.form.controls[key].value;
      }
    }
    param['schoolId'] = this.schoolId;
    param['bimInfoEventsId'] = this.bimEventId;
    param['userDefine1'] = '-1'; // 行业赛

    if (this.form.invalid) {
      this.msg.error('请检查个人信息是否完整');
      this.buttonIsVisible = true;
      return;
    }

    if (this.isMatchName(param['personName'])) {
      this.msg.error('选手姓名不可包含字母、数字、符号');
      this.buttonIsVisible = true;
      return;
    }
    this.bimPersonService.getAllWebBimPeople({
      'bimInfoEventsId.equals': this.bimEventId,
      // 'idCard.equals': param['idCard'],
      'personTel.equals': param['personTel'],
      'userDefine1.equals': param['userDefine1'],
      'isDelete.equals': 0,
    }).subscribe(dres => {
      if (dres && dres.body) {
        if (dres.body.length > 0) {
          const data = dres.body[0];
          if (data.id !== this.personId) {
            this.modalService.warning({
              nzTitle: '该手机号已经注册，请更换其他手机号',
              nzContent: '',
              nzOkText: '确定',
              nzOkType: 'warning',
            });
            this.buttonIsVisible = true;
            return;
          } else {
            this.checkSchool(param);
          }
        } else {
          this.checkSchool(param);
        }
      }
    });
  }

  checkSchool(param) {
    if (isNullOrUndefined(this.schoolId)) {
      this.bimSchoolsService.createBimSchools({
        'name': param['schoolName'],
        'regCode': -1,
        'userDefine4': -1,
        'isDelete': 0
      }).subscribe(res => {
        if (res && res.body) {
          param['schoolId'] = res.body.id;
          this.saveData(param);
        }
      });
    } else {
      this.saveData(param);
    }
  }

  saveData(param) {
    this.bimPersonService.updateWebPerson(param).subscribe(res => {
      this.record = res.body;
      if (this.record) {
        this.msg.info('修改成功！');
        this.buttonIsVisible = true;
        this.nzModalRef.destroy();
      } else {
        this.msg.error('保存失败');
        this.buttonIsVisible = true;
      }
    });
  }

  isMatchMobile(mobile: string) {
    const regex = /^1\d{10}$/;
    return mobile.match(regex);
  }

  isMatchName(str: string) {
    const regex = /([a-z0-9][a-z0-9]*)/;
    return str.match(regex);
  }

  selectSchool() {
    this.modal.static(OrgListComponent, {
      qParam: {
        'userDefine4.equals': '-1'
      }
    }).subscribe(res => {
      if (res != null && res.length > 1) {
        this.msg.error('只能选择一个单位，请重新选择！');
        return false;
      } else if (res != null && res.length === 1) {
        this.schoolId = res[0].id;
        this.record.schoolName = res[0].name;
        this.form.patchValue(this.record);
        this.form.get('schoolName').disable();
      }
    });
  }
}
