import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpAddressService } from '@shared/session/http-address.service';
import { HomeService } from 'app/routes/home/home.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.less'],
})
export class LayoutComponent implements OnInit {
  navList: any = [
    { name: '首页' },
    { name: '在线教育' },
    { name: 'BIM大赛' },
    { name: 'BIM认证' },
    // { name: 'BIM资源' },
    { name: '软件下载' },
  ];
  currentNav: any = { name: '首页' };
  subNavList: any = [
    { code: 'sub-home', name: '首页', path: '/enroll' },
    {
      code: 'team-entry',
      name: '团队赛入口',
      path: '/enroll/team/contestTaskBook',
    },
    { code: 'personal-entry', name: '个人赛入口', path: '' },
    { code: 'learning', name: '学习课堂', path: '' },
    { code: 'regulation', name: '制度文件', path: '/enroll/regulation' },
    { code: 'soft-download', name: '软件下载', path: '/enroll/download' },
    { code: 'contestStyle', name: '比赛风采', path: '/enroll/contestStyle' },
    { code: 'work-show', name: '作品展示', path: '' },
    // { code: 'edu-mall', name: '教育商城', path: '' },
  ];
  currentSubNav: any = { code: 'sub-home', name: '首页', path: '/enroll' };
  thirdNavList: any = [];
  currentThirdNav: any = { name: '', path: '' };
  showThirdNav = false;

  pamms = {};
  loading = true;
  bannerList: any[];
  imagePrefix = '';

  constructor(
    private router: Router,
    private homeService: HomeService,
    private httpAddressService: HttpAddressService,
  ) {
    this.imagePrefix =
      this.httpAddressService.apiGateway + '/thsadmin/api/sys-files/download/';
  }

  ngOnInit() {
    this.getBanner();
  }

  // 获取banner图片
  getBanner() {
    if (this.router.url.indexOf('certification') !== -1) {
      // 认证
      this.pamms['type.equals'] = 2;
    } else {
      // 首页
      this.pamms['type.equals'] = 1;
    }
    this.pamms['isOk.equals'] = 1;
    this.pamms['sort'] = ['createdDate,asc'];
    this.pamms['size'] = 5;
    this.homeService.getbanners(this.pamms).subscribe(res => {
      this.loading = false;
      this.bannerList = res.body;
    });
  }

  subOnClick(value) {
    this.currentSubNav = value;
    if (value.code === 'sub-home') {
      this.router.navigate(['/enroll']);
    }

    // if (value.code === 'team-entry') {
    //   this.showThirdNav = true;
    //   this.thirdNavList = [
    //     { name: '学校注册' },
    //     { name: '学校信息管理' },
    //     { name: '校内作品评分' },
    //     { name: '团队赛报名' },
    //     { name: '团队信息管理' },
    //     { name: '作品上传', path: '/enroll/work-upload' },
    //     { name: '回执打印上传' },
    //     { name: '全国评委评分' },
    //   ];
    //   this.currentThirdNav = this.thirdNavList[0];
    // }
    this.router.navigate([value.path]);
  }

  thirdOnClick(value) {
    this.currentThirdNav = value;
    this.router.navigate([value.path]);
  }
}
