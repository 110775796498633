import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { CacheService } from '@delon/cache';
import { GuidUtil } from '@shared/utils/guid.util';
import { NumberUtil } from '@shared/utils/numberUtil.util';
import { UserService } from 'app/routes/admin/user.service';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { isNullOrUndefined } from 'util';
import { BimInfoSchoolsService } from '../org-information-manage/bim-info-schools.service';
import { HttpClient } from '@angular/common/http';
import { ArrayService } from '@delon/util';
import { BimSchoolsService } from 'app/routes/enroll/team/school-register/bim-school.service';

@Component({
  selector: 'app-org-register',
  templateUrl: './school-register.component.html',
  styles: []
})
export class SchoolRegisterComponent implements OnInit {

  // 是否登录
  isLogin = false;
  title = '单位注册';

  public locationList = [];
  public provinceList = [];
  public schoolTypeList = { '0': '建设单位', '1': '设计院', '2': '咨询公司', '3': '施工企业', '4': '院校', '5': '其他' };
  form: FormGroup;

  @ViewChild('modalWarning')
  modalWarning: TemplateRef<any>;
  public warningList = [];
  public submitting = false;

  public isEdit = false;
  public bimSchoolId = null;
  public bimSchool = null;
  public infoEventId = null;
  public bimInfoId = null;
  public bimInfo = this.cacheService.get('bimInfo')['value'];

  constructor(
    private router: Router,
    private fb: FormBuilder,
    // private http: HttpClient,
    private msg: NzMessageService,
    private userService: UserService,
    private routeInfo: ActivatedRoute,
    private cacheService: CacheService,
    // private arrayService: ArrayService,
    private modalService: NzModalService,
    private bimSchoolsService: BimSchoolsService,
    private bimInfoSchoolsService: BimInfoSchoolsService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  ) {
    this.form = this.fb.group({
      id: [null, []],
      name: [null, [Validators.required, Validators.maxLength(100)]],
      schoolType: [null, [Validators.required]],
      regCode: [-1, []], // 类型为单位
      userDefine4: [-1, []], // 类型为单位
      userDefine5: [null, [Validators.required, Validators.maxLength(100)]],
      address: [null, [Validators.required, Validators.maxLength(100)]],
    });
    const token = this.tokenService.get().token;
    if (token && token !== null && token !== '') {
      this.isLogin = true;
    }
    this.isEdit = this.routeInfo.snapshot.queryParams['isEdit'];
    this.bimSchoolId = this.tokenService.get().bimSchoolId;
    this.infoEventId = this.routeInfo.snapshot.params['bimEventId'];
  }

  ngOnInit() {
    if (isNullOrUndefined(this.bimInfo)) {
      this.router.navigate(['/enroll/contestSelect']);
      return;
    }
    this.bimInfoId = this.bimInfo.id;

    if (this.isEdit) {
      this.title = '学校信息修改';
      this.initSchoolData();
    } else {
      if (!isNullOrUndefined(this.bimSchoolId)) {
        this.router.navigate(['/enroll/team/schoolInformationManage' + `/${this.infoEventId}`]);
        return;
      }
    }
  }

  // loadCityData() {
  //   const provinceCity = this.cacheService.get('provinceCity')['value'];
  //   if (isNullOrUndefined(provinceCity)) {
  //     this.http.get('./assets/cities.json').subscribe((res: any) => {
  //       const data = res.cities
  //       .filter(e => e.parent_id === null || e.parent_id <= 34) // 只要二级
  //       .map(item => {
  //         return {
  //           id: item.id,
  //           value: item.name,
  //           label: item.name,
  //           parentId: item.parent_id
  //         };
  //       });
  //       const result: any[] = this.arrayService.arrToTree(data, {idMapName: 'id', parentIdMapName: 'parentId'});
  //       result.forEach(item => this.setLeafCity(item));
  //       this.nzOptions = result;
  //       this.cacheService.set('provinceCity', result);
  //     });
  //   } else {
  //     this.nzOptions = provinceCity;
  //   }
  // }

  submit() {
    const subParam = {};
    // tslint:disable-next-line:forin
    for (const key in this.form.controls) {
      this.form.controls[ key ].markAsDirty();
      this.form.controls[ key ].updateValueAndValidity();
      subParam[key] =  this.form.controls[key].value;
    }
    if (!this.checkData()) {
      return;
    }
    console.log(subParam);
    if (!this.isEdit) {
      this.submitting = true;
      this.bimSchoolsService.query({
        'userDefine4.equals': '-1',
        'userDefine5.equals': subParam['userDefine5']
      }).subscribe(res => {
        this.submitting = false;
        if (res && res.body.length > 0) {
          this.msg.warning('该单位已经注册');
          return;
        } else {
          this.saveData(subParam);
        }
      });
    } else {
      this.saveData(subParam);
    }
  }

  saveData(subParam) {
    this.submitting = true;
    this.bimSchoolsService.create(subParam).subscribe(res => {
      console.log(res);
      this.submitting = false;
      const data = res.body;
      if (data) {
        if (!this.isEdit) {
          this.msg.success('注册成功，请等待审核');
          this.tokenService.set({
            ...this.tokenService.get(),
            bimSchoolId: data.id
          });
          // 自动确认参赛
          this.confirmJoin(data.id);
          window.location.reload();
        } else {
          this.msg.success('修改成功，请等待审核');
          this.router.navigate(['/enroll/team/schoolInformationManage' + `/${this.infoEventId}`]);
        }
      }
    }, () => this.submitting = false);
  }

  confirmJoin(bimSchoolId) {
    this.bimInfoSchoolsService.create({
      'bimInfoId': this.bimInfoId,
      'schoolId': bimSchoolId
    }).subscribe(res => {
      this.msg.success('参加成功');
    });
  }

  checkData() {
    const token = this.tokenService.get().token;
    if (isNullOrUndefined(token)) {
      this.onError('登录失效，请重新登录');
      return false;
    }
    if (!this.form.valid) {
      this.onError('请填写带 * 的信息');
      return false;
    }
    return true;
  }

  checkPersonData(msg, list) {
    for (let i = 0; i < list.length; i++) {
      const data = list[i];
      if (isNullOrUndefined(data.name) || isNullOrUndefined(data.mobile) ||
          isNullOrUndefined(data.email) || isNullOrUndefined(data.zw) || isNullOrUndefined(data.profession)) {
        this.onError(msg + '信息未填写完整');
        return false;
      }
      if (!NumberUtil.isMatchMobile(data.mobile)) {
        this.onError(msg + '【' + data.name + '】手机号格式错误');
        return false;
      }
      if (!NumberUtil.isMatchEmail(data.email)) {
        this.onError(msg + '【' + data.name + '】邮箱格式错误');
        return false;
      }
    }
    return true;
  }

  onError(msg: string) {
    this.msg.remove();
    this.msg.error(msg);
  }

  initSchoolData() {
    if (isNullOrUndefined(this.bimSchoolId)) {
      this.msg.info('无此学校信息，请重新登录');
    } else {
      this.submitting = true;
      // this.bimSchoolsService.getBimSchoolById(this.bimSchoolId).subscribe(res => {
      //   this.bimSchool = res;
      //   this.form.patchValue(this.bimSchool);
      //   this.submitting = false;
      // });
    }
  }

}
