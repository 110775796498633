export class PreviewImgVideo {
    public id?: any;
    public fileName?: string;
    public fileDesc?: string;
    public fileUrl?: string;
    public fileType?: string;
    public coverUrl?: string;//视频封面图片路径
    public remark?: string;

    constructor(
         id?: any,
         fileName?: string,
         fileDesc?: string,
         fileUrl?: string,
         fileType?: string,
         coverUrl?:string,
         remark?: string
    ) {
        this.id = id ? id : null;
        this.fileName = fileName ? fileName : null;
        this.fileDesc = fileDesc ? fileDesc : null;
        this.fileUrl = fileUrl ? fileUrl : null;
        this.fileType = fileType ? fileType : null;
        this.coverUrl = coverUrl ? coverUrl : null;
        this.remark = remark ? remark : null;
    }
}
