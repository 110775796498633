import { Component, OnInit } from '@angular/core';
import {BimWorksService} from "../../team/work-upload/bim-works.service";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpAddressService} from "@shared/session/http-address.service";
import {BimWorks} from "../../team/work-upload/bim-works.model";
import {ThsadminFileService} from "@shared/components/f-upload/thsadmin-file.service";
import * as OSS from 'ali-oss';

@Component({
  selector: 'app-works-shows-detail',
  templateUrl: './works-shows-detail.component.html',
  styleUrls: ['./works-shows-detail.component.less']
})
export class WorksShowsDetailComponent implements OnInit {

  imgIndex: number;
  titleDetailList = ['作品资料', '图片展示', '视频展示'];
  titleDetailItem = 1;

  imgList = [
    { src: 'https://edu.thsware.com/UploadFiles/works/q2/62745207801671.jpg' },
    { src: 'https://edu.thsware.com/UploadFiles/works/q2/62745209319044.jpg' },
  ];

  imagePrefix = '';
  // 视频播放前缀
  videoPrefix: string;
  workId = '';
  workInfo: BimWorks = new BimWorks();
  modelFileList = [];
  videoFileList = [];
  pptFileList = [];
  wordFileList = [];
  imageList = [];
  isInit = false;
  myPlayer: any;

  schoolLogo: any = {url: ''};
  public ossClient;

  constructor(
    private bimWorksService: BimWorksService,
    private activatedRoute: ActivatedRoute,
    private httpAddressService: HttpAddressService,
    private thsadminFileService: ThsadminFileService,
  ) {
    this.imagePrefix = this.httpAddressService.apiGateway + '/thsadmin/api/sys-files/download/';
    this.videoPrefix = this.httpAddressService.fileGateway + this.httpAddressService.FileServer + '/sys-files/workFileView/';
    this.ossClient = new OSS(this.httpAddressService.ossServe);
  }

  ngOnInit() {
    this.imgIndex = 0;

    this.activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams) {
        this.workId = queryParams.workId;
        this.getWorkDetails();
        this.getFiles();
      }
    });
  }

  changeImg(i) {
    this.imgIndex = i;
  }
  changeTitleDetail(i) {
    this.titleDetailItem = i;
    if (i === 2 && this.videoFileList && this.videoFileList.length > 0) {
      this.initVideo();
    }
  }

  getWorkDetails() {
    this.bimWorksService.findWorkDetails({id: this.workId}).subscribe(res => {
      if (res && res.body) {
        this.workInfo = res.body;
        this.getSchoolLogo();
      }
    })
  }

  getFiles() {
    const params = {
      'fileFk.equals': this.workId,
      sort: ['createdDate,asc']
    };
    this.thsadminFileService.getSysFile(params).subscribe(res => {
      if (res && res.body && res.body.length > 0) {
        this.modelFileList = [];
        this.videoFileList = [];
        this.pptFileList = [];
        this.wordFileList = [];
        this.imageList = [];
        res.body.forEach(item => {
          let downUrl;

          if (item.extField2 === 'AliOss') {
            if (item.extField4 === '1') {
              downUrl = this.ossClient.signatureUrl(item.fileSavePath);
              this.initWorkFile(item, downUrl);
            }
          } else {
            downUrl = this.httpAddressService.apiGateway + '/thsadmin/api/sys-files/download/' + item.id;
            this.initWorkFile(item, downUrl);
          }
          // let file = {
          //   uid: item.id,
          //   name: item.fileName,
          //   size: item.fileSize,
          //   url: this.httpAddressService.apiGateway + '/thsadmin/api/sys-files/download/' + item.id,
          //   path: item.fileSavePath
          // };
          // if (item.extField1 === 'team_works_model') {
          //   this.modelFileList.push(file);
          // } else if (item.extField1 === 'team_works_video') {
          //   file.url = this.videoPrefix + item.id;
          //   this.videoFileList.push(file);
          // } else if (item.extField1 === 'team_works_ppt') {
          //   this.pptFileList.push(file);
          // } else if (item.extField1 === 'team_works_word') {
          //   this.wordFileList.push(file);
          // } else if (item.extField1 === 'team_works_image') {
          //   file.url = this.imagePrefix + item.id;
          //   this.imageList.push(file);
          // }
        });
        console.log('imageList:', this.imageList);
      }
    });
  }

  initWorkFile(item, downUrl) {
    const file = {
      uid: item.id,
      name: item.fileName,
      size: item.fileSize,
      url: downUrl,
      path: item.fileSavePath,
      extField2: item.extField2
    };
    if (item.extField1 === 'team_works_model') {
      if ((item.extField2 === null) || item.extField2 === 'AliOss' && item.extField4 === '1') {
        this.modelFileList.push(file);
      }
    } else if (item.extField1 === 'team_works_video') {
      if ((item.extField2 === null) || item.extField2 === 'AliOss' && item.extField4 === '1') {
        this.videoFileList.push(file);
      }
    } else if (item.extField1 === 'team_works_ppt') {
      this.pptFileList.push(file);
    } else if (item.extField1 === 'team_works_word') {
      this.wordFileList.push(file);
    } else if (item.extField1 === 'team_works_image') {
      this.imageList.push(file);
    }
  }

  initVideo() {
    if (this.isInit) {
      return;
    }
    this.isInit = true;
    const __this = this;
    videojs.addLanguage('zh-CN', {
      'Play': '播放',
      'Pause': '暂停',
      'Current Time': '当前时间',
      'Duration': '时长',
      'Remaining Time': '剩余时间',
      'Stream Type': '媒体流类型',
      'LIVE': '直播',
      'Loaded': '加载完毕',
      'Progress': '进度',
      'Fullscreen': '全屏',
      'Non-Fullscreen': '退出全屏',
      'Mute': '静音',
      'Unmute': '取消静音',
      'Playback Rate': '播放速度',
      'Subtitles': '字幕',
      'subtitles off': '关闭字幕',
      'Captions': '内嵌字幕',
      'captions off': '关闭内嵌字幕',
      'Chapters': '节目段落',
      'Close Modal Dialog': '关闭弹窗',
      'Descriptions': '描述',
      'descriptions off': '关闭描述',
      'Audio Track': '音轨',
      'You aborted the media playback': '视频播放被终止',
      'A network error caused the media download to fail part-way.': '网络错误导致视频下载中途失败。',
      // tslint:disable-next-line: max-line-length
      'The media could not be loaded, either because the server or network failed or because the format is not supported.': '视频因格式不支持或者服务器或网络的问题无法加载。',
      // tslint:disable-next-line: max-line-length
      'The media playback was aborted due to a corruption problem or because the media used features your browser did not support.': '由于视频文件损坏或是该视频使用了你的浏览器不支持的功能，播放终止。',
      'No compatible source was found for this media.': '无法找到此视频兼容的源。',
      'The media is encrypted and we do not have the keys to decrypt it.': '视频已加密，无法解密。',
      'Play Video': '播放视频',
      'Close': '关闭',
      'Modal Window': '弹窗',
      'This is a modal window': '这是一个弹窗',
      'This modal can be closed by pressing the Escape key or activating the close button.': '可以按ESC按键或启用关闭按钮来关闭此弹窗。',
      ', opens captions settings dialog': ', 开启标题设置弹窗',
      ', opens subtitles settings dialog': ', 开启字幕设置弹窗',
      ', opens descriptions settings dialog': ', 开启描述设置弹窗',
      ', selected': ', 选择',
      'captions settings': '字幕设定',
      'Audio Player': '音频播放器',
      'Video Player': '视频播放器',
      'Replay': '重播',
      'Progress Bar': '进度小节',
      'Volume Level': '音量',
      'subtitles settings': '字幕设定',
      'descriptions settings': '描述设定',
      'Text': '文字',
      'White': '白',
      'Black': '黑',
      'Red': '红',
      'Green': '绿',
      'Blue': '蓝',
      'Yellow': '黄',
      'Magenta': '紫红',
      'Cyan': '青',
      'Background': '背景',
      'Window': '视窗',
      'Transparent': '透明',
      'Semi-Transparent': '半透明',
      'Opaque': '不透明',
      'Font Size': '字体尺寸',
      'Text Edge Style': '字体边缘样式',
      'None': '无',
      'Raised': '浮雕',
      'Depressed': '压低',
      'Uniform': '均匀',
      'Dropshadow': '下阴影',
      'Font Family': '字体库',
      'Proportional Sans-Serif': '比例无细体',
      'Monospace Sans-Serif': '单间隔无细体',
      'Proportional Serif': '比例细体',
      'Monospace Serif': '单间隔细体',
      'Casual': '舒适',
      'Script': '手写体',
      'Small Caps': '小型大写字体',
      'Reset': '重启',
      'restore all settings to the default values': '恢复全部设定至预设值',
      'Done': '完成',
      'Caption Settings Dialog': '字幕设定视窗',
      'Beginning of dialog window. Escape will cancel and close the window.': '开始对话视窗。离开会取消及关闭视窗',
      'End of dialog window.': '结束对话视窗'
    });
    this.myPlayer = videojs('my-video');

    videojs('my-video').ready(function() {
      const player = this;
      // player.play();
    });
  }

  getSchoolLogo() {
    const params = {
      'fileFk.equals': this.workInfo.schoolId,
      'extField1.equals': 'SCHOOL_LOGO'
    };
    this.thsadminFileService.getSysFile(params).subscribe(res => {
      if (res && res.body && res.body.length > 0) {
        const item = res.body[0];
        let file = {
          uid: item.id,
          name: item.fileName,
          size: item.fileSize,
          url: this.httpAddressService.apiGateway + '/thsadmin/api/sys-files/download/' + item.id,
          path: item.fileSavePath
        };
        this.schoolLogo = file;
      }
    })
  }
}
