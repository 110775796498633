import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MyEntryComponent } from './my-entry/my-entry.component';
import { PersonalIndexComponent } from './personal-index/personal-index.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { ProvinceMyEntryComponent } from './province/province-my-entry/province-my-entry.component';
import { ProvinceSignUpComponent } from './province/province-sign-up/province-sign-up.component';

const routes: Routes = [
  {
    path: 'personal',
    component: PersonalIndexComponent,
    children: [
      {
        path: 'signUp/:bimEventId',
        component: SignUpComponent,
        data: { title: '个人赛报名' },
      },
      {
        path: 'myEntry/:bimEventId',
        component: MyEntryComponent,
        data: { title: '我的个人赛' },
      },
      {
        path: 'provinceSignUp/:bimEventId',
        component: ProvinceSignUpComponent,
        data: { title: '省赛个人赛报名' },
      },
      {
        path: 'provinceMyEntry/:bimEventId',
        component: ProvinceMyEntryComponent,
        data: { title: '省赛我的个人赛' },
      },
    ]
  }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PersonalRoutingModule { }
