import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpResponse } from '@angular/common/http';
import { BimPerson } from './bim-person.model';
import {ThsBaseService } from  '@shared/components/thsBaseService';
import { HttpAddressService } from '@shared/session/http-address.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export class BimPersonService extends ThsBaseService<BimPerson> {

    private bimWebPersonms = '';

    constructor(http: HttpClient, httpAddressService: HttpAddressService) {
        super(http, httpAddressService.BimServe + `/bim-people`);
        this.bimWebPersonms = httpAddressService.BimWebServe + `/bim-people`;
    }
    
    createWebPerson(queryParams) {
      const copy = this.convertDateFromClient(queryParams);
      return this.http.post<BimPerson>(this.bimWebPersonms, copy, { observe: 'response'})
      .pipe(map((res: HttpResponse<BimPerson>) => this.convertDateFromServer(res)));
    }
    
    updateWebPerson(queryParams) {
      const copy = this.convertDateFromClient(queryParams);
      return this.http.put<BimPerson>(this.bimWebPersonms, copy, { observe: 'response'})
      .pipe(map((res: HttpResponse<BimPerson>) => this.convertDateFromServer(res)));
    }
    
    getAllWebBimPeople(queryParams) {
      return this.http.get<BimPerson[]>(this.bimWebPersonms, {params: queryParams ,  observe: 'response'})
      .pipe(map((res: HttpResponse<BimPerson[]>) => this.convertDateArrayFromServer(res)));
    }


        /** GET: 分页、多条件查询记录列表 */
      queryNew (queryParams ?: any): Observable<HttpResponse<BimPerson[]>> {
          let url = this.resourceUrl + "/getAllBimTeamsNew"
          return this.http.get<BimPerson[]>(url, {params: queryParams ,  observe: 'response'})
          .pipe(map((res: HttpResponse<BimPerson[]>) => this.convertDateArrayFromServer(res)));
      }

      /** GET: 分页、多条件查询记录列表 */
      queryNewAnd (queryParams ?: any): Observable<HttpResponse<BimPerson[]>> {
        let url = this.resourceUrl + "/getAllBimTeamsNewAnd"
        return this.http.get<BimPerson[]>(url, {params: queryParams ,  observe: 'response'})
        .pipe(map((res: HttpResponse<BimPerson[]>) => this.convertDateArrayFromServer(res)));
    }
      



    exportExcel(queryParams ?: any) {
        const url = `${this.resourceUrl}/exportExcel`;
    
        //需要token验证的打开下载链接的方式与不需要验证的windows.open是一样的
        this.http.get(url ,  {
          params: queryParams ,
          headers: new HttpHeaders({ 'Content-Type': 'application/vnd.ms-excel' }),
          observe: 'response',
          responseType: 'blob',
          withCredentials: true,
        }).subscribe(
          data => {
            let blob = new Blob([data.body]);
            let objectUrl = URL.createObjectURL(blob);
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display:none');
            a.setAttribute('href', objectUrl);
            a.setAttribute('download',  '个人参赛名单' + '.xlsx');
            a.click();
            URL.revokeObjectURL(objectUrl);
          },
          error => {
            this.handleError('下载失败,请稍后重试!');
            console.error('个人参赛名单下载失败 ->', error);
          },
        );
    
      }


  
      
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param ResultData - optional value to return as the observable ResultData
   */
  private handleError<T>(operation = 'operation', ResultData?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for module consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty ResultData.
      return of(ResultData as T);
    };
  }

  /** Log a ModuleService message with the MessageService */
  private log(message: string) {
    // this.messageService.info('ModuleService: ' + message);
    console.log('ResultDataService: ' + message);
  }

  checkPersonApply(queryParams ?: any): Observable<any> {
    return this.http.get<any>(this.resourceUrl + '/checkPersonApply', {params: queryParams ,  observe: 'response'});
  }
}

