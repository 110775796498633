import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd';
import { isNullOrUndefined } from 'util';
import * as moment from 'moment';
import { CacheService } from '@delon/cache';
import { Router } from '@angular/router';
import { SchoolWorkScoringService } from '../../school-work-scoring/school-work-scoring.service';

@Component({
  selector: 'app-reply-file-manage',
  templateUrl: './reply-file-manage.component.html',
  styleUrls: ['./reply-file-manage.component.less']
})
export class ReplyFileManageComponent implements OnInit {

  public teamId = this.cacheService.get('bimTeamId')['value'];
  public bimInfoEventId = null;

  public page = 0;
  public size = 10;
  public total = '0';
  schoolWorkList = [];
  public isloadding = false;

  public queryParams = {
    // 查询搜索框的字段要预置
    'name': null,
  };
  public pageSort = ['uploadDate,desc'];

  public bimInfo = this.cacheService.get('bimInfo')['value'];
  public curTeamEvent = this.cacheService.get('curTeamEvent')['value'];

  // 0:未开始 1：开始  2：结束
  public schoolWorkScoreStatus = 0;

  public isScoreDetail = false;
  public schoolWork = null;

  constructor(
    private router: Router,
    private msg: NzMessageService,
    private cacheService: CacheService,
    private schoolWorkScoringService: SchoolWorkScoringService,
  ) {
    this.schoolWorkScoreStatus = this.curTeamEvent.schoolWorkScoreStatus;
    this.bimInfoEventId = this.curTeamEvent.id;
  }

  ngOnInit(): void {
    if (isNullOrUndefined(this.bimInfo)) {
      this.router.navigate(['/enroll/contestSelect']);
      return;
    }
    this.getDataList();
  }

  getDataList(isReset?: boolean, isSearch?: boolean) {
    if (isNullOrUndefined(this.teamId)) {
      this.msg.info('无报名团队，请重新登录');
      return;
    }
    const copyParams = this.getParams(isReset, isSearch);
    this.isloadding = true;
    this.schoolWorkScoringService.getStudentWorkDataList(copyParams).subscribe(res => {
      this.isloadding = false;
      this.schoolWorkList = res.body;
      if (this.schoolWorkList.length === 0 && this.page > 0) {
        this.page --;
        this.getDataList();
      }
      this.total = res.headers.get('X-Total-Count');
    });
  }

  getParams(isReset?: boolean, isSearch?: boolean) {
    const copyParams = {};
    const q = this.queryParams;
    if (isReset === true) {
      this.page = 0;
    }
    copyParams['teamId'] = this.teamId;
    copyParams['bimInfoEventId'] = this.bimInfoEventId;
    copyParams['workScoreStatus'] = 2;
    copyParams['workType'] = 3;
    Object.keys(q).forEach(function (key) {
      if (q[key] !== '' && q[key] !== null) {
        copyParams[key] = q[key];
      }
    });
    copyParams['page'] = this.page;
    copyParams['size'] = this.size;
    if (isSearch) {
      this.page = 0;
      copyParams['page'] = 0;
    }
    copyParams['sort'] = this.pageSort;
    return copyParams;
  }

  formatSubmitStatus(value, date) {
    if (value + '' === '1') {
      return '已提交' + (isNullOrUndefined(date) ? '' : ` 【${moment(date).format('YYYYMMDD')}】`);
    } else {
      return '未提交';
    }
  }

  getStatusClass(value) {
    if (value + '' === '1') {
      return '';
    } else {
      return 'red';
    }
  }

  sort(sort: { key: string, value: string }): void {
    this.pageSort = [`${sort.key},${sort.value.replace('end', '')}`];
    this.getDataList();
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChange(event) {
    this.page = 0;
    this.size = event;
    this.getDataList();
  }

 /**
  * 页码变动事件
  * @param
  */
  changePageIndex(pageIndex) {
    this.page = pageIndex - 1;
    this.getDataList();
  }

  edit(item) {
    this.router.navigate([`/enroll/team/replyFileUpload/${this.bimInfoEventId}`]);
  }

}
