import { Component, OnInit } from '@angular/core';
import {CacheService} from "@delon/cache";
import {HomeService} from "../../home/home.service";
import {BimWorksService} from "../team/work-upload/bim-works.service";
import {NzMessageService} from "ng-zorro-antd";
import {ThsadminFileService} from "@shared/components/f-upload/thsadmin-file.service";
import {BimWorks} from "../team/work-upload/bim-works.model";
import {HttpAddressService} from "@shared/session/http-address.service";
import {Router} from "@angular/router";
import { isNullOrUndefined } from 'util';
import * as OSS from 'ali-oss';

@Component({
  selector: 'app-works-shows',
  templateUrl: './works-shows.component.html',
  styleUrls: ['./works-shows.component.less']
})
export class WorksShowsComponent implements OnInit {

  eventList = [];
  eventValue = '';
  sortType = 1;
  worksList: BimWorks[] = [];
  page = 1;
  size = 12;
  total = 0;
  isLoading = true;

  searchWorkName = '';
  searchTeamName = '';

  bimInfo: any;
  public ossClient;
  constructor(
    private cacheService: CacheService,
    private homeService: HomeService,
    private bimWorksService: BimWorksService,
    private msg: NzMessageService,
    private thsadminFileService: ThsadminFileService,
    private httpAddressService: HttpAddressService,
    private router: Router,
  ) {
    this.bimInfo = this.cacheService.get('bimInfo')['value'];
    this.ossClient = new OSS(this.httpAddressService.ossServe);
  }

  ngOnInit() {
    this.getBimInfoEvents();
  }

  getBimInfoEvents() {
    const params = {
      'bimInfoId.equals': this.bimInfo.id,
      'eventsType.equals': 0,
      'isDelete.equals': 0,
      sort: ['createTime,desc']
    };
    this.homeService.getBimInfoEvent(params).subscribe(res => {
      if (res && res.body) {
        this.eventList = res.body;
        if (this.eventList.length > 0) {
          this.eventValue = this.eventList[0].id;
          this.getBim(true);
        }
      }
      // this.eventList = res;
    })
  }

  eventsChange(value) {
    this.searchTeamName = null;
    this.searchWorkName = null;
    this.eventValue = value;
    this.getBim(true);
  }

  sortClick(value) {
    this.sortType = value;
    this.getBim(true);
  }

  getBim(pageRest?: boolean) {
    if (pageRest) {
      this.page = 1;
      this.searchTeamName = null;
      this.searchWorkName = null;
    }
    if (this.eventValue && this.eventValue.length > 0) {
      const params = {
        'eventsId.equals': this.eventValue,
        'workType.equals': 0,
        'workSubmitStatus.equals': 1,
        page: this.page - 1,
        size: this.size,
      };
      if (this.searchTeamName && this.searchTeamName.length > 0) {
        params['teamName.contains'] = this.searchTeamName.trim();
      }
      if (this.searchWorkName && this.searchWorkName.length > 0) {
        params['name.contains'] = this.searchWorkName.trim();
      }
      if (this.sortType === 1) {
        params['sort'] = ['uploadDate,desc'];
      } else if (this.sortType === 2) {
        params['sort'] = ['hit,desc'];
      }
      this.bimWorksService.showWorks(params).subscribe(res => {
        if (res && res.body) {
          this.worksList = res.body;
          // tslint:disable-next-line:radix
          this.total = parseInt(res.headers.get('X-Total-Count'));
          this.worksList.forEach(item => {
            if (!isNullOrUndefined(item.firstImgId)) {
              if (item.fileType === 'AliOss') {
                item.firstImgUrl = this.ossClient.signatureUrl(item.firstImgSavePath);
              } else {
                item.firstImgUrl = this.httpAddressService.apiGateway + '/thsadmin/api/sys-files/download/' + item.firstImgId;
              }
            } else {
              item.firstImgUrl = null;
            }
          })
        }
        
        this.isLoading = false;
      },error => {
        this.isLoading = false;
      })
    }
  }

  showDetails(work) {
    const params = {
      workId: work.id
    };
    if (this.bimInfo.type === 'province') {
      this.router.navigate(['/enroll/show'], { queryParams: params});
    } else {
      this.router.navigate(['/enroll/worksShowsDetail'], { queryParams: params});
    }
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChangePage(event) {
    this.page = event;
    this.getBim();
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChangeSize(event) {
    this.size = event;
    this.getBim();
  }

  formatTeamName(teamName) {
    if (teamName && teamName.length > 18) {
      return teamName.substring(0, 18) + '...';
    }
    return teamName;
  }
}
