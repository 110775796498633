import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpAddressService } from '@shared/session/http-address.service';
import { HomeService } from 'app/routes/enroll/home/home.service';
import { isNullOrUndefined } from 'util';
import { CacheService } from '@delon/cache';
import { BimStore } from '@shared/store/bim.store';

@Component({
  selector: 'app-learning-classroom',
  templateUrl: './learning-classroom.component.html',
  styleUrls: ['./learning-classroom.component.less']
})
export class LearningClassroomComponent implements OnInit {

  @Input() contentType = 'LearningClassRoom';
  public title = '';

  public LearningClassroomList = [];
  public curSelect: any = null;
  public fileList = [];

  public page = 0;
  public size = 20;
  public total = '0';

  public bimInfoList = [];


  imagePrefix = '';

  public queryParams = {
    // 查询搜索框的字段要预置
    'bimInfoId.equals': null,
    'title.contains': null,
    // sort固定
    sort: ['istop,desc', 'hit,asc', 'createTime,desc']
  };

  public bimInfo = null;

  constructor(
    private homeService: HomeService,
    private routeInfo: ActivatedRoute,
    private httpAddressService: HttpAddressService,
    private bimStore: BimStore,
    private cacheService: CacheService
  ) {
    this.imagePrefix =
      this.httpAddressService.apiGateway + '/thsadmin/api/sys-files/download/';
  }

  ngOnInit(): void {
    this.contentType = "LearningClassRoom";//this.routeInfo.snapshot.params['contentType'];
    if (this.contentType === 'LearningClassRoom') {
      this.title = '学习课堂';
    }

    this.bimInfo = this.cacheService.get('bimInfo')['value'];
    if(this.bimInfo && this.bimInfo.id){
      this.queryParams['bimInfoId.equals'] = this.bimInfo.id;
    }

    this.initBimInfoList();
    this.getThsNews();
  }

  initBimInfoList() {
    this.homeService
    .getBimInfos({
      size: 1000,
      'isDelete.equals': 0,
      'status.equals': 1,
      sort: ['startDate,desc'],
    })
    .subscribe(res => {
      console.log(res);
      this.bimInfoList = res.body;
    });
  }


  /**
   * 
   * @param isReset 
   * @param isSearch 
   * @param isSelect 是否选择
   */
  getThsNews(isReset?: boolean, isSearch?: boolean,isSelect?:boolean) {
    const params =  this.getParams(isReset, isSearch);
    this.homeService.getThsNews(params).subscribe(res => {
      this.LearningClassroomList = res.body;
      this.total = res.headers.get('X-Total-Count');
      if (this.LearningClassroomList && this.LearningClassroomList.length > 0) {
        this.curSelect = this.LearningClassroomList[0];
        this.initDataFile();
      }
    });

    if(isSelect && this.queryParams['bimInfoId.equals']){
      const selectBimInfos = this.bimInfoList.filter(w=>w.id === this.queryParams['bimInfoId.equals']);
      if(selectBimInfos && selectBimInfos.length > 0){
        this.bimStore.bimInfoStore.next(selectBimInfos[0]);
      }else{
        this.bimStore.bimInfoStore.next(null);
      }
    }
  }


  getParams(isReset?: boolean, isSearch?: boolean) {
    const copyParams = {};
    const q = this.queryParams;
    if (isReset === true) {
      this.page = 0;
      Object.keys(q).forEach(function (key) {
        if (key.indexOf('isOk') > -1) {
          q[key] = '';
        } else {
          q[key] = null;
        }
      });
    }
    Object.keys(q).forEach(function (key) {
      if (q[key] !== '' && q[key] !== null) {
        copyParams[key] = q[key];
      }
    });
    if (!isNullOrUndefined(this.contentType)) {
      copyParams['contenttype.equals'] = this.contentType;
    }
    copyParams['istop.equals'] = '1';
    copyParams['page'] = this.page;
    copyParams['size'] = this.size;
    if (isSearch) {
      this.page = 0;
      copyParams['page'] = 0;
    }
    return copyParams;
  }


  initDataFile() {
    this.homeService.getAllFiles({
      'fileFk.equals': this.curSelect.id,
      'extField1.equals': this.contentType
    }).subscribe(fRes => {
      this.fileList = fRes.body;
    });
  }

  downFile(fileId) {
    const a = document.createElement('a');
    const url =  this.httpAddressService.apiGateway + '/thsadmin/api/sys-files/download/' + fileId;
    a.href = url;
    a.click();
  }

  clickCoop(item) {
    this.curSelect = item;
    this.initDataFile();
  }



  /**
  * 页码数量变动事件
  * @param
  */
 paginationChange(event: { pi: number; ps: number; }) {
  if (event.pi === undefined) {
    return;
  }
  this.page = event.pi - 1;
  this.size = event.ps;
  this.getThsNews();
}

/**
* 页码数量变动事件
* @param
*/
paginationChangePage(event) {
  this.page = event - 1;
  this.getThsNews();
}

/**
* 页码数量变动事件
* @param
*/
paginationChangeSize(event) {
  this.size = event;
  this.getThsNews();
}

}
