import { Component, Inject, OnInit } from '@angular/core';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { ModalHelper } from '@delon/theme';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { isNullOrUndefined } from 'util';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import { CacheService } from '@delon/cache';
import { DictService } from '@shared/components/dict/dict.service';
import { BimCertRegedistService } from '../bim-cert-regedist/bim-cert-regedist.service';
import { UserLoginComponent } from 'app/routes/passport/login/login.component';

@Component({
  selector: 'app-certificate-index',
  templateUrl: './certificate-index.component.html',
  styleUrls: ['./certificate-index.component.less'],
})
export class CertificateIndexComponent implements OnInit {
  public id = this.cacheService.get('bimEventId')['value'];
  newIt = { name: '住房城乡建设领域BIM应用专业技能认证', link: `/certification/index`, isNeedLogin: false, isdisable: false, isReadOnly: false  };

  currentNav: any = null;
  // 是否登录
  isLogin = false;

  public CERT_TYPE = 'bimCertType';
  public certTypeList = [];

  public curTeamEvent = this.cacheService.get('curTeamEvent')['value'];

  public isShowProduct = false;
  public initCertProductList = [];
  public certProductList = [];

  public isShowCertInfo = false;
  public certProductInfo = null;

  constructor(
    private router: Router,
    private msg: NzMessageService,
    private dictService: DictService,
    private modalHelper: ModalHelper,
    private cacheService: CacheService,
    private modalService: NzModalService,
    private bimCertRegedistService: BimCertRegedistService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  ) {
    const token = this.tokenService.get().token;
    if (token && token !== null && token !== '') {
      this.isLogin = true;
    }
  }

  ngOnInit() {
    if (this.isLogin) {
      this.initCertType();
    } else {
      this._loginUser(null);
    }
  }

  selCertType(item) {
    this.routerNav(item);
  }

  routerNav(item) {
    this.currentNav = item;
    //免考认证类型 数据字典中 itemValue3为原认证类型值，处理新增的“建筑信息模型（BIM）职业能力认证（原THS-BIM职业能力认证）”类型
    this.certProductList = this.initCertProductList.filter(e => e.certType === item.itemValue1 || e.certType === item.itemValue3);
    this.isShowCertInfo = false;
    this.isShowProduct = true;
  }

  // 获取认证类别
  initCertType() {
    this.dictService.findAllByDictNo(this.CERT_TYPE).subscribe(res => {
      this.certTypeList = res.body;
      this.initCertProduct();
    });
  }

  initCertProduct() {
    this.bimCertRegedistService.getMyCertProductData().subscribe(res => {
      this.initCertProductList = res.body;
      this.routerNav(this.certTypeList[0]);
    });
  }

  certInfo(item) {
    this.isShowProduct = false;
    this.isShowCertInfo = true;
    this.certProductInfo = item;
    console.log(item);
  }

  /**
   * 登陆
   */
  _loginUser(item) {
    this.modalService.confirm({
      nzTitle: '确认确定登录?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.modalHelper.static(UserLoginComponent, {  }, 600).subscribe((data: any) => {
          if (data) {
            this.msg.info('登陆成功');
            this.isLogin = true;
            this.routerNav(item);
          }
        });
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }
}
