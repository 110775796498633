import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd';
import { WebService } from '../web.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'app/routes/admin/user.service';

@Component({
  selector: 'app-update-email',
  templateUrl: './update-email.component.html',
  styleUrls: ['./update-email.component.less']
})
export class UpdateEmailComponent implements OnInit{

  step: number = 1;

  count = 0;
  interval$: any;

  isBindSuccess: boolean = false;

  form: FormGroup;

  loading = false;

  constructor(private location: Location,
    public msg: NzMessageService,
    private webService: WebService,
    private router: Router,
    private routerInfo: ActivatedRoute,
    private userService: UserService,
    fb: FormBuilder) {
    this.form = fb.group({
      email: [null, [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/)]],
      authCode: [null, [Validators.required]],
    });
  }


 
  get email() {
    return this.form.controls.email;
  }

  get authCode() {
    return this.form.controls.authCode;
  }


  ngOnInit() {

  }

  nextStep(step: number) {
    if (step === 2) {
      if (this.email.invalid) {
         return;
      }
      this.loading = true;
      this.webService.sendEmailCode(this.email.value).subscribe(res => {
        this.loading = false;
        const result = res;
        if (result && result.success) {
          this.step = step;
        } else {
          this.msg.error('验证码发送失败，请稍后重试');
        }
      });
      return;
    }
    this.step = step;
  }


  emailSubmit() {
    const param = this.checkAndToParam();
    if (param === '') {
      return;
    }
    this.loading = true;
      // 修改密码
      this.userService.updateEmail(param).subscribe(res => {
        this.loading = false;
        if (res && res.body) {
          const result = res.body;
          if (result.code !== '1') {
            this.msg.remove();
            this.msg.error(result.message);
            return;
          }
          this.msg.info('修改成功');
          this.router.navigate(['/home'], { queryParams: { hasFooter: false }});
        }
      });
  }

  checkAndToParam() {
      if ((this.authCode.dirty && this.authCode.errors) || this.authCode.value === null ) {
        this.msg.remove();
        this.msg.error('请确认验证码填写正确');
        return '';
      }

      if (this.email.errors) {
        this.msg.remove();
        this.msg.error('请确认邮箱格式正确');
        return '';
      }

     const  param = {
      // userPassword: hex_md5(this.pwd.value),
      vCode: this.authCode.value,
      email: this.email.value,
     };
     return param;
    

  }

  goback() {
    this.location.back();
  }

}
