import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CacheService } from '@delon/cache';
import { HttpAddressService } from '@shared/session/http-address.service';
import { param } from 'jquery';
import { isNullOrUndefined } from 'util';
import * as moment from 'moment';
import { HomeService } from 'app/routes/enterprise/home/home.service';


@Component({
  selector: 'app-enterprise-contest-dynamic',
  templateUrl: './contest-dynamic.component.html',
  styleUrls: ['./contest-dynamic.component.less'],
})
export class ContestDynamicComponent implements OnInit {

  public bimDynamicList = [];
  public page = 0;
  public size = 5;
  public total = '0';


  public bimInfoId = null;
  public bimInfo = this.cacheService.get('bimInfo')['value'];
  tabTitle = '比赛动态';
  CONTENT_TYPE = 'bimDynamic';
  

  constructor(
    private router: Router,
    private homeService: HomeService,
    private cacheService: CacheService,
    private httpAddressService: HttpAddressService
  ) {
    
  }

  ngOnInit() {
    if (isNullOrUndefined(this.bimInfo)) {
      this.router.navigate(['/enterprise']);
      return;
    }
    this.bimInfoId = this.bimInfo.id;
    this.getThsNews(this.CONTENT_TYPE, true);
  }

  getThsNews(contentType, isInit?: boolean) {
    const params = {
      'bimInfoId.equals': this.bimInfoId,
      'contenttype.equals': contentType,
      page: this.page,
      size: this.size,
      sort: ['istop,desc', 'createTime,desc'],
    };
    this.homeService.getThsNews(params).subscribe(res => {
      this.bimDynamicList = res.body;
      this.total = res.headers.get('X-Total-Count');
    });
  }


  openUrl(url) {
    if (!isNullOrUndefined(url)) {
      if (url.startsWith('http://') || url.startsWith('https://')) {
        window.open(url);
      } else {
        window.open('http://' + url);
      }
    }
  }

  getParams() {
    const params = {
      'bimInfoId.equals': this.bimInfoId,
      size: '10000',
    };
    return params;
  }

  formatDateYM(str: string) {
    return moment(str).format('YYYY-MM');
  }

  formatDateD(str: string) {
    return moment(str).format('DD');
  }

  /**
   * 页码数量变动事件
   * @param
   */
  paginationChangePage(event, contentType) {
    this.page = event - 1;
    this.getThsNews(contentType);
  }

  /**
   * 页码数量变动事件
   * @param
   */
  paginationChangeSize(event, contentType) {
    this.size = event;
    this.getThsNews(contentType);
  }

  dynamciClick(item) {
    this.router.navigate(['/dynamicDetail'], {
      queryParams: {
        id: item.id,
      },
    });
  }

  formatDate(str: string) {
    return moment(str).format('YYYY年MM月DD日');
  }
}
