import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { CacheService } from '@delon/cache';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { isNullOrUndefined } from 'util';
import { SchoolWorkScoringService } from '../school-work-scoring/school-work-scoring.service';
import { BimScoringRuleService } from './bim-scoring-rule.service';

@Component({
  selector: 'app-score-rubric',
  templateUrl: './score-rubric.component.html',
  styleUrls: ['./score-rubric.component.less']
})
export class ScoreRubricComponent implements OnInit {

  public totalScore = 0;
  public totalScoreLimit = null;//最大分数
  public scoreList = [];
  public bimWork = null;
  public bimWorkId = null;
  public bimSchoolId = null;
  public bimInfo = this.cacheService.get('bimInfo')['value'];
  public bimScoringRule = null;
  public entityId = null;
  public curTeamEvent = this.cacheService.get('curTeamEvent')['value'];
  public maxTopCount = null;

  public hasSubmit = false;
  public loading = false;

  public advisorId = null;

  constructor(
    private router: Router,
    private msg: NzMessageService,
    private routeInfo: ActivatedRoute,
    private cacheService: CacheService,
    private modalService: NzModalService,
    private schoolWorkScoringService: SchoolWorkScoringService,
    private bimScoringRuleService: BimScoringRuleService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  ) {
    this.bimSchoolId = this.tokenService.get().bimSchoolId;
    this.bimWorkId = this.routeInfo.snapshot.params['bimWorkId'];
    this.advisorId = this.routeInfo.snapshot.params['advisorId'];
    this.entityId = tokenService.get().entity_id;
    this.maxTopCount = this.curTeamEvent.maxTopCount;
  }

  ngOnInit() {
    if (isNullOrUndefined(this.bimInfo)) {
      this.router.navigate(['/enroll/contestSelect']);
      return;
    }
    this.initData();
  }

  initData() {
    if (!isNullOrUndefined(this.bimWorkId)) {
      this.loading = true;
      this.bimScoringRuleService.getBimWorkCores({
        'workId.equals': this.bimWorkId,
        'advisorId.equals': isNullOrUndefined(this.advisorId) ?  this.entityId : this.advisorId
      }).subscribe(dres => {
        if (dres && dres.body.length > 0 && dres.body[0].submit === 1) {
          this.hasSubmit = true;
        }
        this.bimScoringRuleService.getRuleItemByWorkId({
          'bimWorkId': this.bimWorkId,
          'bimInfoId': this.bimInfo.id,
          'advisorId': isNullOrUndefined(this.advisorId) ?  this.entityId : this.advisorId
        }).subscribe(res => {
          this.loading = false;
          if (res && res.body) {
            const data = res.body;
            this.bimWork = data.bimWork;
            if (!isNullOrUndefined(data.bimScoringRule)) {
              this.bimScoringRule = data.bimScoringRule;
              const scoreLimit = parseFloat(this.bimScoringRule.userDefine1);
              if(!isNaN(scoreLimit)){
                this.totalScoreLimit = scoreLimit;
              }
            }
            if (!isNullOrUndefined(data.ruleItemData)) {
              const ruleItemData = data.ruleItemData;
              const fields = ['itemName2', 'itemRule', 'answer', 'remark'];
              if (ruleItemData && ruleItemData.length > 0) {
                ruleItemData.forEach(e => {
                  for (let i = 0; i < fields.length; i++) {
                    const field = fields[i];
                    if (!isNullOrUndefined(e[field])) {
                      e[field] = e[field].replaceAll('\n', '<br>');
                    }
                  }
                })
              }
              this.scoreList = ruleItemData;
            }
            this.scoreChange();
          }
        });
      });
    }
  }

  scoreChange(item?, index?) {
    let totalScore = 0;
    this.scoreList.forEach(e => {
      if (!isNullOrUndefined(e.getScore) && e.getScore !== '') {
        totalScore += e.getScore * 100;
      }
    });
    this.totalScore = totalScore / 100;
  }

  submitScore(inx) {
    if(!isNullOrUndefined(this.totalScoreLimit) && this.totalScore > this.totalScoreLimit){
      //总分验证（实际评分不能超过总得分）
      this.modalService.warning({
        nzTitle: '提示',
        nzContent: `作品总得分不能超过总分：${this.totalScoreLimit} 分`,
        nzOkText: '确定',
      });
      return;
    }
    const param = [];
    this.scoreList.forEach(e => {
      if (!isNullOrUndefined(e.getScore) && e.getScore !== '') {
        param.push({
          'bimScoringRuleItemId': e.id,
          'core': e.getScore
        });
      }
    });
    const subParam = {
      'bimWorkId': this.bimWorkId,
      'advisorId': isNullOrUndefined(this.advisorId) ?  this.entityId : this.advisorId,
      'scoreList': param,
      'saveType': inx
    };
    if (inx === 1) {
      this.modalService.confirm({
        nzTitle: '提交后将无法修改，确认提交?',
        nzOkText: '是',
        nzOkType: 'danger',
        nzOnOk: () => {
          subParam['schoolId'] = this.bimSchoolId;
          subParam['bimInfoId'] = this.bimInfo.id;
          if (isNullOrUndefined(this.advisorId)) {
            subParam['maxTopCount'] = this.maxTopCount;
          }
          subParam['workType'] = this.bimWork.workType;
          this.saveData(subParam, inx);
        },
        nzCancelText: '否',
        nzOnCancel: () => {
          console.log('Cancel');
        }
      });
    } else {
      this.saveData(subParam, inx);
    }
  }

  saveData(subParam, inx) {
    this.loading = true;
    this.bimScoringRuleService.saveWorkCores(subParam).subscribe(res => {
      this.loading = false;
      if (res && res.body) {
        const data = res.body;
        if (data.success) {
          if (inx === 0) {
            this.msg.info('保存成功');
          } else {
            this.onWarning('提交成功');
            this.hasSubmit = true;
          }
        } else {
          this.msg.warning(data.message);
        }
      }
    }, () => this.loading = false);
  }

  private onError(message: string) {
    this.msg.remove();
    this.msg.create('error', message);
  }

  private onWarning(message) {
    this.msg.remove();
    this.msg.create('warning', message);
  }

}
