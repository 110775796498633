
import { Component, Injectable, Injector, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'layout-enter-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less']
})
export class EnterFooterComponent {
  constructor() {

  }

}
