import { Component, OnInit, Inject } from '@angular/core';
import { HttpAddressService } from '@shared/session/http-address.service';
import { OrderDetailService } from './order-detail.service';
import { ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { CacheService } from '@delon/cache';
import { ArrayService } from '@delon/util';
import { HttpClient } from '@angular/common/http';
import { NzMessageService, NzModalRef, NzModalService } from 'ng-zorro-antd';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.less']
})
export class OrderDetailComponent implements OnInit {

  id = '';

  validateForm: FormGroup;
  validateForm1: FormGroup;

  courseInfo: any;
  order: any;
  loading = false;
  // payType = 'wx';
  radioValue = '1';

  zfbPayUrl = '';
  wxQr = '';

  imgPrefix = '';

  isVisible = false;

  // 是否显示其它支付
  isShow = false;


  //添加开票信息
  fapiaoShowMsg = '请填写真实完整的姓名或单位名称';
  public proviceCity = null;
  public nzOptions = null;
  public selInvoiceType = null;


  constructor(
    private fb: FormBuilder,
   private router: Router,
   private modal: NzModalRef,
   private httpAddressService: HttpAddressService,
   private orderDetailService: OrderDetailService,
   private cacheService: CacheService,
   private arrayService: ArrayService,
   private http: HttpClient,
   private msg: NzMessageService,
   private routeInfo: ActivatedRoute,
   @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,

  ) {

        this.validateForm = this.fb.group({
          invoiceReceivingName: [null, [Validators.required, Validators.maxLength(50)]],
          invoiceReceivingTel: [null, [Validators.required, Validators.pattern(/^1\d{10}$/)]],
          province: [null, [Validators.required]],
          city: [null, [Validators.required]],
          region: [null, [Validators.required]],
          invoiceReceivingAddress: [null, [Validators.required]],
        });
        this.validateForm1 = this.fb.group({
          invoiceType: [null, [Validators.required]],
          invoiceTitle: [null, [Validators.required]],
          taxpayerSn: [null, [Validators.required]],
          invoiceAddress: [null, []],
          invoiceTel: [null, []],
          bankName: [null, []],
          bankAccount: [null, []],
        });

       
        this.imgPrefix =  this.httpAddressService.apiGateway + this.httpAddressService.systemServe + '/sys-files/download/';     
   }

  ngOnInit() {
    this.initPayUrl();
    this.loadCityData();

   if (!isNullOrUndefined(this.order.id)) {
      this.loadData();
    }

  }


  
  // getData() {
  //   let queryParam={};
  //   queryParam["id.equals"] = this.id;
  //   this.orderDetailService.query(this.id).subscribe(res => {
  //     const body = res.body;
  //     if (body) {
  //       this.order = body;
  //       this.loadData();
  //     } else {
  //       this.msg.error('订单异常');
  //     }
  //   });
  // }

  loadData() {
    if (this.order.status === 1) {
      this.setDisabled();
    }
    if (this.order.province !== null && this.order.city !== null || this.order.region !== null) {
      this.proviceCity = [this.order.province, this.order.city, this.order.region];
    }
    if (!isNullOrUndefined(this.order.paymentType)) {
      this.changeType(3 - this.order.paymentType);
    }
    this.order.invoiceType = (this.order.invoiceType !== null && this.order.invoiceType !== undefined ? this.order.invoiceType : 2) + '';
    this.selInvoiceType = this.order.invoiceType;
    this.validateForm.patchValue(this.order);
    this.validateForm1.patchValue(this.order);


  }


  setDisabled() {
     for (const key in this.validateForm.controls) {
        this.validateForm.get(key).disable();
    }
     for (const key in this.validateForm1.controls) {
        this.validateForm1.get(key).disable();
    }
  }


  initPayUrl() {
    this.zfbPayUrl = this.httpAddressService.apiGateway + this.httpAddressService.EduServe
    + '/edu-course-pays/ali-pay/' + this.order.id;
    // this.wxPayUrl = this.httpAddressService.apiGateway +  this.httpAddressService.EduServe
    // + '/edu-course-pays/wx-pay/' + this.order.id;
  }


  toggleShow() {
    this.isShow = !this.isShow;
    $('#pay-mode-others').slideToggle('fast');
  }

  // aliPayNow(event) {
  //   // event.preventDefault();
  //   const token = this.tokenService.get().token;
  //   const url = this.zfbPayUrl;
  //   const xhr = new XMLHttpRequest();
  //   xhr.open('get', url, true);
  //   xhr.setRequestHeader('Authorization', 'Bearer ' + token);
  //   xhr.send();
  // }

  payNow() {
    if (this.radioValue === '1') {
      this.loading = true;
      this.orderDetailService.wxPay(this.order.id).subscribe(res => {
        const recond = res;
        if (recond && recond.success === true) {
           this.wxQr = this.formatQr(recond.data);
           console.log(this.wxQr);
        }
        this.loading = false;
        this.showModal();
      });

    }
  }


  formatQr(qd: string) {
    let tmp = qd.replace(/%2F/g, '/');
        tmp = tmp.replace(/%3D/g, '=');
        tmp = tmp.replace(/%3F/g, '?');
        tmp = tmp.replace(/%3A/g, ':');
     return tmp;
  }

  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
      this.isVisible = false;
      this.modal.destroy();
  }

  handleCancel(): void {
    this.isVisible = false;
  }

//加载城市数据
  loadCityData() {
    const provinceCity = this.cacheService.get('provinceCity')['value'];
    if (isNullOrUndefined(provinceCity)) {
      this.http.get('./assets/cities.json').subscribe((res: any) => {
        const data = res.cities
        // .filter(e => e.parent_id === null || e.parent_id <= 34) // 只要二级
        .map(item => {
          return {
            id: item.id,
            value: item.name,
            label: item.name,
            parentId: item.parent_id
          };
        });
        const result: any[] = this.arrayService.arrToTree(data, {idMapName: 'id', parentIdMapName: 'parentId'});
        result.forEach(item => this.setLeafCity(item));
        this.nzOptions = result;
        this.cacheService.set('provinceCity', result);
      });
    } else {
      this.nzOptions = provinceCity;
    }
  }

  //设置城市数据
  setLeafCity(city) {
    if (city.children && city.children.length) {
      city.children.forEach(item => this.setLeafCity(item));
    } else {
      delete city.children;
      city.isLeaf = true;
    }
  }

//城市改变数据
  provinceChange(item) {
    if (!isNullOrUndefined(item) && item.length > 2) {
      this.validateForm.controls['province'].setValue(item[0]);
      this.validateForm.controls['city'].setValue(item[1]);
      this.validateForm.controls['region'].setValue(item[2]);
    }
  }

  //发票类型选择改变事件
  invoiceTypeChange(value) {
    this.selInvoiceType = value;
    if (value === '1') {
      this.fapiaoShowMsg = '请填写真实完整的单位名称';
      // tslint:disable-next-line:forin
      for (const key in this.validateForm1.controls) {
          this.validateForm1.get(key).setValidators([Validators.required]);
      }
    } else if (value === '0') {

      this.validateForm1.get('invoiceTitle').setValidators([Validators.required]);
      this.validateForm1.get('taxpayerSn').setValidators([Validators.required]);

      this.fapiaoShowMsg = '请填写真实完整的姓名或单位名称';
      this.validateForm1.get('invoiceAddress').clearValidators();
      this.validateForm1.get('invoiceTel').clearValidators();
      this.validateForm1.get('bankName').clearValidators();
      this.validateForm1.get('bankAccount').clearValidators();
    } else if (value === '2') {
      // tslint:disable-next-line:forin
      this.fapiaoShowMsg = '';
      // tslint:disable-next-line:forin
      for (const key in this.validateForm1.controls) {
        this.validateForm1.get(key).clearValidators();
      }
    }
  }



  submit() {
    const subParam = this.order;
    if (this.selInvoiceType === '2') {
        // tslint:disable-next-line:forin
        for (const key in this.validateForm1.controls) {
          this.validateForm1.get(key).clearValidators();
        }
    }

    // tslint:disable-next-line:forin
    for (const key in this.validateForm.controls) {
      this.validateForm.controls[ key ].markAsDirty();
      this.validateForm.controls[ key ].updateValueAndValidity();
      subParam[key] =  this.validateForm.controls[key].value;
    }
    // tslint:disable-next-line:forin
    for (const key in this.validateForm1.controls) {
      this.validateForm1.controls[ key ].markAsDirty();
      this.validateForm1.controls[ key ].updateValueAndValidity();
      subParam[key] =  this.validateForm1.controls[key].value;
      // 开票状态
      subParam['invoiceStatus'] = 0;
      if (this.selInvoiceType === '2') {
        // 不开发票
          if (key !== 'invoiceType') {
            subParam[key] = null;
            subParam['invoiceStatus'] = 2;
          }
      } else if (this.selInvoiceType === '0') {
        // 普通发票
        if (key !== 'invoiceType' && key !== 'invoiceTitle' && key !== 'taxpayerSn') {
          subParam[key] = null;
        }
      }
    }
    if (this.validateForm.invalid || this.validateForm1.invalid) {
      this.onError({ 'message': '请检查信息是否完整！' });
      return;
    }
    this.saveData(subParam);
  }

  saveData(subParam: any) {
    this.orderDetailService.update(subParam).subscribe(res => {
      if (res && res.body) {
        this.loading = false;
        this.msg.info('保存成功');
        this.payNow();
      }
    });
  }


  changeType(id) {
    this.radioValue = id;
    $('#pay_type_' + id).removeClass().addClass('active');
    $('#pay_type_' + (3 - id)).removeClass();
  }


  private onError(error: { message: string; }) {
    this.msg.remove();
    this.msg.create('error', error.message);
  }

}
