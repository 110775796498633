import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpAddressService } from '@shared/session/http-address.service';
import { HomeService } from 'app/routes/enroll/home/home.service';

@Component({
  selector: 'app-coop-parenter',
  templateUrl: './coop-parenter.component.html',
  styleUrls: ['./coop-parenter.component.less'],
})
export class CoopCarenterComponent implements OnInit {

  @Input() contentType = '';
  public title = '';

  public coopParenterList = [];
  public curSelect: any = null;
  public fileList = [];

  constructor(
    private homeService: HomeService,
    private routeInfo: ActivatedRoute,
    private httpAddressService: HttpAddressService,
  ) {
  }

  ngOnInit(): void {
    this.contentType = this.routeInfo.snapshot.params['contentType'];
    if (this.contentType === 'coopCollege') {
      this.title = '合作院校';
    }
    this.getThsNews();
  }

  getThsNews() {
    const params = {
      'contenttype.equals': this.contentType,
      'size': 1000,
      'sort': ['istop,desc', 'author,asc', 'createTime,desc']
    };
    this.homeService.getThsNews(params).subscribe(res => {
      this.coopParenterList = res.body;
      if (this.coopParenterList && this.coopParenterList.length > 0) {
        this.curSelect = this.coopParenterList[0];
        this.initDataFile();
      }
    });
  }

  initDataFile() {
    this.homeService.getAllFiles({
      'fileFk.equals': this.curSelect.id,
      'extField1.equals': this.contentType
    }).subscribe(fRes => {
      this.fileList = fRes.body;
    });
  }

  downFile(fileId) {
    const a = document.createElement('a');
    const url =  this.httpAddressService.apiGateway + '/thsadmin/api/sys-files/download/' + fileId;
    a.href = url;
    a.click();
  }

  clickCoop(item) {
    this.curSelect = item;
    this.initDataFile();
  }

}
