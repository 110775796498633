import { Component, Inject, OnInit } from '@angular/core';
import { CacheService } from '@delon/cache';

import { ActivatedRoute } from '@angular/router';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';

import { _HttpClient, ModalHelper } from '@delon/theme';
import { isNullOrUndefined } from 'util';
import * as moment from 'moment';
import { BimPerson } from 'app/routes/enroll/personal/bim-person.model';
import { BimPersonService } from 'app/routes/enroll/personal/bim-person.service';
import { CompetPersonEditComponent } from '../edit-person/edit.component';

@Component({
  selector: 'app-compet-my-entry-city',
  templateUrl: './my-entry.component.html',
  styleUrls: ['./my-entry.component.less'],
})
export class CompetMyEntryCityComponent implements OnInit {
  public bimEventId = null;
  checkedItem = 0;
  public bimPerson: BimPerson;
  matchDetailList = [];

  isVisible = false;

  personSchooleList: any[] = [];
  public signUpflag = 0;
  public curTeamEvent = this.cacheService.get('curTeamEvent')['value'];

  constructor(
    private cacheService: CacheService,
    private routerInfo: ActivatedRoute,
    private bimPersonService: BimPersonService,
    public modal: ModalHelper,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  ) {
    this.bimPerson = new BimPerson();
    const id = this.routerInfo.snapshot.params['bimEventId'];

    this.bimEventId = id;
    this.cacheService.set('personBimEventId', this.bimEventId);
  }

  ngOnInit() {
    // 未报名
    this.signUpflag = 0;
    if (this.curTeamEvent && !isNullOrUndefined(this.curTeamEvent.startTime)) {
      const startTime = Date.parse(
        moment(this.curTeamEvent.startTime).format('YYYY-MM-DD'),
      );
      const endTime = Date.parse(
        moment(this.curTeamEvent.endTime).format('YYYY-MM-DD'),
      );
      const nowTime = Date.parse(moment(new Date()).format('YYYY-MM-DD'));
      this.signUpflag = nowTime < startTime ? 2 : nowTime > endTime ? 1 : 0;

      console.log(this.curTeamEvent);
    }
    this.getData();
  }

  getData() {
    const mobile = this.tokenService.get().mobile;
    let copyParams = { sort: ['createTime,desc'] };
    copyParams['personTel.equals'] = mobile;
    copyParams['bimInfoEventsId.equals'] = this.bimEventId;
    copyParams['isDelete.equals'] = 0;
    copyParams['page'] = 0;
    copyParams['size'] = 10;

    // 查询学生是否加入团队
    this.bimPersonService.queryNewAnd(copyParams).subscribe(res => {
      if (res && res.body.length > 0) {
        this.bimPerson = res.body[0];
      } else {
        this.bimPerson = new BimPerson();
      }
    });
  }

  changeItem() {
    if (this.matchDetailList.length > 0) {
      if (this.checkedItem >= 1) {
        this.checkedItem = 0;
      } else {
        this.checkedItem = 1;
      }
    } else {
      if (this.checkedItem >= 1) {
        this.checkedItem = 0;
      } else {
        this.checkedItem = 2;
      }
    }
  }

  editSchoolInfo() {
    this.modal
      .static(CompetPersonEditComponent, {
        personId: this.bimPerson.id,
        bimEventId: this.bimEventId,
      })

      .subscribe(() => {
        this.getData();
      });
  }
}
