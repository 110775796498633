import { Component, OnInit } from '@angular/core';
import { HomeService } from '../home/home.service';
import * as moment from 'moment';
import { HttpAddressService } from '@shared/session/http-address.service';
import { formArrayNameProvider } from '@angular/forms/src/directives/reactive_directives/form_group_name';
import { Router } from '@angular/router';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.less']
})
export class CourseComponent implements OnInit {
  // 视口宽度(包括滚动条)
  viewportW: number;
  // 滚动条宽度
  scrollBarW: number;
  // 内容固定宽度
  fixedW = 1200;
  // 负边距百分比
  mPercent: string = '';

  courseList1 = ['全部', '智能建造技术', '工程管理', '工程造价', '绿色建筑碳排放', '更多课程'];
  courseValue=[];
  courseValue1 = 0;
  courseValue2 = 0;
  courseList2 = ['全部','工程管理','工程造价','绿色建筑碳排放','更多课程'];


  dataSet: any[] = [];
  ItemTypeSubListArray:any[]=[];
  ItemTypeListArray: any[] = [];
  imagePrefix = '';


  public liveBroadcastsPage = 0;
  public liveBroadcastsSize = 6;
  public liveBroadCastTotal = '0';
  public liveBroadcastList = [];
  previewAddress: string;


  constructor(
    private homeService: HomeService,
    private httpAddressService: HttpAddressService,
    private router: Router,
  ) {
 //   this.imagePrefix = this.httpAddressService.imgServe + '/sys-files/download/';
   // this.previewAddress = this.httpAddressService.apiGateway + '/thsadmin/api/sys-files/download/';

   this.imagePrefix = this.httpAddressService.imgServe + '/sys-files/preview/';
   this.previewAddress = this.httpAddressService.apiGateway + '/thsadmin/api/sys-files/preview/';
 
  }

  


  ngOnInit() {
    this.getAllEduLiveBroadcasts();
    this.getCourseClassifies();
  }
  
  ngAfterContentInit() {
    this.computePercent();
    window.onresize = () => {
      this.computePercent();
    };
  }

  ngAfterContentChecked(): void {
    // 文档宽度(不包括滚动条：在1920设备屏幕下，页面没渲染前为1920，渲染后为1914)
    const clientW = document.documentElement.clientWidth;
    if (!this.scrollBarW && clientW < this.viewportW) {
      // 动态获取浏览器滚动条宽度
      this.scrollBarW = this.viewportW - clientW;
    }
  }

  computePercent() {
    this.viewportW = window.innerWidth;
    this.mPercent = (this.viewportW - this.fixedW) * 100 / 2 / 1200 + '%';
  }
  getStyle() {
    if (this.viewportW > 1200) {
      return {
        // 右边距需减除滚动条宽度
        'margin': `0 calc(-${this.mPercent} + ${this.scrollBarW}px) 0 -${this.mPercent}`
      };
    } else {
      return {}
    }
  }



  getAllEduLiveBroadcasts(){
    const params = {
      'isValid.equals': 1,
       page: this.liveBroadcastsPage,
      size: this.liveBroadcastsSize,
      sort: ['jhiOrder,asc', 'createTime,desc'],
    };
    this.homeService.getAllEduLiveBroadcasts(params).subscribe(res => {
      if(res)
      { 
        this.liveBroadcastList = res.body;
        let curDate = new Date();
        this.liveBroadcastList.forEach(item=>{
            if(new Date(item.startTime) <= curDate && new Date(item.endTime ) >= curDate)
            {
                item["dateStatus"] = "1";
            }else{
              item["dateStatus"] = "0";
            }

            if (item.startTime && item.startTime !== null) {
                 item.startTime = moment(item.startTime).format('YYYY-MM-DD HH:mm:ss');
            }

            if (item.endTime && item.endTime !== null) {
              item.endTime = moment(item.endTime).format('YYYY-MM-DD HH:mm:ss');
            }
            
        });

        this.liveBroadCastTotal = res.headers.get('X-Total-Count');
      }
    });
    
  }


  // 获取教育云分类
  getCourseClassifies () {
    // 分类 0 为无效 1 为有效
    this.homeService.geteducourseclassifies().subscribe((listRes: any) => {
      this.dataSet = listRes.body.data;
      this.getCouserList();
      this.getCourseClassifiesSubType();
    });
  }


  //获取课程分类中的一级子分类
  getCourseClassifiesSubType(){
    this.ItemTypeSubListArray = [];
    if (this.dataSet !== undefined && this.dataSet != null) {
          for (let i = 0; i < this.dataSet.length; i++) {
            this.homeService.getDictDetailsByValue2(this.dataSet[i].id).subscribe((subres: any) => {
              let data = subres;
               
                  if(data.success)
                  {
                      let disctId =  data.data[0].id;                   
                      this.homeService.getDictDetailsByPid(disctId).subscribe((dictSubres: any) => {
                        
                         if(dictSubres.success)
                         {
                            
                            this.ItemTypeSubListArray[i]=[];

                            this.ItemTypeSubListArray[i].push({ parentId: disctId, id: '-1', itemText: '全部' });
 

                            for(let j= 0; j <dictSubres.data.length; j++ )
                            {
                               
                               let disctItem = dictSubres.data[j];
                               this.ItemTypeSubListArray[i].push({ parentId: disctItem.parentId, id: disctItem.id, itemText: disctItem.itemText }) ;
                            };

                            this.ItemTypeSubListArray[i].push({ parentId: disctId, id: '-2', itemText: '更多课程' });
                          
                         }
                         
                      });

                  }
               });
          }
    }
  }


  // 查询专题分类八条数据
  getCouserList() {
    if (this.dataSet !== undefined && this.dataSet != null) {
      const copyParams = {};
          for (let i = 0; i < this.dataSet.length; i++) {
            this.courseValue[i] = 0;
            copyParams['classifyId'] = this.dataSet[i].id;
            this.homeService.geteducoursestop8(copyParams).subscribe((subres: any) => {
                 this.ItemTypeListArray[i] = subres.body.data;
               });
          }
    }
  }


  setCourseValue(i:number,j:number,classifyId:string,dictId:string){
      this.courseValue[i] = j;
      if(dictId === "-1")
      {
          this.getCouserListByclassifyId(i, classifyId);
      }else if(dictId === "-2")
      {
    //    /#/course/list?typeid=ee0cf2ae-9aeb-4e04-9af6-80a5fb244e25&typename=高职课程


        let url = "/course/list";
        this.router.navigate([url], { queryParams: { typeid: classifyId, typename: this.dataSet[i].name}});

      }
      else{
        this.top8ByCourseTypeId(i, dictId);
      }
  }

 // 查询专题分类八条数据
 getCouserListByclassifyId(i:number,classifyId:string) {
          const copyParams = {};
          copyParams['classifyId'] = classifyId;         
          this.homeService.geteducoursestop8(copyParams).subscribe((subres: any) => {
               this.ItemTypeListArray[i] = subres.body.data;
             });
  }

   // 查询子分类八条数据
   top8ByCourseTypeId(i:number,dictId:string) {
      const copyParams = {};
      copyParams['courseTypeId'] = dictId;         
      this.homeService.top8ByCourseTypeId(copyParams).subscribe((subres: any) => {
          this.ItemTypeListArray[i] = subres.body.data;
        });
  }


  reloadData(page)
  {
    
      this.liveBroadcastsPage = page - 1;
      this.getAllEduLiveBroadcasts();
  }

}
