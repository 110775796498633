import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { ModalHelper } from '@delon/theme';
import { UserLoginComponent } from 'app/routes/passport/login/login.component';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { isNullOrUndefined } from 'util';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import { CacheService } from '@delon/cache';
import { BimSchoolsService } from '../school-register/bim-school.service';
import { BimSchoolDetailsService } from '../school-register/bim-school-details.service';
import { BimWorksService } from '../work-upload/bim-works.service';

@Component({
  selector: 'app-team-index',
  templateUrl: './team-index.component.html',
  styleUrls: ['./team-index.component.less'],
})
export class TeamIndexComponent implements OnInit {
  public id = this.cacheService.get('bimEventId')['value'];
  navList: any = [
    { name: '大赛任务书', link: `/enroll/team/contestTaskBook/${this.id}`, isNeedLogin: false, isdisable: true, isReadOnly: false  }, // 0
    { name: '学校注册', link: `/enroll/team/schoolRegister/${this.id}`, isNeedLogin: true, isdisable: true, isReadOnly: true  }, // 1
    { name: '学校信息管理', link: `/enroll/team/schoolInformationManage/${this.id}`, isNeedLogin: true, isdisable: true, isReadOnly: true  }, // 2
    { name: '团队赛报名', link: `/enroll/team/competitionRegistration/${this.id}`, isNeedLogin: true, isdisable: true, isReadOnly: true  }, // 3
    { name: '团队信息管理', link: `/enroll/team/competitionTeamInfo/${this.id}`, isNeedLogin: true, isdisable: true, isReadOnly: true  }, // 4
    { name: '作品上传', link: `/enroll/team/work-upload/${this.id}`, isNeedLogin: true, isdisable: false, isReadOnly: false  }, // 5
    { name: '回执打印上传', link: `/enroll/team/receiptUpload/${this.id}`, isNeedLogin: true, isdisable: false, isReadOnly: false  }, // 6
    { name: '校内作品评分', link: `/enroll/team/schoolRating/${this.id}`, isNeedLogin: true, isdisable: true, isReadOnly: false  }, // 7
    { name: '全国评委评分', link: `/enroll/team/judgeRating/${this.id}`, isNeedLogin: true, isdisable: false, isReadOnly: false  }, // 8
    { name: '作品信息管理', link: `/enroll/team/teamWorkInfo`, isNeedLogin: true, isdisable: false, isReadOnly: false  }, // 9

    { name: '全国答辩评委分配作品', link: `/enroll/team/replyWork/${this.id}`, isNeedLogin: true, isdisable: false, isReadOnly: false  }, // 10

    { name: '省赛学校注册', link: `/enroll/team/provinceSchoolRegister/${this.id}`, isNeedLogin: true, isdisable: true, isReadOnly: true  }, // 11
    { name: '省赛学校信息管理', link: `/enroll/team/provinceSchoolInformationManage/${this.id}`, isNeedLogin: true, isdisable: true, isReadOnly: true  }, // 12
    { name: '比赛报名', link: `/enroll/team/provinceCompetitionRegistration/${this.id}`, isNeedLogin: true, isdisable: true, isReadOnly: true  }, // 13
    { name: '报名信息管理', link: `/enroll/team/provinceCompetitionInfo/${this.id}`, isNeedLogin: true, isdisable: true, isReadOnly: true  }, // 14

    { name: '答辩材料上传', link: `/enroll/team/replyFileUpload/${this.id}`, isNeedLogin: true, isdisable: false, isReadOnly: false  }, // 15
    { name: '答辩材料管理', link: `/enroll/team/replyFileManage`, isNeedLogin: true, isdisable: false, isReadOnly: false  }, // 16
  ];

  public schoolNavIndex = [1, 2];
  public schoolRecNavIndex = [7, 8, 10];
  public teamNavIndex = [3, 4, 5, 6, 9, 15, 16];

  
  public provinceSchoolNavIndex = [11, 12];
  public proinceNavIndex = [13, 14];
  public countryNavIndex = [3, 4, 5, 6, 7, 8, 9, 10];

  currentNav: any = null;
  // 是否登录
  isLogin = false;
  isInitStudent = false;
  isInitTeacher = false;

  public curTeamEvent = this.cacheService.get('curTeamEvent')['value'];

  constructor(
    private router: Router,
    private msg: NzMessageService,
    private modalHelper: ModalHelper,
    private cacheService: CacheService,
    private modalService: NzModalService,
    private bimWorksService: BimWorksService,
    private bimSchoolsService: BimSchoolsService,
    private bimSchoolDetailsService: BimSchoolDetailsService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  ) {
    let bimInfo = this.cacheService.get('bimInfo')['value'];
    
    // if (bimInfo.type == "province") {
    //   this.navList = [
    //     { name: '大赛任务书', link: `/enroll/team/contestTaskBook/${this.id}`, isNeedLogin: false, isdisable: true, isReadOnly: false }, // 0
    //     { name: '比赛报名', link: `/enroll/team/provinceCompetitionRegistration/${this.id}`, isNeedLogin: true, isdisable: true, isReadOnly: false }, // 1
    //     { name: '报名信息管理', link: `/enroll/team/provinceCompetitionInfo/${this.id}`, isNeedLogin: true, isdisable: false, isReadOnly: false }, // 2
    //     { name: '校内作品评分', link: `/enroll/team/schoolRating/${this.id}`, isNeedLogin: true, isdisable: true, isReadOnly: false }, // 3
    //   ];
    // //   this.teamNavIndex = [1, 2, 3];
    // }
    let tokenVal = this.tokenService.get();
    const token = tokenVal.token;
    let userType = tokenVal.entity_type;
    const mobile = tokenVal.mobile;
    this.currentNav = this.navList[0];
    router.events.subscribe(evt => {
      if (evt instanceof NavigationEnd) {
        if (bimInfo.type === 'province') {
          this.schoolNavIndex.forEach(e => {
            this.navList[e].isdisable = false;
          });
          if (bimInfo.isLoginRequire === '0') {
            // 报名不需要登录
            this.navList[13].isNeedLogin = false;
          }
          if (bimInfo.isSchoolRequire === '0') {
            // 报名不需要注册学校
            this.provinceSchoolNavIndex.forEach(e => {
              this.navList[e].isdisable = false;
            });
          } else {
            this.navList[13].isNeedLogin = true;
          }
          this.countryNavIndex.forEach(e => {
            this.navList[e].isdisable = false;
          });
        } else {
          this.provinceSchoolNavIndex.forEach(e => {
            this.navList[e].isdisable = false;
          });
          this.proinceNavIndex.forEach(e => {
            this.navList[e].isdisable = false;
          });
        }
        const id = this.cacheService.get('bimEventId')['value'];
        this.navList.forEach(item => {
          item.link = item.link.replace(this.id, id);
          if (evt.url === item.link) {
            this.currentNav = item;
          }
        });

        tokenVal = this.tokenService.get();
        userType = tokenVal.entity_type;
        if ((userType === '0' || userType === '2') && (!this.isInitStudent || this.id !== id)) {
          this.isInitStudent = true;
          // 学生
          this.schoolNavIndex.forEach(e => {
            this.navList[e].isdisable = false;
          });
          for (let i = 0; i < 2; i++) {
            this.navList[this.teamNavIndex[i]].isReadOnly = true;
          }
          this.schoolRecNavIndex.forEach(e => {
            this.navList[e].isdisable = false;
          });
          this.provinceSchoolNavIndex.forEach(e => {
            this.navList[e].isdisable = false;
          });
          this.curTeamEvent = this.cacheService.get('curTeamEvent')['value'];

          if (bimInfo.type == 'province') {
            this.navList[13].isdisable = false;
          }
          // 查询学生是否加入团队
          this.bimSchoolsService.getTeamStudentByTelAndEventId({
            'mobile': mobile,
            'eventId': id
          }).subscribe(res => {
            if (bimInfo.type !== 'province') {
              if (res && res.body.length > 0) {
                const data = res.body[0];
                // 有加入团队
                this.cacheService.set('bimTeamId', data.teamId);
                this.navList[4].isReadOnly = false;
  
                // 校内作品或全国作品上传开始
                if (this.curTeamEvent &&
                      (this.curTeamEvent.schoolWorkUploadStatus === 1 || this.curTeamEvent.advisorsWorkUploadStatus === 1)) {
                  this.navList[this.teamNavIndex[2]].isdisable = true;
                }
                // 回执打印状态开始且团队晋级
                if (this.curTeamEvent && (this.curTeamEvent.receiptStatus === 1 || this.curTeamEvent.receiptStatus === 2) && data.schoolTop === 1) {
                  this.navList[this.teamNavIndex[3]].isdisable = true;
                }
                // 答辩材料上传状态开始且团队晋级
                if (this.curTeamEvent && (this.curTeamEvent.replyWorkUploadStatus === 1 || this.curTeamEvent.replyWorkUploadStatus === 2)) {
                  this.cacheService.set('teamSchoolTop', data.schoolTop);
                  if (data.schoolTop === 1) {
                    this.navList[this.teamNavIndex[5]].isdisable = true;
                    this.navList[this.teamNavIndex[6]].isdisable = true;
                  } else {
                    // 是否被新增入答辩材料管理
                    this.bimWorksService.query({
                      'teamId.equals': data.teamId,
                      'workType.equals': 3
                    }).subscribe(res => {
                      if (res && res.body.length > 0) {
                        this.navList[this.teamNavIndex[5]].isdisable = true;
                        this.navList[this.teamNavIndex[6]].isdisable = true;
                      } else {
                      }
                    });
                  }
                }
                this.navList[this.teamNavIndex[4]].isdisable = true;
              } else {
                this.cacheService.set('bimTeamId', null);
                this.navList[3].isReadOnly = false;
              }
            } else {
              if (res && res.body.length > 0) {
                const data = res.body[0];
                // 有加入团队
                this.cacheService.set('bimTeamId', data.teamId);
                this.navList[14].isReadOnly = false;
              } else {
                this.cacheService.set('bimTeamId', null);
                this.navList[14].isReadOnly = true;
              }
            }
          });
        }

        if ((userType === '1') && (!this.isInitTeacher || this.id !== id)) {
          this.cacheService.set('bimTeamId', null);
          this.isInitTeacher = true;
          if (bimInfo.type !== 'province') {
            // 查询用户是否为评委
            this.bimSchoolsService.getBimAdvisor({
              'tel.equals': mobile,
              'bimInfoEventsId.equals': id
            }).subscribe(res => {
              if (res && res.body.length > 0) {
                // 是评委
                const dataL = res.body;
                dataL.forEach(data => {
                  // 判断是否为答辩评委
                  if (data.isReply === 1) {
                    this.navList[this.schoolRecNavIndex[2]].isdisable = true;
                    // 答辩评委
                    this.tokenService.set({
                      ...this.tokenService.get(),
                      reply_advisor_id: data.id
                    });
                  } else {
                    this.navList[this.schoolRecNavIndex[1]].isdisable = true;
                    // 全国评委
                    this.tokenService.set({
                      ...this.tokenService.get(),
                      country_advisor_id: data.id
                    });
                  }
                });
              } else {
                // 不是全国评委
              }
            });
          } else {
            // 是否是学校日常联系人
            this.bimSchoolDetailsService.query({
              'linkmanPhone.equals': mobile,
              'bimInfoEventsId.equals': id
            }).subscribe(res => {
              if (res && res.body) {
                const data = res.body;
                if (data.length > 0) {
                  // 是学校联系人，可查看、修改所有团队信息
                  this.cacheService.set('bimSchoolDetail', data[0]);
                  this.tokenService.set({
                    ...this.tokenService.get(),
                    bim_school_details_id: data[0].id,
                    bimProvinceSchoolId: data[0].bimSchoolId
                  });
                  // 是否有报名团队
                  this.bimSchoolsService.getBimTeam({
                    'bimInfoEventsId.equals': id,
                    'schoolId.equals': data[0].bimSchoolId,
                    'isDelete.equals': '0'
                  }).subscribe(res => {
                    const teamData = res.body;
                    if (teamData && teamData.length > 0) {
                      // 已报名注册团队
                      this.navList[this.provinceSchoolNavIndex[1]].isReadOnly = false;
                      this.navList[this.proinceNavIndex[1]].isReadOnly = false;
                      this.tokenService.set({
                        ...this.tokenService.get(),
                        is_registry_team: true
                      });
                    } else {
                      // 尚未报名团队
                      this.navList[this.proinceNavIndex[0]].isReadOnly = false;
                      this.navList[this.proinceNavIndex[1]].isReadOnly = true;
                      this.tokenService.set({
                        ...this.tokenService.get(),
                        is_registry_team: false
                      });
                    }
                  });
                } else {
                  // 是否是学校带队老师
                  this.bimSchoolDetailsService.query({
                    'leaderTeacher.contains': '&quot;mobile&quot;:&quot;' + mobile + '&quot;',
                    'bimInfoEventsId.equals': id
                  }).subscribe(lRes => {
                    if (lRes && lRes.body) {
                      const lData = lRes.body;
                      if (lData.length > 0) {
                        // 是学校带队老师，可查看学校报名信息，不可修改
                        this.cacheService.set('bimSchoolDetail', lData[0]);
                        this.navList[this.proinceNavIndex[1]].isReadOnly = false;
                        this.tokenService.set({
                          ...this.tokenService.get(),
                          bim_school_leader_id: lData[0].id,
                          bimProvinceSchoolId: lData[0].bimSchoolId
                        });
                      } else {
                        // 老师尚未报名
                        // 报名是否需要注册学校
                        if (bimInfo.isSchoolRequire === '1') {
                          // 需要
                          // 查询是否有注册学校
                          const entityId = tokenVal.entity_id;
                          this.bimSchoolsService.getBimSchoolByCreatorAndType({
                            'entityId': entityId,
                            'schoolType': 'province',
                          }).subscribe(res => {
                            if (res && res.body) {
                              const data = res.body;
                              if (data.length > 0) {
                                this.tokenService.set({
                                  ...this.tokenService.get(),
                                  bimProvinceSchoolId: data[0].id
                                });
                                // 已注册学校
                                // 不可再注册 可查看信息 可报名
                                this.navList[this.provinceSchoolNavIndex[0]].isReadOnly = true;
                                this.navList[this.provinceSchoolNavIndex[1]].isReadOnly = false;
                                this.navList[this.proinceNavIndex[0]].isReadOnly = false;
                              } else {
                                // 未注册学校
                                // 可注册 不可查看 不可报名
                                this.navList[this.provinceSchoolNavIndex[0]].isReadOnly = false;
                                this.navList[this.provinceSchoolNavIndex[1]].isReadOnly = true;
                                this.navList[this.proinceNavIndex[0]].isReadOnly = true;
                              }
                            }
                          });
                        } else {
                          this.provinceSchoolNavIndex.forEach(e => {
                            this.navList[e].isdisable = false;
                          });
                          this.navList[this.proinceNavIndex[0]].isReadOnly = false;
                        }
                        this.navList[this.proinceNavIndex[1]].isReadOnly = true;
                        this.cacheService.set('bimSchoolDetail', null);
                      }
                    }
                  });
                }
              }
            })
          }
        }
        this.id = id;
      }
    });
    if (token && token !== null && token !== '') {
      this.isLogin = true;
      if (userType === '1') {
        this.cacheService.set('bimTeamId', null);
        // 老师
        const bimSchoolId = this.tokenService.get().bimSchoolId;
        this.teamNavIndex.forEach(e => {
          this.navList[e].isdisable = false;
        });
        if (isNullOrUndefined(bimSchoolId)) {
          this.navList[1].isReadOnly = false;
        } else {
          this.navList[2].isReadOnly = false;
        }
        // 学校评委 校内作品评分未开始
        if (tokenVal.is_school_recommend !== 1) {
          this.navList[this.schoolRecNavIndex[0]].isdisable = false;
        }
      }
    }
  }

  ngOnInit() {
  }

  clickNav(item) {
    if (this.isLogin || !item.isNeedLogin) {
      this.routerNav(item);
    } else {
      this._loginUser(item);
    }
  }

  routerNav(item) {
    // this.currentNav = item;
    this.router.navigate([item.link]);
  }

  /**
   * 登陆
   */
  _loginUser(item) {
    this.modalService.confirm({
      nzTitle: '确认确定登录?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.modalHelper.static(UserLoginComponent, {  }, 600).subscribe((data: any) => {
          if (data) {
            this.msg.info('登陆成功');
            this.isLogin = true;
            // window.location.reload();
            this.routerNav(item);
          }
        });
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }
}
