import { Injectable } from '@angular/core';
import { ThsBaseService } from '@shared/components/thsBaseService';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
import { BimSchools } from './bim-school.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class  BimSchoolsService extends ThsBaseService<BimSchools>  {
  private bimTeamUrl = '';
  private bimTeamStudentUrl = '';
  private bimItemUrl = '';
  private bimInfoEventUrl = '';
  private bimInfoEventItemUrl = '';
  private bimAdvisorUrl = '';
  private bimInfoEventMutexUrl = '';
  private bimWebSchoolUrl = '';
  constructor(
    public http: HttpClient, httpAddressService: HttpAddressService ) {
    super(http, httpAddressService.BimServe + '/bim-schools');
    this.bimTeamUrl = httpAddressService.BimServe + `/bim-teams`;
    this.bimTeamStudentUrl = httpAddressService.BimServe + `/bim-team-students`;
    this.bimItemUrl = httpAddressService.BimServe + `/bim-items`;
    this.bimInfoEventUrl = httpAddressService.BimServe + `/bim-info-events`;
    this.bimInfoEventItemUrl = httpAddressService.BimServe + `/bim-info-events-items`;
    this.bimAdvisorUrl = httpAddressService.BimServe + `/bim-advisors`;
    this.bimInfoEventMutexUrl = httpAddressService.BimServe + `/bim-info-events-mutexes`;
    this.bimWebSchoolUrl = httpAddressService.BimWebServe + '/bim-schools';
  }

  // 注册学校
  registerSchoolInfo(param: any) {
    const url =  `${this.resourceUrl}/registerSchoolInfo`;
    return this.http.post<any>(url, param, { observe: 'response' });
  }

  // 根据id获取学校信息
  getBimSchoolById(id: string): Observable<any> {
    const url = `${this.resourceUrl}/getBimSchoolById/${id}`;
    return this.http.get(url).pipe(map((res: HttpResponse<any>) => this.convertDateFromServer(res)));
  }

  // 获取大赛赛项信息
  getBimInfoEventItem(queryParams ?: any): Observable<any> {
    return this.http.get<any>(this.bimInfoEventItemUrl, {params: queryParams ,  observe: 'response'})
    .pipe(map((res: HttpResponse<any>) => this.convertDateArrayFromServer(res)));
  }

  getBimItem(queryParams ?: any): Observable<any> {
    return this.http.get<any>(this.bimItemUrl, {params: queryParams ,  observe: 'response'})
    .pipe(map((res: HttpResponse<any>) => this.convertDateArrayFromServer(res)));
  }

  // 获取团队信息
  getBimTeam(queryParams ?: any): Observable<any> {
    return this.http.get<any>(this.bimTeamUrl, {params: queryParams ,  observe: 'response'})
    .pipe(map((res: HttpResponse<any>) => this.convertDateArrayFromServer(res)));
  }

  // 查询团队信息 参数-电话、赛项
  getTeamStudentByTelAndEventId(queryParams ?: any): Observable<any> {
    return this.http.get<any>(`${this.bimTeamStudentUrl}/getTeamStudentByTelAndEventId`, {params: queryParams ,  observe: 'response'})
    .pipe(map((res: HttpResponse<any>) => this.convertDateArrayFromServer(res)));
  }

  // 团队报名
  registerTeamInfo(param: any) {
    const url =  `${this.bimTeamUrl}/registerTeamInfo`;
    return this.http.post<any>(url, param, { observe: 'response' });
  }

  // 获取团队学生
  getBimTeamStudent(queryParams ?: any): Observable<any> {
    return this.http.get<any>(this.bimTeamStudentUrl, {params: queryParams ,  observe: 'response'})
    .pipe(map((res: HttpResponse<any>) => this.convertDateArrayFromServer(res)));
  }

  // 获取大赛赛项
  getBimInfoEvent(queryParams ?: any): Observable<any> {
    return this.http.get<any>(this.bimInfoEventUrl, {params: queryParams ,  observe: 'response'})
    .pipe(map((res: HttpResponse<any>) => this.convertDateArrayFromServer(res)));
  }

  // 根据id获取团队信息
  getBimTeamById(id: string): Observable<any> {
    const url = `${this.bimTeamUrl}/getBimTeamInfoById/${id}`;
    return this.http.get(url).pipe(map((res: HttpResponse<any>) => this.convertDateFromServer(res)));
  }

  // 根据赛项bimInfoEventsId获取专项信息
  getBimItemInfoByEventId(queryParams): Observable<any> {
    const url = `${this.bimInfoEventItemUrl}/getBimItemInfoByEventId`;
    return this.http.get(url, {params: queryParams ,  observe: 'response'});
  }

  // 获取全国评委
  getBimAdvisor(queryParams ?: any): Observable<any> {
    return this.http.get<any>(this.bimAdvisorUrl, {params: queryParams ,  observe: 'response'})
    .pipe(map((res: HttpResponse<any>) => this.convertDateArrayFromServer(res)));
  }

  // 获取大赛专项互斥内容
  getMutexNameByEventId(queryParams: any): Observable<any> {
    return this.http.get<any>(`${this.bimInfoEventMutexUrl}/getMutexNameByEventId`, {params: queryParams, observe: 'response'});
  }

  createBimSchools(record): Observable<HttpResponse<any>> {
      const copy = this.convertDateFromClient(record);
      return this.http.post<any>(this.bimWebSchoolUrl, copy, { observe: 'response'})
      .pipe(map((res: HttpResponse<any>) => this.convertDateFromServer(res)));
  }

  // 获取大赛赛项信息
  getBimInfoEventById(id: string): Observable<any> {
    const url = `${this.bimInfoEventUrl}/${id}`;
    return this.http.get(url).pipe(map((res: HttpResponse<any>) => this.convertDateFromServer(res)));
  }

  getAllMutexDataPageList(param: any) {
      return this.http.get<any[]>(`${this.bimInfoEventMutexUrl}/getAllDataPageList`, { params: param, observe: 'response' })
          .pipe(map((res: HttpResponse<any[]>) => this.convertDateArrayFromServer(res)));
  }

  // 省赛团队报名
  registerProvinceTeamInfo(param: any) {
    const url =  `${this.bimTeamUrl}/registerProvinceTeamInfo`;
    return this.http.post<any>(url, param, { observe: 'response' });
  }

  // 获取团队信息
  getProvinceTeamInfo(queryParams ?: any): Observable<any> {
    return this.http.get<any>(`${this.bimTeamUrl}/getProvinceTeamInfo`, {params: queryParams ,  observe: 'response'});
  }

  // 省赛团队修改带队老师
  saveProvinceLeader(param: any) {
    const url =  `${this.bimTeamUrl}/saveProvinceLeader`;
    return this.http.post<any>(url, param, { observe: 'response' });
  }

  // 省赛团队修改团队信息
  saveProvinceTeam(param: any) {
    const url =  `${this.bimTeamUrl}/saveProvinceTeam`;
    return this.http.post<any>(url, param, { observe: 'response' });
  }
  
  // 根据id获取学校信息
  getBimSchoolByCreatorAndType(param: any): Observable<any> {
    const url = `${this.resourceUrl}/getBimSchoolByCreatorAndType`;
    return this.http.get(url, {params: param ,  observe: 'response'}).pipe(map((res: HttpResponse<any>) => this.convertDateFromServer(res)));
  }
}
