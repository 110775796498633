import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BimCertificationComponent } from './bim-certification/bim-certification.component';
import { CertificationComponent } from './certification.component';
import { DetailComponent } from './detail/detail.component';
import { ListComponent } from './list/list.component'; import { BimCertificationConfirmComponent } from './bim-certification/bim-certification-confirm/bim-certification-confirm.component';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: '', component: CertificationComponent, data: { title: '认证' } },
      // 认证列表页
      { path: 'list', component: ListComponent, data: { title: '认证列表页' } },
      // 认证详情页
      { path: 'detail', component: DetailComponent, data: { title: '认证详情页' } },
      // 免考认证报名页
      { path: 'bimCertification', component: BimCertificationComponent, data: { title: '免考认证报名页' } },
      { path: 'bimCertificationConfirm', component: BimCertificationConfirmComponent, data: { title: '免考认证报名确认页' } },
      {
        path: 'certificate',
        loadChildren: './certificate-index/certificate.module#CertificateModule',
        data: { title: '证书' },
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CertificationRoutingModule { }
