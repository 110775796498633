import { Component, OnInit } from '@angular/core';
import { CacheService } from '@delon/cache';
import { isNullOrUndefined } from 'util';
import { Router } from '@angular/router';
import { HomeService } from '../home/home.service';
import { ThsadminFileService } from '@shared/components/f-upload/thsadmin-file.service';
import { HttpAddressService } from '@shared/session/http-address.service';
import * as OSS from 'ali-oss';
import { ModalHelper } from '@delon/theme';
import { PreviewImgVideoComponent } from '@shared/components/preview-imgvideo/preview-imgvideo.component';
import { PreviewImgVideo } from '@shared/components/preview-imgvideo/preview-imgvideo.model';

@Component({
  selector: 'app-game-style',
  templateUrl: './game-style.component.html',
  styleUrls: ['./game-style.component.less'],
})
export class GameStyleComponent implements OnInit {
  menuItems = [];
  selectedMenuItem: number | null = 0;
  curData = {};
  dataAllList = [];
  imagePrefix = '';
  isLoading = true;
  isLoadingContent = true;
  public ossClient;
  
  public bimInfoId = null;
  public bimInfo = null;


  constructor(
    private homeService: HomeService,
    private thsadminFileService: ThsadminFileService,
    private router: Router,
    private cacheService: CacheService,
    private modalHelper: ModalHelper,
    private httpAddressService: HttpAddressService) {

      this.bimInfo = this.cacheService.get('bimInfo')['value'];
      
      this.imagePrefix = this.httpAddressService.apiGateway + '/thsadmin/api/sys-files/download/';
      
      this.ossClient = new OSS(this.httpAddressService.ossServe);
    }

  
  contentType = 'bimCompetitionMien';
  typeList = new Map<any,any[]>();

  ngOnInit() {
    if (isNullOrUndefined(this.bimInfo)) {
      // this.router.navigate(['/enroll/contestSelect']);
      this.router.navigate(['/enroll/contest']);
      return;
    }

    this.bimInfoId = this.bimInfo.id;

    this.getContestStyles();
  }

  getContestStyles(){
    this.menuItems = [];
    this.typeList = new Map();
    const params = {
      'bimInfoId.equals': this.bimInfoId,
      'contenttype.equals': this.contentType,
      'isDelete.equals': '0',//是否启用
      page: 0,
      size: 2000,
      sort: ['istop,desc','isreport,asc','hit,asc', 'createTime,desc'],
    };
    console.log(params);
    this.homeService.getThsNews(params).subscribe(res => {
      this.dataAllList = res.body;
      const map = new Map<string,any[]>();
      if(this.dataAllList){
        this.dataAllList.forEach((item,index) => {
          let datas = [];
          const key = item.title;
          if(map.has(key)){
            datas = map.get(key);
          }
          datas.push(item);
          map.set(key,datas);
        });
      }
      if(map.size > 0){
        const keys = Array.from(map.keys());
        const datas = map.get(keys[0]);
        const menuDatas = [];
        if(datas && datas.length > 0){
          datas.forEach(w=>{
            menuDatas.push(w);
          })
        }
        this.menuItems = menuDatas;

        this.curData = menuDatas[0];
        this.selectedMenuItem = 0;
        this.getFileList();
      }
      this.typeList = map;
      this.isLoading = false;
      
    });
  }

  
  changeTab(tab){
    const menuDatas = [];
    const datas = this.typeList.get(tab.tab._title);
    if(datas && datas.length > 0){
      datas.forEach(w=>{
        menuDatas.push(w);
      })
      this.curData = menuDatas[0];
      this.selectedMenuItem = 0;
      this.getFileList();
    }
    
    this.menuItems = menuDatas;
    
  }


  selectMenuItem(index: number,item): void {
    this.selectedMenuItem = index;
    this.curData = item;
    this.getFileList();
  }


  getFileList(){
    this.isLoadingContent = true;
    const fileList = this.curData['fileList'];
    if(fileList && fileList.length > 0){
      this.isLoadingContent = false;
      return;
    }
    const params = {
      'fileFk.equals': this.curData['id'],
      'extField1.equals': this.contentType,
      page: 0,
      size: 1000,
      sort:['createdDate,asc','extField5,asc']
    }
    
    this.thsadminFileService.getSysFile(params).subscribe(res => {
      this.isLoadingContent = false;
      const data = res.body;
      const videoIds = [];
      if(data){
        data.forEach(item => {
          if(this.curData['edit'] === 'Video'){
            videoIds.push(item.id);
          }
          if (item.extField2 === 'AliOss') {
            item.imgUrl = this.ossClient.signatureUrl(item.fileSavePath);
          } else {
            item.imgUrl = this.httpAddressService.apiGateway + '/thsadmin/api/sys-files/download/' + item.id;
          }
        });
        
      }
      if(videoIds && videoIds.length > 0){
        //获取视频封面
        const params2 = {
          'fileFk.in': videoIds.join(','),
          page: 0,
          size: 2000,
          sort:['createdDate,desc']
        }

        this.thsadminFileService.getSysFile(params2).subscribe(res => {
          const result = res.body;
          if(result){
            result.forEach(item => {
              const selDatas = data.filter(w=>w.id === item.fileFk);
              if(selDatas != null && selDatas.length > 0){
                if (item.extField2 === 'AliOss') {
                  selDatas[0]['coverImgUrl'] = this.ossClient.signatureUrl(item.fileSavePath);
                } else {
                  selDatas[0]['coverImgUrl'] = this.httpAddressService.apiGateway + '/thsadmin/api/sys-files/download/' + item.id;
                }
              }
            });
          }
        })
      }
      
      this.curData['fileList'] = data;
    })
  }
  
  reback(){
    this.router.navigate(['/enroll/contestStyleIndex']);
  }

  previews(index,item){
    
    const dataList = this.curData['fileList'];
    const fileList:PreviewImgVideo[] = [];
    if(dataList && dataList.length > 0){
      dataList.forEach(item => {
        fileList.push(new PreviewImgVideo(item.id, item.fileName,item.extField5,item.imgUrl,item.fileType,item.coverImgUrl,item.fileDesc));
      });
    }
    let fileType = 'image';
    if(this.curData['edit'] === 'Video'){
      fileType = 'video';
    }
    this.modalHelper.open(PreviewImgVideoComponent, { fileType:fileType,title: (this.bimInfo ? this.bimInfo.name : ''), dataList: fileList,curPreviewIndex: index }, 1000, { nzClassName: 'preview-imgvideo-modal' }).subscribe(() => {
      
    });
  }
}
