import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
import { ThsBaseService } from '@shared/components/thsBaseService';

@Injectable({
  providedIn: 'root'
})
export class HomeService extends ThsBaseService<any> {
  private bimAdvertiseUrl = '';
  private bimInfoQqUrl = '';
  private bimSchoolUrl = '';
  private bimInfoUrl = '';
  private sysFilesUrl = '';
  private thsNewUrl = '';
  private bimInfoEventUrl = '';
  private bimInfoProcessUrl = '';
  private bimInfoProcessNodeUrl = '';
  private bimInfoCompanyUrl = '';//联系方式

  constructor(
    public http: HttpClient,
    private httpAddressService: HttpAddressService,
  ) {
    super(http, httpAddressService.BimServe);
    this.thsNewUrl = this.httpAddressService.BimWebServe + '/ths-news';
    this.bimInfoUrl = this.httpAddressService.BimWebServe + '/bim-infos';
    this.bimInfoQqUrl = this.httpAddressService.BimWebServe + '/bim-info-qqs';
    this.bimAdvertiseUrl = this.httpAddressService.BimWebServe + '/bim-info-advertisements';
    this.bimSchoolUrl = this.httpAddressService.BimWebServe + '/bim-schools';
    this.sysFilesUrl = httpAddressService.FileServer + '/sys-files';
    this.bimInfoEventUrl = httpAddressService.BimWebServe + `/bim-info-events`;
    this.bimInfoProcessUrl = httpAddressService.BimWebServe + `/bim-info-processes`;
    this.bimInfoProcessNodeUrl = httpAddressService.BimWebServe + `/bim-info-process-nodes`;
    this.bimInfoCompanyUrl = httpAddressService.BimWebServe + `/bim-info-companies`;
  }

  //  查询bim大赛的数据
  getBimInfos(queryParams?: any) {
    this.resourceUrl = this.bimInfoUrl;
    return this.query(queryParams);
  }

  //  查询bim大赛的数据(含单位信息和标签信息)
  getAllContestInfos(queryParams?: any) {
    this.resourceUrl = this.bimInfoUrl + '/getAllContestInfos';
    return this.query(queryParams);
  }

  /**
   * 获取省份数据
   * @returns 
   */
  getAllProvinceList(){
    this.resourceUrl = this.bimInfoUrl + '/getProvinceList';
    return this.query();
  }

  //  查询bim大赛qq的数据
  getBimQqs(queryParams?: any) {
    this.resourceUrl = this.bimInfoQqUrl;
    return this.query(queryParams);
  }

  //  查询bim大赛发文的数据
  getThsNews(queryParams?: any) {
    this.resourceUrl = this.thsNewUrl;
    return this.query(queryParams);
  }

  getBimAdversise(queryParams?: any) {
    this.resourceUrl = this.bimAdvertiseUrl;
    return this.query(queryParams);
  }

  // 获取bim大赛报名学校
  getBimSchool(queryParams?: any) {
    this.resourceUrl = this.bimSchoolUrl;
    return this.query(queryParams);
  }

  getThsNewsById(id) {
    this.resourceUrl = this.thsNewUrl;
    return this.find(id);
  }

  updateThsNew(record) {
    this.resourceUrl = this.thsNewUrl;
    return this.update(record);
  }

  getAllFiles(queryParams) {
    this.resourceUrl = `${this.sysFilesUrl}/getAllSysFile`;
    console.log(this.resourceUrl);
    return this.query(queryParams);
  }

  getBimInfoEnent(queryParams?: any) {
    this.resourceUrl = this.bimInfoEventUrl;
    return this.query(queryParams);
  }

  getBimInfoProcess(queryParams?: any) {
    this.resourceUrl = this.bimInfoProcessUrl;
    return this.query(queryParams);
  }

  getBimInfoProcessNode(queryParams?: any) {
    this.resourceUrl = this.bimInfoProcessNodeUrl;
    return this.query(queryParams);
  }

  // 获取bim大赛报名学校
  getProvinceSchoolInfoPage(queryParams?: any) {
    this.resourceUrl = `${this.bimSchoolUrl}/getProvinceSchoolInfoPage`;
    return this.query(queryParams);
  }

  /**
   * 获取赛事联系方式（联系人和相关链接）
   * @param queryParams 
   * @returns 
   */
  getBimInfoContactData(queryParams?:any){
    this.resourceUrl = `${this.bimInfoCompanyUrl}/getBimInfoContactInfos`;
    return this.query(queryParams);
  }

}
