import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ThsBaseService } from '@shared/components/thsBaseService';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EduSoftwareDownloadService extends ThsBaseService<any> {
  constructor(http: HttpClient, httpAddressService: HttpAddressService) {
    super(http, httpAddressService.EduServe + '/edu-software-downloads');
}

    /** POST: 添加一条新记录 */
    addEduSoftwareDownload (record: any): Observable<HttpResponse<any>> {
        const copy = this.convertDateFromClient(record);
        return this.http.post<any>(this.resourceUrl + '/add' , copy, { observe: 'response'})
        .pipe(map((res: HttpResponse<any>) => this.convertDateFromServer(res)));
    }

}
