import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpResponse } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { HttpAddressService } from '@shared/session/http-address.service';
import { NzMessageService } from 'ng-zorro-antd';
import { catchError } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class HomeService {
  private edubannersurl = '';
  private educourseclassifies = '';
  private educoursestop4 = '';
  private edubannersurllist = '';
  private bimInfoUrl = '';
  private bimInfoEventUrl = '';
  private eduLiveBroadcast='';
  private educoursestop8  ='';
  private bimInfoEventItemUrl = '';
  private bimItemUrl = '';
  private bimInfoEventMutexUrl = '';
  constructor(
    private http: HttpClient,
    private httpAddressService: HttpAddressService,
    private messageService: NzMessageService
  ) {
    this.edubannersurl = this.httpAddressService.EduWebServe + '/edu-banner-img';
    this.educourseclassifies = this.httpAddressService.EduWebServe + '/edu-course-classifies';
    this.educoursestop4 = this.httpAddressService.EduWebServe + '/edu-courses/top4';
    this.educoursestop8 = this.httpAddressService.EduWebServe + '/edu-courses/top8';
    this.edubannersurllist = this.httpAddressService.apiGateway + '/thsadmin/api/sys-files';
    this.bimInfoUrl = this.httpAddressService.BimWebServe + `/bim-infos`;
    this.bimInfoEventUrl = this.httpAddressService.BimWebServe + `/bim-info-events`;
    this.eduLiveBroadcast = this.httpAddressService.BimWebServe + '/edu-live-broadcasts';
    this.bimInfoEventItemUrl = this.httpAddressService.BimWebServe + `/bim-info-events-items`;
    this.bimItemUrl = this.httpAddressService.BimWebServe + `/bim-items`;
    this.bimInfoEventMutexUrl = this.httpAddressService.BimWebServe + `/bim-info-events-mutexes`;
  }

  //  查询banner图片的数据
  getbanners(pamms: any) {
    const url = `${this.edubannersurl}`;
    return this.http.get<any>(url, { params: pamms, observe: 'response' }).pipe(catchError(this.handleError<any>('getbanners')));
  }

  // 获取教育云课程分类
  geteducourseclassifies() {
    const url = `${this.educourseclassifies}`;
    return this.http.get<any>(url, { observe: 'response' })
      .pipe(catchError(this.handleError<any>('geteducourseclassifies')));
  }
  // 获取分类前4条
  geteducoursestop4(copyParams: any) {
    const url = `${this.educoursestop4}`;
    return this.http.get<any>(url, { params: copyParams, observe: 'response' })
      .pipe(catchError(this.handleError<any>('geteducoursestop4')));
  }


    // 获取分类前8条
    geteducoursestop8(copyParams: any) {
      const url = `${this.educoursestop8}`;
      return this.http.get<any>(url, { params: copyParams, observe: 'response' })
        .pipe(catchError(this.handleError<any>('geteducoursestop8')));
    }
  

  
    // 直播管理的分页数据
    getAllEduLiveBroadcasts(queryParams?: any) {

      const url = `${this.eduLiveBroadcast}`;
      return this.http.get<any>(url, { params: queryParams, observe: 'response' })
      .pipe(catchError(this.handleError<any>('getAllEduLiveBroadcasts')));

    }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for module consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a ModuleService message with the MessageService */
  private log(message: string) {
    // this.messageService.info('ModuleService: ' + message);
    console.log('indexService: ' + message);
  }

  getBimInfoEvent(queryParams?: any) {
    const url = `${this.bimInfoEventUrl}`;
    return this.http.get<any>(url, { params: queryParams, observe: 'response' })
      .pipe(catchError(this.handleError<any>('getBimInfoEvent')));
  }


  //  查询bim大赛发文的数据
  getThsNews(queryParams?: any) {
    let url =this.httpAddressService.BimWebServe + '/ths-news';
    return this.http.get<any>(url, { params: queryParams, observe: 'response' })
    .pipe(catchError(this.handleError<any>('getThsNews')));
  }

    
  //  查询bim大赛的数据
  getBimInfos(queryParams?: any) {
    return this.http.get<any>(this.bimInfoUrl, { params: queryParams, observe: 'response' })
    .pipe(catchError(this.handleError<any>('getBimInfos')));
  }



/**
 * 根据父节点ID获取所有字典值
 * @param pid 
 */
    getDictDetailsByPid(pid: string) {
      let url =this.httpAddressService.EduWebServe + `/edu-resoures/getDictDetailsByPid/${pid}`;
      return this.http.get<any>(url).pipe(catchError(this.handleError<any>('getDictDetailsByPid')));
    }

    /**
     * 根据value2的值获取所有字典值
     * @param pid 
     */
    getDictDetailsByValue2(value2: string) {
      let url =this.httpAddressService.EduWebServe + `/edu-resoures/getDictDetailsByValue2/${value2}`;
      return this.http.get<any>(url).pipe(catchError(this.handleError<any>('getDictDetailsByValue2')));
    }


        /**
     * 获取通过子分类获取前8条课程
     * @param copyParams 
     */
    top8ByCourseTypeId(copyParams: any) {
      let url =this.httpAddressService.EduWebServe + `/edu-courses/top8ByCourseTypeId`;
      return this.http.get<any>(url, { params: copyParams, observe: 'response' })
        .pipe(catchError(this.handleError<any>('top8ByCourseTypeId')));
    }
    
    // 获取大赛赛项信息
    getBimInfoEventItem(queryParams ?: any): Observable<any> {
      return this.http.get<any>(this.bimInfoEventItemUrl, {params: queryParams ,  observe: 'response'});
    }

    getBimItem(queryParams ?: any): Observable<any> {
      return this.http.get<any>(this.bimItemUrl, {params: queryParams ,  observe: 'response'});
    }

    getAllMutexDataPageList(param: any) {
        return this.http.get<any[]>(`${this.bimInfoEventMutexUrl}/getAllDataPageList`, { params: param, observe: 'response' });
    }
  
}
