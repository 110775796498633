import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../../../shared/shared.module';
import { UtilsModule } from '../../../shared/utils/utils.module';
import { CertificateRoutingModule} from './certificate-routing.module';
import {CertificateIndexComponent } from './certificate-index.component'
import {CertificateComponent } from '../certificate/certificate'

const COMPONENTS = [
  CertificateIndexComponent,
  CertificateComponent
];
const COMPONENTS_NOROUNT = [];

@NgModule({
  imports: [SharedModule, UtilsModule, CommonModule, CertificateRoutingModule],
  declarations: [...COMPONENTS, ...COMPONENTS_NOROUNT],
  providers: [],
  entryComponents: COMPONENTS_NOROUNT,
})
export class CertificateModule { }
