import { PipeTransform, Pipe } from '@angular/core';

@Pipe({name: 'keyvalue'})
export class KeyValuePipe implements PipeTransform {
  transform(value, args: Map<any,any>): any {
    const keys = [];
    const values = Array.from(value.keys());
    
    if(values){
      values.forEach(key => {
        keys.push({
          key: key,
          value: value.get(key)
        });
      })
    }
    return keys;
  }
}
