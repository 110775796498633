import { Component, Inject, Input, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { HttpAddressService } from '@shared/session/http-address.service';
import { NzMessageService } from 'ng-zorro-antd';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.html',
  styleUrls: ['./certificate.less'],
})
export class CertificateComponent implements OnInit {

  @Input() certProductInfo = null;

  public certFileList = [];
  public bimCertFilePdf = 'BIM_CERT_FILE_PDF';
  public bimCertFileImg = 'BIM_CERT_FILE_IMG';

  previewAddress: string;
  public certFilePreviewUrl = null;

  public certType = null;
  public certValidUrl = 'https://www.ccen.com.cn/zscx.jsp?urltype=tree.TreeTempUrl&wbtreeid=1154';

  constructor(
    private msg: NzMessageService,
    private httpAddressService: HttpAddressService,
  ) {
    this.previewAddress = httpAddressService.apiGateway + '/thsadmin/api/sys-files/preview/';
  }

  ngOnInit(): void {
    console.log(this.certProductInfo);
    
    if (this.certProductInfo !== null) {
      this.certType = this.certProductInfo.certType;
      if (this.certType === 'autodesk') {
        this.certValidUrl = 'https://www.adsk-certification.cn/certificate_search/user_cert.php';
      }
      if(this.certType === '1XLevelCertificate'){
        //1+X证书
        this.certValidUrl = 'http://jndj.osta.org.cn';
      }
      if(!isNullOrUndefined(this.certProductInfo.fileList)){
        this.certFileList = this.certProductInfo.fileList;
        this.certFileList.forEach(e => {
          if (e.extField1 === this.bimCertFileImg) {
            this.certFilePreviewUrl = this.previewAddress + e.id;
          }
        });
      }
    }
  }

  validCert() {
    const a = document.createElement('a');
    a.href = this.certValidUrl;
    a.target = '_blank';
    a.click();
  }

  downFile = (certType) => {
    console.log(certType);
    const fileList = this.certFileList.filter(e => e.extField1 === certType);
    if (fileList && fileList.length > 0) {
      const downFileId = fileList[0].id;
      const a = document.createElement('a');
      const url =  environment.SERVER_URL + '/thsadmin/api/sys-files/download/' + downFileId;
      const filename = fileList[0].fileName;
      a.href = url;
      a.download = filename;
      a.click();
    } else {
      this.msg.warning('未上传此类型证书，不可下载！');
    }
  }

}
