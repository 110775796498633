import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../../../shared/shared.module';
import { UtilsModule } from '../../../shared/utils/utils.module';
import { CompetitionRoutingModule } from './competition-routing.module';
import { PersonalIndexComponent } from './personal-index/personal-index.component';
import { CompetSignUpComponent } from './sign-up/sign-up.component';
import { CompetSignUpCityComponent } from './sign-up-city/sign-up.component';
import { CompetMyEntryCityComponent } from './my-entry-city/my-entry.component';
import { CompetPersonEditComponent } from './edit-person/edit.component';

const COMPONENTS = [
  PersonalIndexComponent,
  CompetSignUpComponent,
  CompetSignUpCityComponent,
  CompetMyEntryCityComponent,
];
const COMPONENTS_NOROUNT = [
  CompetPersonEditComponent,
  // PersonEditResultComponent,
];

@NgModule({
  imports: [SharedModule, UtilsModule, CommonModule, CompetitionRoutingModule],
  declarations: [...COMPONENTS, ...COMPONENTS_NOROUNT,],
  providers: [],
  entryComponents: COMPONENTS_NOROUNT,
})
export class CompetitionModule { }
