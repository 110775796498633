import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd';

@Component({
  selector: 'passport-register-result',
  templateUrl: './register-result.component.html'
})
export class UserRegisterResultComponent {
  titleMsg = '你的账户注册成功';
  type = true;
  constructor(
    public msg: NzMessageService,
    private routerInfo: ActivatedRoute,
    ) {
      const tmp = this.routerInfo.snapshot.queryParams['result'] + '';
      if (tmp !== '成功'){
        this.titleMsg = tmp;
      }
      if (tmp.indexOf('用户中心冲突') !== -1) {
        this.type = false;
      }

  }
}
