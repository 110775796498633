import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@env/environment';
import { HttpAddressService } from '@shared/session/http-address.service';
import { isNullOrUndefined } from 'util';
import { HomeService } from '../home.service';

@Component({
  selector: 'app-enterprise-dynamic-detail',
  templateUrl: './dynamic-detail.component.html',
  styleUrls: ['./dynamic-detail.component.less'],
})
export class DynamicDetailComponent implements OnInit {

  bimDynamicId = '';
  data = null;
  fileList = [];

  conentType = '';


  constructor(
    private homeService: HomeService,
    private routeInfo: ActivatedRoute,
    private httpAddressService: HttpAddressService,
  ) {
    this.bimDynamicId = this.routeInfo.snapshot.queryParams['id'];
    this.conentType = this.routeInfo.snapshot.queryParams['type'];
  }

  ngOnInit(): void {
    this.initData();
  }

  initData() {
    if (!isNullOrUndefined(this.bimDynamicId)) {
      this.homeService.getThsNewsById(this.bimDynamicId).subscribe(res => {
        if (res && res.body) {
          this.data = res.body;
          if (this.data) {
            const hit = isNullOrUndefined(this.data.hit) ? 1 : this.data.hit + 1;
            this.data.hit = hit;
            this.homeService.updateThsNew(this.data).subscribe();
          }
        }
      });
      this.homeService.getAllFiles({
        'fileFk.equals': this.bimDynamicId,
        'extField1.equals': this.conentType
      }).subscribe(res => {
        this.fileList = res.body;
      });
    }
  }


  downFile(fileId) {
    const a = document.createElement('a');
    const url =  this.httpAddressService.apiGateway + '/thsadmin/api/sys-files/download/' + fileId;
    a.href = url;
    a.click();
  }

}
